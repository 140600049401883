import {
  GameCreative,
  InputBestPerformanceCreativeOrderByFieldEnum,
} from '__generated__/gql/graphql'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { CreativeInspirationFilter } from 'pages/ads-inspiration/type'

import { CountryGroup } from './useCreateAdsStore'

interface CreativesState {
  bookmarkedInspiration: string[]
  filter: CreativeInspirationFilter
  runningCreatives: GameCreative[]
  bestPerformingCreatives: GameCreative[]
  bestPerformingCreativesLoading: boolean
  bestPerformingCreativesFilterCountry: CountryGroup
  bestPerformingCreativesFilterSort: InputBestPerformanceCreativeOrderByFieldEnum
  creativePublishTime: string | null

  setBookmarkedInspiration: (ids: string[]) => void
  setFilter: (filter: CreativeInspirationFilter) => void
  setRunningCreatives: (creatives: GameCreative[]) => void
  setBestPerformingCreatives: (creatives: GameCreative[]) => void
  setBestPerformingCreativesLoading: (bestPerformingCreativesLoading: boolean) => void
  setBestPerformingCreativesFilterCountry: (country: CountryGroup) => void
  setBestPerformingCreativesFilterSort: (
    sortBy: InputBestPerformanceCreativeOrderByFieldEnum,
  ) => void
  setCreativePublishTime: (time: string | null) => void
  resetState: () => void
}

const useCreativesStore = create<CreativesState>()(
  persist(
    (set) => ({
      bookmarkedInspiration: [],
      filter: {
        country: {} as CountryGroup,
        bookmark: false,
        channel: [],
        orientation: [],
        mediaType: [],
        tags: [],
      },
      runningCreatives: [],
      bestPerformingCreatives: [],
      bestPerformingCreativesLoading: false,
      bestPerformingCreativesFilterCountry: {} as CountryGroup,
      bestPerformingCreativesFilterSort: InputBestPerformanceCreativeOrderByFieldEnum.Clicks,
      creativePublishTime: null,

      setBookmarkedInspiration: (ids: string[]) =>
        set((state) => ({ ...state, bookmarkedInspiration: ids })),
      setFilter: (filter: CreativeInspirationFilter) =>
        set((state) => ({ ...state, filter: filter })),
      setRunningCreatives: (creatives: GameCreative[]) =>
        set((state) => ({ ...state, runningCreatives: creatives })),
      setBestPerformingCreatives: (creatives: GameCreative[]) =>
        set((state) => ({ ...state, bestPerformingCreatives: creatives })),
      setBestPerformingCreativesFilterCountry: (country: CountryGroup) =>
        set((state) => ({ ...state, bestPerformingCreativesFilterCountry: country })),
      setBestPerformingCreativesFilterSort: (
        sortBy: InputBestPerformanceCreativeOrderByFieldEnum,
      ) => set((state) => ({ ...state, bestPerformingCreativesFilterSort: sortBy })),
      setBestPerformingCreativesLoading: (bestPerformingCreativesLoading: boolean) =>
        set((state) => ({ ...state, bestPerformingCreativesLoading })),
      setCreativePublishTime: (time: string | null) =>
        set((state) => ({ ...state, creativePublishTime: time })),
      resetState: () =>
        set(() => ({
          bookmarkedInspiration: [],
          runningCreatives: [],
          filter: {
            country: {} as CountryGroup,
            bookmark: false,
            channel: [],
            orientation: [],
            mediaType: [],
            tags: [],
          },
          bestPerformingCreatives: [],
          creativePublishTime: null,
        })),
    }),
    {
      name: 'creatives-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useCreativesStore
