import { Flex, Typography } from 'antd'

import IconLib from 'components/commons/icon-lib'

import colors from 'theme/colors'

import { IHeaderInfoItem } from './type'

const { Text } = Typography
export default function HeaderInfoItem({ icon, label }: IHeaderInfoItem) {
  return (
    <Flex align='center' gap={20} style={{ padding: '10px 0' }}>
      <IconLib icon={icon} size='base' />
      <Text style={{ fontSize: 12, fontWeight: 700, color: colors.gray90 }}>{label}</Text>
    </Flex>
  )
}
