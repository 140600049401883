import { AdType } from '__generated__/gql/graphql'
import { Card, Flex, message, Modal, Typography } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useMediationStore from 'stores/useMediationStore'
import useModalStore from 'stores/useModalStore'

import LabsButton from 'components/commons/labs-kit/button'
import MediationAdUnitPreview from 'components/modules/mediation/ad-unit-preview'

import colors from 'theme/colors'

import CreateAdUnitBanner from './banner'
import CreateAdUnitInterstitial from './interstitial'
import CreateAdUnitRewarderVideo from './rewarded-video'

const { Title, Text } = Typography

export default function CreateAdUnitModal() {
  const { t } = useTranslation()

  const { isCreateAdUnitModalShow, closeAll } = useModalStore()
  const { selectedAdType, tempAdUnit, setTempAdUnit } = useMediationStore()

  const [adUnitsName, setAdUnitsName] = useState<string[]>([''])
  const [isTouched, setIsTouched] = useState(false)

  const handleAdd = useCallback(() => {
    setAdUnitsName([...adUnitsName, ''])
  }, [adUnitsName])

  const handleCreate = useCallback(() => {
    if (!selectedAdType) return
    setTempAdUnit([...tempAdUnit, ...adUnitsName.map((name) => ({ name, type: selectedAdType }))])
    closeAll()
    message.success('Ad unit has been added')
    setAdUnitsName([''])
  }, [tempAdUnit, adUnitsName, selectedAdType])

  const hasDuplicateName = useMemo(() => {
    return adUnitsName.length !== new Set(adUnitsName).size
  }, [adUnitsName])

  const hasBlankName = useMemo(() => {
    return adUnitsName.some((adUnit) => adUnit === '')
  }, [adUnitsName])

  const handleChange = useCallback(
    (adUnits: string[]) => {
      if (!isTouched) setIsTouched(true)
      setAdUnitsName(adUnits)
    },
    [isTouched],
  )

  if (!isCreateAdUnitModalShow || !selectedAdType) return null
  return (
    <Modal
      centered
      width={1100}
      open={isCreateAdUnitModalShow}
      title={
        <Title level={5} style={{ fontWeight: 700, margin: 0, color: colors.gray90 }}>
          {t('mediation_create_ad_unit_modal_title')}
        </Title>
      }
      onCancel={closeAll}
      footer={false}>
      <Flex gap={33}>
        <Flex vertical align='center' gap={20} style={{ padding: '24px 24px 0px' }}>
          {selectedAdType === AdType.InterstitialAds && (
            <CreateAdUnitInterstitial adUnitsName={adUnitsName} setAdUnitsName={handleChange} />
          )}
          {selectedAdType === AdType.RewardedVideoAds && (
            <CreateAdUnitRewarderVideo adUnitsName={adUnitsName} setAdUnitsName={handleChange} />
          )}
          {selectedAdType === AdType.BannerAds && (
            <CreateAdUnitBanner adUnitsName={adUnitsName} setAdUnitsName={handleChange} />
          )}

          <Card
            style={{
              borderStyle: 'dashed',
              backgroundColor: colors.navy10,
              width: '100%',
              marginBottom: 10,
            }}
            bodyStyle={{ padding: '8px 16px' }}>
            <Flex align='center' justify='center' gap={12}>
              <Text style={{ color: colors.gray70 }}>
                {t('mediation_create_ad_unit_modal_ad_more_label')}
              </Text>
              <LabsButton variant='outline' size='sm' onClick={handleAdd}>
                {t('mediation_create_ad_unit_modal_ad_more_button')}
              </LabsButton>
            </Flex>
          </Card>
          <LabsButton
            width='wide'
            onClick={handleCreate}
            isDisabled={hasDuplicateName || hasBlankName}>
            {t('mediation_create_ad_unit_modal_create_button')}
          </LabsButton>
          {hasDuplicateName && (
            <Text style={{ color: colors.red60, fontSize: 12 }}>*Ad Unit name must be unique</Text>
          )}
          {!hasDuplicateName && isTouched && hasBlankName && (
            <Text style={{ color: colors.red60, fontSize: 12 }}>*Ad Unit name must be filled</Text>
          )}
        </Flex>
        <Flex flex={0}>
          {selectedAdType === AdType.InterstitialAds && (
            <MediationAdUnitPreview mediaUrl='/videos/mediation-interstitial.mp4' />
          )}
          {selectedAdType === AdType.BannerAds && (
            <MediationAdUnitPreview mediaUrl='/videos/mediation-banner.mp4' />
          )}
          {selectedAdType === AdType.RewardedVideoAds && (
            <MediationAdUnitPreview mediaUrl='/videos/mediation-rewarded-video.mp4' />
          )}
        </Flex>
      </Flex>
    </Modal>
  )
}
