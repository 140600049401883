import { CopyOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { MediationsQuery, MediationsQueryVariables } from '__generated__/gql/graphql'
import { Flex, Input, Modal, Spin, Typography } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getMediationAdUnit } from 'services/graphql/queries'

import { handleCopyToClipboard } from 'utils/CommonUtils'

import useMediationStore from 'stores/useMediationStore'
import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import { labsOpenInNew } from 'components/commons/icon-lib/icons/base'
import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Title, Text } = Typography

export default function DetailAdUnitModal() {
  const { t } = useTranslation()

  const { isAdUnitDetailModalShow, closeAll } = useModalStore()
  const { selectedAdUnit } = useMediationStore()
  const { selectedGameStore } = useUserStore()

  const { data: mediationAdUnit, loading: mediationAdUnitLoading } = useQuery<
    MediationsQuery,
    MediationsQueryVariables
  >(getMediationAdUnit, {
    variables: {
      gameStoreId: selectedGameStore?.id as string,
    },
    skip: !selectedGameStore?.id || !isAdUnitDetailModalShow,
  })

  const mediationAppId = useMemo(() => {
    return mediationAdUnit?.mediations.length ? mediationAdUnit.mediations[0].appId : '-'
  }, [mediationAdUnit])

  if (!isAdUnitDetailModalShow || !selectedAdUnit) return null
  return (
    <Modal
      centered
      width={660}
      open={isAdUnitDetailModalShow}
      title={
        <Title level={5} style={{ fontWeight: 700, margin: 0, color: colors.gray90 }}>
          {selectedAdUnit.adUnitName}
        </Title>
      }
      onCancel={closeAll}
      footer={false}>
      <Flex vertical gap={40} style={{ padding: '24px 24px 0px' }}>
        <Flex align='center' gap={30}>
          <Text style={{ width: 100, color: colors.gray90, fontSize: 12 }}>Ad Unit ID</Text>
          <Text style={{ fontSize: 16, color: colors.gray90, fontWeight: 500 }}>
            {selectedAdUnit.adUnitId || '-'}
          </Text>
        </Flex>
        <Flex vertical gap={18}>
          <Text style={{ color: colors.gray90, fontWeight: 500 }}>How to Implement</Text>
          <Flex align='center' gap={30}>
            <Text style={{ width: 100, color: colors.gray90, fontSize: 12 }}>App ID</Text>
            <Input
              suffix={
                mediationAdUnitLoading ? (
                  <Spin size='small' />
                ) : (
                  <CopyOutlined onClick={() => handleCopyToClipboard(mediationAppId)} />
                )
              }
              value={mediationAppId}
              disabled={true}
            />
          </Flex>
          <Text style={{ color: colors.gray70 }}>
            Integrating the Google Mobile Ads SDK into your app is the first step toward displaying
            ads and earning revenue. Once integrated, proceed to the next step in the AdMob
            Initialization section.
          </Text>
          <Text style={{ color: colors.gray70 }}>
            Please click the link below to access the documentation that will guide you through the
            SDK implementation.
          </Text>
          <LabsButton variant='outline' size='sm' icon={labsOpenInNew} iconPosition='end'>
            See Documentation
          </LabsButton>
        </Flex>
        <Flex justify='center'>
          <LabsButton width='wide' onClick={closeAll}>
            Okay
          </LabsButton>
        </Flex>
      </Flex>
    </Modal>
  )
}
