import { Flex, Typography } from 'antd'

import colors from 'theme/colors'

const { Text } = Typography
export default function DemoVersionTag() {
  return (
    <Flex
      justify='center'
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        width: 'fit-content',
        zIndex: 30,
      }}>
      <Flex
        justify='center'
        align='center'
        style={{
          height: 34,
          width: 138,
          position: 'relative',
        }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: 34,
            width: 138,
            background: 'linear-gradient(269deg, #7420E2 -3.56%, #FC238B 144.04%)',
            borderRadius: '0 0 10px 10px',
          }}></div>

        <Text style={{ color: colors.gray10, fontWeight: 500, position: 'relative' }}>
          Demo Version
        </Text>
      </Flex>
    </Flex>
  )
}
