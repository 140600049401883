import { LoadingOutlined } from '@ant-design/icons'
import { GamePublisherStatus } from '__generated__/gql/graphql'
import { Flex, Layout, Spin } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { CSSProperties, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import {
  ONBOARDING_ADD_GAME_ROUTE,
  ONBOARDING_INVITATION_ROUTE,
  WAITING_LIST_ROUTE,
} from 'constants/Routes'

import useGeneralStore from 'stores/useGeneralStore'
import useMenuStore, { Navigation } from 'stores/useMenuStore'
import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useUserInfo from 'hooks/useUserInfo'

import DemoVersionTag from 'components/commons/demo-version-tag'
import AuthenticatedHeader from 'components/commons/header'
import SideNavigation from 'components/commons/side-navigation'

import colors from 'theme/colors'

import { LayoutContext } from 'types/layout'
import { usePermissionContext } from 'types/permission'

const layoutStyle: CSSProperties = {
  backgroundColor: colors.gray10,
  padding: 0,
}

export default function AuthenticatedLayout({ children }: PropsWithChildren) {
  const { user, games } = useUserStore()
  const location = useLocation()
  const { getUserInfo, getGames } = useUserInfo()
  const { isAuthenticated } = usePermissionContext()
  const { showSetDisplayNameModal } = useModalStore()
  const { setIsExpanded } = useMenuStore()
  const { info } = useGeneralStore()

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const navigate = useNavigate()

  useEffect(() => {
    if (!user.roles || !user.roles.length) return
    if (user.gamePublisherStatus === GamePublisherStatus.WaitList) {
      navigate(`/${WAITING_LIST_ROUTE}`)
      return
    }

    if (user.roles.includes('admin') && info.showOnboard) {
      if (!games.length) navigate(`/${ONBOARDING_ADD_GAME_ROUTE}`)
      else navigate(`/${ONBOARDING_INVITATION_ROUTE}`)
      return
    }

    if (!user.displayName && !!games.length) showSetDisplayNameModal()
  }, [user, games])

  useEffect(() => {
    getUserInfo().then(() => getGames().finally(() => setIsLoading(false)))
  }, [])

  const authenticatedHeaderRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)
  const [authenticatedHeaderHeight, setAuthenticatedHeaderHeight] = useState<number>(0)
  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const [footerHeight, setFooterHeight] = useState<number>(0)

  useEffect(() => {
    const updateHeights = () => {
      if (authenticatedHeaderRef.current) {
        setAuthenticatedHeaderHeight(authenticatedHeaderRef.current.offsetHeight)
      }

      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight)
      }

      if (footerRef.current) {
        setFooterHeight(footerRef.current.offsetHeight)
      }
    }

    window.addEventListener('resize', updateHeights)
    updateHeights()

    return () => window.removeEventListener('resize', updateHeights)
  }, [])

  const { setActiveNavigation } = useMenuStore()

  useEffect(() => {
    const path = location.pathname.split('/')
    setActiveNavigation(path[1] as Navigation)
  }, [])

  useEffect(() => {
    if (window.innerWidth <= 1024) setIsExpanded(false)
  }, [])
  return (
    <Layout>
      {user.isDemo && <DemoVersionTag />}
      {(!location.pathname.includes('inquiry') || !isAuthenticated) && (
        <AuthenticatedHeader authenticatedHeaderRef={authenticatedHeaderRef} />
      )}
      <LayoutContext.Provider value={{ authenticatedHeaderHeight, headerHeight, footerHeight }}>
        <Layout>
          <SideNavigation />
          <Content
            style={{ ...layoutStyle, minHeight: `calc(100vh - ${authenticatedHeaderHeight}px)` }}>
            {isLoading ? (
              <Flex align='center' justify='center' style={{ height: '100%', width: '100%' }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
              </Flex>
            ) : (
              children
            )}
          </Content>
        </Layout>
      </LayoutContext.Provider>
    </Layout>
  )
}
