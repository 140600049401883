import { GameCreativeFormat } from '__generated__/gql/graphql'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { CreativeInspirationFilter } from 'pages/ads-inspiration/type'

import { CountryGroup } from './useCreateAdsStore'

export type View = 'grid' | 'list'
export type Tab = 'creatives' | 'headlines' | 'descriptions'
export interface SelectedCreative {
  mediaUrl: string
  mediaType: GameCreativeFormat
}

interface CreativesState {
  activeTab: Tab
  view: View
  filter: CreativeInspirationFilter

  setActiveTab: (tab: Tab) => void
  setView: (view: View) => void
  setFilter: (filter: CreativeInspirationFilter) => void

  selectedCreative: SelectedCreative | null
  setSelectedCreative: (creative: SelectedCreative | null) => void

  resetState: () => void
}

const useMyAdsStore = create<CreativesState>()(
  persist(
    (set) => ({
      activeTab: 'creatives',
      view: 'grid',
      filter: {
        country: {} as CountryGroup,
        bookmark: false,
        channel: [],
        orientation: [],
        mediaType: [],
        tags: [],
      },
      selectedCreative: null,

      setActiveTab: (tab: Tab) => set((state) => ({ ...state, activeTab: tab })),
      setView: (view: View) => set((state) => ({ ...state, view })),
      setFilter: (filter: CreativeInspirationFilter) =>
        set((state) => ({ ...state, filter: filter })),
      setSelectedCreative: (creative: SelectedCreative | null) =>
        set((state) => ({ ...state, selectedCreative: creative })),
      resetState: () =>
        set(() => ({
          activeTab: 'creatives',
          view: 'grid',
          filter: {
            country: {} as CountryGroup,
            bookmark: false,
            channel: [],
            orientation: [],
            mediaType: [],
            tags: [],
          },
          selectedCreative: null,
        })),
    }),
    {
      name: 'my-ads-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useMyAdsStore
