import { CSSProperties } from 'react'

import colors from 'theme/colors'

import { GameStoreTagStatus } from './type'

export const tagStyle: Record<GameStoreTagStatus, CSSProperties> = {
  ACTIVE: {
    color: colors.success70,
    backgroundColor: colors.success10,
    borderColor: colors.success20,
  },
  INACTIVE: {
    color: colors.red70,
    backgroundColor: colors.red10,
    borderColor: colors.red20,
  },
  NOT_INTEGRATED: {
    color: colors.gray70,
    backgroundColor: colors.gray20,
    borderColor: colors.gray30,
  },
}

export const statusLabel: Record<GameStoreTagStatus, string> = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  NOT_INTEGRATED: 'Integration Required',
}

export const statusTooltipLabel: Record<GameStoreTagStatus, string> = {
  ACTIVE: 'This game is active and accessible in any feature',
  INACTIVE: 'User has not completed payment for more than 2 months',
  NOT_INTEGRATED: 'This game needs to complete basic scale integration',
}
