import { FileImageOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client'
import {
  CreateActivityHistoriesMutation,
  CreateActivityHistoriesMutationVariables,
  CreateGameCreativesMutation,
  CreateGameCreativesMutationVariables,
  GameCreativeFormat,
  GameCreativeItemInput,
  GamePublisherBalanceMutationsQuery,
  GamePublisherBalanceMutationsQueryVariables,
  GamePublisherBalanceMutationStatus,
} from '__generated__/gql/graphql'
import { Alert, Button, Col, Flex, Image, Modal, Row, Space, Typography } from 'antd'
import idx from 'idx'
import moment from 'moment'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { createActivityHistories, createGameCreativesMutations } from 'services/graphql/mutations'
import { getBalanceMutation } from 'services/graphql/queries'

import { formatCurrency } from 'utils/FormatterUtils'

import useCreateAdsStore from 'stores/useCreateAdsStore'
import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useTracker from 'hooks/useTracker'

import CountryFlag from 'components/commons/country-flag'
import IconLib from 'components/commons/icon-lib'
import { labsEditOutline, labsInfoCircleOutline } from 'components/commons/icon-lib/icons/base'

import colors from 'theme/colors'

const { Text, Title, Paragraph } = Typography

export default function ReviewCreativesModal() {
  const { t } = useTranslation()
  const { selectedGame } = useUserStore()
  const client = useApolloClient()
  const {
    isReviewCreativesModalShow,
    closeAll,
    showCreativesSubmittingModal,
    showCreativesSubmittedModal,
    showTopupPendingModal,
    showTopupModal,
    showCreativeSetDateTime,
    showSetDailyBudgetModal,
    hideReviewCreativesModal,
  } = useModalStore()
  const { track } = useTracker()
  const { startDate, uploadCreatives, cleanState } = useCreateAdsStore()

  const uploadCreativesSummary = useMemo(() => {
    return uploadCreatives.filter((uploadCreative) => uploadCreative.creatives.length > 0)
  }, [uploadCreatives])

  const [uploadGameCreatives, { loading: uploadGameCreativesLoading }] = useMutation<
    CreateGameCreativesMutation,
    CreateGameCreativesMutationVariables
  >(createGameCreativesMutations)

  const [balanceMutation, { loading: balanceMutationLoading }] = useLazyQuery<
    GamePublisherBalanceMutationsQuery,
    GamePublisherBalanceMutationsQueryVariables
  >(getBalanceMutation, {
    variables: { status: GamePublisherBalanceMutationStatus.Pending },
    fetchPolicy: 'no-cache',
  })

  const [createActivities] = useMutation<
    CreateActivityHistoriesMutation,
    CreateActivityHistoriesMutationVariables
  >(createActivityHistories)

  const handleEditPublishDate = () => {
    closeAll()
    track('show_set_ads_publish_date', {
      type: 'click',
      location: 'review_created_ads_modal',
      location_type: 'icon',
    })
    showCreativeSetDateTime(true)
  }

  const handleEditDailyBudget = () => {
    closeAll()
    track('show_daily_budget_setup', {
      type: 'click',
      location: 'review_created_ads_modal',
      location_type: 'icon',
    })
    showSetDailyBudgetModal(true)
  }

  const handleCancel = () => {
    hideReviewCreativesModal()
  }

  const handleRunAds = () => {
    if (!startDate) return

    closeAll()
    showCreativesSubmittingModal()
    track('publish_ads_attempt', {
      type: 'click',
      location: 'review_created_ads_modal',
      location_type: 'button',
    })

    const gameCreatives: GameCreativeItemInput[] = []

    uploadCreativesSummary.forEach((creativeByCountry) => {
      creativeByCountry.creatives.forEach((creative) => {
        gameCreatives.push({
          format: creative.format,
          targetingCountryCodes: !creativeByCountry.country.isMultipleCountryGroup
            ? [creativeByCountry.country.code]
            : [],
          targetingCountryGroupCodes: creativeByCountry.country.isMultipleCountryGroup
            ? [creativeByCountry.country.code]
            : [],
          mediaUrl: creative.mediaUrl,
        })
      })
    })

    uploadGameCreatives({
      variables: {
        startDate: startDate,
        gameId: selectedGame.id,
        gameCreatives: gameCreatives,
      },
    })
      .then(() => {
        track('publish_ads_success', {
          type: 'success',
          location: 'review_created_ads_modal',
        })

        createActivities({
          variables: {
            activityHistories: [
              {
                key: `game_id_${selectedGame.id}_onboarding_marketing-onboard`,
                value: `publish-your-ads`,
              },
            ],
          },
        }).finally(() => {
          client.refetchQueries({
            include: ['GetActivityHistories'],
          })
        })

        closeAll()
        cleanState()
        showCreativesSubmittedModal()
      })
      .catch((err) => {
        const errorCode = idx(err, (_) => _.networkError.result.errors[0].extensions.code)
        closeAll()
        if (errorCode === 'GAME_CREATIVE_INSUFFICIENT_GAME_PUBLISHER_BALANCE') {
          balanceMutation().then((res) => {
            const hasPendingTopup = res.data?.gamePublisherBalanceMutations.length

            if (hasPendingTopup) showTopupPendingModal()
            else showTopupModal(true)
          })
        }
      })
  }

  if (!selectedGame) return null
  return (
    <Modal
      open={isReviewCreativesModalShow}
      centered
      styles={{ body: { padding: '0px 24px' } }}
      footer={
        <Flex justify='center' gap={18}>
          <Button size='large' onClick={closeAll}>
            Cancel
          </Button>
          <Button
            size='large'
            type='primary'
            onClick={handleRunAds}
            loading={uploadGameCreativesLoading || balanceMutationLoading}
            disabled={uploadGameCreativesLoading || balanceMutationLoading}>
            {t('modal_review_creatives_publish_button')}
          </Button>
        </Flex>
      }
      onCancel={handleCancel}
      width={820}>
      <Flex
        align='center'
        justify='center'
        vertical
        className='scrollbar-hide'
        style={{ overflowY: 'scroll', paddingBottom: 32 }}
        gap={14}>
        <Image
          src='/images/illustration/scale-review-ads.webp'
          style={{ width: '300px' }}
          preview={false}
        />
        <Title level={4} style={{ color: colors.gray90, margin: 0 }}>
          {t('modal_review_creatives_title')}
        </Title>
        <Paragraph style={{ color: colors.gray70, textAlign: 'center', width: '90%' }}>
          {t('modal_review_creatives_description_1')}
          <Text style={{ color: colors.gray70, fontWeight: 'bold' }}>
            {t('modal_review_creatives_description_2')}
          </Text>
          , {t('modal_review_creatives_description_3')}
        </Paragraph>
        <Alert
          style={{
            border: 0,
            background: colors.success10,
            padding: '10px 24px 10px 12px',
            borderRadius: 8,
            width: '100%',
          }}
          message={
            <Flex align='center' gap={12}>
              <Flex flex={0}>
                <IconLib icon={labsInfoCircleOutline} color='success70' size='base' />
              </Flex>
              <Flex flex={1}>
                <Text style={{ fontSize: 12, color: colors.success70 }}>
                  {t('modal_review_creatives_info')}
                </Text>
              </Flex>
            </Flex>
          }
        />
        <Flex
          justify='space-between'
          style={{ width: '100%', backgroundColor: colors.gray20, padding: 16, borderRadius: 10 }}>
          <Flex gap={40}>
            <Flex gap={16}>
              <Text style={{ fontSize: 12, color: colors.gray60 }}>
                {t('modal_review_creatives_daily_budget_limit_label')}:
              </Text>
              <Text style={{ fontSize: 12, fontWeight: 'bold', color: colors.gray90 }}>
                {formatCurrency(selectedGame.dailyBudget || 0)}
              </Text>
            </Flex>
            <div style={{ borderLeft: '1px solid', borderColor: colors.gray30, height: 18 }}></div>
            {selectedGame.isAutoBudgetAllocation ? (
              <Text style={{ fontSize: 12, color: colors.gray90 }}>
                {t('modal_review_creatives_budget_allocation_label')}:{' '}
                <span style={{ fontWeight: 500 }}>{t('general_auto')}</span>
              </Text>
            ) : (
              <Flex gap={12}>
                <Text style={{ fontSize: 12, color: colors.gray90 }}>
                  iOS{' '}
                  <span style={{ fontWeight: 700 }}>
                    {Math.round((selectedGame.iosDailyBudget / selectedGame.dailyBudget) * 100)}
                  </span>
                  %
                </Text>
                <Text style={{ fontSize: 12, color: colors.gray90 }}>
                  Android{' '}
                  <span style={{ fontWeight: 700 }}>
                    {Math.round((selectedGame.androidDailyBudget / selectedGame.dailyBudget) * 100)}
                  </span>
                  %
                </Text>
              </Flex>
            )}
          </Flex>
          <Button
            type='text'
            style={{ padding: 0, height: 'auto' }}
            onClick={handleEditDailyBudget}>
            <IconLib icon={labsEditOutline} />
          </Button>
        </Flex>
        <Flex
          justify='space-between'
          style={{ width: '100%', backgroundColor: colors.gray20, padding: 16, borderRadius: 10 }}>
          <Flex gap={16} style={{ width: '100%' }}>
            <Text style={{ fontSize: 12, color: colors.gray60 }}>
              {t('modal_review_creatives_start_date_label')}:
            </Text>
            <Text style={{ fontSize: 12, fontWeight: 'bold', color: colors.gray90 }}>
              {moment(startDate).format('DD MMMM YYYY - HH:mm')}
            </Text>
          </Flex>
          <Button
            type='text'
            style={{ padding: 0, height: 'auto' }}
            onClick={handleEditPublishDate}>
            <IconLib icon={labsEditOutline} />
          </Button>
        </Flex>
        <Row style={{ width: '100%' }} gutter={[16, 16]}>
          {uploadCreativesSummary.map((creativeByCountry) => {
            if (!creativeByCountry.creatives.length) return null
            const imagesCount = creativeByCountry.creatives.reduce(
              (prev, curr) => prev + (curr.format === GameCreativeFormat.Image ? 1 : 0),
              0,
            )
            const videosCount = creativeByCountry.creatives.reduce(
              (prev, curr) => prev + (curr.format === GameCreativeFormat.Video ? 1 : 0),
              0,
            )
            return (
              <Col
                key={creativeByCountry.country.code}
                span={uploadCreativesSummary.length > 1 ? 12 : 24}
                style={{
                  backgroundColor: colors.gray20,
                  borderRadius: 10,
                  padding: 16,
                }}>
                <Row
                  style={{
                    whiteSpace: 'nowrap',
                    marginBottom: 8,
                  }}
                  align='middle'>
                  <Col span={20}>
                    <Flex align='center' gap={8}>
                      {/* <CountryFlag id={creativeByCountry.country.code} height={18} width={23} /> */}
                      <CountryFlag id='WW' />
                      {/* <Text style={{ color: colors.navy70 }}>{creativeByCountry.country.name}</Text> */}
                      <Text style={{ color: colors.navy70 }}>World Wide</Text>
                    </Flex>
                  </Col>
                  <Col span={4}>
                    <Flex align='center' justify='flex-end' gap={16}>
                      {imagesCount > 0 ? (
                        <Flex align='center' gap={4}>
                          <FileImageOutlined style={{ color: colors.gray70 }} />
                          <Text style={{ color: colors.gray70 }}>{imagesCount}</Text>
                        </Flex>
                      ) : null}
                      {videosCount > 0 ? (
                        <Flex align='center' gap={4}>
                          <VideoCameraOutlined style={{ color: colors.gray70 }} />
                          <Text style={{ color: colors.gray70 }}>{videosCount}</Text>
                        </Flex>
                      ) : null}
                    </Flex>
                  </Col>
                </Row>
                <Space direction='vertical' size={16}>
                  <Flex gap={4} style={{ flexWrap: 'wrap' }}>
                    {creativeByCountry.creatives.map((creative) => (
                      <div
                        key={creative.mediaUrl}
                        style={
                          {
                            // marginLeft: -10,
                            // backgroundColor: 'red',
                          }
                        }>
                        {creative.format === GameCreativeFormat.Image && (
                          <Image
                            src={creative.mediaUrl}
                            style={{ maxHeight: 60, borderRadius: 6 }}
                          />
                        )}
                        {creative.format === GameCreativeFormat.Video && (
                          <video
                            src={creative.mediaUrl}
                            controls
                            height={60}
                            style={{ borderRadius: 6 }}
                          />
                        )}
                      </div>
                    ))}
                  </Flex>
                </Space>
              </Col>
            )
          })}
        </Row>
      </Flex>
    </Modal>
  )
}
