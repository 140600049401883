import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleOutlined,
  FileImageOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import { Col, Flex, Modal, Row, theme, Typography } from 'antd'
import { ReactNode } from 'react'

import useModalStore from 'stores/useModalStore'

import colors from 'theme/colors'

const { Text, Title } = Typography

const Guide = ({
  icon,
  positive,
  title,
  guides,
}: {
  icon: ReactNode
  positive?: boolean
  title: string
  guides: string[]
}) => {
  const {
    token: { colorSuccess, colorError },
  } = theme.useToken()

  return (
    <Flex vertical>
      <Row
        style={{
          backgroundColor: colors.labs20,
          padding: 4,
          borderRadius: 4,
        }}>
        <Col span={2}>
          <Flex align='center' justify='center' style={{ height: '100%' }}>
            {icon}
          </Flex>
        </Col>
        <Col span={22}>
          <Flex align='center' justify='center' gap={4}>
            <Text>{title}</Text>{' '}
            {positive ? (
              <CheckCircleFilled style={{ color: colorSuccess }} />
            ) : (
              <CloseCircleFilled style={{ color: colorError }} />
            )}
          </Flex>
        </Col>
      </Row>
      <ul
        style={{
          paddingInlineStart: 20,
          listStyleType: 'disc',
          color: colors.labs60,
          lineHeight: '16x',
          marginTop: 10,
        }}>
        {guides.map((g) => (
          <li key={g}>
            <Text style={{ color: colors.labs60 }}>{g}</Text>
          </li>
        ))}
      </ul>
    </Flex>
  )
}

export default function GuidelineCreativesUploadModal() {
  const {
    token: { colorWarning },
  } = theme.useToken()
  const { isGuidelineCreativesUploadModalShow, closeAll } = useModalStore()

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{ color: colors.labs90, fontWeight: 500, margin: 0, marginBottom: 16 }}>
          Creative Dos and Don&apos;ts Guidelines
        </Title>
      }
      centered
      open={isGuidelineCreativesUploadModalShow}
      onCancel={closeAll}
      footer={false}
      width={784}
      style={{
        overflow: 'hidden',
        position: 'relative',
      }}>
      <ExclamationCircleOutlined
        style={{
          color: colorWarning,
          fontSize: 120,
          position: 'absolute',
          top: -16,
          left: -16,
          rotate: '-25deg',
          opacity: 0.2,
          zIndex: 0,
        }}
      />
      <Row gutter={[16, 16]} style={{ zIndex: 1 }}>
        <Col span={12}>
          <Guide
            icon={<FileImageOutlined />}
            title='Do'
            positive
            guides={[
              'Keep file size under 5Mb.',
              'Use PNG or JPG formats.',
              'Include minimum of 2 creatives per ratio.',
              'Include at least 2 ratios per format.',
            ]}
          />
        </Col>
        <Col span={12}>
          <Guide
            icon={<VideoCameraOutlined />}
            title='Do'
            positive
            guides={[
              'Ensure 15-60 sec duration.',
              'Keep file size below 50Mb.',
              'Use MP4 format.',
              'Include minimum of 2 creatives per ratio.',
              'Include at least 2 ratios per format.',
            ]}
          />
        </Col>
        <Col span={12}>
          <Guide
            icon={<FileImageOutlined />}
            title="Don't"
            guides={[
              'Use images with text, logos, or excessive whitespace.',
              'Utilize collages or blurry / unrecognizable visuals.',
              'Crop or warp images making the subject unclear.',
              'Include nudity, even for artistic purposes',
            ]}
          />
        </Col>
        <Col span={12}>
          <Guide
            icon={<VideoCameraOutlined />}
            title="Don't"
            guides={[
              'Use videos with illegible text or poor quality.',
              'Include sexual content, copyrighted material, or false claims.',
              'Feature improper content or negative events.',
              'Include nudity, offensive language, or election/political content.',
            ]}
          />
        </Col>
      </Row>
    </Modal>
  )
}
