import React, { ReactNode } from 'react'

import NetworkErrorPage from 'pages/error/network-error'
import PermissionDeniedPage from 'pages/error/permission-denied'
import FullScreenLoader from 'pages/fullscreen-loader'

import useIsPermissionGranted from 'hooks/useIsPermissionGranted'

import AuthenticatedLayout from 'components/layouts/AuthenticatedLayout'

import { GamePublisherUserPermissionEnum, PermissionContext } from 'types/permission'

type Props = {
  children: ReactNode
  permission: GamePublisherUserPermissionEnum
  hasTab?: boolean
}

const PermissionGuardedRoute: React.FC<Props> = ({ children, permission, hasTab = false }) => {
  const { isPermissionGranted, isPermissionLoading, isPermissionError } =
    useIsPermissionGranted(permission)

  if (isPermissionLoading) {
    return <FullScreenLoader isAuthenticated />
  }

  if (isPermissionError) {
    return (
      <PermissionContext.Provider value={{ isAuthenticated: false }}>
        <NetworkErrorPage />
      </PermissionContext.Provider>
    )
  }

  if (!isPermissionGranted) {
    return (
      <PermissionContext.Provider value={{ isAuthenticated: false }}>
        {hasTab ? (
          <PermissionDeniedPage height={'calc(100vh - 131px)'} />
        ) : (
          <AuthenticatedLayout>
            <PermissionDeniedPage />
          </AuthenticatedLayout>
        )}
      </PermissionContext.Provider>
    )
  }

  return children
}

export default PermissionGuardedRoute
