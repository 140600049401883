import { useQuery } from '@apollo/client'
import {
  GetIntegrationBySlugQuery,
  GetIntegrationBySlugQueryVariables,
} from '__generated__/gql/graphql'
import { Flex, Image, Modal, Spin, Typography } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getIntegrationBySlug } from 'services/graphql/queries'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Title, Text } = Typography

export default function IntegrationRequestFeedback() {
  const { t } = useTranslation()
  const { selectedGame } = useUserStore()

  const { isIntegrationRequestFeedbackModalShow, selectedIntegrationSlug, closeAll } =
    useModalStore()

  const { data: integrationData, loading: isIntegrationLoading } = useQuery<
    GetIntegrationBySlugQuery,
    GetIntegrationBySlugQueryVariables
  >(getIntegrationBySlug, {
    variables: { slug: selectedIntegrationSlug },
    skip: !selectedGame || Object.keys(selectedGame).length === 0,
  })

  const integration = useMemo(() => {
    return integrationData?.getIntegrationBySlug
  }, [integrationData])

  return (
    <Modal
      title={false}
      open={isIntegrationRequestFeedbackModalShow}
      footer={false}
      centered
      width={600}
      closable={false}>
      {integration && !isIntegrationLoading ? (
        <Flex vertical justify='center' align='center' gap={30} style={{ padding: '24px 48px' }}>
          {integration.iconUrl && (
            <Image
              src={integration.iconUrl}
              height={50}
              width={50}
              preview={false}
              style={{ borderRadius: 4 }}
            />
          )}

          <Flex vertical gap={10} align='center'>
            <Title level={5} style={{ color: colors.gray90, margin: 0 }}>
              Thank you for your interest
            </Title>
            <Text style={{ color: colors.gray70, textAlign: 'center' }}>
              We will send an email notification when the {integration.name} is ready
            </Text>
          </Flex>
          <LabsButton width='wide' onClick={closeAll}>
            {t('general_okay')}
          </LabsButton>
        </Flex>
      ) : (
        <Flex
          vertical
          justify='center'
          align='center'
          gap={10}
          style={{ height: 500, width: '100%' }}>
          <Spin />
        </Flex>
      )}
    </Modal>
  )
}
