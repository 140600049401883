import { useApolloClient, useMutation } from '@apollo/client'
import {
  RemoveGamePublisherUserMutation,
  RemoveGamePublisherUserMutationVariables,
} from '__generated__/gql/graphql'
import { Flex, Image, message, Modal, Typography } from 'antd'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { removeGamePublisherUser } from 'services/graphql/mutations'

import useModalStore from 'stores/useModalStore'

import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Title, Text } = Typography

export default function RemoveRoleConfirmationModal() {
  const { t } = useTranslation()
  const { isConfirmationRemoveRoleModalShow, closeAll, emailRoleSelected } = useModalStore()
  const client = useApolloClient()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [removeMember, { loading: isRemoveMemberLoading }] = useMutation<
    RemoveGamePublisherUserMutation,
    RemoveGamePublisherUserMutationVariables
  >(removeGamePublisherUser)

  const handleRemove = useCallback(() => {
    setIsLoading(true)
    removeMember({
      variables: {
        email: emailRoleSelected,
      },
    })
      .then((res) => {
        if (res.errors || !res.data?.removePublisherUserByEmail) {
          if (res.errors) {
            console.error(res.errors)
          }

          message.error(`Failed to remove member`)
        }

        message.success(`Member removed`)
      })
      .catch((err) => {
        console.error(err)
        message.error(`Failed to remove member`)
      })
      .finally(() => {
        client.refetchQueries({
          include: ['GetUserListsByPublisherId'],
        })
        setIsLoading(false)
        setTimeout(() => {
          closeAll()
        }, 500)
      })
  }, [setIsLoading, removeMember, closeAll, emailRoleSelected])

  return (
    <Modal
      title={false}
      centered
      open={isConfirmationRemoveRoleModalShow}
      onCancel={closeAll}
      footer={false}
      width={580}>
      <Flex
        vertical
        gap={40}
        justify='center'
        align='center'
        style={{ padding: '10px 50px 0px 50px' }}>
        <Flex vertical gap={20} justify='center' align='center'>
          <Title level={4} style={{ margin: 0, padding: 0, color: colors.gray90 }}>
            Are you sure want to delete this member?
          </Title>
          <Flex vertical justify='center' align='center'>
            <Text style={{ color: colors.gray70 }}>
              You will delete this email:{' '}
              <span style={{ fontWeight: 'bold' }}>{emailRoleSelected}</span>
            </Text>
            <Text style={{ color: colors.gray70 }}>
              This action can’t be undone. Make sure your team knows.
            </Text>
          </Flex>
        </Flex>
        <Flex gap={40} justify='center' align='center'>
          <LabsButton
            variant='outline'
            width='wide'
            onClick={closeAll}
            isDisabled={isLoading || isRemoveMemberLoading}>
            {t('general_cancel')}
          </LabsButton>
          <LabsButton
            variant='solid'
            color='danger'
            width='wide'
            onClick={handleRemove}
            isLoading={isLoading || isRemoveMemberLoading}
            isDisabled={isLoading || isRemoveMemberLoading}>
            Confirm Delete
          </LabsButton>
        </Flex>
      </Flex>
    </Modal>
  )
}
