import { useQuery } from '@apollo/client'
import {
  FindGameStoreByIdQuery,
  FindGameStoreByIdQueryVariables,
  GameStore,
} from '__generated__/gql/graphql'
import { Flex, Modal, Spin, Typography } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { findGameStoreById } from 'services/graphql/queries'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import colors from 'theme/colors'

import InspirationPreference from '../edit-game-modal/inspiration-preference'

const { Title, Text } = Typography
export default function EditPreferenceModal() {
  const { t } = useTranslation()

  const { selectedGame } = useUserStore()
  const { isScaleEditGamePreferenceModalShow, closeAll } = useModalStore()

  const gameStoreId = useMemo(() => {
    if (!isScaleEditGamePreferenceModalShow) return null
    if (!selectedGame || !selectedGame.stores.length) return null
    return selectedGame.stores[0].id
  }, [selectedGame, isScaleEditGamePreferenceModalShow])

  const { data, loading } = useQuery<FindGameStoreByIdQuery, FindGameStoreByIdQueryVariables>(
    findGameStoreById,
    {
      variables: { gameStoreId: gameStoreId || '-' },
      skip: !gameStoreId || !isScaleEditGamePreferenceModalShow,
      fetchPolicy: 'no-cache',
    },
  )

  const gameStore = useMemo(() => {
    return data?.findGameStoreById || null
  }, [data])

  if (!isScaleEditGamePreferenceModalShow) return null
  return (
    <Modal
      title={false}
      centered
      open={isScaleEditGamePreferenceModalShow}
      onCancel={closeAll}
      footer={false}
      width={770}>
      <Flex vertical gap={10}>
        <Flex vertical>
          <Title level={5} style={{ margin: 0, fontSize: 16, color: colors.gray90 }}>
            {t('scale_edit_game_preferences_title')}
          </Title>
          <Text style={{ color: colors.gray70 }}>
            {t('scale_edit_game_preferences_explanation')}
          </Text>
        </Flex>
        {loading || !gameStore ? (
          <Flex align='center' justify='center' style={{ height: 300 }}>
            <Spin />
          </Flex>
        ) : (
          <InspirationPreference gameStore={gameStore as GameStore} />
        )}
      </Flex>
    </Modal>
  )
}
