import {
  GamePublisherStatus,
  GamePublisherUserStatus,
  GameSdkIntegrationStatus,
  GameStoreIntegration,
  GameStoreIntegrationCustom,
} from '__generated__/gql/graphql'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { CountryGroup } from './useCreateAdsStore'

interface UserInformation {
  uid: string
  email: string
  displayName: string
  balance: number
  status: GamePublisherUserStatus
  isDemo: boolean
  companyName: string
  companySize: string
  isUseFullSDK: boolean
  roles: string[]
  gamePublisherStatus: GamePublisherStatus
  gameEngine: string
}

interface GameTag {
  id: string
  name: string
  code: string
}

interface GameGenre {
  id: string
  name: string
  code: string
}

interface SimilarGame {
  id: string
  name: string
}

interface GameStore {
  id: string
  url: string
  name: string
  os: string
  iconUrl?: string
  mediationId?: string
  packageName: string | null
  integrations: GameStoreIntegrationCustom[]
  qsdkProductCode: string | null
  isBasicScaleIntegrated: boolean
  isMediationIntegrated: boolean
}

export interface GameInformation {
  id: string
  name: string
  countries: CountryGroup[]
  blacklistedCountries: CountryGroup[]
  countryGroups: CountryGroup[]
  tags: GameTag[]
  genre: GameGenre[]
  integrationStatus: GameSdkIntegrationStatus
  isIntegrated: boolean
  uniqueSellingPoints: string
  dailyBudget: number
  androidDailyBudget: number
  iosDailyBudget: number
  isAutoBudgetAllocation: boolean
  googleBundleId: string
  similarGames: SimilarGame[]
  similarGameSubmissions: string[]
  hasCreatives: boolean
  isCheckCreativeValidation: boolean
  stores: GameStore[]
  logo?: string
  integrateInAppAds: boolean
  integrateInAppPurchase: boolean
}

export interface Language {
  code: string
  name: string
  label: string
  path: string
}

interface GameInformationFormState {
  user: UserInformation
  games: GameInformation[]
  selectedGame: GameInformation
  selectedGameStore: GameStore | undefined
  selectedLanguage: Language
  hasGameCreative: boolean
  hasIntegratedGame: boolean
  hasIntegratedMediation: boolean

  setUser: (user: UserInformation) => void
  setGames: (games: GameInformation[]) => void
  setSelectedGame: (game: GameInformation) => void
  setSelectedGameStore: (gameStore: GameStore | undefined) => void
  setSelectedLanguage: (language: Language) => void
  setHasGameCreative: (bool: boolean) => void
  setHasIntegratedGame: (bool: boolean) => void
  setHasIntegratedMediation: (bool: boolean) => void
  resetState: () => void
}

const useUserStore = create<GameInformationFormState>()(
  persist(
    (set) => ({
      user: {} as UserInformation,
      games: [],
      selectedGame: {} as GameInformation,
      selectedGameStore: undefined,
      selectedLanguage: { code: 'en', label: 'EN', name: 'english', path: 'translation_en.json' },
      hasGameCreative: false,
      hasIntegratedGame: false,
      hasIntegratedMediation: false,

      setUser: (user: UserInformation) => set((state) => ({ ...state, user: user })),
      setGames: (games: GameInformation[]) => set((state) => ({ ...state, games: games })),
      setSelectedGame: (game: GameInformation) =>
        set((state) => ({ ...state, selectedGame: game })),
      setSelectedGameStore: (gameStore: GameStore | undefined) =>
        set((state) => ({ ...state, selectedGameStore: gameStore })),
      setSelectedLanguage: (langauge: Language) =>
        set((state) => ({ ...state, selectedLanguage: langauge })),
      setHasGameCreative: (bool: boolean) => set((state) => ({ ...state, hasGameCreative: bool })),
      setHasIntegratedGame: (bool: boolean) =>
        set((state) => ({ ...state, hasIntegratedGame: bool })),
      setHasIntegratedMediation: (bool: boolean) =>
        set((state) => ({ ...state, hasIntegratedMediation: bool })),
      resetState: () =>
        set(() => ({
          user: {} as UserInformation,
          games: [],
          selectedGame: {} as GameInformation,
          selectedGameStore: undefined,
          selectedLanguage: {
            code: 'en',
            label: 'EN',
            name: 'english',
            path: 'translation_en.json',
          },
          hasGameCreative: false,
          hasIntegratedGame: false,
          hasIntegratedMediation: false,
        })),
    }),
    {
      name: 'user-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useUserStore
