import { Typography } from 'antd'
import { addDays, differenceInDays, format, parseISO } from 'date-fns'
import React, { CSSProperties } from 'react'

const { Text } = Typography

export const capitalizeWords = (input: string) => {
  return (input || '')
    .split(/\s+/) // Split input by whitespace
    .map((word) => {
      // Capitalize the first letter of the word if it's an alphabet character
      if (word.length > 0 && /[a-zA-Z]/.test(word[0])) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase()
      }
      return word // Return the word unchanged if it doesn't start with an alphabet character
    })
    .join(' ') // Join words back with a space
}

export const truncateText = (text: string, maxLength: number) => {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text
}

export const textWithBreak = (
  text: string,
  style?: CSSProperties | undefined,
): React.ReactElement[] => {
  const splitText = text.split('\n')

  return splitText.map((t, i) =>
    React.createElement(
      React.Fragment,
      { key: i },
      React.createElement(Text, { style }, t),
      i < splitText.length - 1 && React.createElement('br', { key: `br-${i}` }),
    ),
  )
}

export const isValidURL = (s: string) => {
  return /^https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/i.test(s)
}

export const isValidTapTapURL = (s: string) => {
  return /^https:\/\/www\.taptap\.cn\/app\/\d+$/i.test(s)
}

export const splitFilename = (s: string) => {
  if (s.length <= 15) {
    return s
  }

  return s.slice(0, 4) + '...' + s.slice(s.length - 8, s.length)
}

export const lowerAndCapitalize = (strings: string[]): string[] => {
  const modifiedStrings: string[] = []
  strings.forEach((string) => {
    const lowerString: string = string.toLowerCase()
    const modifiedString: string = lowerString.charAt(0).toUpperCase() + lowerString.slice(1)
    modifiedStrings.push(modifiedString)
  })
  return modifiedStrings
}

export const isValidEmail = (s: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(s)
}

export const capitalizeDashSeparatedString = (s: string) => {
  // Split the string by dashes into an array of words
  const words = s.split('-')

  // Capitalize each word
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
  }

  return words.join(' ')
}

export const replaceAll = (string: string, search: string, replace: string) => {
  return string.split(search).join(replace)
}

export const generateAdUnitName = (adUnitPrefix: string, gameName: string, placement: string) => {
  return `${adUnitPrefix}_${replaceAll(
    replaceAll(replaceAll(gameName.trim(), ':', ''), '!', ''),
    ' ',
    '_',
  )}_${placement}`
}

export const isHtmlString = (s: string) => {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i
  return htmlRegex.test(s)
}

export const parserJsonString = (str: string): Record<string, any> | null => {
  try {
    return JSON.parse(str)
  } catch {
    return null
  }
}

export const doubleDateRange = (startDateStr: string, endDateStr: string) => {
  const startDate = parseISO(startDateStr)
  const endDate = parseISO(endDateStr)

  // Calculate the difference in days between endDate and startDate
  const daysDifference = differenceInDays(endDate, startDate)

  // Calculate the new start date by subtracting daysDifference days from startDate
  const adjustedStartDate = addDays(startDate, -(daysDifference + 1))

  // Format the dates as yyyy-MM-dd strings
  const formattedStartDate = format(adjustedStartDate, 'yyyy-MM-dd')
  const formattedEndDate = format(endDate, 'yyyy-MM-dd')

  return [formattedStartDate, formattedEndDate]
}

export const generateDateRange = (dateFrom: string, dateTo: string): string[] => {
  const startDate = new Date(dateFrom)
  const endDate = new Date(dateTo)
  const dateArray: string[] = []

  while (startDate <= endDate) {
    dateArray.push(startDate.toISOString().split('T')[0]) // Format as 'YYYY-MM-DD'
    startDate.setDate(startDate.getDate() + 1) // Increment the date
  }

  return dateArray
}
