import { Card, Flex, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from 'utils/FormatterUtils'
import { generateAdUnitName } from 'utils/StringUtils'

import useUserStore from 'stores/useUserStore'

import IconLib from 'components/commons/icon-lib'
import { labsStar } from 'components/commons/icon-lib/icons/base'
import MediationAdUnitPreview from 'components/modules/mediation/ad-unit-preview'

import colors from 'theme/colors'

import { IAdUnitTemplate } from './type'

const { Text } = Typography
export default function AdUnitTemplate({
  adUnit,
  adUnitPrefix,
  mediaUrl,
  estimatedRevenue,
  placements,
}: IAdUnitTemplate) {
  const { t } = useTranslation()

  const { selectedGame } = useUserStore()

  return (
    <Card style={{ height: '100%' }} bodyStyle={{ padding: '10px 20px', height: '100%' }}>
      <Flex vertical gap={12} justify='space-between' style={{ height: '100%' }}>
        <Flex vertical gap={12}>
          <Text style={{ fontWeight: 700, fontSize: 16 }}>{adUnit}</Text>
          <Flex vertical>
            <Text style={{ fontWeight: 700, fontSize: 12, color: colors.gray70 }}>
              {t('mediation_create_ad_unit_template_modal_placement_label')}:
            </Text>
            <Text>{placements.join(', ')}</Text>
          </Flex>
          <Flex vertical>
            <Text style={{ fontWeight: 700, fontSize: 12, color: colors.gray70 }}>
              {t('mediation_create_ad_unit_template_modal_ad_units_label')}:
            </Text>
            <ul style={{ marginLeft: 20 }}>
              {placements.map((placement) => (
                <li key={placement} style={{ listStyleType: 'disc' }}>
                  <Text>
                    {generateAdUnitName(
                      adUnitPrefix || adUnit,
                      selectedGame.name,
                      placement.replace(' ', '_'),
                    )}
                  </Text>
                </li>
              ))}
            </ul>
          </Flex>
        </Flex>
        <Flex vertical gap={12}>
          <Flex justify='center'>
            <MediationAdUnitPreview mediaUrl={mediaUrl} width={110} />
          </Flex>

          <Flex gap={12}>
            <Flex flex={0} style={{ paddingTop: 3 }}>
              <IconLib icon={labsStar} color='success50' size='base' />
            </Flex>
            <Text>
              {t('mediation_create_ad_unit_template_modal_result_1')}{' '}
              <span style={{ fontWeight: 700 }}>{formatCurrency(estimatedRevenue)}</span>{' '}
              {t('mediation_create_ad_unit_template_modal_result_2')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  )
}
