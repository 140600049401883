import { Select } from 'antd'
import { BaseOptionType } from 'antd/es/select'
import React, { CSSProperties } from 'react'

import colors from 'theme/colors'

import { selectTagRender } from './Tag'
import { ISelect, Variant } from './type'

const variantStyle: { [k in Variant]: CSSProperties } = {
  primary: {
    backgroundColor: colors.gray10,
    borderColor: colors.gray40,
    color: colors.gray90,
  },
  transparent: {},
}

export default function SelectOptions(props: ISelect) {
  const filterSort = (optionA: BaseOptionType, optionB: BaseOptionType) => {
    if (props.sortOption === false) return
    return (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
  }

  const filterOption = (input: string, option: BaseOptionType | undefined) =>
    (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())

  return (
    <Select
      size='large'
      tagRender={selectTagRender}
      tokenSeparators={[',']}
      optionFilterProp='children'
      filterOption={filterOption}
      filterSort={filterSort}
      style={{
        ...variantStyle[props.variant || 'primary'],
        ...props.style,
      }}
      {...props}
    />
  )
}
