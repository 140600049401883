import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface FeedbackData {
  isLiked: boolean
  module: 'creatives' | 'headlines' | 'descriptions'
  feedback: string
  gameCreativeId: string
}

interface GameInformationFormState {
  isCreativesSubmittingModalShow: boolean
  showCreativesSubmittingModal: () => void

  isCreativesSubmittedModalShow: boolean
  showCreativesSubmittedModal: (isScheduled?: boolean) => void
  isCreativeSubmissionScheduled: boolean

  isCreativeFilterShow: boolean
  showIsCreativeFilterShow: () => void

  isReviewCreativesModalShow: boolean
  showReviewCreativesModal: () => void
  hideReviewCreativesModal: () => void

  isCreativeSetDateTimeShow: boolean
  isPublishIntended: boolean
  showCreativeSetDateTime: (isPublishIntended: boolean) => void
  hideCreativeSetDateTime: () => void

  isInsufficientBalanceModalShow: boolean
  showInsufficientBalanceModal: () => void

  isTopupModalShow: boolean
  isInsufficientBalance: boolean
  showTopupModal: (inInsufficientBalance: boolean) => void

  isTopupPendingModalShow: boolean
  showTopupPendingModal: () => void

  isTopupSuccessModalShow: boolean
  showTopupSuccessModal: () => void

  isGuidelineCreativesUploadModalShow: boolean
  showGuidelineCreativesUploadModal: () => void

  isSetDailyBudgetModalShow: boolean
  showSetDailyBudgetModal: (isPublishIntended: boolean) => void
  hideSetDailyBudgetModal: () => void

  selectedAddOnProductSlug: string
  setSelectedAddOnProductSlug: (selectedAddOnProductSlug: string) => void
  isProductModalShow: boolean
  showProductModal: (selectedAddOnProductSlug: string) => void
  closeProductModal: () => void

  isProductConfirmationModalShow: boolean
  showProductConfirmationModal: () => void

  isProductInterestFeedbackModalShow: boolean
  showProductInterestFeedbackModalShow: () => void

  selectedIntegrationSlug: string
  isIntegrationConfirmationModalShow: boolean
  showIntegrationModalConfirmation: (integrationSlug: string) => void

  isIntegrationRequestFeedbackModalShow: boolean
  showIntegrationRequestFeedbackModal: () => void

  isNoPermissionModalShow: boolean
  showNoPermissionModal: () => void
  closeNoPermissionModal: () => void

  isScaleEditGamePreferenceModalShow: boolean
  showScaleEditGamePreferenceModal: () => void
  closeScaleEditGamePreferenceModal: () => void

  isComingSoonModalShow: boolean
  showComingSoonModal: () => void

  isScaleIntegrationRequiredModalShow: boolean
  showScaleIntegrationRequiredModal: () => void

  isWaitingCurrentIntegrationModalShow: boolean
  showWaitingCurrentIntegrationModal: () => void

  isFeedbackModalShow: boolean
  feedbackData: FeedbackData
  showFeedbackModal: (feedbackData: FeedbackData) => void

  isLabsSupportModalShow: boolean
  showLabsSupportModal: () => void

  isMediationDataGlossaryModalShow: boolean
  showMediationDataGlossaryModal: () => void

  isForgotPasswordModalShow: boolean
  showForgotPasswordModalShow: () => void

  isSetDisplayNameModalShow: boolean
  showSetDisplayNameModal: () => void
  hideSetDisplayNameModal: () => void

  isBasicScaleIntegrationModalShow: boolean
  showBasicScaleIntegrationModal: () => void

  isIntegrationCompletionConfirmationModalShow: boolean
  showIntegrationCompletionConfirmationModal: () => void

  isIntegrationNotEligibleCompletionConfirmationModalShow: boolean
  showIntegrationNotEligibleCompletionConfirmationModal: () => void

  isIntegrationAdjustTokenModalShow: boolean
  showIntegrationAdjustTokenModal: () => void

  isChooseTemplateAdUnitModalShow: boolean
  showChooseTemplateAdUnitModal: () => void

  isCreateAdUnitModalShow: boolean
  showCreateAdUnitModal: () => void
  isEditAdUnitModalShow: boolean
  showEditAdUnitModal: () => void

  isAdUnitDetailModalShow: boolean
  showAdUnitDetailModal: () => void

  isDeleteAdUnitConfirmationModalShow: boolean
  showDeleteAdUnitConfirmationModal: () => void
  isMarketingOnboardingModalShow: boolean
  showScaleSdkIntegrationModal: () => void

  isBalanceLimitedOfferModalShow: boolean
  showBalanceLimitedOfferModal: () => void

  isBalanceOfferAppSubmittedModalShow: boolean
  showBalanceOfferAppSubmittedModal: () => void

  nameRoleSelected: string
  emailRoleSelected: string
  isConfirmationRemoveRoleModalShow: boolean
  showConfirmationRemoveRoleModal: (nameRoleSelected: string, email: string) => void

  isEditMemberRoleModalShow: boolean
  showEditMemberRoleModal: (nameRoleSelected: string, email: string) => void

  isCreateNewMemberModalShow: boolean
  showCreateNewMemberModal: () => void

  isNotAllowedModalShow: boolean
  showNotAllowedModal: () => void

  isStartOnboardModalShow: boolean
  showStartOnboardModal: () => void

  selectedGameStoreId: string | null
  setSelectedGameStoreId: (gameStoreId: string | null) => void

  isGameDetailModalShow: boolean
  showGameDetailModal: (gameStoreId: string) => void

  isDeleteGameStoreConfirmationModalShow: boolean
  showDeleteGameStoreConfirmationModal: (gameStoreId: string) => void

  isEditGameModalShow: boolean
  showEditGameModal: (gameStoreId: string) => void

  isMediationIntegrationCompletionConfirmationModalShow: boolean
  showMediationIntegrationCompletionConfirmationModal: () => void

  isMediationIntegratedModalShow: boolean
  showMediationIntegratedModal: () => void

  isCreativePreviewModalShow: boolean
  showCreativePreviewModal: () => void

  isWarningIncompleteDataModalShow: boolean
  showWarningIncompleteDataModal: () => void

  closeAll: () => void
}

const useModalStore = create<GameInformationFormState>()(
  persist(
    (set) => ({
      isCreativesSubmittingModalShow: false,
      isCreativesSubmittedModalShow: false,
      isCreativeSubmissionScheduled: false,
      isCreativeFilterShow: false,
      isReviewCreativesModalShow: false,
      isCreativeSetDateTimeShow: false,
      isPublishIntended: false,
      isInsufficientBalanceModalShow: false,
      isTopupModalShow: false,
      isInsufficientBalance: false,
      isTopupPendingModalShow: false,
      isTopupSuccessModalShow: false,
      isGuidelineCreativesUploadModalShow: false,
      isSetDailyBudgetModalShow: false,
      isScaleEditGamePreferenceModalShow: false,
      isComingSoonModalShow: false,
      isScaleIntegrationRequiredModalShow: false,
      isWaitingCurrentIntegrationModalShow: false,
      selectedAddOnProductSlug: '',
      isNoPermissionModalShow: false,
      isProductModalShow: false,
      isProductConfirmationModalShow: false,
      selectedIntegrationSlug: '',
      isIntegrationConfirmationModalShow: false,
      isIntegrationRequestFeedbackModalShow: false,
      isProductInterestFeedbackModalShow: false,
      isFeedbackModalShow: false,
      isLabsSupportModalShow: false,
      isMediationDataGlossaryModalShow: false,
      isForgotPasswordModalShow: false,
      isSetDisplayNameModalShow: false,
      feedbackData: {} as FeedbackData,
      isBasicScaleIntegrationModalShow: false,
      isIntegrationCompletionConfirmationModalShow: false,
      isIntegrationNotEligibleCompletionConfirmationModalShow: false,
      isIntegrationAdjustTokenModalShow: false,
      isChooseTemplateAdUnitModalShow: false,
      isCreateAdUnitModalShow: false,
      isEditAdUnitModalShow: false,
      isAdUnitDetailModalShow: false,
      isDeleteAdUnitConfirmationModalShow: false,
      isStartOnboardModalShow: false,
      isConfirmationRemoveRoleModalShow: false,
      isEditMemberRoleModalShow: false,
      isCreateNewMemberModalShow: false,
      nameRoleSelected: '',
      emailRoleSelected: '',
      isMarketingOnboardingModalShow: false,
      isBalanceLimitedOfferModalShow: false,
      isBalanceOfferAppSubmittedModalShow: false,
      isNotAllowedModalShow: false,
      selectedGameStoreId: null,
      isGameDetailModalShow: false,
      isDeleteGameStoreConfirmationModalShow: false,
      isEditGameModalShow: false,
      isMediationIntegrationCompletionConfirmationModalShow: false,
      isMediationIntegratedModalShow: false,
      isCreativePreviewModalShow: false,
      isWarningIncompleteDataModalShow: false,
      showCreativesSubmittingModal: () =>
        set((state) => ({ ...state, isCreativesSubmittingModalShow: true })),
      showCreativesSubmittedModal: (isScheduled?: boolean) =>
        set((state) => ({
          ...state,
          isCreativesSubmittedModalShow: true,
          isCreativeSubmissionScheduled: isScheduled ? true : false,
        })),
      showIsCreativeFilterShow: () => set((state) => ({ ...state, isCreativeFilterShow: true })),
      showReviewCreativesModal: () =>
        set((state) => ({ ...state, isReviewCreativesModalShow: true })),
      hideReviewCreativesModal: () =>
        set((state) => ({ ...state, isReviewCreativesModalShow: false, isPublishIntended: false })),
      showCreativeSetDateTime: (isPublishIntended: boolean) =>
        set((state) => ({
          ...state,
          isCreativeSetDateTimeShow: true,
          isPublishIntended: isPublishIntended,
        })),
      hideCreativeSetDateTime: () =>
        set((state) => ({ ...state, isCreativeSetDateTimeShow: false })),
      showInsufficientBalanceModal: () =>
        set((state) => ({ ...state, isInsufficientBalanceModalShow: true })),
      showProductModal: (selectedAddOnProductSlug: string) =>
        set((state) => ({ ...state, selectedAddOnProductSlug, isProductModalShow: true })),
      setSelectedAddOnProductSlug: (selectedAddOnProductSlug: string) =>
        set((state) => ({ ...state, selectedAddOnProductSlug })),
      closeProductModal: () => set((state) => ({ ...state, isProductModalShow: false })),
      showProductConfirmationModal: () =>
        set((state) => ({ ...state, isProductConfirmationModalShow: true })),
      showProductInterestFeedbackModalShow: () =>
        set((state) => ({ ...state, isProductInterestFeedbackModalShow: true })),
      showIntegrationModalConfirmation: (integrationSlug: string) =>
        set((state) => ({
          ...state,
          selectedIntegrationSlug: integrationSlug,
          isIntegrationConfirmationModalShow: true,
        })),
      showIntegrationRequestFeedbackModal: () =>
        set((state) => ({ ...state, isIntegrationRequestFeedbackModalShow: true })),

      showNoPermissionModal: () => set((state) => ({ ...state, isNoPermissionModalShow: true })),
      closeNoPermissionModal: () => set((state) => ({ ...state, isNoPermissionModalShow: false })),
      showTopupModal: (isInsufficientBalance: boolean) =>
        set((state) => ({
          ...state,
          isTopupModalShow: true,
          isInsufficientBalance: isInsufficientBalance,
        })),
      showTopupPendingModal: () => set((state) => ({ ...state, isTopupPendingModalShow: true })),
      showTopupSuccessModal: () => set((state) => ({ ...state, isTopupSuccessModalShow: true })),
      showGuidelineCreativesUploadModal: () =>
        set((state) => ({ ...state, isGuidelineCreativesUploadModalShow: true })),
      showSetDailyBudgetModal: (isPublishIntended: boolean) =>
        set((state) => ({
          ...state,
          isPublishIntended: isPublishIntended,
          isSetDailyBudgetModalShow: true,
        })),
      hideSetDailyBudgetModal: () =>
        set((state) => ({ ...state, isSetDailyBudgetModalShow: false })),
      showWaitingCurrentIntegrationModal: () =>
        set((state) => ({ ...state, isWaitingCurrentIntegrationModalShow: true })),
      showScaleEditGamePreferenceModal: () =>
        set((state) => ({ ...state, isScaleEditGamePreferenceModalShow: true })),
      closeScaleEditGamePreferenceModal: () =>
        set((state) => ({ ...state, isScaleEditGamePreferenceModalShow: false })),
      showComingSoonModal: () => set((state) => ({ ...state, isComingSoonModalShow: true })),
      showScaleIntegrationRequiredModal: () =>
        set((state) => ({ ...state, isScaleIntegrationRequiredModalShow: true })),
      showFeedbackModal: (feedbackData: FeedbackData) =>
        set((state) => ({
          ...state,
          isFeedbackModalShow: true,
          feedbackData: feedbackData,
        })),
      showLabsSupportModal: () =>
        set((state) => ({
          ...state,
          isLabsSupportModalShow: true,
        })),
      showMediationDataGlossaryModal: () =>
        set((state) => ({
          ...state,
          isMediationDataGlossaryModalShow: true,
        })),
      showForgotPasswordModalShow: () =>
        set((state) => ({
          ...state,
          isForgotPasswordModalShow: true,
        })),
      showSetDisplayNameModal: () =>
        set((state) => ({
          ...state,
          isSetDisplayNameModalShow: true,
        })),
      hideSetDisplayNameModal: () =>
        set((state) => ({
          ...state,
          isSetDisplayNameModalShow: false,
        })),
      showBasicScaleIntegrationModal: () =>
        set((state) => ({
          ...state,
          isBasicScaleIntegrationModalShow: true,
        })),
      showIntegrationCompletionConfirmationModal: () =>
        set((state) => ({
          ...state,
          isIntegrationCompletionConfirmationModalShow: true,
        })),
      showIntegrationNotEligibleCompletionConfirmationModal: () =>
        set((state) => ({
          ...state,
          isIntegrationNotEligibleCompletionConfirmationModalShow: true,
        })),
      showIntegrationAdjustTokenModal: () =>
        set((state) => ({
          ...state,
          isIntegrationAdjustTokenModalShow: true,
        })),
      showChooseTemplateAdUnitModal: () =>
        set((state) => ({
          ...state,
          isChooseTemplateAdUnitModalShow: true,
        })),
      showCreateAdUnitModal: () =>
        set((state) => ({
          ...state,
          isCreateAdUnitModalShow: true,
        })),
      showEditAdUnitModal: () =>
        set((state) => ({
          ...state,
          isEditAdUnitModalShow: true,
        })),
      showAdUnitDetailModal: () =>
        set((state) => ({
          ...state,
          isAdUnitDetailModalShow: true,
        })),
      showDeleteAdUnitConfirmationModal: () =>
        set((state) => ({
          ...state,
          isDeleteAdUnitConfirmationModalShow: true,
        })),
      showStartOnboardModal: () =>
        set((state) => ({
          ...state,
          isStartOnboardModalShow: true,
        })),
      showScaleSdkIntegrationModal: () =>
        set((state) => ({ ...state, isMarketingOnboardingModalShow: true })),
      showBalanceLimitedOfferModal: () =>
        set((state) => ({ ...state, isBalanceLimitedOfferModalShow: true })),
      showBalanceOfferAppSubmittedModal: () =>
        set((state) => ({ ...state, isBalanceOfferAppSubmittedModalShow: true })),

      showConfirmationRemoveRoleModal: (nameRoleSelected: string, emailRoleSelected: string) =>
        set((state) => ({
          ...state,
          nameRoleSelected,
          emailRoleSelected,
          isConfirmationRemoveRoleModalShow: true,
        })),
      showEditMemberRoleModal: (nameRoleSelected: string, emailRoleSelected: string) =>
        set((state) => ({
          ...state,
          nameRoleSelected,
          emailRoleSelected,
          isEditMemberRoleModalShow: true,
        })),
      showCreateNewMemberModal: () =>
        set((state) => ({
          ...state,
          isCreateNewMemberModalShow: true,
        })),
      showNotAllowedModal: () =>
        set((state) => ({
          ...state,
          isNotAllowedModalShow: true,
        })),
      setSelectedGameStoreId: (gameStoreId: string | null) =>
        set((state) => ({
          ...state,
          selectedGameStoreId: gameStoreId,
        })),
      showGameDetailModal: (gameStoreId: string) =>
        set((state) => ({
          ...state,
          selectedGameStoreId: gameStoreId,
          isGameDetailModalShow: true,
        })),
      showDeleteGameStoreConfirmationModal: (gameStoreId: string) =>
        set((state) => ({
          ...state,
          selectedGameStoreId: gameStoreId,
          isDeleteGameStoreConfirmationModalShow: true,
        })),
      showEditGameModal: (gameStoreId: string) =>
        set((state) => ({
          ...state,
          selectedGameStoreId: gameStoreId,
          isEditGameModalShow: true,
        })),
      showMediationIntegrationCompletionConfirmationModal: () =>
        set((state) => ({
          ...state,
          isMediationIntegrationCompletionConfirmationModalShow: true,
        })),
      showMediationIntegratedModal: () =>
        set((state) => ({
          ...state,
          isMediationIntegratedModalShow: true,
        })),
      showCreativePreviewModal: () =>
        set((state) => ({
          ...state,
          isCreativePreviewModalShow: true,
        })),
      showWarningIncompleteDataModal: () =>
        set((state) => ({
          ...state,
          isWarningIncompleteDataModalShow: true,
        })),

      closeAll: () =>
        set(() => ({
          isCreativesSubmittingModalShow: false,
          isCreativesSubmittedModalShow: false,
          isCreativeSubmissionScheduled: false,
          isCreativeFilterShow: false,
          isReviewCreativesModalShow: false,
          isCreativeSetDateTimeShow: false,
          isPublishIntended: false,
          isInsufficientBalanceModalShow: false,
          isTopupModalShow: false,
          isInsufficientBalance: false,
          isTopupPendingModalShow: false,
          isTopupSuccessModalShow: false,
          isGuidelineCreativesUploadModalShow: false,
          isSetDailyBudgetModalShow: false,
          isScaleEditGamePreferenceModalShow: false,
          isWaitingCurrentIntegrationModalShow: false,
          isComingSoonModalShow: false,
          isScaleIntegrationRequiredModalShow: false,
          isFeedbackModalShow: false,
          feedbackData: {} as FeedbackData,
          isLabsSupportModalShow: false,
          isMediationDataGlossaryModalShow: false,
          isSetDisplayNameModalShow: false,
          isProductModalShow: false,
          isProductConfirmationModalShow: false,
          isProductInterestFeedbackModalShow: false,
          isIntegrationConfirmationModalShow: false,
          isIntegrationRequestFeedbackModalShow: false,
          isBasicScaleIntegrationModalShow: false,
          isIntegrationCompletionConfirmationModalShow: false,
          isIntegrationNotEligibleCompletionConfirmationModalShow: false,
          isIntegrationAdjustTokenModalShow: false,
          isForgotPasswordModalShow: false,
          isChooseTemplateAdUnitModalShow: false,
          isCreateAdUnitModalShow: false,
          isEditAdUnitModalShow: false,
          isAdUnitDetailModalShow: false,
          isDeleteAdUnitConfirmationModalShow: false,
          isStartOnboardModalShow: false,
          isConfirmationRemoveRoleModalShow: false,
          isEditMemberRoleModalShow: false,
          isCreateNewMemberModalShow: false,
          isMarketingOnboardingModalShow: false,
          isBalanceLimitedOfferModalShow: false,
          isBalanceOfferAppSubmittedModalShow: false,
          isNotAllowedModalShow: false,
          selectedGameStoreId: null,
          isGameDetailModalShow: false,
          isDeleteGameStoreConfirmationModalShow: false,
          isEditGameModalShow: false,
          isMediationIntegrationCompletionConfirmationModalShow: false,
          isMediationIntegratedModalShow: false,
          isCreativePreviewModalShow: false,
          isWarningIncompleteDataModalShow: false,
        })),
    }),
    {
      name: 'modal-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useModalStore
