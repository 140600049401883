import { Divider, Dropdown, Flex, MenuProps, Typography } from 'antd'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ACCOUNT_SETTING_ROUTE, GAME_ROUTE } from 'constants/Routes'

import { formatCurrency } from 'utils/FormatterUtils'
import { capitalizeWords } from 'utils/StringUtils'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useLogout from 'hooks/useLogout'
import useTracker from 'hooks/useTracker'

import {
  labsGame, // labsGame,
  labsGearOutline,
  labsLogout,
  labsSupport,
} from 'components/commons/icon-lib/icons/base'

import colors from 'theme/colors'

import HeaderInfoItem from './header-info-item'
import HeaderPublisherInfo from './publisher-info'

const { Text } = Typography

export default function UserInfo() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { handleLogout } = useLogout()
  const { track } = useTracker()

  const { user } = useUserStore()
  const { showLabsSupportModal } = useModalStore()

  const cardOutlineStyle: CSSProperties = {
    borderWidth: 1,
    borderColor: colors.gray30,
    borderStyle: 'solid',
    height: 50,
    minWidth: 150,
    padding: '10px 14px',
    borderRadius: 6,
  }

  const handleClickMyGames = () => {
    navigate(`/${GAME_ROUTE}`)
  }

  const handleClickAccountSetting = () => {
    navigate(`/${ACCOUNT_SETTING_ROUTE}`)
  }

  const handleClickHelpCenter = () => {
    showLabsSupportModal()
  }

  const handleClickLogout = () => {
    handleLogout()
  }

  const dropdownItems: MenuProps['items'] = [
    {
      label: <HeaderPublisherInfo />,
      key: 'publisher-info',
      className: 'no-hover',
    },
    {
      type: 'divider',
      style: {
        height: 6,
        backgroundColor: colors.gray20,
      },
    },
    {
      onClick: () => handleClickMyGames(),
      label: <HeaderInfoItem icon={labsGame} label={t('header_info_my_games')} />,
      key: 'my-games',
    },
    {
      onClick: () => handleClickAccountSetting(),
      label: <HeaderInfoItem icon={labsGearOutline} label={t('header_info_account_setting')} />,
      key: 'setting',
    },
    {
      onClick: () => handleClickHelpCenter(),
      label: <HeaderInfoItem icon={labsSupport} label={t('header_info_help_center')} />,
      key: 'help-center',
    },
    {
      type: 'divider',
      style: {
        height: 6,
        backgroundColor: colors.gray20,
      },
    },
    {
      onClick: () => handleClickLogout(),
      label: <HeaderInfoItem icon={labsLogout} label={t('general_logout')} />,
      key: 'logout',
    },
  ]

  const onOpened = (isOpened: boolean) => {
    if (isOpened) {
      track('show_user_info', {
        type: 'click',
        location: 'header',
        location_type: 'button',
      })
    }
  }

  return (
    <Dropdown
      menu={{ items: dropdownItems }}
      trigger={['click']}
      onOpenChange={onOpened}
      overlayStyle={{ width: 264 }}>
      <a onClick={(e) => e.preventDefault()}>
        <Flex className='card-outline' align='center' gap={10} style={cardOutlineStyle}>
          <Flex vertical style={{ width: '100%' }}>
            <Text
              style={{
                color: colors.gray90,
                maxWidth: user.isDemo ? 250 : 145,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {capitalizeWords(user.displayName)}
            </Text>
            <Flex gap={4} align='center'>
              <Text style={{ fontSize: 12, color: colors.gray70 }}>
                {t('layout_header_balance')}:
              </Text>
              <Text style={{ fontSize: 12, color: colors.gray90, fontWeight: 500 }}>
                {formatCurrency(user.balance, 0)}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </a>
    </Dropdown>
  )
}
