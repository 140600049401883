import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { CSSProperties } from 'react'

import colors from 'theme/colors'

import { IFullScreenLoader } from './type'

const baseLayoutStyle: CSSProperties = {
  height: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const authenticationLayoutStyle: CSSProperties = {
  background: 'linear-gradient(203.93deg, #0A54BB 15.37%, #06459B 114.17%)',
  width: '100vw',
  height: '100vh',
}

const authenticatedLayoutStyle: CSSProperties = {
  backgroundColor: colors.gray10,
}

export default function FullScreenLoader({ isAuthenticated }: IFullScreenLoader) {
  const layoutStyle = isAuthenticated ? authenticatedLayoutStyle : authenticationLayoutStyle

  return (
    <div style={{ ...baseLayoutStyle, ...layoutStyle }}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
    </div>
  )
}
