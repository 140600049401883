import { gql } from '@apollo/client'

export const gameGenresQuery = gql`
  query GameGenres {
    gameGenres(limit: 1000, offset: 0) {
      id
      name
      code
      isDisabled
    }
  }
`

export const gameTagsQuery = gql`
  query GameTags {
    gameTags(limit: 1000, offset: 0) {
      id
      name
      code
      isDisabled
    }
  }
`

export const gameRegionsQuery = gql`
  query GameTargetingCountries($all: Boolean) {
    gameTargetingCountries(limit: 1000, offset: 0, all: $all) {
      id
      name
      code
      isDisabled
    }
  }
`

export const gameTargetingCountryGroupsQuery = gql`
  query GameTargetingCountryGroups {
    gameTargetingCountryGroups(limit: 1000, offset: 0) {
      id
      name
      code
      isDisabled
    }
  }
`

export const gameLanguagesQuery = gql`
  query GameLanguages {
    gameLanguages(limit: 1000, offset: 0) {
      id
      name
      code
      isDisabled
    }
  }
`

export const getGamePublisherUser = gql`
  query GetGamePublisherUser {
    gamePublisherUser {
      id
      uid
      displayName
      email
      status
      roles {
        slug
      }
      hasFirstTopupBalance
      showOnboard
      showBalanceLimitedOffer
      permissions {
        id
        slug
        name
      }
      gamePublisher {
        id
        status
        gameEngine
        balance
        isDemo
        companyName
        companySize
        isUseFullSDK
        games(limit: 1000, offset: 0) {
          id
          name
          gameLogoUrl
          integrateInAppAds
          integrateInAppPurchase
          countries {
            code
            name
          }
          countryGroups {
            code
            name
          }
          blacklistedCountries {
            code
            name
          }
          genres {
            id
            name
            code
          }
          tags {
            id
            name
            code
          }
          appStoreUrl
          playStoreUrl
          sdkIntegrationStatus
          uniqueSellingPoints
          dailyBudget
          androidDailyBudget
          iosDailyBudget
          isAutoBudgetAllocation
          googleBundleId
          similarGames {
            id
            name
          }
          similarGameSubmissions {
            name
          }
          isAnyCreatives
          isIntegrated
          isCheckCreativeValidation
          stores {
            id
            status
            url
            mediation {
              id
              appId
            }
            store {
              id
              name
              os
              iconUrl
            }
            integrations {
              id
              slug
              name
              status
              method
            }
            bundleId
            qsdkProductCode
            isBasicScaleIntegrated
            isMediationIntegrated
          }
        }
      }
    }
  }
`

export const getGameById = gql`
  query GetGameById($gameId: Int!) {
    gameById(gameId: $gameId) {
      name
      appStoreUrl
      playStoreUrl
      sdkIntegrationStatus
      uniqueSellingPoints
      dailyBudget
      stores {
        store {
          name
          iconUrl
        }
        bundles {
          name
        }
      }
      countries {
        code
        name
      }
      countryGroups {
        code
        name
      }
    }
  }
`

export const creativesPerformancePrediction = gql`
  query CreativesPerformancePrediction(
    $gameId: ID!
    $ratioFormat: [CreativePerformancePredictionFormatRatio!]!
  ) {
    creativesPerformancePrediction(gameId: $gameId, ratioFormat: $ratioFormat) {
      points
      grade
      validation {
        status
        key
        message
        ratio
      }
      guide {
        key
        isError
        message
      }
    }
  }
`

export const getAudienceAnalytics = gql`
  query GetAudienceAnalytics(
    $gameId: ID!
    $storeIds: [Int!]!
    $startDate: String!
    $endDate: String!
    $channels: [AudienceAnalyticsChannel!]
    $countryNames: [String!]
    $gameRevenueSource: GameRevenueSource!
  ) {
    audienceAnalytics(
      gameId: $gameId
      storeIds: $storeIds
      startDate: $startDate
      endDate: $endDate
      channels: $channels
      countryNames: $countryNames
      gameRevenueSource: $gameRevenueSource
    ) {
      totalCPI
      totalROAS
      totalRevenue
      totalInstalls
      totalAdSpending
      metrics {
        country
        countryCode
        date
        adSpending
        roas
        cpi
        installs
      }
      countryPerformances {
        country
        countryCode
        totalROAS
        totalInstalls
        totalAdSpending
      }
    }
  }
`

export const getBannerCarousels = gql`
  query BannerCourselContent {
    bannerCourselContent(limit: 10, offset: 0) {
      id
      mediaUrl
    }
  }
`

export const getBalanceMutation = gql`
  query GamePublisherBalanceMutations($status: GamePublisherBalanceMutationStatus!) {
    gamePublisherBalanceMutations(limit: 1, offset: 0, status: $status) {
      amount
      status
    }
  }
`

export const getGameCreatives = gql`
  query GameCreatives(
    $gameId: ID!
    $orderByCreatedDate: InputOrderByEnum!
    $status: GameCreativeStatus!
  ) {
    gameCreatives(
      limit: 1000
      offset: 0
      gameId: $gameId
      orderByCreatedDate: $orderByCreatedDate
      status: $status
    ) {
      id
      mediaUrl
      format
      status
      rating
      countries {
        code
      }
      startDate
    }
  }
`

export const getMyAds = gql`
  query MyAds(
    $limit: Int!
    $offset: Int!
    $gameId: ID!
    $countryCode: String!
    $isCountryGroup: Boolean!
    $platformIn: [String!]
    $orientationIn: [CreativeInspirationOrientation!]
    $creativeTypeIn: [GameCreativeFormat!]!
    $tagsIn: [String!]
  ) {
    myAds(
      limit: $limit
      offset: $offset
      gameId: $gameId
      countryCode: $countryCode
      isCountryGroup: $isCountryGroup
      platformIn: $platformIn
      orientationIn: $orientationIn
      creativeTypeIn: $creativeTypeIn
      tagsIn: $tagsIn
    ) {
      total
      data {
        id
        format
        mediaUrl
        mediaText
        metadatas {
          id
          type
          impressions
          ctr
          ccr
          score
        }
      }
    }
  }
`

export const getAudienceAnalyticsRoasCohortByCountries = gql`
  query GetAudienceAnalyticsRoasCohortByCountries(
    $gameId: ID!
    $storeIds: [Int!]!
    $startDate: String!
    $endDate: String!
    $limit: Int
    $channels: [AudienceAnalyticsChannel!]
    $countryNames: [String!]
    $gameRevenueSource: GameRevenueSource!
  ) {
    audienceAnalyticsRoasCohortByCountries(
      gameId: $gameId
      storeIds: $storeIds
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      channels: $channels
      countryNames: $countryNames
      gameRevenueSource: $gameRevenueSource
    ) {
      country
      countryCode
      cost
      roas_d0
      roas_d1
      roas_d2
      roas_d3
      roas_d4
      roas_d5
      roas_d6
      roas_d7
    }
  }
`

export const getAudienceAnalyticsAdvanceInformations = gql`
  query GetAudienceAnalyticsAdvanceInformations(
    $gameId: ID!
    $storeIds: [Int!]!
    $startDate: String!
    $endDate: String!
    $viewBy: AudienceAnalyticsAdvanceInformationViewBy!
    $channels: [AudienceAnalyticsChannel!]
    $countryNames: [String!]
    $gameRevenueSource: GameRevenueSource!
  ) {
    audienceAnalyticsAdvanceInformations(
      gameId: $gameId
      storeIds: $storeIds
      startDate: $startDate
      endDate: $endDate
      viewBy: $viewBy
      channels: $channels
      countryNames: $countryNames
      gameRevenueSource: $gameRevenueSource
    ) {
      dimension
      dimension_image_url
      cost
      installs
      ecpi
      revenue
      roas
      roas_d0
      roas_d1
    }
  }
`

export const getStoreLists = gql`
  query getStoreLists($limit: Int!, $offset: Int!) {
    stores(limit: $limit, offset: $offset) {
      id
      name
      iconUrl
      isActive
    }
  }
`

export const getActiveStores = gql`
  query GetActiveStore {
    getActiveStore {
      id
      name
      iconUrl
      isActive
      os
    }
  }
`

export const integrationStatus = gql`
  query IntegrationStatus(
    $gameId: Int!
    $gameStoreId: String!
    $platform: String!
    $productCode: String!
    $isIaa: Int!
    $isIap: Int!
    $useLogin: Int!
    $products: [SDKCheckerProduct!]!
  ) {
    integrationStatus(
      gameId: $gameId
      gameStoreId: $gameStoreId
      platform: $platform
      productCode: $productCode
      isIaa: $isIaa
      isIap: $isIap
      useLogin: $useLogin
      products: $products
    ) {
      result
      data {
        platform
        name
        slug
        is_checkable
        status
        message
      }
      error {
        id
        message
      }
    }
  }
`

export const getAIDescription = gql`
  query GenerateDescription(
    $gameName: String!
    $gameGenre: String!
    $gameKeywords: [String!]!
    $gameControl: GameAIDescGenOptionInput!
    $gameTheme: GameAIDescGenOptionInput!
    $gameReward: GameAIDescGenOptionInput!
    $gameExplanation: GameExplanationInput
    $mediaUrl: String
    $gameTags: [String!]
  ) {
    aiPrediction(
      gameName: $gameName
      gameGenre: $gameGenre
      gameKeywords: $gameKeywords
      gameControl: $gameControl
      gameTheme: $gameTheme
      gameReward: $gameReward
      gameExplanation: $gameExplanation
      mediaUrl: $mediaUrl
      gameTags: $gameTags
    ) {
      longDescription
      shortDescription
    }
  }
`

export const getGoogleStoreListingConversions = gql`
  query getGoogleStoreListingConversions(
    $gameId: Int!
    $dateStart: Date!
    $dateEnd: Date!
    $genreId: Int!
    $countryCode: String!
  ) {
    googleStoreListingConversions(
      gameId: $gameId
      dateStart: $dateStart
      dateEnd: $dateEnd
      genreId: $genreId
      countryCode: $countryCode
    ) {
      cvr
      cvrVsPreviousPeriod
      acquisitions
      acquisitionsVsPreviousPeriod
      visitors
      visitorsVsPreviousPeriod
      details {
        date
        visitors
        acquisitions
        cvr
        cvrPeersMedian
        cvr25thPercentile
        cvr75thPercentile
      }
    }
  }
`

export const getGameStoreList = gql`
  query getGameStoreList($id: ID!) {
    game(id: $id) {
      name
      stores {
        store {
          name
        }
      }
      descriptions {
        language {
          code
          name
        }
        shortDescription
        longDescription
      }
      storeAssetAppIcon
      storeAssetFeatureGraphic
      storeAssetPhoneScreenshots {
        path
      }
      storeAssetTablet7inScreenshots {
        path
      }
      storeAssetTablet10inScreenshots {
        path
      }
    }
  }
`
export const getGoogleStoreListingExperiment = gql`
  query GoogleStoreListingExperiments($limit: Int!, $offset: Int!, $gameId: Int!) {
    googleStoreListingExperiments(limit: $limit, offset: $offset, gameId: $gameId) {
      id
      name
      startDate
      variants
      status
    }
  }
`

export const getGoogleStoreListingExperimentVariantSummaries = gql`
  query GoogleStoreListingExperimentVariantSummaries(
    $experimentId: ID!
    $installerType: GoogleStoreListingExperimentInstallerType!
  ) {
    googleStoreListingExperimentVariantSummaries(
      experimentId: $experimentId
      installerType: $installerType
    ) {
      name
      audience
      installersCurrent
      installersScaled
      performanceLower
      performanceUpper
      details {
        date
        installersScaled
      }
    }
  }
`

export const gameStoreBundles = gql`
  query GameStoreBundles($gameId: ID!, $storeId: ID!) {
    gameStoreBundles(limit: 1000, offset: 0, gameId: $gameId, storeId: $storeId) {
      name
      status
      size
      releaseNote
      createdAt
    }
  }
`

export const getGoogleStoreListingConversionsSumByCountry = gql`
  query getGoogleStoreListingConversionsSumByCountry(
    $gameId: Int!
    $dateStart: Date!
    $dateEnd: Date!
    $genreId: Int!
  ) {
    googleStoreListingConversionsSumByCountry(
      gameId: $gameId
      dateStart: $dateStart
      dateEnd: $dateEnd
      genreId: $genreId
    ) {
      countryCode
      totalVisitors
      totalAcquisitions
      cvr
    }
  }
`

export const getAudienceAnalyticsRoasProjections = gql`
  query GetAudienceAnalyticsRoasProjections(
    $gameId: ID!
    $storeIds: [Int!]!
    $startDate: String!
    $endDate: String!
    $maxHistoryDays: Int!
    $maxProjectionDays: Int!
    $gameRevenueSource: GameRevenueSource!
    $channels: [AudienceAnalyticsChannel!]
    $countryNames: [String!]
  ) {
    audienceAnalyticsRoasProjections(
      gameId: $gameId
      storeIds: $storeIds
      startDate: $startDate
      endDate: $endDate
      maxHistoryDays: $maxHistoryDays
      maxProjectionDays: $maxProjectionDays
      gameRevenueSource: $gameRevenueSource
      channels: $channels
      countryNames: $countryNames
    ) {
      day
      value
    }
  }
`

export const getGamePublisherUserPermissions = gql`
  query GetGamePublisherUserPermissions {
    gamePublisherUser {
      id
      permissions {
        id
        slug
        name
      }
    }
  }
`

export const sumGooglePlayConsoleConversion = gql`
  query SumGooglePlayConsoleConversion(
    $bundleId: String!
    $dateStart: Date!
    $dateEnd: Date!
    $countryCode: String!
  ) {
    sumGooglePlayConsoleConversion(
      bundleId: $bundleId
      dateStart: $dateStart
      dateEnd: $dateEnd
      countryCode: $countryCode
    ) {
      cvr
      cvrVsPreviousPeriod
      acquisitions
      acquisitionsVsPreviousPeriod
      visitors
      visitorsVsPreviousPeriod
    }
  }
`

export const sumGooglePlayConsoleConversionByCountryCode = gql`
  query SumGooglePlayConsoleConversionByCountryCode(
    $bundleId: String!
    $dateStart: Date!
    $dateEnd: Date!
  ) {
    sumGooglePlayConsoleConversionByCountryCode(
      bundleId: $bundleId
      dateStart: $dateStart
      dateEnd: $dateEnd
    ) {
      countryCode
      visitors
      acquisitions
      cvr
    }
  }
`

export const getCreativeInspiration = gql`
  query CreativeInspiration($id: ID!) {
    creativeInspiration(id: $id) {
      id
      gameName
      countries {
        code
        name
        id
        isDisabled
        metadatas {
          id
          type
          format
          value
        }
      }
      mediaUrl
      labsScore
      impression
      dimension
      fileFormat
      daysActive
      firstSeen
      lastSeen
      creativeType
      orientation
      adPlatforms {
        id
        name
      }
      tags {
        id
        name
        isActive
      }
      stores {
        id
        name
        iconUrl
        isActive
        os
      }
      createdAt
    }
  }
`

export const getCreativeInspirations = gql`
  query CreativeInspirations(
    $limit: Int!
    $offset: Int!
    $countryCode: String!
    $platformIn: [String!]
    $orientationIn: [CreativeInspirationOrientation!]
    $creativeTypeIn: [CreativeInspirationMediaType!]
    $tagsIn: [String!]
    $similarGamesIn: [String!]
  ) {
    creativeInspirations(
      limit: $limit
      offset: $offset
      countryCode: $countryCode
      platformIn: $platformIn
      orientationIn: $orientationIn
      creativeTypeIn: $creativeTypeIn
      tagsIn: $tagsIn
      similarGamesIn: $similarGamesIn
    ) {
      total
      data {
        id
        gameName
        countries {
          code
          name
          id
          isDisabled
          metadatas {
            type
            format
            value
          }
        }
        mediaUrl
        thumbnailUrl
        labsScore
        impression
        dimension
        fileFormat
        daysActive
        firstSeen
        lastSeen
        creativeType
        orientation
        createdAt
        adPlatforms {
          id
          name
        }
        tags {
          id
          name
          isActive
        }
        stores {
          id
          name
          iconUrl
          isActive
          os
        }
      }
    }
  }
`

export const getBestCreativeInspirations = gql`
  query BestCreativeInspirations(
    $limit: Int!
    $offset: Int!
    $countryCode: String!
    $tagCode: String
    $similarGamesIn: [String!]
  ) {
    bestCreativeInspirations(
      limit: $limit
      offset: $offset
      countryCode: $countryCode
      tagCode: $tagCode
      similarGamesIn: $similarGamesIn
    ) {
      id
      gameName
      countries {
        code
        name
        id
        isDisabled
        metadatas {
          type
          format
          value
        }
      }
      mediaUrl
      thumbnailUrl
      labsScore
      impression
      dimension
      fileFormat
      daysActive
      firstSeen
      lastSeen
      creativeType
      orientation
      createdAt
      adPlatforms {
        id
        name
      }
      tags {
        id
        name
        isActive
      }
      stores {
        id
        name
        iconUrl
        isActive
        os
      }
    }
  }
`

export const getGameStoresByGameId = gql`
  query GetGameStoresByGameId($gameId: ID!) {
    getGameStoresByGameId(gameId: $gameId) {
      storeId
      status
      id
      url
      bundleId
      adjustAppToken
      firebasePropertyId
      qsdkProductCode
      internalTestingUrl
      scaleStatus
      isShownScaleCompletedModal
      isShownScaleSdkCheckerCompletedModal
      isShownScaleSdkIntegrationCompletedModal
      integrations {
        slug
        name
        iconUrl
        status
        method
      }

      statusDetail {
        verboseName
        fontColor
      }
      store {
        id
        name
        iconUrl
        os
      }
    }
  }
`

export const findGameStoreByGameIdStoreId = gql`
  query FindGameStoreByGameIdStoreId($gameId: ID!, $storeId: ID!) {
    findGameStoreByGameIdStoreId(gameId: $gameId, storeId: $storeId) {
      id
      storeId
      status
      game {
        id
        name
      }
      store {
        iconUrl
      }
      integrations {
        slug
        method
      }
      qsdkOpenId
      qsdkOpenKey
      qsdkProductCode
      qsdkCallbackKey
      firebaseAppId
      firebaseConfigUrl
      facebookAppId
      facebookAppSecret
      facebookClientToken
      adjustAppToken
      adjustRevenueToken
      adjustSecretKey
      bundleId
      firebasePropertyId
    }
  }
`

export const gameStoreAssetUploadStatus = gql`
  query GameStoreAssetUploadStatus($uploadID: String!) {
    gameStoreAssetUploadStatus(uploadID: $uploadID) {
      percentage
      status
      assetUrl
    }
  }
`

export const gameStoreBundlesByGameStoreId = gql`
  query GameStoreBundlesByGameStoreId($gameStoreId: ID!, $limit: Int, $offset: Int) {
    gameStoreBundlesByGameStoreId(gameStoreId: $gameStoreId, limit: $limit, offset: $offset) {
      id
      gameStoreId
      createdAt
      name
      path
      url
      releaseNote
      size
      status
      feedbackNote
      errorLogUrl
      statusDetail {
        id
        verboseName
        fontColor
      }
    }
  }
`
export const bookmarkedCreativeInspiration = gql`
  query GetGamePublisherUserCreativeInspiration {
    gamePublisherUser {
      creativeInspirations {
        id
      }
    }
  }
`

export const getSetupInformationByGameId = gql`
  query GetSetupInformationByGameId($id: ID!) {
    game(id: $id) {
      appContentMap
      trackListMap
      iaaListMap
      skus {
        skuId
        name
        price
        description
        note
        imageUrl
      }
      rawAssets {
        mediaUrl
        orientation
        mimeType
      }
    }
  }
`

export const supersetConfiguration = gql`
  query GetSupersetConfig($id: String!) {
    supersetConfiguration(id: $id)
  }
`

export const getCampaignChannel = gql`
  query CampaignChannel {
    campaignChannel {
      code
      adjustNetworkName
    }
  }
`

export const getSimilarGames = gql`
  query GetSimilarGames($name: String, $withId: [String!], $genre: String!) {
    similarGames(name: $name, withId: $withId, genre: $genre) {
      id
      name
      iconURL
      category
    }
  }
`

export const getActivityHistories = gql`
  query GetActivityHistories($key: String!) {
    getActivityHistories(key: $key)
  }
`

export const analyticsAds = gql`
  query AnalyticsAds(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $revenueType: String!
  ) {
    analyticsAds(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      revenueType: $revenueType
    ) {
      revenue
      adSpending
      install
      ROAS
    }
  }
`

export const breakevenProjection = gql`
  query BreakevenProjection(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $revenueType: String!
  ) {
    breakevenProjection(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      revenueType: $revenueType
    ) {
      expectedBreakeven
      isReady
    }
  }
`

export const addOnProducts = gql`
  query AddOnProducts($tag: AddOnCategory) {
    addOns(tag: $tag) {
      id
      slug
      title
      description
      objective
      keyFeatures
      tags
      iconUrl
      status
    }
  }
`

export const addOnProduct = gql`
  query AddOnProduct($productSlug: String!) {
    addOn(productSlug: $productSlug) {
      id
      slug
      title
      description
      objective
      keyFeatures
      tags
      iconUrl
      status
    }
  }
`

export const gameAddOns = gql`
  query GameAddOns($gameId: ID!) {
    gameAddOns(gameId: $gameId) {
      id
      slug
      title
      description
      objective
      keyFeatures
      tags
      iconUrl
      status
    }
  }
`

export const expectedBreakevenProjection = gql`
  query ExpectedBreakevenProjection(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $revenueType: String!
  ) {
    expectedBreakevenProjection(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      revenueType: $revenueType
    ) {
      expectedBreakevenMed
      expectedBreakevenUp
      expectedBreakevenBot
      isReady
    }
  }
`

export const overviewInfoDataAnalytic = gql`
  query OverviewInfoDataAnalytic(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $revenueType: String!
    $source: SourceType!
  ) {
    infoDataAnalytic(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      revenueType: $revenueType
      source: $source
    ) {
      isDataReady
      revenueActual
      revenueIncrement
      installActual
      installIncrement
      dauAvgActual
      dauAvgIncrement
      cvrActual
      cvrIncrement
      tspuActual
      tspuIncrement
      spuActual
      spuIncrement
      installRateActual
      installRateIncrement
      adSpendingActual
      adSpendingIncrement
    }
  }
`

export const installCountryLists = gql`
  query TotalInstallByCountries(
    $id: ID!
    # $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $revenueType: String!
  ) {
    totalInstallByCountries(
      id: $id
      # countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      revenueType: $revenueType
      limit: 1000
    ) {
      roasD0Min
      roasD0Max
      data {
        countryCode
        roasD0
        totalInstall
        breakeventPoint
        ltv
        projRevenue
      }
    }
  }
`

export const dailyDataAnalytics = gql`
  query DailyDataAnalytics(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $revenueType: String!
    $source: SourceType!
  ) {
    dailyDataAnalytics(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      revenueType: $revenueType
      source: $source
    ) {
      date
      revenueActual
      installActual
      dauAvgActual
      conversion
      installRate
      spu
      tspu
      adSpending
    }
  }
`

export const roasProjection = gql`
  query RoasProjection(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $revenueType: String!
  ) {
    roasProjection(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      revenueType: $revenueType
    ) {
      cohort_value
      roas_projection
    }
  }
`

export const adPerformanceDetail = gql`
  query AdPerformanceDetail(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $revenueType: String!
    $viewBy: AudienceAnalyticsAdvanceInformationViewBy!
  ) {
    adPerformanceDetail(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      revenueType: $revenueType
      viewBy: $viewBy
    ) {
      country
      channel
      adSpending
      totalRevenue
      totalInstall
      eCPI
      roasD0
    }
  }
`

export const retentionCohortPerformance = gql`
  query RetentionCohortPerformance(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $revenueType: String!
    $viewBy: AudienceAnalyticsAdvanceInformationViewBy!
  ) {
    retentionCohortPerformance(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      revenueType: $revenueType
      viewBy: $viewBy
    ) {
      country
      channel
      revenue
      install
      ecpi
      ad_spending
      d0_roas
      d1_roas
      d2_roas
      d3_roas
      d4_roas
      d5_roas
      d6_roas
      d7_roas
      d14_roas
      d21_roas
      d30_roas
      d60_roas
      d90_roas
      d120_roas
      d150_roas
      d180_roas
      retention_d1
      retention_d2
      retention_d3
      retention_d4
      retention_d5
      retention_d6
      retention_d7
      retention_d14
      retention_d30
    }
  }
`

export const retentionCohortPerformanceByDate = gql`
  query RetentionCohortPerformanceByDate(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $revenueType: String!
  ) {
    retentionCohortPerformanceByDate(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      revenueType: $revenueType
    ) {
      date_key
      total_user_d0
      retention_d1
      retention_d2
      retention_d3
      retention_d4
      retention_d5
      retention_d6
      retention_d7
    }
  }
`

export const getGameStoreIntegrationBySlug = gql`
  query GetGameStoreIntegrationBySlug($storeIds: [String!]!, $integrationSlug: String!) {
    getGameStoreIntegrationBySlug(storeIds: $storeIds, integrationSlug: $integrationSlug) {
      id
      gameStoreId
      integrationId
      integration {
        slug
        name
        iconUrl
      }
      status
    }
  }
`

export const getIntegrationBySlug = gql`
  query GetIntegrationBySlug($slug: String!) {
    getIntegrationBySlug(slug: $slug) {
      slug
      name
      iconUrl
    }
  }
`

export const marketingCasualPerformance = gql`
  query MarketingCasualPerformance(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $revenueType: String!
  ) {
    marketingCasualPerformance(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      revenueType: $revenueType
    ) {
      dateKey
      install
      cohortRetentionUser
      remainingUser
      adCost
      cpi
      revenueToDate
      arpuToDate
      roasToDate
      projRoasD30
      projRoasD45
      projRoasD60
      projRoasD90
      projLTVD90
      projEndStateLTV
      expectedPaybackPeriod

      roasD0
      roasD1
      roasD2
      roasD3
      roasD4
      roasD5
      roasD6
      roasD7
      roasD10
      roasD14
      roasD21
      roasD28
      roasD30

      retentionRateD1
      retentionRateD2
      retentionRateD3
      retentionRateD4
      retentionRateD5
      retentionRateD6
      retentionRateD7
      retentionRateD10
      retentionRateD14
      retentionRateD21
      retentionRateD28
      retentionRateD30

      ltvD0
      ltvD1
      ltvD2
      ltvD3
      ltvD4
      ltvD5
      ltvD6
      ltvD7
      ltvD10
      ltvD14
      ltvD21
      ltvD28
      ltvD30
    }
  }
`

export const gameCreativeFeedbacks = gql`
  query GameCreativeFeedbacks($limit: Int!, $offset: Int!) {
    gameCreativeFeedbacks(limit: $limit, offset: $offset) {
      id
      isLike
      gameCreativeId
      feedback
    }
  }
`

export const getIntegratedAdUnits = gql`
  query GetIntegratedAdUnits(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
  ) {
    getIntegratedAdUnits(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      ad_unit
      ad_format
      aipu
      ad_impression
      est_revenue
      fill_rate
    }
  }
`

export const dailyTrendLineAnalytics = gql`
  query DailyTrendLineAnalytics(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $revenueType: String!
    $source: SourceType!
  ) {
    dailyTrendLineAnalytics(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      revenueType: $revenueType
      source: $source
    ) {
      dateKey
      revenue
      gross_profit
      install
      cpi
      adSpending
      roasD0
      dau
      arpu
    }
  }
`

export const getMediationByCountry = gql`
  query GetMediationByCountry(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
  ) {
    getMediationByCountry(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      date_key
      country
      aipu
      rpm
      fill_rate
      aipud0
      est_revenue
    }
  }
`

export const getMediationAipuCohort = gql`
  query GetMediationAipuCohort(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
  ) {
    getMediationAipuCohort(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      date_key
      aipu_d0
      aipu_d1
      aipu_d2
      aipu_d3
      aipu_d4
      aipu_d5
      aipu_d6
      aipu_d7
    }
  }
`

export const getRetentionCohortPerformance = gql`
  query GetRetentionCohortPerformance(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $revenueType: String!
    $metric: CohortMetricType!
    $dimension: DimensionType!
    $daysPeriod: [Int!]!
    $source: SourceType!
  ) {
    cohortPerformance(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      revenueType: $revenueType
      metric: $metric
      dimension: $dimension
      daysPeriod: $daysPeriod
      source: $source
    ) {
      dimension_name
      total_user
      cohort_days {
        day
        value
      }
    }
  }
`

export const roles = gql`
  query Roles {
    roles {
      name
      slug
    }
  }
`

export const getAppStoreInformation = gql`
  query GetAppStoreInformation($appStoreUrl: String!) {
    appStoreInformation(appStoreUrl: $appStoreUrl) {
      id
      title
      bundleId
      platform
      success
      iconUrl
      error
    }
  }
`

export const getCustomPerformance = gql`
  query CustomPerformance(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $revenueType: String!
    $dimensions: [DimensionType!]!
    $metrics: MetricsInput!
    $source: SourceType!
  ) {
    customPerformance(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      revenueType: $revenueType
      dimensions: $dimensions
      metrics: $metrics
      source: $source
    )
  }
`

export const getSDKUsers = gql`
  query getSDKUsers(
    $page: Int!
    $limit: Int!
    $productCodes: [String!]!
    $regTimeFrom: Int
    $regTimeTo: Int
    $uid: Int
    $username: String
    $roleName: String
    $roleID: String
  ) {
    sdkUsers(
      limit: $limit
      page: $page
      productCodes: $productCodes
      regTimeFrom: $regTimeFrom
      regTimeTo: $regTimeTo
      uid: $uid
      username: $username
      gameRoleName: $roleName
      gameRoleID: $roleID
    ) {
      error
      page
      totalPage
      limit
      totalData
      data {
        uid
        username
        productID
        channelCode
        isGuest
        userStatus
        regTime
        profile {
          uid
          nickName
          regType
          regIP
          regDevice
          lastLoginTime
          loginTimes
          firstPayTime
          lastPayTime
          payTimes
          payAmount
        }
        product {
          id
          productCode
          productName
          callbackURL
        }
      }
    }
  }
`
export const getSDKRoles = gql`
  query getSDKRoles($page: Int!, $limit: Int!, $uid: Int!) {
    sdkRoles(limit: $limit, page: $page, uid: $uid) {
      error
      page
      totalPage
      limit
      totalData
      data {
        id
        serverName
        gameRoleName
        gameRoleId
      }
    }
  }
`
export const getSDKOrders = gql`
  query getSDKOrders(
    $page: Int!
    $limit: Int!
    $productCodes: [String!]!
    $createTimeFrom: Int
    $createTimeTo: Int
    $uid: Int
    $username: String
    $roleName: String
    $roleID: String
  ) {
    sdkOrders(
      limit: $limit
      page: $page
      productCodes: $productCodes
      createTimeFrom: $createTimeFrom
      createTimeTo: $createTimeTo
      uid: $uid
      username: $username
      gameRoleName: $roleName
      gameRoleID: $roleID
    ) {
      error
      page
      totalPage
      limit
      totalData
      data {
        id
        orderNo
        productId
        channelCode
        uid
        username
        usdAmount
        dealUSDAmount
        createTime
        payStatus
        asyncStatus
        productOrderNo
        orderGoodsId
        orderSubject
        callbackURL
        extrasParams
        serverName
        roleName
        roleLevel
        product {
          id
          productCode
          productName
          callbackURL
        }
        googleOrder {
          orderId
          orderNo
        }
      }
    }
  }
`
export const getUaOverview = gql`
  query UaOverview(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $revenueType: String!
    $source: SourceType!
  ) {
    uaOverview(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      revenueType: $revenueType
      source: $source
    ) {
      datasets {
        date
        cpi
        ad_spending
        roas_d0
        revenue
        iaa_revenue
        iap_revenue
        ltv
        impression
        click
        install
        login
        register
        dau
        arpu
      }
      total_cpi
      total_ad_spending
      total_arpu
      total_click
      total_impression
      total_install
      total_login
      total_register
      total_ltv
      total_revenue
      total_roas_d0
      total_dau
    }
  }
`

export const getLTVPerformance = gql`
  query GetLTVPerformance(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $revenueType: String!
  ) {
    ltvPerformance(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      revenueType: $revenueType
    ) {
      ltvActual
      ltvIncrement
      dataset {
        dateKey
        value
      }
    }
  }
`

export const findSDKOrder = gql`
  query findSDKOrder($id: Int!) {
    sdkOrder(id: $id) {
      error
      data {
        id
        orderNo
        productId
        channelCode
        uid
        username
        usdAmount
        dealUSDAmount
        createTime
        payStatus
        asyncStatus
        productOrderNo
        orderGoodsId
        orderSubject
        callbackURL
        extrasParams
        serverName
        roleName
        roleLevel
        product {
          id
          productCode
          productName
          callbackURL
        }
        googleOrder {
          orderId
          orderNo
        }
      }
    }
  }
`
export const getUserActiveDetail = gql`
  query GetUserActiveDetail(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $source: SourceType!
  ) {
    getUserActiveDetail(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      source: $source
    ) {
      userActiveActual
      userActiveIncrement
      dataset {
        dateKey
        value
      }
    }
  }
`

export const findSDKUser = gql`
  query findSDKUser($uid: Int!) {
    sdkUser(uid: $uid) {
      error
      data {
        uid
        username
        productID
        channelCode
        isGuest
        userStatus
        regTime
        profile {
          uid
          nickName
          regType
          regIP
          regDevice
          lastLoginTime
          loginTimes
          firstPayTime
          lastPayTime
          payTimes
          payAmount
        }
        product {
          id
          productCode
          productName
          callbackURL
        }
      }
    }
  }
`
export const getDailyPerformance = gql`
  query DailyPerformanceByMetric(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $revenueType: String!
    $dimension: DimensionType!
    $metric: DailyMetricType!
  ) {
    dailyPerformanceByMetric(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      revenueType: $revenueType
      dimension: $dimension
      metric: $metric
    ) {
      key
      dataset {
        dateKey
        value
      }
      total
    }
  }
`

export const getALUPerformance = gql`
  query GetALUPerformance(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $source: SourceType!
  ) {
    aluPerformance(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      source: $source
    ) {
      aluActual
      aluIncrement
      dataset {
        dateKey
        value
      }
    }
  }
`

export const getSDKOrderCallGames = gql`
  query getSDKOrderCallGames($page: Int!, $limit: Int!, $orderNo: String!) {
    sdkOrderCallGames(limit: $limit, page: $page, orderNo: $orderNo) {
      error
      page
      totalPage
      limit
      totalData
      data {
        id
        createTime
        status
      }
    }
  }
`
export const getComparisonCohortMetricDayByDay = gql`
  query GetComparisonCohortMetricDayByDay(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $revenueType: String!
    $metrics: [CohortMetricType!]!
    $source: SourceType!
  ) {
    getComparisonCohortMetricDayByDay(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      revenueType: $revenueType
      metrics: $metrics
      source: $source
    ) {
      date
      time_spent_per_user
      session_per_user
    }
  }
`

export const getUserTenureData = gql`
  query GetUserTenureData(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $viewBy: UserTenureViewBy!
    $source: SourceType!
  ) {
    getUserTenureData(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      viewBy: $viewBy
      source: $source
    ) {
      key
      value
    }
  }
`

export const getMediationAdUnit = gql`
  query Mediations($gameStoreId: String!) {
    mediations(gameStoreId: $gameStoreId) {
      id
      appId
      mediationAdUnit {
        id
        mediationId
        adUnitId
        adType
        adUnitName
        labsAdUnitName
        adUnitStatus
      }
    }
  }
`

export const getAvailableAdUnitId = gql`
  query AvailableAdUnitId($gameStoreId: String!) {
    availableAdUnitId(gameStoreId: $gameStoreId) {
      id
      appId
      mediationAdUnit {
        id
        mediationId
        adUnitId
        adType
        adUnitName
        labsAdUnitName
        adUnitStatus
      }
    }
  }
`

export const getMediationDailyPerformanceSummary = gql`
  query GetMediationDailyPerformanceSummary(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
  ) {
    getMediationDailyPerformanceSummary(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
    ) {
      dataset {
        date
        iaa_revenue
        rpm
        aipu
        fill_rate
      }
      total_iaa_revenue
      total_aipu
      total_aipu_d0
      total_fill_rate
      total_rpm
    }
  }
`

export const getMediationCohortPerformanceSummary = gql`
  query GetMediationCohortPerformanceSummary(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
  ) {
    getMediationCohortPerformanceSummary(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
    ) {
      date
      cohort_day
      aipu
    }
  }
`

export const getMediationInDepthInsight = gql`
  query GetInDepthInsights(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $dimension: DimensionType!
  ) {
    aipu: cohortPerformance(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      revenueType: "all"
      metric: AIPU
      dimension: $dimension
      daysPeriod: [0]
      source: APP_LOVIN
    ) {
      dimension_name
      total_user
      cohort_days {
        day
        value
      }
    }
    tspu_d0: cohortPerformance(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      revenueType: ""
      metric: TOTAL_TIME_SPENT
      dimension: $dimension
      daysPeriod: [0]
      source: QSDK
    ) {
      dimension_name
      total_user
      cohort_days {
        day
        value
      }
    }
    retention: cohortPerformance(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      revenueType: ""
      metric: USER_RETENTION_RATE
      dimension: $dimension
      daysPeriod: [1]
      source: QSDK
    ) {
      dimension_name
      total_user
      cohort_days {
        day
        value
      }
    }
  }
`

export const getAdjustDataCorrectnessChecker = gql`
  query CheckAdjustPurchaseEvent(
    $advertisingID: String!
    $appToken: String!
    $authToken: String!
    $eventToken: String!
    $lastHour: Int!
  ) {
    checkAdjustPurchaseEvent(
      advertisingID: $advertisingID
      appToken: $appToken
      authToken: $authToken
      eventToken: $eventToken
      lastHour: $lastHour
    ) {
      statusCode
      error
      data {
        lastAppVersion
        trackerName
        installTime
        clickTime
        installState
        eventToken
        lastEventTime
        totalEvent
        totalAmount
      }
    }
  }
`

export const checkFirebaseRealtimeEvent = gql`
  query CheckFirebaseRealtimeEvent($propertyID: String!, $platform: String!, $eventName: String!) {
    checkFirebaseRealtimeEvent(
      propertyID: $propertyID
      eventName: $eventName
      platform: $platform
    ) {
      eventName
      platform
      minutesAgo
      eventCount
    }
  }
`

export const getMediationInsight = gql`
  query GetMediationInsight(
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $packageNames: [String!]!
    $source: SourceType!
  ) {
    getMediationInsight(
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      packageNames: $packageNames
      source: $source
    ) {
      aipu {
        total
        dataset {
          date
          value
        }
      }
      tspu {
        total
        dataset {
          date
          value
        }
      }
      retention {
        total
        dataset {
          date
          value
        }
      }
      roas {
        total
        dataset {
          date
          value
        }
      }
    }
  }
`
export const getUsers = gql`
  query GetUserListsByPublisherId {
    getUserListsByPublisherId {
      displayName
      email
      status
      roles {
        name
      }
    }
  }
`

// Custom Dashboard Structure

export const getPaidUserAndPayRateDataset = gql`
  query GetPaidUserAndPayRateDataset(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
  ) {
    getPaidUserAndPayRateDataset(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      date
      paidNewUser {
        type
        value
      }
      paidExistingUser {
        type
        value
      }
      payRateNewUser {
        type
        value
      }
      payRateExistingUser {
        type
        value
      }
    }
  }
`

export const userRoles = gql`
  query UserRoles($email: String!) {
    getRolesByEmail(email: $email) {
      name
      slug
    }
  }
`

export const getARPPUAndPaidUserDataset = gql`
  query GetARPPUAndPaidUserDataset(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
    $revenueType: String!
  ) {
    getARPPUAndPaidUserDataset(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
      revenueType: $revenueType
    ) {
      date
      arppu {
        type
        value
      }
      paidUser {
        type
        value
      }
    }
  }
`

export const getDailyUserTenurePurchaseDataset = gql`
  query GetDailyUserTenurePurchaseDataset(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
  ) {
    getDailyUserTenurePurchaseDataset(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      userTenure {
        type
        value
      }
      revenue {
        type
        value
      }
    }
  }
`

export const getUserProjectionActualDNU = gql`
  query GetUserProjectionActualDNU(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
  ) {
    getUserProjectionActualDNU(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      date
      estimatedRemainingUser {
        type
        value
      }
      actualTotalActiveUser {
        type
        value
      }
      actualOrganicInstall {
        type
        value
      }
    }
  }
`

export const getUserProjectionProjectionDNU = gql`
  query GetUserProjectionProjectionDNU(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
  ) {
    getUserProjectionProjectionDNU(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      date
      estimatedRemainingUser {
        type
        value
      }
      actualTotalActiveUser {
        type
        value
      }
      actualOrganicInstall {
        type
        value
      }
    }
  }
`

export const getRevenueProjectionActualDNU = gql`
  query GetRevenueProjectionActualDNU(
    $packageNames: [String!]!
    $countries: [String!]!
    $dateFrom: String!
    $dateTo: String!
  ) {
    getRevenueProjectionActualDNU(
      packageNames: $packageNames
      countries: $countries
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      date
      actualRevenue {
        type
        value
      }
    }
  }
`

export const getGamesByPublisherId = gql`
  query GetGamesByPublisherId {
    games(limit: 1000, offset: 0) {
      name
      gameLogoUrl
      updatedAt
      integrateInAppAds
      integrateInAppPurchase
      countries {
        id
        code
        name
      }
      countryGroups {
        id
        code
        name
      }
      genres {
        id
        name
        code
      }
      tags {
        id
        name
        code
      }
      sdkIntegrationStatus
      uniqueSellingPoints
      similarGames {
        id
        name
      }
      similarGameSubmissions {
        name
      }
      isIntegrated
      stores {
        id
        url
        updatedAt
        status
        gameDevelopmentStage
        gameEngine
        store {
          id
          name
          os
          iconUrl
        }
        integrations {
          id
          slug
          name
          status
          method
        }
        bundleId
      }
    }
  }
`

export const findGameStoreById = gql`
  query FindGameStoreById($gameStoreId: String!) {
    findGameStoreById(gameStoreId: $gameStoreId) {
      id
      bundleId
      firebaseAppId
      firebasePropertyId
      qsdkOpenId
      qsdkOpenKey
      qsdkProductCode
      qsdkCallbackKey
      facebookAppId
      facebookAppSecret
      adjustAppToken
      adjustSecretKey
      mediation {
        id
        appId
      }
      url
      gameDevelopmentStage
      gameEngine
      status
      integrations {
        id
        slug
        name
        status
        method
      }
      game {
        id
        gameLogoUrl
        name
        uniqueSellingPoints
        genres {
          id
          name
          code
        }
        tags {
          id
          name
          code
        }
        countries {
          id
          name
          code
        }
        countryGroups {
          id
          name
          code
        }
        blacklistedCountries {
          id
          name
          code
        }
        similarGames {
          id
          name
        }
      }
      store {
        id
        name
        os
      }
    }
  }
`

export const checkGamePublisherStatusByEmail = gql`
  query GamePublisherStatus($email: String!) {
    gamePublisherStatus(email: $email)
  }
`

export const getLoginLogs = gql`
  query getLoginLogs($page: Int!, $limit: Int!, $productCodes: [String!]!, $uid: Int!) {
    sdkLoginLogs(limit: $limit, page: $page, productCodes: $productCodes, uid: $uid) {
      error
      page
      totalPage
      limit
      totalData
      data {
        uid
        productId
        channelCode
        loginStatus
        createTime
        deviceId
        loginType
        loginIp
        product {
          id
          productCode
          productName
          callbackURL
        }
      }
    }
  }
`
