import { useRef } from 'react'

const INTERVAL_TIME = 1000

type UseCountdownReturn = {
  getTime: () => number
  startCountdown: (callback: () => void) => void
  resetCountdown: () => void
}

export const useCountdown = (initialTime: number): UseCountdownReturn => {
  const timeRef = useRef<number>(initialTime)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const startCountdown = (callback: () => void) => {
    if (intervalRef.current) clearInterval(intervalRef.current)

    intervalRef.current = setInterval(() => {
      if (timeRef.current <= 0) {
        if (intervalRef.current) clearInterval(intervalRef.current)
        callback()
      } else {
        timeRef.current -= INTERVAL_TIME
      }
    }, INTERVAL_TIME)
  }

  const resetCountdown = () => {
    if (intervalRef.current) clearInterval(intervalRef.current)
    timeRef.current = initialTime
  }

  const getTime = () => timeRef.current

  return { getTime, startCountdown, resetCountdown }
}

export const formatTime = (milliseconds: number): string => {
  const totalSeconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60

  const paddedMinutes = String(minutes).padStart(2, '0')
  const paddedSeconds = String(seconds).padStart(2, '0')

  return `${paddedMinutes}:${paddedSeconds}`
}
