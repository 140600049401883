import { Flex, Image, Modal, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import useModalStore from 'stores/useModalStore'

import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Text } = Typography

export default function ComingSoonModal() {
  const { t } = useTranslation()
  const { isComingSoonModalShow, closeAll } = useModalStore()

  return (
    <Modal
      title={false}
      centered
      open={isComingSoonModalShow}
      onCancel={closeAll}
      footer={false}
      width={663}>
      <Flex vertical justify='center' align='center' gap={30} style={{ padding: 30 }}>
        <Image
          src='/images/illustration/coming-soon-board.webp'
          height={123}
          width={134}
          preview={false}
        />

        <Text style={{ color: colors.gray90 }}>{t('upcoming_modal_description')}</Text>
        <LabsButton width='wide' onClick={closeAll}>
          {t('general_okay')}
        </LabsButton>
      </Flex>
    </Modal>
  )
}
