import { Col, Divider, Row, Typography } from 'antd'
import Modal from 'antd/es/modal/Modal'

import useModalStore from 'stores/useModalStore'

import colors from 'theme/colors'

const { Text, Title } = Typography
export default function MediationDataGlossaryModal() {
  const { isMediationDataGlossaryModalShow, closeAll } = useModalStore()

  return (
    <Modal
      centered
      width={728}
      open={isMediationDataGlossaryModalShow}
      onCancel={closeAll}
      title={
        <Title level={4} style={{ color: colors.gray90, margin: 0 }}>
          Data Glossary
        </Title>
      }
      footer={false}
      styles={{
        header: {
          margin: 0,
        },
        // content: {
        //   background:
        //     'linear-gradient(25deg, rgba(54, 133, 241, 0.00) 6.78%, rgba(54, 133, 241, 0.20) 94.63%), #FFF',
        // },
      }}>
      <Divider style={{ marginTop: 12 }} />
      <Row gutter={[0, 4]}>
        <Col span={6}>
          <Text style={{ color: colors.gray90 }}>Ad Revenue</Text>
        </Col>
        <Col span={18}>
          <Text style={{ color: colors.gray90 }}>
            Revenue that comes from In-App Ads in the game
          </Text>
        </Col>

        <Col span={6}>
          <Text style={{ color: colors.gray90 }}>Daily AIPU</Text>
        </Col>
        <Col span={18}>
          <Text style={{ color: colors.gray90 }}>
            Daily Average Ad Impression per User (Ad Impression / DAU)
          </Text>
        </Col>

        <Col span={6}>
          <Text style={{ color: colors.gray90 }}>AIPU D+0</Text>
        </Col>
        <Col span={18}>
          <Text style={{ color: colors.gray90 }}>
            Average Ad Impression per User D+0 (Ad Impression D+0 / DNU)
          </Text>
        </Col>

        <Col span={6}>
          <Text style={{ color: colors.gray90 }}>RPM</Text>
        </Col>
        <Col span={18}>
          <Text style={{ color: colors.gray90 }}>
            Revenue per Mile - (IAA Revenue / Impressions) x 1,000
          </Text>
        </Col>

        <Col span={6}>
          <Text style={{ color: colors.gray90 }}>Ad Impression</Text>
        </Col>
        <Col span={18}>
          <Text style={{ color: colors.gray90 }}>
            How many ads are seen in the game for our users
          </Text>
        </Col>

        <Col span={6}>
          <Text style={{ color: colors.gray90 }}>Ad Impression D+0</Text>
        </Col>
        <Col span={18}>
          <Text style={{ color: colors.gray90 }}>
            How many ads are seen in the game for our new users
          </Text>
        </Col>

        <Col span={6}>
          <Text style={{ color: colors.gray90 }}>ADPV</Text>
        </Col>
        <Col span={18}>
          <Text style={{ color: colors.gray90 }}>
            Average Advertisement Page View per User (Request / DAU)
          </Text>
        </Col>

        <Col span={6}>
          <Text style={{ color: colors.gray90 }}>Request</Text>
        </Col>
        <Col span={18}>
          <Text style={{ color: colors.gray90 }}>No of an Ad was successfully requested</Text>
        </Col>

        <Col span={6}>
          <Text style={{ color: colors.gray90 }}>Fill Rate</Text>
        </Col>
        <Col span={18}>
          <Text style={{ color: colors.gray90 }}>
            Percentage of Ad Request that were filled with an Ad (Response / Request)
          </Text>
        </Col>
      </Row>
    </Modal>
  )
}
