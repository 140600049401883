import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

import { SETTING_UP_ACCOUNT_ROUTE } from 'constants/Routes'

const SettingUpAccount = lazy(() => import('pages/setting-up-account'))

const SettingUpAccountRoute = (
  <Route
    path={SETTING_UP_ACCOUNT_ROUTE}
    element={
      <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
        <SettingUpAccount />
      </Suspense>
    }
  />
)

export default SettingUpAccountRoute
