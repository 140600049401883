import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  GameDevelopmentStage,
  GameGenre,
  GameGenresQuery,
  GameGenresQueryVariables,
  GetAppStoreInformationQuery,
  GetAppStoreInformationQueryVariables,
  UpdateGameDetailMutation,
  UpdateGameDetailMutationVariables,
} from '__generated__/gql/graphql'
import { Flex, Form, Image, Input, message, Radio, Spin } from 'antd'
import { CSSProperties, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { updateGameDetail } from 'services/graphql/mutations'
import { gameGenresQuery, getAppStoreInformation } from 'services/graphql/queries'
import { useDebounce } from 'use-debounce'

import { GAME_ENGINE_OPTIONS } from 'constants/Form'

import useModalStore from 'stores/useModalStore'

import useUserInfo from 'hooks/useUserInfo'

import InputGroup from 'components/commons/form/input-group'
import SelectOptions from 'components/commons/form/select-options'
import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

import { GameInfoData } from '../type'
import { GameDetailsFormData } from './type'

const radioButtonStyle: CSSProperties = {
  borderWidth: 1,
  borderColor: colors.gray40,
  borderStyle: 'solid',
  padding: '8px 16px 8px 16px',
  borderRadius: 8,
  height: 40,
  alignItems: 'center',
}

const APPLE_APP_STORE_BASE_URL_REGEX = /^https:\/\/apps\.apple\.com\//
const GOOGLE_PLAY_STORE_BASE_URL_REGEX = /^https:\/\/play\.google\.com\//
export default function GameDetailTab({ gameStore }: GameInfoData) {
  const client = useApolloClient()
  const { t } = useTranslation()
  const [gameDetailForm] = Form.useForm()

  const appStoreUrl = Form.useWatch(['game store url'], gameDetailForm)

  const { getGames } = useUserInfo()
  const { closeAll } = useModalStore()

  const [storeUrlDebounceValue] = useDebounce<string>(appStoreUrl as string, 500)

  const { data: getAppStoreInformationData, loading: getAppStoreInformationLoading } = useQuery<
    GetAppStoreInformationQuery,
    GetAppStoreInformationQueryVariables
  >(getAppStoreInformation, {
    variables: {
      appStoreUrl: storeUrlDebounceValue,
    },
    skip: !storeUrlDebounceValue,
  })

  const appData = useMemo(() => {
    return {
      iconUrl: getAppStoreInformationData?.appStoreInformation.iconUrl,
      bundleId: getAppStoreInformationData?.appStoreInformation.bundleId,
      platform: getAppStoreInformationData?.appStoreInformation.platform,
      title: getAppStoreInformationData?.appStoreInformation.title,
      error: getAppStoreInformationData?.appStoreInformation.error,
    }
  }, [getAppStoreInformationData])

  const { data: genres, loading: isGenreLoading } = useQuery<
    GameGenresQuery,
    GameGenresQueryVariables
  >(gameGenresQuery)

  const [updateGames, { loading: isUpdateLoading }] = useMutation<
    UpdateGameDetailMutation,
    UpdateGameDetailMutationVariables
  >(updateGameDetail)

  const genreOptions = useMemo(() => {
    const options = genres?.gameGenres?.map((genre: GameGenre) => {
      return { label: genre.name, value: genre.code, disabled: genre.isDisabled }
    })

    return options || []
  }, [genres])

  const handleSubmit = useCallback(
    (formData: GameDetailsFormData) => {
      if (!gameStore) return
      updateGames({
        variables: {
          gameStoreId: gameStore.id,
          gameDevelopmentStage: formData['your stage'],
          gameTitle: formData['game title'],
          gameEngine: formData['game engine'],
          gameGenre: formData['game genres'],
          appUrl: formData['game store url'],
          bundleId: appData.bundleId || '',
          logoUrl: appData.iconUrl || '',
          uniqueSellingPoints: gameStore.game.uniqueSellingPoints || '',
          gameTag: gameStore.game.tags.map((tag) => tag.code) || [],
          similarGame: (gameStore.game.similarGames || []).map((game) => game.id) || [],
          targetRegion:
            [
              ...gameStore.game.countries.map((country) => country.code),
              ...gameStore.game.countryGroups.map((country) => country.code),
            ] || [],
          blocklistRegion: gameStore.game.blacklistedCountries.map((country) => country.code) || [],
        },
      }).then(() => {
        client.refetchQueries({
          include: ['GetGamesByPublisherId', 'GetGamePublisherUser'],
        })
        message.success('Game details updated successfully')
        closeAll()
        getGames()
      })
    },
    [gameStore, updateGames, appData],
  )

  useEffect(() => {
    gameDetailForm.setFieldsValue({
      'game title': gameStore?.game.name,
      'your stage': gameStore?.gameDevelopmentStage,
      'game store url': gameStore?.url,
      'game engine': gameStore?.gameEngine,
      'game bundle id': gameStore?.bundleId,
      'game genres': gameStore?.game.genres.map((genre) => genre.code) || [],
    })
  }, [gameStore])

  useEffect(() => {
    if (!appStoreUrl) return
    gameDetailForm.validateFields()

    if (!appData.platform) {
      gameDetailForm.setFieldsValue({
        'game bundle id': '',
      })
      return
    }

    if (appData.bundleId) {
      gameDetailForm.setFieldsValue({
        'game bundle id': appData.bundleId,
      })
    }

    if (appData.iconUrl) {
      gameDetailForm.setFieldsValue({
        'game icon url': appData.iconUrl,
      })
    }
  }, [appData])

  return (
    <Flex style={{ padding: '24px 4px 0', maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
      <Form<GameDetailsFormData>
        name='game-information-form'
        form={gameDetailForm}
        onFinish={handleSubmit}
        scrollToFirstError={true}
        style={{ width: '100%' }}>
        <Flex vertical style={{ height: '100%' }}>
          <Flex vertical gap={6} flex={1}>
            <div style={{ position: 'relative' }}>
              <InputGroup<GameDetailsFormData>
                name='game title'
                title={t('game_onboard_stage_game_title_label')}
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input
                  style={{ height: 40 }}
                  placeholder={t('game_onboard_stage_game_title_placeholder')}
                />
              </InputGroup>
              <div style={{ position: 'absolute', right: 10, top: 23, height: 48 }}>
                <Flex
                  align='center'
                  justify='center'
                  gap={4}
                  style={{ height: '100%', width: '100%' }}>
                  {gameStore?.store.os?.toLowerCase() === 'android' && (
                    <Image
                      src='/images/icon/play-store.webp'
                      height={24}
                      width={24}
                      preview={false}
                    />
                  )}

                  {gameStore?.store.os?.toLowerCase() === 'ios' && (
                    <Image
                      src='/images/icon/app-store.webp'
                      height={24}
                      width={24}
                      preview={false}
                    />
                  )}
                </Flex>
              </div>
            </div>

            <InputGroup<GameDetailsFormData>
              name='your stage'
              title={t('game_onboard_stage_label')}
              rules={[
                {
                  required: true,
                },
              ]}>
              <Radio.Group
                style={{ width: '100%' }}
                disabled={gameStore?.gameDevelopmentStage === GameDevelopmentStage.Release}>
                <Flex flex={1} gap={16} align='center'>
                  <label style={{ width: '50%', cursor: 'pointer' }}>
                    <Flex flex={1} style={radioButtonStyle}>
                      <Radio value={GameDevelopmentStage.Release}>{t('general_released')}</Radio>
                    </Flex>
                  </label>
                  <label style={{ width: '50%', cursor: 'pointer' }}>
                    <Flex flex={1} style={radioButtonStyle}>
                      <Radio value={GameDevelopmentStage.NotRelease}>
                        {t('general_not_released')}
                      </Radio>
                    </Flex>
                  </label>
                </Flex>
              </Radio.Group>
            </InputGroup>

            <div style={{ position: 'relative' }}>
              <InputGroup<GameDetailsFormData>
                name='game store url'
                title={t('game_onboard_stage_game_store_url_label')}
                rules={[
                  {
                    validator: (_, value) => {
                      if (
                        (gameStore?.store.os?.toLowerCase() === 'ios' &&
                          APPLE_APP_STORE_BASE_URL_REGEX.test(value as string)) ||
                        (gameStore?.store.os?.toLowerCase() === 'android' &&
                          GOOGLE_PLAY_STORE_BASE_URL_REGEX.test(value as string))
                      ) {
                        if (appData.error === 'store_not_found')
                          return Promise.reject(
                            new Error("URL is not valid, we couldn't find your game."),
                          )
                        else return Promise.resolve()
                      } else
                        return Promise.reject(
                          new Error(`The game store URL for ${gameStore?.store.os} is invalid.`),
                        )
                    },
                  },
                ]}>
                <Input
                  disabled={gameStore?.gameDevelopmentStage === GameDevelopmentStage.Release}
                  style={{ height: 40 }}
                  placeholder={
                    gameStore?.store.os?.toLowerCase() === 'ios'
                      ? 'e.g https://apps.apple.com/...'
                      : 'https://play.google.com/...'
                  }
                />
              </InputGroup>
              <div style={{ position: 'absolute', right: 10, top: 23, height: 48 }}>
                <Flex
                  align='center'
                  justify='center'
                  gap={4}
                  style={{ height: '100%', width: '100%' }}>
                  {getAppStoreInformationLoading && <Spin size='small' />}
                </Flex>
              </div>
            </div>

            <InputGroup<GameDetailsFormData>
              name='game engine'
              title={t('game_onboard_stage_game_engine_label')}
              rules={[
                {
                  required: true,
                },
              ]}>
              <SelectOptions
                options={GAME_ENGINE_OPTIONS}
                placeholder='Choose game engine'
                style={{
                  height: 40,
                }}
              />
            </InputGroup>

            <InputGroup<GameDetailsFormData>
              name='game bundle id'
              title={t('game_onboard_stage_bundle_id_label')}
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input
                disabled={true}
                style={{ height: 40 }}
                placeholder={t('game_onboard_stage_bundle_id_placeholder')}
              />
            </InputGroup>

            <InputGroup<GameDetailsFormData>
              name='game genres'
              title={t('account_setting_my_games_game_genre_title')}
              rules={[
                {
                  required: true,
                },
              ]}>
              <SelectOptions
                mode='multiple'
                loading={isGenreLoading}
                variant='primary'
                placeholder={t('account_setting_my_games_game_genre_placeholder')}
                options={genreOptions}
                style={{
                  height: 40,
                }}
                sortOption={true}
              />
            </InputGroup>
            <br />
            <Flex flex={0} justify='center'>
              <LabsButton
                width='wide'
                htmlType='submit'
                isLoading={isUpdateLoading || getAppStoreInformationLoading}>
                Save Changes
              </LabsButton>
            </Flex>
          </Flex>
        </Flex>
      </Form>
    </Flex>
  )
}
