import { useMutation } from '@apollo/client'
import {
  GameStoreIntegrationStatus,
  NotifyBundleUploadMutation,
  NotifyBundleUploadMutationVariables,
} from '__generated__/gql/graphql'
import { Flex, message, Modal, Spin, Typography } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { notifyBundleUpload } from 'services/graphql/mutations'

import { INTEGRATION_ROUTE } from 'constants/Routes'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useIntegration from 'hooks/useIntegration'

import SlideToConfirm from 'components/commons/slide-to-confirm'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function IntegrationCompletionConfirmationModal() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isLoading, updateStoreIntegrationStatus } = useIntegration()
  const { selectedGameStore } = useUserStore()
  const { isIntegrationCompletionConfirmationModalShow, closeAll } = useModalStore()

  const [sendNotification] = useMutation<
    NotifyBundleUploadMutation,
    NotifyBundleUploadMutationVariables
  >(notifyBundleUpload)

  const handleConfirm = useCallback(() => {
    if (!selectedGameStore) return

    updateStoreIntegrationStatus({
      adjust: GameStoreIntegrationStatus.Completed,
      firebase: GameStoreIntegrationStatus.Completed,
    }).then(() => {
      sendNotification({ variables: { gameStoreId: selectedGameStore.id } })
      message.success('Integration completed successfully')
      closeAll()
      navigate(`/${INTEGRATION_ROUTE}`)
    })
  }, [selectedGameStore])

  return (
    <Modal
      open={isIntegrationCompletionConfirmationModalShow}
      title={false}
      centered
      footer={false}
      onCancel={closeAll}
      width={623}>
      {isLoading ? (
        <Flex
          vertical
          justify='center'
          align='center'
          gap={10}
          style={{ height: 200, width: '100%' }}>
          <Spin />
        </Flex>
      ) : (
        <Flex
          vertical
          align='center'
          justify='space-between'
          gap={32}
          style={{ padding: '20px 16px' }}>
          <Flex vertical align='center' gap={12}>
            <Title level={4} style={{ margin: 0, color: colors.gray90 }}>
              {t('modal_integration_completion_title')}
            </Title>
            <Flex vertical>
              <Text style={{ color: colors.gray90, textAlign: 'center' }}>
                {t('modal_integration_completion_first_description')}
              </Text>
              <Text style={{ color: colors.gray90, textAlign: 'center' }}>
                {t('modal_integration_completion_second_description')}
              </Text>
            </Flex>
          </Flex>
          <Flex justify='center' style={{ width: 420 }}>
            <SlideToConfirm
              label={t('modal_integration_completion_button')}
              handleConfirm={handleConfirm}
            />
          </Flex>
        </Flex>
      )}
    </Modal>
  )
}
