import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { GetGameStoresByGameIdQuery } from '../__generated__/gql/graphql'

interface StoreUrl {
  playStore: string
  appStore: string
}
type Step = 'onboard' | 'form' | 'finished'
interface UniqueSellingPoint {
  gameplay: string
  socialInteraction: string
  monetizationAndEngagement: string
  otherInsights: string
}

interface ScaleIntegrationState {
  step: Step
  setStep: (step: Step) => void

  storeUrl: StoreUrl
  setStoreUrl: (storeUrl: StoreUrl) => void

  activeKeyGuide: string[]
  setActiveKeyGuide: (activeKeyGuide: string[]) => void

  uniqueSellingPoint: UniqueSellingPoint
  setUniqueSellingPoint: (uniqueSellingPoint: UniqueSellingPoint) => void

  gameOfInterest: string[]
  setGameOfInterest: (gameOfInterest: string[]) => void

  gameRegion: string[]
  setGameRegion: (gameRegion: string[]) => void

  gameStores: GetGameStoresByGameIdQuery
  setGameStores: (gameStores: GetGameStoresByGameIdQuery) => void

  resetState: () => void
}

const useMarketingOnboardStore = create<ScaleIntegrationState>()(
  persist(
    (set) => ({
      step: 'onboard',
      storeUrl: {
        appStore: '',
        playStore: '',
      },
      uniqueSellingPoint: {
        gameplay: '',
        monetizationAndEngagement: '',
        otherInsights: '',
        socialInteraction: '',
      },
      gameStores: [],
      gameOfInterest: [],
      gameRegion: [],
      activeKeyGuide: [],
      setGameStores: (gameStores: GetGameStoresByGameIdQuery) =>
        set((state) => ({ ...state, gameStores: gameStores })),
      setActiveKeyGuide: (activeKeyGuide: string[]) =>
        set((state) => ({ ...state, activeKeyGuide })),
      setStep: (step: Step) => set((state) => ({ ...state, step })),
      setStoreUrl: (storeUrl: StoreUrl) => set((state) => ({ ...state, storeUrl })),
      setUniqueSellingPoint: (uniqueSellingPoint: UniqueSellingPoint) =>
        set((state) => ({ ...state, uniqueSellingPoint })),
      setGameOfInterest: (gameOfInterest: string[]) =>
        set((state) => ({ ...state, gameOfInterest })),
      setGameRegion: (gameRegion: string[]) => set((state) => ({ ...state, gameRegion })),

      resetState: () =>
        set(() => ({
          step: 'onboard',
          storeUrl: {
            appStore: '',
            playStore: '',
          },
          uniqueSellingPoint: {
            gameplay: '',
            monetizationAndEngagement: '',
            otherInsights: '',
            socialInteraction: '',
          },
          gameOfInterest: [],
          gameRegion: [],
          activeKeyGuide: [],
        })),
    }),
    {
      name: 'marketing-onboard-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useMarketingOnboardStore
