import { useMutation } from '@apollo/client'
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  SendRequestForgotPasswordMutation,
  SendRequestForgotPasswordMutationVariables,
  VerifyRequestResetPasswordMutation,
  VerifyRequestResetPasswordMutationVariables,
} from '__generated__/gql/graphql'
import {
  sendRequestResetPassword,
  setNewPassword,
  verifyRequestResetPassword,
} from 'services/graphql/mutations'

export default function useForgotPassword() {
  const [
    requestResetPassword,
    { loading: requestResetPasswordLoading, error: requestResetPasswordError },
  ] = useMutation<SendRequestForgotPasswordMutation, SendRequestForgotPasswordMutationVariables>(
    sendRequestResetPassword,
  )

  const [
    verifyResetPassword,
    { loading: verifyResetPasswordLoading, error: verifyResetPasswordError },
  ] = useMutation<VerifyRequestResetPasswordMutation, VerifyRequestResetPasswordMutationVariables>(
    verifyRequestResetPassword,
  )

  const [resetPassword, { loading: resetPasswordLoading, error: resetPassworddError }] =
    useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(setNewPassword)

  return {
    requestResetPassword,
    verifyResetPassword,
    resetPassword,

    requestResetPasswordLoading,
    verifyResetPasswordLoading,
    resetPasswordLoading,

    requestResetPasswordError,
    verifyResetPasswordError,
    resetPassworddError,
  }
}
