import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface MappedCountryState {
  mappedCountryInformation: Record<string, string>
  setMappedCountryInformation: (info: Record<string, string>) => void
  resetState: () => void
}

const useMappedCountryStore = create<MappedCountryState>()(
  persist(
    (set) => ({
      mappedCountryInformation: {} as Record<string, string>,

      setMappedCountryInformation: (info: Record<string, string>) =>
        set((state) => ({ ...state, mappedCountryInformation: info })),
      resetState: () =>
        set({
          mappedCountryInformation: {},
        }),
    }),
    {
      name: 'mapped-country-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useMappedCountryStore
