import { Flex, Modal, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import useModalStore from 'stores/useModalStore'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function NoPermissionModal() {
  const { isNoPermissionModalShow, closeNoPermissionModal } = useModalStore()
  const { t } = useTranslation()
  return (
    <Modal
      title={false}
      open={isNoPermissionModalShow}
      footer={false}
      centered
      width={700}
      onCancel={() => closeNoPermissionModal()}>
      <div
        style={{
          height: 450,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Flex
          vertical
          gap={20}
          align='center'
          justify='center'
          style={{ width: '100%', height: '100%' }}>
          <img src='/images/illustration/forbidden.webp' height={250} width={250} />
          <Flex vertical gap={12} align='center'>
            <Title level={4} style={{ color: colors.gray90, fontWeight: 'bold', margin: 0 }}>
              {t('forbidden_page_no_allowed_title')}
            </Title>
            <Flex vertical align='center'>
              <Text style={{ fontSize: 14, color: colors.gray70 }}>
                {t('forbidden_page_no_allowed_text_1')}
              </Text>
              <Text style={{ fontSize: 14, color: colors.gray70 }}>
                {t('forbidden_page_no_allowed_text_2')}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </Modal>
  )
}
