import { Typography } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { USER_ACQUISITION_PUBLISH_ADS_ROUTE } from 'constants/Routes'

import useModalStore from 'stores/useModalStore'

import useGameInfo from 'hooks/useGameInfo'
import useIsPermissionGranted from 'hooks/useIsPermissionGranted'

import LabsButton from 'components/commons/labs-kit/button'

import { GamePublisherUserPermissionEnum } from 'types/permission'

const { Text } = Typography
export default function HeaderCreateAds() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isGameIntegrated } = useGameInfo()
  const { isPermissionGranted } = useIsPermissionGranted(
    GamePublisherUserPermissionEnum.CREATE_NEW_ADS,
  )

  const { showScaleIntegrationRequiredModal, showNoPermissionModal } = useModalStore()

  const handleClick = useCallback(() => {
    if (!isPermissionGranted) {
      showNoPermissionModal()
      return
    }

    if (!isGameIntegrated) {
      showScaleIntegrationRequiredModal()
      return
    }

    navigate(`/${USER_ACQUISITION_PUBLISH_ADS_ROUTE}`)
  }, [isGameIntegrated, isPermissionGranted])

  return (
    <LabsButton size='sm' onClick={handleClick}>
      <Text style={{ fontSize: 18, lineHeight: '18px', color: '#fff', marginRight: 4 }}>+</Text>
      {t('layout_header_create_ads')}
    </LabsButton>
  )
}
