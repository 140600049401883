import { lazy } from 'react'
import { Route } from 'react-router-dom'

const ErrorAccountInactivePage = lazy(() => import('pages/error/inactive-account'))

const GameRouter = (
  <Route path='error'>
    <Route path='inactive' element={<ErrorAccountInactivePage />} />
  </Route>
)

export default GameRouter
