import { useApolloClient, useMutation } from '@apollo/client'
import {
  CreateActivityHistoriesMutation,
  CreateActivityHistoriesMutationVariables,
  TopupMutation,
  TopupMutationVariables,
} from '__generated__/gql/graphql'
import { Button, Card, Flex, message, Modal, Radio, Space, Typography } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createActivityHistories, topupMutations } from 'services/graphql/mutations'

import { TOPUP_VALUE, TOPUP_VALUE_OPTIONS } from 'constants/Options'

import { formatCurrency } from 'utils/FormatterUtils'

import useGeneralStore from 'stores/useGeneralStore'
import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useTracker from 'hooks/useTracker'

import BalanceLimitedOffer from 'components/commons/balance-limited-offer'
import InputNumber from 'components/commons/form/input-number'

import colors from 'theme/colors'

const { Text, Title } = Typography

const MIN_TOPUP_AMOUNT = 500
const ModalTitle = () => {
  const { t } = useTranslation()
  const { isInsufficientBalance } = useModalStore()

  return (
    <Flex vertical gap={4}>
      {isInsufficientBalance ? (
        <>
          <Title level={4} style={{ color: colors.navy80, margin: 0 }}>
            {t('scale_creatives_topup_modal_insufficient_title')}
          </Title>
          <Text style={{ color: colors.gray70, fontWeight: 'bold' }}>
            {t('scale_creatives_topup_modal_insufficient_subtitle')}
          </Text>
        </>
      ) : (
        <Title level={4} style={{ color: colors.navy80 }}>
          {t('scale_creatives_topup_modal_title')}
        </Title>
      )}
    </Flex>
  )
}

export default function TopUpModal() {
  const { t } = useTranslation()
  const { track } = useTracker()
  const { user, selectedGame } = useUserStore()
  const { info } = useGeneralStore()

  const { isTopupModalShow, showTopupSuccessModal, closeAll } = useModalStore()

  const [customAmount, setCustomAmount] = useState<string | null>(null)
  const [selectedAmount, setSelectedAmount] = useState<TOPUP_VALUE | null>(
    TOPUP_VALUE_OPTIONS[0].value as TOPUP_VALUE,
  )

  const [topup, { loading }] = useMutation<TopupMutation, TopupMutationVariables>(topupMutations)

  const isInsufficientAmount = useMemo(() => {
    if (!customAmount) return false
    return (
      (customAmount !== '' && Number(customAmount) < MIN_TOPUP_AMOUNT) ||
      (customAmount !== null && Number(customAmount) < MIN_TOPUP_AMOUNT)
    )
  }, [customAmount])

  const handleSubmit = useCallback(() => {
    let preferredAmount

    if (customAmount) preferredAmount = customAmount
    else preferredAmount = selectedAmount

    if (!preferredAmount) return

    track('topup_attempt', {
      type: 'click',
      location: 'topup_modal',
      location_type: 'button',
    })

    topup({
      variables: {
        amount: parseInt(preferredAmount),
      },
    })
      .then(() => {
        closeAll()
        showTopupSuccessModal()

        setCustomAmount(null)
        setSelectedAmount(TOPUP_VALUE_OPTIONS[0].value as TOPUP_VALUE)

        track('topup_success', {
          type: 'success',
          location: 'topup_modal',
        })
      })
      .catch((err) => {
        message.error(`cannot handle your request right now`)
        console.error(err)
      })
  }, [track, topup, customAmount, selectedAmount])

  useEffect(() => {
    if (!customAmount) return

    const matchedCustomWithProvidedOptions = TOPUP_VALUE_OPTIONS.find(
      (option) => option.value == customAmount,
    )
    if (matchedCustomWithProvidedOptions)
      setSelectedAmount(matchedCustomWithProvidedOptions.value as TOPUP_VALUE)
    else setSelectedAmount(null)
  }, [customAmount])

  const disabledButton = useMemo(() => {
    return !(user.roles || []).some((role) => ['admin', 'marketing'].includes(role))
  }, [user])

  return (
    <Modal
      open={isTopupModalShow}
      title={<ModalTitle />}
      centered
      footer={false}
      onCancel={closeAll}
      width={663}>
      <Flex vertical>
        {info.showBalanceLimitedOffer && <BalanceLimitedOffer />}
        <Space direction='vertical' size={30} style={{ width: '100%', marginTop: 10 }}>
          <Card style={{ border: 0, backgroundColor: colors.gray20, paddingBottom: 30 }}>
            <Space direction='vertical' size={15} style={{ width: '100%' }}>
              <Text style={{ color: colors.gray80, fontWeight: 'bold' }}>
                {t('scale_creatives_topup_modal_choose_amount_title')}
              </Text>
              <Radio.Group
                id='topup-form'
                onChange={(e) => {
                  setSelectedAmount(e.target.value)
                  setCustomAmount(null)
                }}
                value={selectedAmount}
                size='large'
                style={{ width: '100%' }}>
                <Flex gap={16}>
                  {TOPUP_VALUE_OPTIONS.map((option) => (
                    <Radio.Button
                      key={option.value}
                      value={option.value}
                      style={{
                        flex: 1,
                        borderRadius: 6,
                        border: selectedAmount === option.value ? '2px solid' : '1px solid',
                        borderColor:
                          selectedAmount === option.value ? colors.labs120 : colors.gray40,
                        backgroundColor:
                          selectedAmount === option.value ? colors.labs20 : 'transparent',
                        textAlign: 'center',
                      }}>
                      <Text
                        style={{
                          color: selectedAmount === option.value ? colors.gray90 : colors.gray60,
                        }}>
                        {formatCurrency(parseInt(option.label), 0)}
                      </Text>
                    </Radio.Button>
                  ))}
                </Flex>
              </Radio.Group>
              <Text style={{ fontWeight: 500, color: colors.gray80 }}>
                {t('scale_creatives_topup_modal_choose_amount_or_label')}
              </Text>

              <div style={{ width: '60%', minWidth: 340 }}>
                <Flex vertical gap={4}>
                  <InputNumber
                    placeholder='Enter Amount'
                    size='large'
                    value={customAmount}
                    type='string'
                    addonBefore='$'
                    onChange={(value) => {
                      setCustomAmount(value as string)
                    }}
                  />
                  {isInsufficientAmount && (
                    <Text style={{ fontSize: 12, color: colors.red70 }}>
                      *{t('topup_modal_min_amount_label')} ${MIN_TOPUP_AMOUNT}
                    </Text>
                  )}
                </Flex>
              </div>
            </Space>
          </Card>
          <Flex justify='center' gap={18}>
            <Button size='large' onClick={closeAll}>
              {t('general_cancel')}
            </Button>
            <Button
              size='large'
              type='primary'
              onClick={handleSubmit}
              disabled={loading || isInsufficientAmount || disabledButton}>
              {t('scale_creatives_topup_modal_button')}
            </Button>
          </Flex>
        </Space>
      </Flex>
    </Modal>
  )
}
