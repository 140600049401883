import { GamePublisherStatus } from '__generated__/gql/graphql'
import Cookies from 'js-cookie'
import React, { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import { COOKIE_KEY_AUTH_TOKEN } from 'constants/CookieKeys'
import { AUTHENTICATION_ROUTE, WAITING_LIST_ROUTE } from 'constants/Routes'

import useUserStore from 'stores/useUserStore'
import useWaitListStore from 'stores/useWaitListStore'

type Props = {
  children: ReactNode
}

const AuthenticatedRoute: React.FC<Props> = ({ children }) => {
  const { user: userWaitlist } = useWaitListStore()
  const { user } = useUserStore()

  if (!Cookies.get(COOKIE_KEY_AUTH_TOKEN)) {
    if (userWaitlist?.email) return <Navigate to={`/${WAITING_LIST_ROUTE}`} replace />
    else return <Navigate to={`/${AUTHENTICATION_ROUTE}`} replace />
  }

  if (!!Object.keys(user).length && user.gamePublisherStatus === GamePublisherStatus.WaitList) {
    return <Navigate to={`/${WAITING_LIST_ROUTE}`} replace />
  }

  return children
}

export default AuthenticatedRoute
