import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  FindGameStoreByGameIdStoreIdQuery,
  FindGameStoreByGameIdStoreIdQueryVariables,
  GameStoreIntegrationInput,
  GameStoreIntegrationMethod,
  GameStoreIntegrationStatus,
  GetActivityHistoriesQuery,
  GetActivityHistoriesQueryVariables,
  GetGameStoreIntegrationBySlugQuery,
  GetGameStoreIntegrationBySlugQueryVariables,
  UpsertGameStoreIntegrationsMutation,
  UpsertGameStoreIntegrationsMutationVariables,
} from '__generated__/gql/graphql'
import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { upsertGameStoreIntegrations } from 'services/graphql/mutations'
import {
  findGameStoreByGameIdStoreId,
  getActivityHistories,
  getGameStoreIntegrationBySlug,
} from 'services/graphql/queries'

import useUserStore from 'stores/useUserStore'

import { labsAndroid, labsApple } from 'components/commons/icon-lib/icons/colored'
import { BasicIntegrationMethod, HybridStep } from 'components/modules/integration/type'

import useUserInfo from './useUserInfo'

const useIntegration = () => {
  const client = useApolloClient()
  const { getGames } = useUserInfo()

  const [searchParams] = useSearchParams()
  const selectedStoreId = searchParams.get('gs_id')
  const hybridWizardStep = searchParams.get('hybrid_step')

  const { selectedGame, selectedGameStore } = useUserStore()

  const { data: gameStore, loading: isGameStoreLoading } = useQuery<
    FindGameStoreByGameIdStoreIdQuery,
    FindGameStoreByGameIdStoreIdQueryVariables
  >(findGameStoreByGameIdStoreId, {
    variables: {
      gameId: selectedGame?.id || '',
      storeId: selectedGameStore?.os === 'Android' ? '1' : '2',
    },
    skip: !selectedStoreId || !selectedGame?.id,
    fetchPolicy: 'no-cache',
  })

  const { data: gameStoreIntegrationId, loading: isGameStoreIntegrationLoading } = useQuery<
    GetGameStoreIntegrationBySlugQuery,
    GetGameStoreIntegrationBySlugQueryVariables
  >(getGameStoreIntegrationBySlug, {
    variables: {
      integrationSlug: hybridWizardStep || '',
      storeIds: [selectedStoreId || ''],
    },
    skip: !selectedStoreId || !hybridWizardStep,
  })

  const selectedGameStoreIntegration = useMemo(() => {
    const integrations = gameStoreIntegrationId?.getGameStoreIntegrationBySlug || []
    if (integrations.length === 0) return null
    return integrations[0]
  }, [gameStoreIntegrationId])

  const { data: activityHistoriesData, loading: isActivityStoryLoading } = useQuery<
    GetActivityHistoriesQuery,
    GetActivityHistoriesQueryVariables
  >(getActivityHistories, {
    variables: {
      key: `game_store_integration_id_${selectedGameStoreIntegration?.id}`,
    },
    skip: !selectedGameStoreIntegration,
  })

  const activityHistories = useMemo(() => {
    const histories = activityHistoriesData?.getActivityHistories || []
    return Object.keys(histories).map((historyKey: string) => {
      return {
        key: historyKey,
        value: histories[historyKey].length ? histories[historyKey][0] : null,
      }
    })
  }, [activityHistoriesData])

  const integratingStoreId = useMemo(() => {
    if (!selectedGameStore) return 1
    return selectedGameStore.integrations.find(
      (store) => store.status !== GameStoreIntegrationStatus.NotStarted,
    )?.id
  }, [selectedGameStore])

  const platformIcon = useMemo(() => {
    if (selectedGameStore?.os === 'Android') return labsAndroid
    else if (selectedGameStore?.os === 'iOS') return labsApple
    else return null
  }, [selectedGameStore])

  const basicIntegrationMethod: BasicIntegrationMethod | null = useMemo(() => {
    if (!selectedGameStore) return null

    const integrations = selectedGameStore.integrations.filter(
      (integration) =>
        ['adjust-data-tracking', 'firebase-data-tracking'].includes(integration.slug) &&
        integration.status !== GameStoreIntegrationStatus.NotStarted,
    )
    const isFullSdk = integrations.every(
      (integration) => integration.method === GameStoreIntegrationMethod.FullSdk,
    )
    const isFullShareAccess = integrations.every(
      (integration) => integration.method === GameStoreIntegrationMethod.ShareAccess,
    )

    if (isFullSdk) return 'full-sdk'
    else if (isFullShareAccess) return 'full-share-access'
    else return 'hybrid'
  }, [selectedGameStore])

  const hybridWizardSteps: { label: string; slug: HybridStep }[] = useMemo(() => {
    if (!selectedGameStore) return []
    const integrations =
      selectedGameStore.integrations.filter((integration) =>
        ['adjust-data-tracking', 'firebase-data-tracking'].includes(integration.slug),
      ) || []

    const sortedIntegrationStep = integrations
      .sort((a, b) => {
        if (a.method === GameStoreIntegrationMethod.ShareAccess) return -1
        if (b.method === GameStoreIntegrationMethod.ShareAccess) return 1
        return 0
      })
      .map((integration) => {
        let label = integration.name
        if (integration.slug === 'firebase-data-tracking') label = 'Firebase Integration'
        if (integration.slug === 'adjust-data-tracking') label = 'Adjust Integration'
        if (integration.slug === 'facebook-data-tracking') label = 'Facebook Integration'
        return {
          label: label,
          slug: integration.slug as HybridStep,
        }
      })

    return [
      ...sortedIntegrationStep,
      {
        label: 'Integration Checker',
        slug: 'integration-checker',
      },
    ]
  }, [selectedGameStore])

  const [updateGameStoreIntegrationStatus, { loading: isUpdatingStatusLoading }] = useMutation<
    UpsertGameStoreIntegrationsMutation,
    UpsertGameStoreIntegrationsMutationVariables
  >(upsertGameStoreIntegrations)

  const updateStoreIntegrationStatus = useCallback(
    async ({
      adjust,
      firebase,
      facebook,
      admob,
    }: {
      adjust?: GameStoreIntegrationStatus
      firebase?: GameStoreIntegrationStatus
      facebook?: GameStoreIntegrationStatus
      admob?: GameStoreIntegrationStatus
    }) => {
      const integrations: GameStoreIntegrationInput[] = []

      const adjustIntegration = selectedGameStore?.integrations.find(
        (integration) => integration.slug === 'adjust-data-tracking',
      )
      const firebaseIntegration = selectedGameStore?.integrations.find(
        (integration) => integration.slug === 'firebase-data-tracking',
      )
      const facebookIntegration = selectedGameStore?.integrations.find(
        (integration) => integration.slug === 'facebook-data-tracking',
      )
      const admobIntegration = selectedGameStore?.integrations.find(
        (integration) => integration.slug === 'admob',
      )

      if (adjust && adjustIntegration)
        integrations.push({
          method: adjustIntegration.method as GameStoreIntegrationMethod,
          slug: adjustIntegration.slug,
          status: adjust,
        })
      if (firebase && firebaseIntegration)
        integrations.push({
          method: firebaseIntegration.method as GameStoreIntegrationMethod,
          slug: firebaseIntegration.slug,
          status: firebase,
        })
      if (facebook && facebookIntegration)
        integrations.push({
          method: facebookIntegration.method as GameStoreIntegrationMethod,
          slug: facebookIntegration.slug,
          status: facebook,
        })
      if (admob && admobIntegration)
        integrations.push({
          method: GameStoreIntegrationMethod.FullSdk,
          slug: admobIntegration.slug,
          status: admob,
        })

      if (!selectedGameStore || !integrations.length) return Promise.resolve()
      await updateGameStoreIntegrationStatus({
        variables: {
          gameStoreId: selectedGameStore.id,
          gameStoreIntegrations: integrations.map((integration) => ({
            slug: integration.slug,
            status: integration.status,
            method: integration.method,
          })),
        },
      }).then(async () => {
        client.refetchQueries({
          include: ['GetGameStoreIntegrationBySlug'],
        })

        return await getGames().finally(() => Promise.resolve())
      })
    },

    [selectedGameStore],
  )

  const gameInfoDatabase = useMemo(() => {
    const adjustIntegration = gameStore?.findGameStoreByGameIdStoreId?.integrations.find(
      (integration) => integration.slug === 'adjust-data-tracking',
    )

    const firebaseIntegration = gameStore?.findGameStoreByGameIdStoreId?.integrations.find(
      (integration) => integration.slug === 'firebase-data-tracking',
    )

    const isHybrid =
      adjustIntegration?.method === GameStoreIntegrationMethod.FullSdk ||
      firebaseIntegration?.method === GameStoreIntegrationMethod.FullSdk

    return {
      packageName: {
        label: 'Package Name',
        value: gameStore?.findGameStoreByGameIdStoreId?.bundleId || '',
        isRequired: isHybrid ? true : false,
      },
      sdkOpenId: {
        label: 'SDK Open ID',
        value: gameStore?.findGameStoreByGameIdStoreId?.qsdkOpenId || '',
        isRequired: isHybrid ? true : false,
      },
      sdkOpenKey: {
        label: 'SDK Open Key',
        value: gameStore?.findGameStoreByGameIdStoreId?.qsdkOpenKey || '',
        isRequired: isHybrid ? true : false,
      },
      productCode: {
        label: 'SDK ProductCode',
        value: gameStore?.findGameStoreByGameIdStoreId?.qsdkProductCode || '',
        isRequired: isHybrid ? true : false,
      },
      sdkCallbackKey: {
        label: 'SDK Callback Key',
        value: gameStore?.findGameStoreByGameIdStoreId?.qsdkCallbackKey || '',
        isRequired: isHybrid ? true : false,
      },
      // facebookAppId: {
      //   label: 'FB App ID',
      //   value: gameStore?.findGameStoreByGameIdStoreId?.facebookAppId || '',
      // },
      // facebookAppSecret: {
      //   label: 'FB App Secret',
      //   value: gameStore?.findGameStoreByGameIdStoreId?.facebookAppSecret || '',
      // },
      // facebookClientToken: {
      //   label: 'FB Client Token',
      //   value: gameStore?.findGameStoreByGameIdStoreId?.facebookClientToken || '',
      // },
      firebaseAppId: {
        label: 'Firebase APP ID',
        value: gameStore?.findGameStoreByGameIdStoreId?.firebaseAppId || '',
        isRequired:
          firebaseIntegration?.method === GameStoreIntegrationMethod.FullSdk ? true : false,
      },
      firebaseConfigUrl: {
        label: 'Firebase File Config',
        value: gameStore?.findGameStoreByGameIdStoreId?.firebaseConfigUrl || '',
        isRequired:
          firebaseIntegration?.method === GameStoreIntegrationMethod.FullSdk ? true : false,
      },
      firebasePropertyId: {
        label: 'Firebase Property ID',
        value: gameStore?.findGameStoreByGameIdStoreId?.firebasePropertyId || '',
        isRequired:
          firebaseIntegration?.method === GameStoreIntegrationMethod.FullSdk ? true : false,
      },
      adjustAppToken: {
        label: 'Adjust App Token',
        value: gameStore?.findGameStoreByGameIdStoreId?.adjustAppToken || '',
        isRequired: adjustIntegration?.method === GameStoreIntegrationMethod.FullSdk ? true : false,
      },
      adjustRevenueToken: {
        label: 'Adjust Purchase Token',
        value: gameStore?.findGameStoreByGameIdStoreId?.adjustRevenueToken || '',
        isRequired: adjustIntegration?.method === GameStoreIntegrationMethod.FullSdk ? true : false,
      },
      adjustSecretApp: {
        label: 'Adjust Secret Key',
        value: gameStore?.findGameStoreByGameIdStoreId?.adjustSecretKey || '',
        isRequired: adjustIntegration?.method === GameStoreIntegrationMethod.FullSdk ? true : false,
      },
    }
  }, [gameStore])

  const isIntegrating = useMemo(() => {
    if (!selectedGameStore) return false

    return selectedGameStore?.integrations
      .filter(
        (integration) =>
          integration.slug === 'adjust-data-tracking' ||
          integration.slug === 'firebase-data-tracking',
      )
      .some(
        (integration) =>
          integration.status !== GameStoreIntegrationStatus.NotStarted &&
          integration.status !== GameStoreIntegrationStatus.Completed,
      )
  }, [selectedGameStore])

  return {
    selectedStoreId,
    selectedGameStoreIntegration,
    activityHistories,
    integratingStoreId,
    platformIcon,
    isLoading:
      isGameStoreIntegrationLoading ||
      isActivityStoryLoading ||
      isGameStoreLoading ||
      isUpdatingStatusLoading,
    basicIntegrationMethod,
    hybridWizardStep: hybridWizardStep as HybridStep,
    hybridWizardSteps,
    gameInfoDatabase,
    isIntegrating,
    updateStoreIntegrationStatus,
  }
}

export default useIntegration
