import { createContext, useContext } from 'react'

interface LayoutContextType {
  authenticatedHeaderHeight: number
  headerHeight?: number | null
  footerHeight?: number | null
}

export const LayoutContext = createContext<LayoutContextType | undefined>(undefined)
export const useLayoutContext = () => {
  const context = useContext(LayoutContext)
  if (context === undefined) {
    throw new Error('useLayoutContext must be used within a LayoutProvider')
  }
  return context
}
