import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export type Navigation =
  | 'dashboard'
  | 'my-ads'
  | 'ads-inspiration'
  | 'analytics'
  | 'analytics/marketing'
  | 'analytics/revenue'
  | 'analytics/game'
  | 'integration'
  | 'add-on'
  | 'labs-support'
  | 'game-analytics'
  | 'game-analytics/daily-monitoring'
  | 'game-analytics/comparison'
  | 'marketing-analytics'
  | 'marketing-analytics/daily-marketing'
  | 'marketing-analytics/retention'
  | 'account-setting'
  | 'infrastructure'
  | 'mediation'
  | 'user-acquisition'
  | 'dashboard'

type GroupNavigation = 'analytics' | 'marketing' | 'game-analytics' | 'marketing-analytics'

interface MenuStore {
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void

  activeNavigation: Navigation
  setActiveNavigation: (navigation: Navigation) => void

  activeGroupNavigation: GroupNavigation[]
  setActiveGroupNavigation: (group: GroupNavigation[]) => void

  resetState: () => void
}

const useMenuStore = create<MenuStore>()(
  persist(
    (set) => ({
      isExpanded: true,
      activeNavigation: 'dashboard',
      activeGroupNavigation: [],
      setActiveNavigation: (navigation: Navigation) =>
        set((state) => ({ ...state, activeNavigation: navigation })),
      setActiveGroupNavigation: (group: GroupNavigation[]) =>
        set((state) => ({ ...state, activeGroupNavigation: group })),
      setIsExpanded: (isExpanded: boolean) => set((state) => ({ ...state, isExpanded })),

      resetState: () =>
        set(() => ({
          isExpanded: true,
          activeNavigation: 'dashboard',
          activeGroupNavigation: [],
        })),
    }),
    {
      name: 'menu-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useMenuStore
