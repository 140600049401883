import { Button, Flex, Modal, Space, Typography } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { USER_ACQUISITION_MY_ADS_ROUTE } from 'constants/Routes'

import useModalStore from 'stores/useModalStore'

import useUserInfo from 'hooks/useUserInfo'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function CreativeSubmittedModal() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isCreativeSubmissionScheduled, isCreativesSubmittedModalShow } = useModalStore()
  const { closeAll } = useModalStore()
  const { getGames } = useUserInfo()

  const handleSeeResult = () => {
    closeAll()
    navigate(`/${USER_ACQUISITION_MY_ADS_ROUTE}`)
  }

  useEffect(() => {
    if (!isCreativeSubmissionScheduled) return
    getGames()
  }, [isCreativeSubmissionScheduled])

  return (
    <Modal
      title={false}
      open={isCreativesSubmittedModalShow}
      footer={false}
      centered
      onCancel={closeAll}>
      <Flex vertical justify='center' align='center' gap={20}>
        <img src='/images/illustration/sdk-download-complete.gif' width={92} height={92} />
        <Space direction='vertical' align='center' size={2} style={{ textAlign: 'center' }}>
          <Title level={4} style={{ color: colors.navy80, margin: 0, fontWeight: 500 }}>
            {isCreativeSubmissionScheduled
              ? t('scale_creatives_ads_submitted_modal_scheduled_title')
              : t('scale_creatives_ads_submitted_modal_title')}
          </Title>
          <Text style={{ color: colors.navy70 }}>
            {t('scale_creatives_ads_submitted_modal_subtitle')}
          </Text>
        </Space>
        <Button type='primary' onClick={handleSeeResult}>
          {t('scale_creatives_ads_submitted_modal_button')}
        </Button>
      </Flex>
    </Modal>
  )
}
