import { Card, Flex, Input, message, Modal, Typography } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { capitalizeWords } from 'utils/StringUtils'

import useMediationStore from 'stores/useMediationStore'
import useModalStore from 'stores/useModalStore'

import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Title, Text } = Typography

export default function EditAdUnitModal() {
  const { t } = useTranslation()

  const { isEditAdUnitModalShow, closeAll } = useModalStore()
  const { selectedAdType, selectedTempAdUnit, tempAdUnit, setTempAdUnit } = useMediationStore()

  const [newAdUnitName, setNewAdUnitName] = useState<string>('')

  const handleUpdate = useCallback(() => {
    if (!selectedAdType || !selectedTempAdUnit) return
    const adUnit = tempAdUnit.find(
      (adUnit) =>
        adUnit.name === selectedTempAdUnit.name && adUnit.type === selectedTempAdUnit.type,
    )
    if (!adUnit) return

    adUnit.name = newAdUnitName
    setTempAdUnit([...tempAdUnit])
    closeAll()
    message.success('Ad unit updated successfully')
    setNewAdUnitName('')
  }, [newAdUnitName, tempAdUnit, selectedTempAdUnit, selectedAdType])

  const hasSameValue = useMemo(() => {
    return selectedTempAdUnit?.name === newAdUnitName
  }, [newAdUnitName, selectedTempAdUnit?.name])

  const isNameAlreadyExist = useMemo(() => {
    return tempAdUnit.some((adUnit) => adUnit.name === newAdUnitName)
  }, [newAdUnitName, tempAdUnit])

  if (!isEditAdUnitModalShow || !selectedAdType) return null
  return (
    <Modal
      centered
      width={660}
      open={isEditAdUnitModalShow}
      title={
        <Title level={5} style={{ fontWeight: 700, margin: 0, color: colors.gray90 }}>
          Edit {capitalizeWords(selectedAdType as string)} Ad Unit Name
        </Title>
      }
      onCancel={closeAll}
      footer={false}>
      <form onSubmit={handleUpdate}>
        <Flex vertical gap={22} style={{ padding: '24px 24px 0px' }}>
          <Flex vertical gap={8}>
            <Text style={{ color: colors.gray70, fontWeight: 500 }}>Ad Unit Name</Text>
            <Input size='large' readOnly value={selectedTempAdUnit?.name} />
          </Flex>
          <Flex vertical gap={8}>
            <Text style={{ color: colors.gray70, fontWeight: 500 }}>New Ad Unit Name</Text>
            <Input
              autoFocus
              size='large'
              placeholder='Ex: Interstitial_GameName_GameFeature'
              value={newAdUnitName}
              onChange={(e) => setNewAdUnitName(e.target.value)}
            />
          </Flex>
          <Flex vertical align='center' gap={10} style={{ marginTop: 10 }}>
            <LabsButton
              htmlType='submit'
              width='wide'
              onClick={handleUpdate}
              isDisabled={!newAdUnitName || hasSameValue || isNameAlreadyExist}>
              Submit
            </LabsButton>
            {hasSameValue && (
              <Text style={{ color: colors.red60, fontSize: 12 }}>*Ad Unit cannot be the same</Text>
            )}
            {isNameAlreadyExist && (
              <Text style={{ color: colors.red60, fontSize: 12 }}>
                *Ad Unit name with {newAdUnitName} already exist
              </Text>
            )}
          </Flex>
        </Flex>
      </form>
    </Modal>
  )
}
