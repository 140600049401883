import { createSearchParams, useNavigate } from 'react-router-dom'

export default function useNavigateWithQueryParams() {
  const navigate = useNavigate()
  return (url: string, queryParams?: any, replace?: boolean, hash?: string) => {
    navigate(
      {
        pathname: url,
        search: `?${createSearchParams(queryParams)}`,
        hash: hash,
      },
      { replace: replace ?? true },
    )
  }
}
