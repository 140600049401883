import { lazy, Suspense } from 'react'
import { Navigate, Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

import { DASHBOARD_ROUTE, OVERVIEW_ROUTE, USER_ACQUISITION_ROUTE } from 'constants/Routes'

import TabNavigationLayout from 'components/layouts/TabNavigationLayout'
import AuthenticatedRoute from 'components/routes/AuthenticatedRoute'
import PermissionGuardedRoute from 'components/routes/PermissionGuardedRoute'

import { GamePublisherUserPermissionEnum } from 'types/permission'

const OverViewPage = lazy(() => import('pages/home'))
const UserAcquisitionPage = lazy(() => import('pages/user-acquisition-dashboard'))
const MediationPage = lazy(() => import('pages/mediation-dashboard'))
const InGamePage = lazy(() => import('pages/in-game'))

const items = [
  {
    key: 'overview',
    label: 'dashboard_menu_tab_overview_label',
  },
  {
    key: 'user-acquisition',
    label: 'dashboard_menu_tab_user_acquisition',
  },
  {
    key: 'mediation',
    label: 'dashboard_menu_tab_mediation',
  },
  {
    // isDisabled: true,
    key: 'in-game',
    label: 'dashboard_menu_tab_in_game',
  },
  {
    key: 'custom-dashboard',
    label: 'dashboard_menu_tab_custom_dashboard',
    isDisabled: true,
  },
]

const DashboardRouter = (
  <Route path={DASHBOARD_ROUTE}>
    <Route path='' element={<Navigate to={OVERVIEW_ROUTE} />} />
    <Route
      path={OVERVIEW_ROUTE}
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <TabNavigationLayout path={DASHBOARD_ROUTE} items={items}>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_PAGE_HOME}
                hasTab>
                <OverViewPage />
              </PermissionGuardedRoute>
            </TabNavigationLayout>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
    <Route
      path={USER_ACQUISITION_ROUTE}
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <TabNavigationLayout path={DASHBOARD_ROUTE} items={items}>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_PAGE_HOME}
                hasTab>
                <UserAcquisitionPage />
              </PermissionGuardedRoute>
            </TabNavigationLayout>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
    <Route
      path='mediation'
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <TabNavigationLayout path={DASHBOARD_ROUTE} items={items}>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_PAGE_HOME}
                hasTab>
                <MediationPage />
              </PermissionGuardedRoute>
            </TabNavigationLayout>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
    <Route
      path='in-game'
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <TabNavigationLayout path={DASHBOARD_ROUTE} items={items}>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_PAGE_HOME}
                hasTab>
                <InGamePage />
              </PermissionGuardedRoute>
            </TabNavigationLayout>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
  </Route>
)

export default DashboardRouter
