import { Flex, Typography } from 'antd'

import Tooltip from 'components/commons/tooltip'

import { statusLabel, statusTooltipLabel, tagStyle } from './styles'
import { IGameStoreStatusTag } from './type'

const { Text } = Typography
export default function GameStoreStatusTag({ status }: IGameStoreStatusTag) {
  const colorStyle = tagStyle[status].color
  const backgroundColorStyle = tagStyle[status].backgroundColor
  const borderColorStyle = tagStyle[status].borderColor
  const label = statusLabel[status]
  const tooltipLabel = statusTooltipLabel[status]

  return (
    <div
      style={{
        borderRadius: 20,
        background: backgroundColorStyle,
        padding: '4px 24px',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: borderColorStyle,
        width: 'fit-content',
        margin: 'auto',
      }}>
      <Flex align='center' gap={6} style={{ color: colorStyle }}>
        <Text style={{ fontSize: 14, fontWeight: 500, color: colorStyle }}>{label}</Text>
        <Tooltip color={colorStyle}>{tooltipLabel}</Tooltip>
      </Flex>
    </div>
  )
}
