import { useQuery } from '@apollo/client'
import {
  GameTargetingCountriesQuery,
  GameTargetingCountriesQueryVariables,
  GameTargetingCountry,
  GameTargetingCountryGroup,
  GameTargetingCountryGroupsQuery,
  GameTargetingCountryGroupsQueryVariables,
} from '__generated__/gql/graphql'
import { Flex, Typography } from 'antd'
import type { DefaultOptionType } from 'antd/lib/select'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { gameRegionsQuery, gameTargetingCountryGroupsQuery } from 'services/graphql/queries'

import useMarketingOnboardStore from 'stores/useMarketingOnboardStore'

import SelectOptions from 'components/commons/form/select-options'

import colors from 'theme/colors'

const { Text, Title } = Typography
export default function MarketingOnboardModalWizardFormGameRegion() {
  const { t } = useTranslation()
  const { gameRegion, setGameRegion } = useMarketingOnboardStore()
  const [isLoading] = useState<boolean>(false)

  const { loading: isGameTargetingCountryGroupsLoading, data: gameTargetingCountryGroups } =
    useQuery<GameTargetingCountryGroupsQuery, GameTargetingCountryGroupsQueryVariables>(
      gameTargetingCountryGroupsQuery,
    )

  const { data: gameTargetingCountries, loading: isGameTargetingCountiesLoading } = useQuery<
    GameTargetingCountriesQuery,
    GameTargetingCountriesQueryVariables
  >(gameRegionsQuery)

  const regionOptions: DefaultOptionType[] = useMemo(() => {
    const options = [
      {
        label: t('general_country_groups'),
        title: 'country-groups',
        options:
          gameTargetingCountryGroups?.gameTargetingCountryGroups
            ?.map((countryGroup: GameTargetingCountryGroup) => {
              return {
                label: countryGroup.name,
                value: countryGroup.code,
                disabled: countryGroup.isDisabled,
              }
            })
            ?.sort((a, b) => a.label.localeCompare(b.label)) || [],
      },
      {
        label: t('general_countries'),
        title: 'countries',
        options:
          gameTargetingCountries?.gameTargetingCountries
            ?.map((country: GameTargetingCountry) => {
              return { label: country.name, value: country.code, disabled: country.isDisabled }
            })
            ?.sort((a, b) => a.label.localeCompare(b.label)) || [],
      },
    ]

    return options
  }, [gameTargetingCountries, gameTargetingCountryGroups, t])

  return (
    <Flex vertical align='center' gap={20}>
      <Flex vertical align='center' gap={5}>
        <Title level={5} style={{ color: colors.gray90 }}>
          {t('marketing_onboarding_modal_targeting_country_title')}
        </Title>
        <Text style={{ color: colors.gray70, textAlign: 'center' }}>
          {t('marketing_onboarding_modal_targeting_country_description_1')} <br />{' '}
          {t('marketing_onboarding_modal_targeting_country_description_2')}
        </Text>
      </Flex>
      <Flex vertical gap={4} style={{ width: '80%' }}>
        <Text style={{ fontWeight: 500, color: colors.gray80 }}>Publish Region</Text>
        <SelectOptions
          mode='multiple'
          showSearch
          value={gameRegion || null}
          placeholder='Choose publish region'
          options={regionOptions}
          disabled={
            isLoading || isGameTargetingCountryGroupsLoading || isGameTargetingCountiesLoading
          }
          onChange={(e) => setGameRegion(e)}
        />
      </Flex>
    </Flex>
  )
}
