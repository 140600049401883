import { DownOutlined } from '@ant-design/icons'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  GameTagsQuery,
  GameTagsQueryVariables,
  GameTargetingCountriesQuery,
  GameTargetingCountriesQueryVariables,
  GameTargetingCountry,
  GameTargetingCountryGroup,
  GameTargetingCountryGroupsQuery,
  GameTargetingCountryGroupsQueryVariables,
  GetSimilarGamesQuery,
  GetSimilarGamesQueryVariables,
  UpdateGameDetailMutation,
  UpdateGameDetailMutationVariables,
} from '__generated__/gql/graphql'
import { Flex, Form, message, Typography } from 'antd'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { updateGameDetail } from 'services/graphql/mutations'
import {
  gameRegionsQuery,
  gameTagsQuery,
  gameTargetingCountryGroupsQuery,
  getSimilarGames,
} from 'services/graphql/queries'

import useModalStore from 'stores/useModalStore'

import useUserInfo from 'hooks/useUserInfo'

import InputGroup from 'components/commons/form/input-group'
import InputText from 'components/commons/form/input-text'
import SelectOptions from 'components/commons/form/select-options'
import LabsButton from 'components/commons/labs-kit/button'
import { SelectOpts } from 'components/modules/account-setting/type'

import { GameInfoData } from '../type'
import { GameInspirationPreferenceFormData } from './type'

const { Text } = Typography
export default function InspirationPreference({ gameStore }: GameInfoData) {
  const client = useApolloClient()
  const { t } = useTranslation()
  const [inspirationPreferenceForm] = Form.useForm()

  const similarGame = Form.useWatch(['similar games'], inspirationPreferenceForm)

  const { getGames } = useUserInfo()
  const { closeAll } = useModalStore()

  const { data: gameTagData, loading: isGameTagLoading } = useQuery<
    GameTagsQuery,
    GameTagsQueryVariables
  >(gameTagsQuery)

  const { data: similarGameData, loading: isSimilarGameLoading } = useQuery<
    GetSimilarGamesQuery,
    GetSimilarGamesQueryVariables
  >(getSimilarGames, {
    variables: {
      genre:
        gameStore?.game?.genres && gameStore.game.genres.length > 0
          ? gameStore.game.genres[0].name
          : '',
      withId: gameStore?.game?.similarGames?.map((game) => game.id) || [],
    },
    skip: !gameStore?.game?.genres || gameStore.game.genres.length === 0,
  })

  const { data: targetingCountryData, loading: isTargetingCountryLoading } = useQuery<
    GameTargetingCountriesQuery,
    GameTargetingCountriesQueryVariables
  >(gameRegionsQuery)

  const { data: targetingCountryGroupData, loading: isTargetingCountryGroupLoading } = useQuery<
    GameTargetingCountryGroupsQuery,
    GameTargetingCountryGroupsQueryVariables
  >(gameTargetingCountryGroupsQuery)

  const gameTagOptions: SelectOpts[] =
    gameTagData?.gameTags?.map((tag) => ({ label: tag.name, value: tag.code })) || []
  const similarGameOptions: SelectOpts[] =
    similarGameData?.similarGames?.map((game) => ({ label: game.name, value: game.id })) || []
  const targetRegionOptions = [
    {
      label: t('general_country_groups'),
      title: 'country-groups',
      options:
        targetingCountryGroupData?.gameTargetingCountryGroups
          ?.map((countryGroup: GameTargetingCountryGroup) => {
            return {
              label: countryGroup.name,
              value: countryGroup.code,
              disabled: countryGroup.isDisabled,
            }
          })
          ?.sort((a, b) => a.label.localeCompare(b.label)) || [],
    },
    {
      label: t('general_countries'),
      title: 'countries',
      options:
        targetingCountryData?.gameTargetingCountries
          ?.map((country: GameTargetingCountry) => {
            return { label: country.name, value: country.code, disabled: country.isDisabled }
          })
          ?.sort((a, b) => a.label.localeCompare(b.label)) || [],
    },
  ]
  const blacklistRegionOptions: SelectOpts[] =
    targetingCountryData?.gameTargetingCountries?.map((country) => ({
      label: country.name,
      value: country.code,
    })) || []

  const [updateGames, { loading: isUpdateLoading }] = useMutation<
    UpdateGameDetailMutation,
    UpdateGameDetailMutationVariables
  >(updateGameDetail)

  const usp = gameStore?.game.uniqueSellingPoints || ''
  const uniqueSellingPoints = usp
    .split(';')
    .filter((value) => value !== '')
    .map((usp) => usp.trim())

  const countries = [
    ...(gameStore?.game.countries || []).map((country) => country),
    ...(gameStore?.game.countryGroups || []).map((country) => country),
  ]

  const gamePlayOptions: SelectOpts[] = [
    'Story and Narrative',
    'Exploration and Open World',
    'Casual and Relaxing',
    'Action and Combat',
  ].map((gameplay) => ({ label: gameplay, value: gameplay }))

  const socialInteractionOptions: SelectOpts[] = [
    'Competitive Multiplayer',
    'Cooperative Multiplayer',
    'Social and Community',
    'Solo Play',
  ].map((socialInteraction) => ({ label: socialInteraction, value: socialInteraction }))

  const monetizationAndEngagementOptions: SelectOpts[] = [
    'In-App Ads only',
    'In-App Purchases only',
    'Both IAA and IAP, with a focus on IAA',
    'Both IAA and IAP, with a focus on IAP',
  ].map((monetizationAndEngagement) => ({
    label: monetizationAndEngagement,
    value: monetizationAndEngagement,
  }))

  const handleSubmit = useCallback(
    (formData: GameInspirationPreferenceFormData) => {
      if (!gameStore) return
      updateGames({
        variables: {
          gameStoreId: gameStore.id,
          gameDevelopmentStage: gameStore.gameDevelopmentStage,
          gameTitle: gameStore.game.name,
          gameEngine: gameStore.gameEngine || '',
          gameGenre: gameStore.game.genres.map((genre) => genre.code) || [],
          appUrl: gameStore.url || '',
          bundleId: gameStore.bundleId || '',
          logoUrl: gameStore.game.gameLogoUrl || '',
          uniqueSellingPoints: [
            formData['gameplay'],
            formData['social interaction'],
            formData['monetization and engagement'],
            formData['other'],
          ].join(';'),
          gameTag: formData['game tags'],
          similarGame: formData['similar games'].map((game) => game) || [],
          targetRegion: formData['target regions'],
          blocklistRegion: formData['blocklist region'],
        },
      }).then(() => {
        client.refetchQueries({
          include: ['GetGamesByPublisherId', 'GetGamePublisherUser'],
        })
        message.success('Game details updated successfully')
        closeAll()
        getGames()
      })
    },
    [gameStore, updateGames],
  )

  useEffect(() => {
    inspirationPreferenceForm.setFieldsValue({
      gameplay: uniqueSellingPoints.length >= 1 ? uniqueSellingPoints[0] : null,
      'social interaction': uniqueSellingPoints.length >= 2 ? uniqueSellingPoints[1] : null,
      'monetization and engagement':
        uniqueSellingPoints.length >= 3 ? uniqueSellingPoints[2] : null,
      other: uniqueSellingPoints.length >= 4 ? uniqueSellingPoints[3] : null,
      'game tags': gameStore?.game.tags.map((tag) => tag.code) || [],
      'similar games': (gameStore?.game.similarGames || []).map((game) => game.id) || [],
      'target regions': countries.map((country) => country.code) || [],
      'blocklist region': gameStore?.game.blacklistedCountries.map((country) => country.code) || [],
    })
  }, [gameStore])
  return (
    <Flex style={{ padding: '24px 4px 0', maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
      <Form<GameInspirationPreferenceFormData>
        name='game-information-form'
        form={inspirationPreferenceForm}
        onFinish={handleSubmit}
        scrollToFirstError={true}
        style={{ width: '100%' }}>
        <Flex vertical style={{ height: '100%' }}>
          <Flex vertical gap={6} flex={1}>
            <Text style={{ fontSize: 16, fontWeight: 500 }}>Unique Selling Point</Text>
            <InputGroup<GameInspirationPreferenceFormData>
              name='gameplay'
              title={t('account_setting_my_games_gameplay_title')}
              rules={[
                {
                  required: false,
                },
              ]}>
              <SelectOptions
                options={gamePlayOptions}
                placeholder='Choose game play'
                style={{
                  height: 40,
                }}
              />
            </InputGroup>
            <InputGroup<GameInspirationPreferenceFormData>
              name='social interaction'
              title={t('account_setting_my_games_social_interaction_title')}
              rules={[
                {
                  required: false,
                },
              ]}>
              <SelectOptions
                options={socialInteractionOptions}
                placeholder='Choose social interaction'
                style={{
                  height: 40,
                }}
              />
            </InputGroup>
            <InputGroup<GameInspirationPreferenceFormData>
              name='monetization and engagement'
              title={t('account_setting_my_games_monetization_and_engagement_title')}
              rules={[
                {
                  required: false,
                },
              ]}>
              <SelectOptions
                options={monetizationAndEngagementOptions}
                placeholder='Choose monetization and engagement'
                style={{
                  height: 40,
                }}
              />
            </InputGroup>

            <InputGroup<GameInspirationPreferenceFormData>
              name='other'
              title={t('account_setting_my_games_describe_more_title')}
              variant='base'>
              <InputText
                placeholder={
                  'Share any additional features or aspects you find unique about your game'
                }
              />
            </InputGroup>

            <br />

            <Text style={{ fontSize: 16, fontWeight: 500 }}>Other Info</Text>
            <InputGroup<GameInspirationPreferenceFormData>
              name='game tags'
              title='Game Tag'
              rules={[
                {
                  required: false,
                },
              ]}>
              <SelectOptions
                mode='multiple'
                options={gameTagOptions}
                loading={isGameTagLoading}
                placeholder='Choose game tag'
                style={{
                  height: 40,
                }}
              />
            </InputGroup>
            <InputGroup<GameInspirationPreferenceFormData>
              name='similar games'
              title='Similar Game'
              rules={[
                {
                  required: false,
                  type: 'array',
                  max: 5,
                },
              ]}>
              <SelectOptions
                mode='multiple'
                options={similarGameOptions}
                loading={isSimilarGameLoading}
                maxLength={5}
                placeholder='Choose similar game'
                suffixIcon={
                  <>
                    <span>
                      {(similarGame || []).length} / {5}
                    </span>
                    {<DownOutlined />}
                  </>
                }
                style={{
                  minHeight: 40,
                }}
              />
            </InputGroup>
            <InputGroup<GameInspirationPreferenceFormData>
              name='target regions'
              title='Publish Region'
              rules={[
                {
                  required: true,
                },
              ]}>
              <SelectOptions
                mode='multiple'
                options={targetRegionOptions}
                loading={isTargetingCountryLoading || isTargetingCountryGroupLoading}
                placeholder='Choose publish region'
                sortOption={false}
                style={{
                  height: 40,
                }}
              />
            </InputGroup>
            <InputGroup<GameInspirationPreferenceFormData>
              name='blocklist region'
              title='Blocklisted Country'
              rules={[
                {
                  required: false,
                },
              ]}>
              <SelectOptions
                mode='multiple'
                options={blacklistRegionOptions}
                loading={isTargetingCountryLoading}
                placeholder='Choose blocklisted country'
                style={{
                  height: 40,
                }}
              />
            </InputGroup>
            <br />
            <Flex flex={0} justify='center'>
              <LabsButton width='wide' htmlType='submit' isLoading={isUpdateLoading}>
                Save Changes
              </LabsButton>
            </Flex>
          </Flex>
        </Flex>
      </Form>
    </Flex>
  )
}
