import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

import { COOKIE_KEY_SESSION_ID } from 'constants/CookieKeys'

export default function useIdentifier() {
  const generateId = () => {
    let sessionId = Cookies.get(COOKIE_KEY_SESSION_ID)

    if (!sessionId) {
      sessionId = uuidv4()
      Cookies.set(COOKIE_KEY_SESSION_ID, sessionId, {
        expires: 1,
      })
    }

    return {
      sessionId,
    }
  }

  return { generateId }
}
