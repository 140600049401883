import { Dropdown, MenuProps, Typography } from 'antd'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { LANGUAGE_OPTIONS } from 'constants/Options'

import useUserStore, { Language } from 'stores/useUserStore'

import useTracker from 'hooks/useTracker'

import { labsGlobe } from 'components/commons/icon-lib/icons/base'
import LabsButton from 'components/commons/labs-kit/button'

import { ILanguageSelection } from './type'

const { Text } = Typography

export default function LanguageSelection({ mode }: ILanguageSelection) {
  const isMultiLanguageEnable = useFeatureFlagEnabled('enable-multi-language')
  const { track } = useTracker()

  const { user, selectedLanguage, setSelectedLanguage } = useUserStore()
  const { i18n } = useTranslation()

  // TODO: this is a hack cuz it relies this visual compontent to be rendered to change the selected language
  // We should call this probably in language initialization instead
  // Initialize first i18n based on user store state stored in local storage
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage.code)
  }, [selectedLanguage.code])

  const dropdownItems: MenuProps['items'] = LANGUAGE_OPTIONS.map((language) => {
    return {
      label: <Text style={{ textTransform: 'capitalize' }}>{language.code.toUpperCase()}</Text>,
      key: JSON.stringify(language),
      onClick: () => handleChangeLanguage(JSON.stringify(language)),
    }
  })

  const handleChangeLanguage = (value: string) => {
    const lang = JSON.parse(value) as Language

    track('change_language', {
      type: 'click',
      location: 'header',
      location_type: 'button',
      previous_language: selectedLanguage.code.toUpperCase(),
      target_language: lang.code.toUpperCase(),
    })

    setSelectedLanguage(lang)
  }

  const onOpened = (isOpened: boolean) => {
    if (isOpened) {
      track('show_language_selection', {
        type: 'click',
        location: 'header',
        location_type: 'button',
      })
    }
  }

  // if (!user.isDemo) return null
  return (
    <Dropdown menu={{ items: dropdownItems }} trigger={['click']} onOpenChange={onOpened}>
      <LabsButton
        icon={labsGlobe}
        color={mode === 'dark' ? 'secondary' : 'primary'}
        variant='outline'
        size='sm'>
        {selectedLanguage.code.toUpperCase()}
      </LabsButton>
    </Dropdown>
  )
}
