import { Modal, Typography } from 'antd'
import { useCallback, useMemo } from 'react'

import useMarketingOnboardStore from 'stores/useMarketingOnboardStore'
import useModalStore from 'stores/useModalStore'

import useUserInfo from 'hooks/useUserInfo'

import colors from 'theme/colors'

import MarketingModalOnboard from './marketing-onboard'
import MarketingOnboardModalFinished from './marketing-onboard-finished'
import ScaleSdkIntegrationModalWizardForm from './wizard-form'

const { Text } = Typography

export default function MarketingOnboardingModal() {
  const { getGames } = useUserInfo()

  const { step, resetState } = useMarketingOnboardStore()
  const { isMarketingOnboardingModalShow, closeAll } = useModalStore()

  const handleClose = useCallback(() => {
    getGames()
    if (step === 'finished') resetState()
    closeAll()
  }, [step])

  const isClosable = useMemo(() => {
    return ['onboard', 'finished'].includes(step)
  }, [step])

  if (!isMarketingOnboardingModalShow) return null
  return (
    <Modal
      title={
        step === 'form' ? (
          <Text style={{ fontSize: '16px', fontWeight: 700, color: colors.gray90 }}>
            Let&apos;s get you set up!
          </Text>
        ) : null
      }
      open={isMarketingOnboardingModalShow}
      footer={false}
      centered
      width={step === 'finished' ? undefined : 800}
      maskClosable={isClosable}
      closable={isClosable}
      onCancel={handleClose}>
      {step === 'onboard' && <MarketingModalOnboard />}
      {step === 'form' && <ScaleSdkIntegrationModalWizardForm />}
      {step === 'finished' && <MarketingOnboardModalFinished />}
    </Modal>
  )
}
