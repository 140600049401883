import { InfoCircleOutlined } from '@ant-design/icons'
import { Col, Flex, Form, Row, Space, Tooltip, Typography } from 'antd'
import { ReactElement } from 'react'

import colors from 'theme/colors'

import { IInputGroup } from './type'

const { Text } = Typography
export default function InputGroup<FormData>({
  children,
  title,
  info,
  // icon,
  name,
  rules,
  extra,
  valuePropName,
  variant = 'base',
  validateStatus,
  style,
  customComponent,
}: IInputGroup<FormData>): ReactElement {
  let textColor = ``
  switch (variant) {
    case 'v2':
      textColor = colors.labs10
      break
    case 'grey':
      textColor = colors.gray70
      break
    case 'base':
      textColor = colors.gray70
      break
    default:
      textColor = colors.labs70
      break
  }
  return (
    <Row>
      {/* <Col span={2}>
          {typeof icon === 'string' ? <Pass3Icon name={icon as Pass3IconName} /> : icon}
        </Col> */}
      <Col style={{ width: '100%' }}>
        <Space direction='vertical' size={6} style={{ width: '100%' }}>
          {title && (
            <Row gutter={8}>
              <Col style={{ width: '100%' }}>
                <Flex align='center' justify='space-between' style={{ width: '100%' }}>
                  <Text
                    style={{
                      color: textColor,
                      fontWeight: 'bold',
                    }}>
                    {title}
                  </Text>
                  {customComponent}
                </Flex>
              </Col>
              {info ? (
                <Col>
                  <Tooltip title={info}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Col>
              ) : null}
            </Row>
          )}
          <Form.Item<FormData>
            validateStatus={validateStatus}
            hasFeedback={validateStatus !== undefined ? true : false}
            name={name}
            rules={rules}
            extra={extra}
            valuePropName={valuePropName}
            style={style}>
            {children}
          </Form.Item>
        </Space>
      </Col>
    </Row>
  )
}
