export const INDEX_ROUTE = '/'

export const AUTHENTICATION_ROUTE = 'authentication'
export const AUTHENTICATION_ROUTE_LOGIN = 'authentication?v=login'
export const AUTHENTICATION_ROUTE_REGISTER = 'authentication?v=register'
export const AUTHENTICATION_ROUTE_EMAIL_VERIFICATION = 'authentication?v=email-verification'

export const DASHBOARD_ROUTE = 'dashboard'
export const MEDIATION_DASHBOARD_ROUTE = 'dashboard/mediation'
export const IN_GAME_DASHBOARD_ROUTE = 'dashboard/in-game'

export const USER_ACQUISITION_ROUTE = 'user-acquisition'
export const USER_ACQUISITION_MY_ADS_ROUTE = 'user-acquisition/my-ads'
export const USER_ACQUISITION_PUBLISH_ADS_ROUTE = 'user-acquisition/publish-ads'

export const ADS_INSPIRATION_ROUTE = 'ads-inspiration'
export const ADS_INSPIRATION_DETAIL_ROUTE = 'ads-inspiration/detail'

export const MEDIATION_ROUTE = 'mediation'
export const MEDIATION_INITIALIZATION_SETUP_ROUTE = 'mediation/initialization-setup'
export const MEDIATION_CREATE_AD_UNIT_ROUTE = 'mediation/create-ad-unit'
export const MEDIATION_DELETE_AD_UNIT_ROUTE = 'mediation/delete-ad-unit'
export const MEDIATION_CASE_STUDY_ROUTE = 'mediation/case-study'
export const MEDIATION_CASE_STUDY_HAMSTER_JUMP_ROUTE = 'mediation/case-study/hamster-jump'
export const MEDIATION_BEST_PRACTICE = 'mediation/best-practice'
export const MEDIATION_BEST_PRACTICE_AD_TYPE = 'mediation/best-practice/ad-type?type='
export const MEDIATION_ANALYTICS_DATA_ROUTE = 'mediation/analytics-data'

export const FINANCING_ROUTE = 'financing'

export const ADD_ON_ROUTE = 'add-on'

export const INTEGRATION_ROUTE = 'integration'
export const INTEGRATION_GUIDE_ROUTE = 'integration/guide'
export const INTEGRATION_BASIC_SCALE_ROUTE = 'integration/basic-scale-integration'
export const INTEGRATION_BASIC_SCALE_CHECKER_ROUTE =
  'integration/basic-scale-integration/integration-checker'
export const INTEGRATION_BASIC_SCALE_ADD_BIG_QUERY_ROUTE =
  'integration/basic-scale-integration/add-bigquery'
export const INTEGRATION_BASIC_SCALE_FIREBASE_INVITE_ROUTE =
  'integration/basic-scale-integration/firebase-invite'
export const INTEGRATION_BASIC_SCALE_ADJUST_INVITE_ROUTE =
  'integration/basic-scale-integration/adjust-invite'
export const INTEGRATION_FACEBOOK_ROUTE = 'integration/facebook-integration'
export const INTEGRATION_ADMOB_ROUTE = 'integration/admob-integration'
export const INTEGRATION_ADMOB_CHECKER_ROUTE = 'integration/admob-integration/integration-checker'

export const ACCOUNT_SETTING_ROUTE = 'account-setting'
export const BASIC_INFORMATION_ROUTER = 'profile/basic-information'
export const GAME_INFORMATION_ROUTER = 'game/information'
export const GAME_DETAIL_INFORMATION_ROUTER = 'game/information/detail'

export const GAME_ANALYTICS_ROUTE = 'game-analytics'
export const DAILY_MONITORING_GAME_ANALYTICS_ROUTE = 'game-analytics/daily-monitoring'
export const COMPARISON_GAME_ANALYTICS_ROUTE = 'game-analytics/comparison'

export const MARKETING_ANALYTICS_ROUTE = 'marketing-analytics'
export const DAILY_MARKETING_MARKETING_ANALYTICS_ROUTE = 'marketing-analytics/daily-marketing'
export const RETENTION_MARKETING_ANALYTICS_ROUTE = 'marketing-analytics/retention'

export const INFRASTRUCTURE_ROUTE = 'infrastructure'

export const WAITING_LIST_ROUTE = 'waiting-list'
export const SETTING_UP_ACCOUNT_ROUTE = 'setting-up-account'
export const GETTING_STARTED_ROUTE = 'getting-started'
export const PROGRESSION_ROUTE = 'progression'
export const OVERVIEW_ROUTE = 'overview'

export const GAME_ROUTE = 'game'
export const ADD_GAME_ROUTE = 'game/add-game'
export const ADD_GAME_PLATFORM_ROUTE = 'game/add-platform'

export const GAME_MANAGEMENT_ROUTE = 'game-management'
export const TOS_ROUTE = 'terms-of-service'

export const ONBOARDING_ROUTE = 'onboarding'
export const ONBOARDING_ADD_GAME_ROUTE = 'onboarding/add-game'
export const ONBOARDING_INVITATION_ROUTE = 'onboarding/invitation'
