import { Col, Flex, Row, Typography } from 'antd'

import { handleCopyToClipboard } from 'utils/CommonUtils'

import IconLib from 'components/commons/icon-lib'
import { labsCopyOutline } from 'components/commons/icon-lib/icons/base'

import colors from 'theme/colors'

import { iGameDetailInfoItem } from './type'

const { Text } = Typography
export default function GameDetailInfoItem({ label, value, showCopy }: iGameDetailInfoItem) {
  return (
    <Col span={24}>
      <Row gutter={12} style={{ width: '100%' }}>
        <Col span={8}>
          <Text style={{ color: colors.gray70 }}>{label}</Text>
        </Col>
        <Col span={16}>
          <Flex align='center' gap={4}>
            <Text style={{ color: colors.gray90, fontWeight: 700 }}>{value || '-'}</Text>
            {showCopy && value && (
              <IconLib icon={labsCopyOutline} onClick={() => handleCopyToClipboard(value)} />
            )}
          </Flex>
        </Col>
      </Row>
    </Col>
  )
}
