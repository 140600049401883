import { Flex, Space } from 'antd'
import { Header } from 'antd/es/layout/layout'
import React, { CSSProperties, useCallback } from 'react'

import useMenuStore from 'stores/useMenuStore'

import UserInfo from 'components/modules/header-info'

import colors from 'theme/colors'

import IconLib from '../icon-lib'
import { labsSidebarCollapse, labsSidebarExpand } from '../icon-lib/icons/base'
import HeaderCreateAds from './create-ads'
import LanguageSelection from './language-selection'
import HeaderNotification from './notification'

const headerStyle: CSSProperties = {
  backgroundColor: colors.gray10,
  zIndex: 21,
  position: 'sticky',
  top: 0,
  paddingLeft: 16,
  paddingRight: 24,
  height: 64,
  borderBottom: '1px solid',
  border: 'none',
  boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.12)',
}
interface AuthenticatedHeaderProps {
  authenticatedHeaderRef?: React.RefObject<HTMLDivElement>
}

const AuthenticatedHeader: React.FC<AuthenticatedHeaderProps> = ({ authenticatedHeaderRef }) => {
  const { isExpanded, setIsExpanded } = useMenuStore()

  const handleToggleExpandMenu = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [isExpanded])

  return (
    <Header ref={authenticatedHeaderRef} className='header-labs' style={headerStyle}>
      <Flex justify='space-between' align='center' style={{ height: '100%' }}>
        <Flex align='center' gap={48}>
          <Flex align='center' gap={24}>
            <Flex
              justify='center'
              align='center'
              style={{ padding: 4, cursor: 'pointer' }}
              onClick={handleToggleExpandMenu}>
              <IconLib
                icon={isExpanded ? labsSidebarCollapse : labsSidebarExpand}
                color='labs40'
                size='lg'
              />
            </Flex>
            <img src='/images/noctua-labs.webp' width={192} height={50} alt='' draggable={false} />
          </Flex>
        </Flex>

        <Space size={10}>
          <HeaderCreateAds />
          <LanguageSelection mode='dark' />
          <HeaderNotification />
          <UserInfo />
        </Space>
      </Flex>
    </Header>
  )
}

export default AuthenticatedHeader
