import { Button, Flex, Modal, Typography } from 'antd'
import { t } from 'i18next'

import colors from 'theme/colors'

import useModalStore from '../../../../stores/useModalStore'

const { Text, Title } = Typography

export default function WaitingCurrentIntegration() {
  const { isWaitingCurrentIntegrationModalShow, closeAll } = useModalStore()

  return (
    <Modal
      title={false}
      centered
      open={isWaitingCurrentIntegrationModalShow}
      onCancel={closeAll}
      footer={false}
      width={663}>
      <Flex
        vertical
        justify='center'
        align='center'
        gap={36}
        style={{ padding: '32px 24px 16px 24px' }}>
        <Flex vertical align='center' justify='center' gap={10}>
          <Title level={4} style={{ color: colors.gray90, margin: 0, fontWeight: 700 }}>
            You can’t start the integration
          </Title>
          <Text
            style={{ color: colors.gray70, textAlign: 'center', fontSize: 14, fontWeight: 400 }}>
            You need to complete the current integration process. Once it is done, you will be able
            to start another store integration.
          </Text>
        </Flex>
        <Button
          type='primary'
          size='large'
          style={{ width: 146, color: colors.steel10, fontWeight: 700, fontSize: 14 }}
          onClick={closeAll}>
          {t('general_got_it')}
        </Button>
      </Flex>
    </Modal>
  )
}
