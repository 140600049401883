import { lazy, Suspense } from 'react'
import { Navigate, Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

import { ONBOARDING_ADD_GAME_ROUTE } from 'constants/Routes'

import AuthenticatedRoute from 'components/routes/AuthenticatedRoute'

const AddGamePage = lazy(() => import('pages/onboarding/add-game'))
const InvitationPage = lazy(() => import('pages/onboarding/invitation'))

const OnboardingRouter = (
  <Route path='onboarding'>
    <Route path='' element={<Navigate to={`/${ONBOARDING_ADD_GAME_ROUTE}`} replace />} />
    <Route
      path='add-game'
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <AddGamePage />
          </AuthenticatedRoute>
        </Suspense>
      }
    />
    <Route
      path='invitation'
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <InvitationPage />
          </AuthenticatedRoute>
        </Suspense>
      }
    />
  </Route>
)

export default OnboardingRouter
