import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

import AnonymousRoute from 'components/routes/AnonymousRoute'

const AuthenticationPage = lazy(() => import('pages/authentication'))

const AuthenticationRouter = (
  <Route
    path='authentication'
    element={
      <Suspense fallback={<FullScreenLoader isAuthenticated={false} />}>
        <AnonymousRoute>
          <AuthenticationPage />
        </AnonymousRoute>
      </Suspense>
    }></Route>
)

export default AuthenticationRouter
