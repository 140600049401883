import { AppConfig } from 'types/index'

const appConfig: AppConfig = {
  buildVersion: process.env.REACT_APP_BUILD_VERSION as string,
  env: process.env.REACT_APP_ENV as 'production' | 'development' | 'staging',
  labsNodeJsGQLServiceUrl: process.env.REACT_APP_LABS_NODEJS_GQL_SERVICE_URL as string,

  mixPanelToken: process.env.REACT_APP_MIXPANEL_TOKEN as string,
  androidSdkDownloadUrl: process.env.REACT_APP_ANDROID_SDK_DOWNLOAD_URL as string,
  iosSdkDownloadUrl: process.env.REACT_APP_IOS_SDK_DOWNLOAD_URL as string,

  posthogKey: process.env.REACT_APP_POSTHOG_KEY as string,
  posthogHost: process.env.REACT_APP_POSTHOG_HOST as string,
  supersetBaseURL: process.env.REACT_APP_SUPERSET_BASE_URL as string,
  glitchTipDsn: process.env.REACT_APP_GLITCH_TIP_DSN as string,
}

// @ts-ignore
window.appConfig = appConfig

export default appConfig
