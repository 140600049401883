import { Button, Flex, Modal, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import useModalStore from 'stores/useModalStore'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function WarningIncompleteDataModal() {
  const { t } = useTranslation()
  const { isWarningIncompleteDataModalShow, closeAll } = useModalStore()

  return (
    <Modal
      title={false}
      centered
      open={isWarningIncompleteDataModalShow}
      onCancel={closeAll}
      footer={false}
      width={550}>
      <Flex
        vertical
        justify='center'
        align='center'
        gap={20}
        style={{ padding: '30px 0px 10px 0px' }}>
        <img src='/images/illustration/warning-info.webp' width={62} height={62} />
        <Flex vertical gap={3} align='center' justify='center'>
          <Title
            level={4}
            style={{ color: colors.yellow80, margin: 0, fontWeight: 500, textAlign: 'center' }}>
            Incomplete Data
          </Title>
          <Text style={{ color: colors.gray90, textAlign: 'center' }}>
            The data for the selected date (h-1) may be incomplete.
            <br /> Some charts may not reflect the full dataset.
          </Text>
        </Flex>
        <Button
          type='primary'
          onClick={closeAll}
          style={{
            padding: '7px 20px',
            justifyContent: 'center',
            height: 36,
          }}>
          Ok, I understand
        </Button>
      </Flex>
    </Modal>
  )
}
