import { useQuery } from '@apollo/client'
import {
  FindGameStoreByIdQuery,
  FindGameStoreByIdQueryVariables,
  GameStore,
} from '__generated__/gql/graphql'
import { Flex, Modal, Spin, Tabs, Typography } from 'antd'
import { useMemo, useState } from 'react'
import { findGameStoreById } from 'services/graphql/queries'

import useModalStore from 'stores/useModalStore'

import colors from 'theme/colors'

import GameDetailTab from './game-detail'
import InspirationPreference from './inspiration-preference'
import { GameDetailModalTab } from './type'

const { Text, Title } = Typography
const { TabPane } = Tabs
export default function EditGameModal() {
  const { selectedGameStoreId, isEditGameModalShow, closeAll } = useModalStore()

  const [columnsTab, setColumnsTab] = useState<GameDetailModalTab>('game-detail')

  const { data, loading } = useQuery<FindGameStoreByIdQuery, FindGameStoreByIdQueryVariables>(
    findGameStoreById,
    {
      variables: { gameStoreId: selectedGameStoreId || '-' },
      skip: !selectedGameStoreId,
      fetchPolicy: 'no-cache',
    },
  )

  const gameStore = useMemo(() => {
    return data?.findGameStoreById || null
  }, [data])

  const ModalTitle = useMemo(() => {
    if (!gameStore) return null
    return (
      <Title level={3} style={{ color: colors.gray90, margin: 0 }}>
        Edit Your Game
      </Title>
    )
  }, [gameStore])

  if (!isEditGameModalShow) return null
  return (
    <Modal
      title={ModalTitle}
      centered
      open={isEditGameModalShow}
      onCancel={closeAll}
      footer={false}
      width={770}>
      <Flex vertical gap={20} style={{ padding: '12px 20px 24px', minHeight: 500 }}>
        {!gameStore || loading ? (
          <Flex align='center' justify='center' style={{ height: '100%' }}>
            <Spin />
          </Flex>
        ) : (
          <Tabs
            id='tabs-default'
            indicatorSize={0}
            activeKey={columnsTab}
            tabBarGutter={10}
            onTabClick={(tab) => setColumnsTab(tab as GameDetailModalTab)}
            style={{ width: '100%' }}>
            <TabPane
              key='game-detail'
              tabKey='game-detail'
              tab={<Text style={{ color: colors.gray90 }}>Game Details</Text>}>
              <GameDetailTab gameStore={gameStore as GameStore} />
            </TabPane>
            <TabPane
              key='inspiration-preference'
              tabKey='inspiration-preference'
              tab={<Text style={{ color: colors.gray90 }}>Inspiration Preference</Text>}>
              <InspirationPreference gameStore={gameStore as GameStore} />
            </TabPane>
          </Tabs>
        )}
      </Flex>
    </Modal>
  )
}
