import { Button, Flex, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import useMarketingOnboardStore from 'stores/useMarketingOnboardStore'

import colors from 'theme/colors'

const { Title, Text } = Typography
export default function MarketingModalOnboard() {
  const { t } = useTranslation()

  const { setStep } = useMarketingOnboardStore()

  const handleNext = () => {
    setStep('form')
  }

  return (
    <Flex vertical justify='center' align='center' gap={32} style={{ padding: '20px 0px' }}>
      <img src='/images/icon/marketing-modal-icon.webp' width={70} height={70} />
      <Flex vertical align='center' justify='center' gap={10} style={{ width: 600 }}>
        <Title level={4} style={{ color: colors.gray90, margin: 0, fontWeight: 'bold' }}>
          {t('marketing_onboard_modal_title')}
        </Title>
        <Text style={{ color: colors.gray70, textAlign: 'center' }}>
          {t('marketing_onboard_modal_description')}
        </Text>
      </Flex>
      <Button
        key='get-started-button'
        type='primary'
        size='large'
        style={{
          width: 106,
          justifyContent: 'center',
          alignItems: 'center',
          margin: 0,
          padding: 0,
        }}
        onClick={handleNext}>
        Start Now
      </Button>
    </Flex>
  )
}
