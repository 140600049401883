import { Flex, Image, Typography } from 'antd'
import Modal from 'antd/es/modal/Modal'
import { useTranslation } from 'react-i18next'

import useModalStore from 'stores/useModalStore'

import useTracker from 'hooks/useTracker'

import colors from 'theme/colors'

const { Text, Title } = Typography
export default function LabsSupportModal() {
  const { t } = useTranslation()
  const { track } = useTracker()

  const { isLabsSupportModalShow, closeAll } = useModalStore()

  const handleClickContactSupport = () => {
    window.location.href = 'mailto:support-labs.tech@noctua.gg'
  }

  const handleClickKnowledgeBank = () => {
    const url = 'https://noctua-labs.gitbook.io/noctua-labs/noctua-labs/knowledge-bank'
    window.open(url, '_blank')
  }

  const handleClickTutorial = () => {
    const url = 'https://noctua-labs.gitbook.io/noctua-labs/noctua-labs/tutorial'
    window.open(url, '_blank')
  }

  return (
    <Modal
      centered
      width={500}
      open={isLabsSupportModalShow}
      onCancel={closeAll}
      footer={false}
      styles={{
        content: {
          background:
            'linear-gradient(25deg, rgba(54, 133, 241, 0.00) 6.78%, rgba(54, 133, 241, 0.20) 94.63%), #FFF',
        },
      }}>
      <Flex vertical align='center' gap={32} style={{ padding: '32px 64px' }}>
        <Flex vertical align='center' gap={14}>
          <Image preview={false} src='/images/icon/help-icon.webp' height={72} width={72} />
          <Title level={4} style={{ margin: 0, color: colors.gray90 }}>
            {t('labs_support_modal_title')}
          </Title>
        </Flex>
        <Flex gap={24}>
          <Flex
            vertical
            gap={15}
            align='center'
            justify='end'
            style={{
              borderRadius: 8,
              width: 115,
              height: 87,
              padding: '14px 8px 8px',
              backgroundColor: colors.gray10,
              cursor: 'pointer',
            }}
            onClick={handleClickContactSupport}>
            <Image
              preview={false}
              src='/images/icon/contact-support-icon.webp'
              height={36}
              width={36}
            />
            <Text style={{ fontSize: 12, color: colors.gray70, fontWeight: 700 }}>
              {t('labs_support_modal_contact_support')}
            </Text>
          </Flex>

          <Flex
            vertical
            gap={15}
            align='center'
            justify='end'
            style={{
              borderRadius: 8,
              width: 115,
              height: 87,
              padding: '14px 8px 8px',
              backgroundColor: colors.gray10,
              cursor: 'pointer',
            }}
            onClick={handleClickKnowledgeBank}>
            <Image
              preview={false}
              src='/images/icon/knowledge-bank-support-icon.webp'
              height={28}
              width={40}
            />
            <Text style={{ fontSize: 12, color: colors.gray70, fontWeight: 700 }}>
              {t('labs_support_modal_knowledge_bank')}
            </Text>
          </Flex>

          <Flex
            vertical
            gap={15}
            align='center'
            justify='end'
            style={{
              borderRadius: 8,
              width: 115,
              height: 87,
              padding: '14px 8px 8px',
              backgroundColor: colors.gray10,
              cursor: 'pointer',
            }}
            onClick={handleClickTutorial}>
            <Image preview={false} src='/images/icon/tutorial-icon.webp' height={31} width={27} />
            <Text style={{ fontSize: 12, color: colors.gray70, fontWeight: 700 }}>
              {t('labs_support_modal_tutorial')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  )
}
