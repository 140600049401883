import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

import { GAME_MANAGEMENT_ROUTE } from 'constants/Routes'

import AuthenticatedRoute from 'components/routes/AuthenticatedRoute'
import PermissionGuardedRoute from 'components/routes/PermissionGuardedRoute'

import { GamePublisherUserPermissionEnum } from 'types/permission'

const GameManagementPage = lazy(() => import('pages/game-management'))

const GameManagementRouter = (
  <Route path={GAME_MANAGEMENT_ROUTE}>
    <Route
      path=''
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <PermissionGuardedRoute
              permission={GamePublisherUserPermissionEnum.VIEW_PAGE_GAME_MANAGEMENT}>
              <GameManagementPage />
            </PermissionGuardedRoute>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
  </Route>
)

export default GameManagementRouter
