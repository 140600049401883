import { MetricsDataType } from '__generated__/gql/graphql'
import { message } from 'antd'
import moment from 'moment'

import { formatCurrency, formatNumber } from './FormatterUtils'
import { capitalizeWords } from './StringUtils'

export const handleCopyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value)
  message.success('Success copy to clipboard')
}

export const formatMetricsValue = (type: MetricsDataType, value: string, decimal?: number) => {
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })

  switch (type) {
    case MetricsDataType.Country:
      return regionNames.of((value as string).toUpperCase())
    case MetricsDataType.Number:
      return value ? formatNumber(Number(value), decimal === undefined ? 2 : decimal) : '-'
    case MetricsDataType.Percentage:
      return value ? `${formatNumber(Number(value), decimal === undefined ? 2 : decimal)}%` : '-'
    case MetricsDataType.Currency:
      return value ? formatCurrency(Number(value), decimal === undefined ? 3 : decimal) : '-'
    case MetricsDataType.Date:
      return value ? moment(value as string).format('DD/MM/YYYY') : '-'
    case MetricsDataType.Text:
      return value ? capitalizeWords(value as string) : '-'
    default:
      return value || '-'
  }
}
