import { Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import colors from 'theme/colors'

const { Title } = Typography

export default function NetworkErrorPage() {
  const { t } = useTranslation()

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.labs10,
      }}>
      <Space size={0} direction='vertical' align='center' style={{ paddingBottom: 100 }}>
        <img src='/images/illustration/not-found.webp' height={342} width={342} />
        <Title style={{ margin: 0 }}>Ooops</Title>
        <Title level={4} style={{ color: colors.labs120, fontWeight: 'bold' }}>
          {t('error_page_network_error')}
        </Title>
      </Space>
    </div>
  )
}
