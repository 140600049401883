import {
  CreativeAdPlatform,
  CreativeInspirationMediaType,
  CreativeInspirationOrientation,
} from '__generated__/gql/graphql'
import { Button, Flex, Modal, Typography } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CreativeInspirationTags } from 'pages/ads-inspiration/type'

import useInspirationStore from 'stores/useInspirationStore'
import useModalStore from 'stores/useModalStore'

import useTracker from 'hooks/useTracker'

import IconLib from 'components/commons/icon-lib'
import {
  labsFacebook,
  labsGoogle,
  LabsIconBase,
  labsMintegral,
} from 'components/commons/icon-lib/icons/base'

import colors from 'theme/colors'

const { Text, Title } = Typography

const ModalTitle = () => {
  const { t } = useTranslation()

  return (
    <Title level={4} style={{ color: colors.navy80 }}>
      {t('scale_creative_inspiration_filter_modal_title')}
    </Title>
  )
}

export default function CreativeInspirationFilterModal() {
  const { t } = useTranslation()
  const { track } = useTracker()

  const { filter, setFilter } = useInspirationStore()
  const { isCreativeFilterShow, closeAll } = useModalStore()

  const [hasFilterChanged, setHasFilterChanged] = useState<boolean>(false)
  const [filterChannel, setFilterChannel] = useState<CreativeAdPlatform['name'][]>([])
  const [filterOrientation, setFilterOrientation] = useState<CreativeInspirationOrientation[]>([])
  const [filterMediaType, setFilterMediaType] = useState<CreativeInspirationMediaType[]>([])
  const [filterTags, setFilterTags] = useState<CreativeInspirationTags[]>([])

  const hasOptionsToFilter = useMemo(() => {
    if (filter.channel.length > 0) return true
    if (filter.orientation.length > 0) return true
    if (filter.mediaType.length > 0) return true
    if (filter.tags.length > 0) return true

    return false
  }, [filter])

  const handleFilterChannel = useCallback(
    (value: CreativeAdPlatform['name']) => {
      setHasFilterChanged(true)
      if (filterChannel?.includes(value))
        setFilterChannel(filterChannel?.filter((filter) => filter !== value))
      else setFilterChannel([...filterChannel, value])
    },
    [filterChannel],
  )

  const handleFilterOrientation = useCallback(
    (value: CreativeInspirationOrientation) => {
      setHasFilterChanged(true)
      if (filterOrientation?.includes(value))
        setFilterOrientation(filterOrientation?.filter((filter) => filter !== value))
      else setFilterOrientation([...filterOrientation, value])
    },
    [filterOrientation],
  )

  const handleFilterMediaType = useCallback(
    (value: CreativeInspirationMediaType) => {
      setHasFilterChanged(true)
      if (filterMediaType?.includes(value))
        setFilterMediaType(filterMediaType?.filter((filter) => filter !== value))
      else setFilterMediaType([...filterMediaType, value])
    },
    [filterMediaType],
  )

  const handleFilterTags = useCallback(
    (value: CreativeInspirationTags) => {
      setHasFilterChanged(true)
      if (filterTags?.includes(value))
        setFilterTags(filterTags?.filter((filter) => filter !== value))
      else setFilterTags([...filterTags, value])
    },
    [filterTags],
  )

  const handleClearFilter = useCallback(() => {
    setHasFilterChanged(true)
    setFilter({ ...filter, channel: [], orientation: [], mediaType: [], tags: [] })
    setFilterChannel([])
    setFilterOrientation([])
    setFilterMediaType([])
    setFilterTags([])
    closeAll()

    track('clear_filter', {
      type: 'click',
      location: 'filter_modal',
      location_type: 'button',
    })
  }, [track, filter])

  const handleApplyFilter = useCallback(() => {
    setFilter({
      ...filter,
      channel: filterChannel,
      orientation: filterOrientation,
      mediaType: filterMediaType,
      tags: filterTags,
    })
    closeAll()

    track('apply_filter', {
      type: 'click',
      location: 'filter_modal',
      location_type: 'button',
      filter_channel: filterChannel.join(','),
      filter_orientation: filterOrientation.join(','),
      filter_media_type: filterMediaType.join(','),
      filter_tags: filterTags.join(','),
    })
  }, [track, filter, filterChannel, filterOrientation, filterMediaType, filterTags])

  useEffect(() => {
    if (!isCreativeFilterShow) {
      setHasFilterChanged(false)
      return
    }

    setFilterChannel(filter.channel)
    setFilterOrientation(filter.orientation)
    setFilterMediaType(filter.mediaType)
    setFilterTags(filter.tags)
  }, [isCreativeFilterShow])

  return (
    <Modal
      title={<ModalTitle />}
      centered
      open={isCreativeFilterShow}
      onCancel={closeAll}
      footer={false}
      width={683}>
      <Flex vertical justify='space-between' gap={16}>
        <Flex
          vertical
          gap={32}
          style={{ padding: '16px 24px 24px', backgroundColor: colors.gray20, borderRadius: 14 }}>
          <Flex vertical gap={12}>
            <Text style={{ color: colors.gray70, fontWeight: 700 }}>
              {t('scale_creative_inspiration_filter_modal_platform_label')}
            </Text>
            <Flex gap={12}>
              <FilterButton
                icon={labsGoogle}
                label='Google'
                isSelected={!!filterChannel?.includes('google')}
                onClick={() => handleFilterChannel('google')}
              />
              <FilterButton
                icon={labsFacebook}
                label='Facebook'
                isSelected={!!filterChannel?.includes('facebook')}
                onClick={() => handleFilterChannel('facebook')}
              />
              <FilterButton
                icon={labsMintegral}
                label='Mintegral'
                isSelected={!!filterChannel?.includes('mintegral')}
                onClick={() => handleFilterChannel('mintegral')}
              />
              <FilterButton
                label='Other'
                isSelected={!!filterChannel?.includes('other')}
                onClick={() => handleFilterChannel('other')}
              />
            </Flex>
          </Flex>

          <Flex vertical gap={12}>
            <Text style={{ color: colors.gray70, fontWeight: 700 }}>
              {t('scale_creative_inspiration_filter_modal_dimension_label')}
            </Text>
            <Flex gap={12}>
              <FilterButton
                label='Portrait'
                isSelected={!!filterOrientation?.includes(CreativeInspirationOrientation.Portrait)}
                onClick={() => handleFilterOrientation(CreativeInspirationOrientation.Portrait)}
              />
              <FilterButton
                label='Landscape'
                isSelected={!!filterOrientation?.includes(CreativeInspirationOrientation.Landscape)}
                onClick={() => handleFilterOrientation(CreativeInspirationOrientation.Landscape)}
              />
              <FilterButton
                label='Square'
                isSelected={!!filterOrientation?.includes(CreativeInspirationOrientation.Square)}
                onClick={() => handleFilterOrientation(CreativeInspirationOrientation.Square)}
              />
            </Flex>
          </Flex>

          <Flex vertical gap={12}>
            <Text style={{ color: colors.gray70, fontWeight: 700 }}>
              {t('scale_creative_inspiration_filter_modal_media_type_label')}
            </Text>
            <Flex gap={12}>
              <FilterButton
                label='Video'
                isSelected={!!filterMediaType?.includes(CreativeInspirationMediaType.Video)}
                onClick={() => handleFilterMediaType(CreativeInspirationMediaType.Video)}
              />
              <FilterButton
                label='Image'
                isSelected={!!filterMediaType?.includes(CreativeInspirationMediaType.Image)}
                onClick={() => handleFilterMediaType(CreativeInspirationMediaType.Image)}
              />
            </Flex>
          </Flex>

          <Flex vertical gap={12}>
            <Text style={{ color: colors.gray70, fontWeight: 700 }}>
              {t('scale_creative_inspiration_filter_modal_tags_label')}
            </Text>
            <Flex gap={12}>
              <FilterButton
                label='Gameplay'
                isSelected={!!filterTags?.includes('gameplay')}
                onClick={() => handleFilterTags('gameplay')}
              />
              <FilterButton
                label='Real Person'
                isSelected={!!filterTags?.includes('real-person')}
                onClick={() => handleFilterTags('real-person')}
              />
              <FilterButton
                label='Promotion'
                isSelected={!!filterTags?.includes('promotion')}
                onClick={() => handleFilterTags('promotion')}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex justify='space-between' gap={18}>
          <Button
            size='large'
            onClick={handleClearFilter}
            disabled={!hasOptionsToFilter}
            style={{ width: 175 }}>
            {t('scale_creative_inspiration_filter_modal_clear_button')}
          </Button>
          <Button
            size='large'
            type='primary'
            onClick={handleApplyFilter}
            disabled={!hasFilterChanged}
            style={{ width: 175 }}>
            {t('scale_creative_inspiration_filter_modal_apply_button')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

const FilterButton = ({
  icon,
  label,
  rounded,
  isSelected,
  onClick,
}: {
  icon?: LabsIconBase
  label: string
  rounded?: boolean
  isSelected: boolean
  onClick: () => void
}) => {
  return (
    <Button
      style={{
        backgroundColor: isSelected ? colors.labs10 : 'transparent',
        borderRadius: rounded ? 23 : 4,
        padding: rounded ? '8px 12px' : '10px 32px',
        borderColor: isSelected ? colors.labs120 : colors.gray60,
        height: 'fit-content',
      }}
      onClick={onClick}>
      <Flex align='center' gap={6}>
        {icon && <IconLib icon={icon} />}
        <Text style={{ color: isSelected ? colors.labs120 : colors.gray70 }}>{label}</Text>
      </Flex>
    </Button>
  )
}
