import {
  CreativeMetadata,
  GameCreativeFormat,
  GameCreativeGuide,
  GameCreativeValidation,
  GameCreativeValidationStatus,
} from '__generated__/gql/graphql'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { PerformanceGrade } from 'components/modules/create-ads/creative-meter/type'

export interface Creative {
  format: GameCreativeFormat
  mediaUrl: string
  metadata?: CreativeMetadata
  filename?: string
}

export interface CountryGroup {
  code: string
  name: string
  /**
   * This is necessary to flag whether this is regular country or a country group.
   * This interface is previously named as CountryGroup which is confusing.
   * Might be need to refactor it to Country instead.
   */
  isMultipleCountryGroup?: boolean
}

interface PerformanceScore {
  point: number
  grade: PerformanceGrade
}

export interface UploadCreative {
  country: CountryGroup
  creatives: Creative[]
  performanceScore: PerformanceScore
}

interface CreateAdsStore {
  startDate: string | null
  selectedCountry: CountryGroup
  uploadCreatives: UploadCreative[]
  validation: GameCreativeValidation
  guide: GameCreativeGuide[]

  isUploadingCreative: boolean
  performanceScore: PerformanceScore

  totalBulkUpload: number
  setTotalBulkUpload: (count: number) => void

  countUploadingCreatives: number
  setCountUploadingCreatives: (count: number) => void

  setUploadCreatives: (uploadCreatives: UploadCreative[]) => void
  setIsUploadingCreative: (bool: boolean) => void
  setSelectedCountry: (country: CountryGroup) => void
  setStartDate: (startDate: string | null) => void
  setPerformanceScore: (performanceScore: PerformanceScore) => void
  setValidation: (validation: GameCreativeValidation) => void
  setGuide: (guide: GameCreativeGuide[]) => void
  resetState: () => void
  cleanState: () => void
}

const useCreateAdsStore = create<CreateAdsStore>()(
  persist(
    (set) => ({
      startDate: null,
      selectedCountry: {} as CountryGroup,
      uploadCreatives: [],
      performanceScore: {} as PerformanceScore,
      isUploadingCreative: false,
      totalBulkUpload: 0,
      countUploadingCreatives: 0,
      validation: {
        status: GameCreativeValidationStatus.Good,
        key: '',
        message: '',
        ratio: [],
      },
      guide: [],

      setUploadCreatives: (uploadCreatives: UploadCreative[]) =>
        set((state) => ({ ...state, uploadCreatives: uploadCreatives })),
      setTotalBulkUpload: (count: number) => set((state) => ({ ...state, totalBulkUpload: count })),
      setCountUploadingCreatives: (count: number) =>
        set((state) => ({ ...state, countUploadingCreatives: count })),
      setIsUploadingCreative: (isUploading: boolean) =>
        set((state) => ({ ...state, isUploadingCreative: isUploading })),
      setSelectedCountry: (country: CountryGroup) =>
        set((state) => ({ ...state, selectedCountry: country })),
      setStartDate: (startDate: string | null) => set((state) => ({ ...state, startDate })),
      setPerformanceScore: (performanceScore: PerformanceScore) =>
        set((state) => ({ ...state, performanceScore })),
      setValidation: (validation: GameCreativeValidation) =>
        set((state) => ({ ...state, validation })),
      setGuide: (guide: GameCreativeGuide[]) => set((state) => ({ ...state, guide })),

      cleanState: () =>
        set((state) => ({
          startDate: null,
          selectedCountry: {
            code: state.uploadCreatives[0].country.code,
            name: state.uploadCreatives[0].country.name,
          },
          uploadCreatives: state.uploadCreatives.map((creative) => {
            return {
              country: creative.country,
              creatives: [],
              performanceScore: {} as PerformanceScore,
            }
          }),
        })),
      resetState: () =>
        set(() => ({
          startDate: null,
          selectedCountry: {} as CountryGroup,
          uploadCreatives: [],
          countUploadingCreatives: 0,
          totalBulkUpload: 0,
          validation: {
            status: GameCreativeValidationStatus.Good,
            key: '',
            message: '',
            ratio: [],
          },
          guide: [],
          performanceScore: {} as PerformanceScore,
          isUploadingCreative: false,
        })),
    }),
    {
      name: 'create-ads-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useCreateAdsStore
