import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip as AntdTooltip, Flex } from 'antd'
import { PropsWithChildren } from 'react'

import colors from 'theme/colors'

import { ITooltip } from './type'

// const { Text } = Typography
export default function Tooltip({ title, children, color }: PropsWithChildren<ITooltip>) {
  return (
    <AntdTooltip
      overlayInnerStyle={{ padding: 14, borderRadius: 10 }}
      title={
        <span style={{ color: 'black', fontSize: 12 }}>
          <Flex vertical gap={4}>
            {title && <span style={{ fontWeight: 700 }}>{title}</span>}
            {children}
          </Flex>
        </span>
      }
      placement='top'>
      <InfoCircleOutlined style={{ color: color || colors.gray70 }} />
    </AntdTooltip>
  )
}
