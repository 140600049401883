import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GetSimilarGamesQuery, GetSimilarGamesQueryVariables } from '__generated__/gql/graphql'
import { Card, Col, Flex, Radio, Row, Typography } from 'antd'
import { Select } from 'antd'
import type { RefSelectProps } from 'antd/lib/select'
import { disable } from 'mixpanel-browser'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getSimilarGames } from 'services/graphql/queries'

import useMarketingOnboardStore from 'stores/useMarketingOnboardStore'
import useUserStore from 'stores/useUserStore'

import { selectTagRender } from 'components/commons/form/select-options/Tag'

import colors from 'theme/colors'

const { Text, Title } = Typography

const { Option } = Select
const MAX_SELECT = 5

export default function MarketingOnboardModalWizardFormGameOfInterest() {
  const { t } = useTranslation()
  const { gameOfInterest, setGameOfInterest } = useMarketingOnboardStore()
  const { selectedGame, user } = useUserStore()
  const [similarGames, setSimilarGames] = useState<string[]>(gameOfInterest)
  const debounceTimeout = useRef<number | undefined>(undefined)

  useEffect(() => {
    if (gameOfInterest.length !== 0 || selectedGame.similarGames.length === 0) return

    const similarGameIds = selectedGame.similarGames.map((similarGame) => similarGame.id)
    setSimilarGames(similarGameIds)
    setGameOfInterest(similarGameIds)
  }, [])

  const [gameInspirationOptions, setGameInspirationOptions] = useState<
    { label: string; value: string; iconUrl?: string; category?: string }[]
  >([])

  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [searchKey, { loading: isGameInspirationLoading, data: gameInspirations }] = useLazyQuery<
    GetSimilarGamesQuery,
    GetSimilarGamesQueryVariables
  >(getSimilarGames)

  useEffect(() => {
    if (!Object.keys(user).length) return

    const genre = selectedGame.genre[0].name ?? undefined
    if (!genre) return

    searchKey({
      variables: {
        genre,
        ...(gameOfInterest.length > 0
          ? {
              withId: gameOfInterest,
            }
          : {}),
      },
      fetchPolicy: 'network-only',
    })

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }
    }
  }, [])

  useEffect(() => {
    if (!gameInspirations) return
    const gamePools = gameInspirations?.similarGames ?? []

    if (gamePools.length === 0) {
      setGameInspirationOptions([])
      return
    }

    const gamePoolTmp = gamePools.map((gamepool) => {
      return {
        label: gamepool.name,
        value: gamepool.id,
        iconUrl: gamepool.iconURL ?? undefined,
        category: gamepool.category ?? undefined,
      }
    })
    setGameInspirationOptions(gamePoolTmp)
  }, [gameInspirations])

  const handleSearch = useCallback(
    (key: string) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }

      debounceTimeout.current = window.setTimeout(() => {
        if (!Object.keys(user).length) return

        const genre = selectedGame.genre[0].name ?? undefined
        if (!genre) return

        searchKey({
          variables: {
            name: key,
            genre,
            ...(gameOfInterest.length > 0
              ? {
                  withId: gameOfInterest,
                }
              : {}),
          },
          fetchPolicy: 'network-only',
        })
      }, 500)
    },
    [searchKey, gameOfInterest, selectedGame],
  )

  const recommendedOpt = useMemo(() => {
    return gameInspirationOptions
      .filter(
        (game) =>
          game.category && game.category.toLowerCase() === selectedGame.genre[0].name.toLowerCase(),
      )
      .map((gameOpt) => ({
        label: gameOpt.label,
        value: gameOpt.value,
        disabled: similarGames.length === MAX_SELECT && !similarGames.includes(gameOpt.value),
      }))
  }, [gameInspirationOptions, similarGames])

  const otherGamesOpt = useMemo(() => {
    return gameInspirationOptions
      .filter(
        (game) =>
          !game.category ||
          game.category.toLowerCase() !== selectedGame.genre[0].name.toLowerCase(),
      )
      .map((gameOpt) => ({
        label: gameOpt.label,
        value: gameOpt.value,
        disabled: similarGames.length === MAX_SELECT && !similarGames.includes(gameOpt.value),
      }))
  }, [gameInspirationOptions, similarGames])

  return (
    <Flex vertical align='center' gap={20}>
      <Flex vertical align='center' gap={5}>
        <Title level={5} style={{ color: colors.gray90 }}>
          {t('marketing_onboarding_modal_my_games_game_inspiration_title')}
        </Title>
        <Text style={{ color: colors.gray70 }}>
          {t('marketing_onboarding_modal_my_games_game_inspiration_explanation')}
        </Text>
      </Flex>
      <Select
        optionFilterProp='label'
        size='large'
        loading={isGameInspirationLoading}
        value={similarGames}
        defaultActiveFirstOption={false}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={t('marketing_onboarding_modal_my_games_game_inspiration_placeholder')}
        suffixIcon={
          <>
            <span>
              {similarGames.length} / {MAX_SELECT}
            </span>
            {isFocused ? <SearchOutlined /> : <DownOutlined />}
          </>
        }
        maxLength={5}
        mode='tags'
        tagRender={selectTagRender}
        onSearch={handleSearch}
        onChange={(gameSelected: string[]) => {
          if (gameSelected.length > 5) return
          setSimilarGames(gameSelected)
          setGameOfInterest(gameSelected)
        }}
        style={{
          width: '90%',
          height: 'auto',
          maxHeight: 200,
        }}
        options={[
          {
            label: <span>Recommended Games</span>,
            title: 'Recommended Games',
            options: recommendedOpt,
          },
          {
            label: <span>Other Games</span>,
            title: 'Other Games',
            options: otherGamesOpt,
          },
        ]}
      />
    </Flex>
  )
}
