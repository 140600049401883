import { GameStoreIntegrationMethod } from '__generated__/gql/graphql'
import { Col, Flex, Radio, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import colors from 'theme/colors'

import { IIntegrationRadioGroup } from '../type'

const { Group: RadioGroup } = Radio
const { Text } = Typography

export default function IntegrationRadio({ name, value, onChange }: IIntegrationRadioGroup) {
  const { t } = useTranslation()

  return (
    <RadioGroup
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      style={{ width: '100%' }}>
      <Row gutter={[14, 14]}>
        <Col span={12}>
          <Radio
            value={GameStoreIntegrationMethod.FullSdk}
            style={{
              height: '100%',
              width: '100%',
              padding: 12,
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor:
                value === GameStoreIntegrationMethod.FullSdk ? colors.labs120 : colors.gray30,
              backgroundColor:
                value === GameStoreIntegrationMethod.FullSdk ? colors.labs20 : 'transparent',
              borderRadius: 8,
              cursor: 'pointer',
            }}>
            <Flex vertical style={{ paddingLeft: 10 }}>
              <Text style={{ color: colors.gray90 }}>
                {t('modal_basic_scale_integration_method_labs_sdk_title')}
              </Text>
              <Text style={{ fontSize: 12, color: colors.gray70 }}>
                {t('modal_basic_scale_integration_method_labs_sdk_description')}
              </Text>
            </Flex>
          </Radio>
        </Col>
        <Col span={12}>
          <Radio
            value={GameStoreIntegrationMethod.ShareAccess}
            style={{
              height: '100%',
              width: '100%',
              padding: 12,
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor:
                value === GameStoreIntegrationMethod.ShareAccess ? colors.labs120 : colors.gray30,
              backgroundColor:
                value === GameStoreIntegrationMethod.ShareAccess ? colors.labs20 : 'transparent',
              borderRadius: 8,
              cursor: 'pointer',
            }}>
            <Flex vertical style={{ paddingLeft: 10 }}>
              <Text style={{ color: colors.gray90 }}>
                {t('modal_basic_scale_integration_method_share_access_title')}
              </Text>
              <Text style={{ fontSize: 12, color: colors.gray70 }}>
                {t('modal_basic_scale_integration_method_share_access_description')}
              </Text>
            </Flex>
          </Radio>
        </Col>
      </Row>
    </RadioGroup>
  )
}
