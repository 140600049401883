import { useContext } from 'react'

import { GamePublisherUserPermissionContext } from 'components/contexts/GamePublisherUserPermissionContextProvider'

import { GamePublisherUserPermissionEnum } from 'types/permission'

const useIsPermissionGranted = (permissionSlug: GamePublisherUserPermissionEnum) => {
  const { permissions, isPermissionLoading } = useContext(GamePublisherUserPermissionContext)

  return {
    isPermissionError: !permissions,
    isPermissionGranted: (permissions || []).find(
      (permission) => permission.slug === permissionSlug,
    ),
    isPermissionLoading,
  }
}

export default useIsPermissionGranted
