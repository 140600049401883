import { LoadingOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import clsx from 'clsx'
import { PropsWithChildren, useCallback } from 'react'

import IconLib from 'components/commons/icon-lib'

import { buttonBaseStyle, buttonSizeStyle, fontSizeStyle } from './styles'
import './styles.css'
import { ILabsButton } from './type'

export default function LabsButton({
  children,
  color = 'primary',
  icon,
  iconPosition = 'start',
  size = 'base',
  variant = 'solid',
  url,
  width = 'base',
  isDisabled = false,
  isLoading = false,
  htmlType = 'button',
  onClick,
  tabIndex,
  form,
}: PropsWithChildren<ILabsButton>) {
  const sizeStyle = buttonSizeStyle[size]
  const fontSize = fontSizeStyle[size]
  const pointerStyle = isLoading || isDisabled ? { cursor: 'default' } : { cursor: 'pointer' }

  const handleOpenExternalLink = useCallback(() => {
    window.open(url, '_blank')
  }, [url])

  return (
    <div style={{ display: 'inline' }}>
      <button
        tabIndex={tabIndex}
        form={form}
        type={htmlType}
        className={clsx('labs-button', variant, color, {
          loading: isLoading,
          disabled: isDisabled,
        })}
        style={{
          ...buttonBaseStyle,
          ...sizeStyle,
          ...pointerStyle,
          width: width === 'full' ? '100%' : 'auto',
        }}
        disabled={isDisabled || isLoading}
        onClick={url ? handleOpenExternalLink : onClick}>
        <Flex
          gap={4}
          align='center'
          style={{
            padding: width === 'wide' ? '0 32px' : '',
            whiteSpace: 'nowrap',
            fontSize: fontSize,
            cursor: 'pointer',
          }}>
          {icon && iconPosition === 'start' && (
            <IconLib icon={icon} size={size === 'sm' ? 'xs' : 'sm'} color='none' />
          )}
          {children}
          {icon && iconPosition === 'end' && (
            <IconLib icon={icon} size={size === 'sm' ? 'xs' : 'sm'} color='none' />
          )}
          {isLoading && <LoadingOutlined style={{ fontSize: 12, marginLeft: 6 }} />}
        </Flex>
      </button>
    </div>
  )
}
