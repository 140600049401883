import * as Sentry from '@sentry/browser'
import { App, ConfigProvider } from 'antd'
import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import appConfig from 'config/appConfig'

import ApolloClientContextProvider from 'components/contexts/ApolloClientContextProvider'
import GamePublisherUserPermissionContextProvider from 'components/contexts/GamePublisherUserPermissionContextProvider'
import PostHogContextProvider from 'components/contexts/PostHogContextProvider'
import ModalRegister from 'components/layouts/ModalRegister'

import './font.css'
import StyledComponentsRegistry from './libs/antd/registry'
import './libs/internationalization'
import AppRouter from './router/index'
import './styles/app.css'
import './styles/reset.css'
import themeConfig from './theme/themeConfig'

Sentry.init({
  dsn: appConfig.glitchTipDsn,
  environment: appConfig.env,
  enabled: appConfig.env === 'production',
})

ReactDOM.createRoot(document.getElementById('app') as Element).render(
  <React.StrictMode>
    <PostHogContextProvider>
      <StyledComponentsRegistry>
        <ConfigProvider theme={themeConfig} locale={{ locale: 'en' }}>
          <App>
            <BrowserRouter>
              <ApolloClientContextProvider>
                <GamePublisherUserPermissionContextProvider>
                  <ModalRegister>
                    <AppRouter />
                  </ModalRegister>
                </GamePublisherUserPermissionContextProvider>
              </ApolloClientContextProvider>
            </BrowserRouter>
          </App>
        </ConfigProvider>
      </StyledComponentsRegistry>
    </PostHogContextProvider>
  </React.StrictMode>,
)
