import { Col, Row } from 'antd'

import GameDetailInfoItem from '../info-item'
import { GameInfoData } from '../type'

export default function GameDetailTab({ gameStore }: GameInfoData) {
  return (
    <Row style={{ padding: '24px 0' }} gutter={20}>
      <Col span={12}>
        <Row gutter={[12, 12]}>
          <GameDetailInfoItem label='Game Title' value={gameStore?.game.name} />
          <GameDetailInfoItem label='Game Platform' value={gameStore?.store.os as string} />
          <GameDetailInfoItem label='Game Engine' value={gameStore?.gameEngine as string} />
        </Row>
      </Col>
      <Col span={12}>
        <Row gutter={[12, 12]}>
          <GameDetailInfoItem label='Bundle ID' value={gameStore?.bundleId as string} />
          <GameDetailInfoItem
            label='Game Genre'
            value={gameStore?.game.genres.map((genre) => genre.name).join(', ')}
          />
        </Row>
      </Col>
    </Row>
  )
}
