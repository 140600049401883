import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CreateActivityHistoriesMutation,
  CreateActivityHistoriesMutationVariables,
  GamePublisherBalanceMutationsQuery,
  GamePublisherBalanceMutationsQueryVariables,
  GamePublisherBalanceMutationStatus,
  RequestBalanceLimitedOfferMutation,
  RequestBalanceLimitedOfferMutationVariables,
} from '__generated__/gql/graphql'
import { Button, Col, Flex, Image, message, Modal, Row, Typography } from 'antd'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createActivityHistories, requestBalanceLimitedOffer } from 'services/graphql/mutations'
import { getBalanceMutation } from 'services/graphql/queries'

import { INCOMING_BALANCE_LIMITED_OFFER } from 'constants/game'

import useModalStore from 'stores/useModalStore'

import useTracker from 'hooks/useTracker'
import useUserInfo from 'hooks/useUserInfo'

import {
  labsAnnouncement,
  labsClick,
  labsDiscount,
  labsMeters,
} from 'components/commons/icon-lib/icons/base'
import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

import { ShowTopUpBalanceModalButton } from '../../../../constants/LabsOnboardingSteps'
import IconLib from '../../icon-lib/index'

const { Text, Title } = Typography

export default function BalanceLimitedOfferModal() {
  const { t } = useTranslation()
  const { getUserInfo } = useUserInfo()
  const {
    isBalanceLimitedOfferModalShow,
    showTopupModal,
    closeAll,
    showTopupPendingModal,
    showBalanceOfferAppSubmittedModal,
  } = useModalStore()
  const { track } = useTracker()
  const [balanceMutation] = useLazyQuery<
    GamePublisherBalanceMutationsQuery,
    GamePublisherBalanceMutationsQueryVariables
  >(getBalanceMutation, {
    variables: { status: GamePublisherBalanceMutationStatus.Pending },
    fetchPolicy: 'no-cache',
  })

  const [topupOfferReq, { loading: topupOfferReqLoading }] = useMutation<
    RequestBalanceLimitedOfferMutation,
    RequestBalanceLimitedOfferMutationVariables
  >(requestBalanceLimitedOffer)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const handleCancel = useCallback(() => {
    closeAll()
    balanceMutation().then((res) => {
      const hasPendingTopup = res.data?.gamePublisherBalanceMutations.length

      if (hasPendingTopup) showTopupPendingModal()
      else showTopupModal(false)

      track('action_topup', {
        type: 'click',
        location: 'header',
        location_type: 'button',
        has_pending_topup: hasPendingTopup ? true : false,
      })
    })
  }, [showTopupModal, closeAll])
  const handleClick = useCallback(() => {
    setIsLoading(true)
    topupOfferReq()
      .then((res) => {
        if (res.errors || !res.data) {
          if (res.errors) {
            console.error(res.errors)
          }

          message.error(`Cannot process your request right now`)
        }

        getUserInfo()
        setTimeout(() => {
          closeAll()
          showBalanceOfferAppSubmittedModal()
        }, 300)
      })
      .catch((err) => {
        console.error(err)
        message.error(`Cannot process your request right now`)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [topupOfferReq])

  return (
    <Modal
      className='balance-limited-offer'
      title={false}
      centered
      open={isBalanceLimitedOfferModalShow}
      onCancel={handleCancel}
      footer={false}
      width={969}>
      <Row
        justify='space-between'
        style={{
          padding: '50px 40px 0px 60px',
          width: '100%',
        }}>
        <Col span={14}>
          <Flex vertical gap={30} style={{ width: '100%' }}>
            <Flex vertical>
              <Text
                style={{
                  color: colors.gray90,
                  fontWeight: 'bold',
                  fontSize: 14,
                }}>
                Limited Offer
              </Text>
              <Text
                style={{
                  color: colors.gray90,
                  fontWeight: 'bold',
                  fontSize: 30,
                }}>
                Unlock ${INCOMING_BALANCE_LIMITED_OFFER} Free Credit!
              </Text>
              <Text
                style={{
                  color: colors.gray90,
                  fontSize: 14,
                }}>
                Get ${INCOMING_BALANCE_LIMITED_OFFER} in Free Credit to Kickstart Your Ad Campaigns
                with Labs!
              </Text>
            </Flex>
            <Flex vertical gap={12} style={{ width: '100%' }}>
              <Text
                style={{
                  color: colors.gray90,
                  fontSize: 14,
                }}>
                Why You Should Apply:
              </Text>
              <Row gutter={[0, 20]}>
                <Col span={2} style={{ alignItems: 'baseline' }}>
                  <IconLib icon={labsDiscount} size='lg' color='success50' />
                </Col>
                <Col span={22} style={{ alignItems: 'baseline' }}>
                  <Text
                    style={{
                      color: colors.gray90,
                      fontSize: 14,
                    }}>
                    <span style={{ fontWeight: 'bold' }}>Exclusive Offer:</span> This is a
                    limited-time offer for new users only.
                  </Text>
                </Col>
                <Col span={2} style={{ alignItems: 'baseline' }}>
                  <IconLib icon={labsAnnouncement} size='lg' color='success50' />
                </Col>
                <Col span={22} style={{ alignItems: 'baseline' }}>
                  <Text
                    style={{
                      color: colors.gray90,
                      fontSize: 14,
                    }}>
                    <span style={{ fontWeight: 'bold' }}>Jumpstart Your Ads:</span> Use the $200
                    credit to publish ads and gain traction.
                  </Text>
                </Col>
                <Col span={2} style={{ alignItems: 'baseline' }}>
                  <IconLib icon={labsMeters} size='lg' color='success50' />
                </Col>
                <Col span={22} style={{ alignItems: 'baseline' }}>
                  <Text
                    style={{
                      color: colors.gray90,
                      fontSize: 14,
                    }}>
                    <span style={{ fontWeight: 'bold' }}>Performance-Based Approval:</span>{' '}
                    {`Your request will be approved based on an analysis of your game's performance.`}
                  </Text>
                </Col>
                <Col span={2} style={{ alignItems: 'baseline' }}>
                  <IconLib icon={labsClick} size='lg' color='success50' />
                </Col>
                <Col span={22} style={{ alignItems: 'baseline' }}>
                  <Text
                    style={{
                      color: colors.gray90,
                      fontSize: 14,
                    }}>
                    <span style={{ fontWeight: 'bold' }}>{`Boost Your Game's Visibility:`}</span>{' '}
                    Reach a wider audience and see your game grow.
                  </Text>
                </Col>
              </Row>
            </Flex>
            <div style={{ marginBottom: 40 }}>
              <Button
                type='primary'
                style={{
                  justifyContent: 'center',
                  margin: 0,
                  padding: 0,
                  width: 136,
                  height: 36,
                }}
                onClick={handleClick}
                loading={isLoading || topupOfferReqLoading}>
                Apply Now
              </Button>
            </div>
          </Flex>
        </Col>
        <Col span={8}>
          <Flex justify='center' style={{ width: '100%', height: '100%' }}>
            <Image
              src='/images/illustration/balance-offer-gift.webp'
              preview={false}
              height={'100%'}
              width={'auto'}
            />
          </Flex>
        </Col>
      </Row>
    </Modal>
  )
}
