import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export interface GeneralInformation {
  showBalanceLimitedOffer: boolean
  showOnboard: boolean
  hasFirstTopupBalance: boolean
}

interface GeneralInformationFormState {
  info: GeneralInformation
  setGeneralInfo: (info: GeneralInformation) => void
  resetState: () => void
}

const useGeneralStore = create<GeneralInformationFormState>()(
  persist(
    (set) => ({
      info: {
        showBalanceLimitedOffer: false,
        showOnboard: false,
        hasFirstTopupBalance: false,
      },

      setGeneralInfo: (info: GeneralInformation) => set((state) => ({ ...state, info: info })),
      resetState: () =>
        set(() => ({
          info: {
            showBalanceLimitedOffer: false,
            showOnboard: false,
            hasFirstTopupBalance: false,
          } as GeneralInformation,
        })),
    }),
    {
      name: 'general-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useGeneralStore
