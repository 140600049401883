import { DailyMetricType, DimensionType } from '__generated__/gql/graphql'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export interface DailyPerformanceState {
  dimension: DimensionType
  dimensionLabel: string
  metric: DailyMetricType
  metricLabel: string
  showData: number
}

interface DashboardProgressionState {
  dailyPerformance: DailyPerformanceState
  setDailyPerformance: (dp: DailyPerformanceState) => void
  resetState: () => void
}

const useDashboardProgressionStore = create<DashboardProgressionState>()(
  persist(
    (set) => ({
      dailyPerformance: {
        dimension: DimensionType.Country,
        dimensionLabel: 'Country',
        metric: DailyMetricType.Revenue,
        metricLabel: 'Revenue',
        showData: 5,
      },
      setDailyPerformance: (dp: DailyPerformanceState) =>
        set((state) => ({ ...state, dailyPerformance: dp })),

      resetState: () =>
        set(() => ({
          dailyPerformance: {
            dimension: DimensionType.Country,
            dimensionLabel: 'Country',
            metric: DailyMetricType.Revenue,
            metricLabel: 'Revenue',
            showData: 5,
          },
        })),
    }),
    {
      name: 'dashboard-progression-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useDashboardProgressionStore
