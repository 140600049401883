import { Flex, Image, Modal, Spin, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { INTEGRATION_ROUTE, MEDIATION_ROUTE } from 'constants/Routes'

import useModalStore from 'stores/useModalStore'

import useIntegration from 'hooks/useIntegration'

import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function MediationIntegratedModal() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isLoading } = useIntegration()
  const { isMediationIntegratedModalShow, closeAll } = useModalStore()

  const handleGoToIntegration = () => {
    closeAll()
    navigate(`/${INTEGRATION_ROUTE}`)
  }

  const handleGoToMediation = () => {
    closeAll()
    navigate(`/${MEDIATION_ROUTE}`)
  }

  return (
    <Modal
      open={isMediationIntegratedModalShow}
      title={false}
      centered
      footer={false}
      onCancel={closeAll}
      width={623}>
      {isLoading ? (
        <Flex
          vertical
          justify='center'
          align='center'
          gap={10}
          style={{ height: 200, width: '100%' }}>
          <Spin />
        </Flex>
      ) : (
        <Flex
          vertical
          align='center'
          justify='space-between'
          gap={32}
          style={{ padding: '20px 16px' }}>
          <Flex vertical align='center' gap={12}>
            <Image
              src='/images/illustration/sdk-download-complete.gif'
              height={87}
              width={87}
              preview={false}
            />
            <Title level={4} style={{ margin: 0, color: colors.gray90 }}>
              {t('mediation_integrated_modal_title')}
            </Title>
            <Text style={{ color: colors.gray90, textAlign: 'center' }}>
              {t('mediation_integrated_modal_description')}
            </Text>
          </Flex>
          <Flex vertical gap={12} style={{ width: 330 }}>
            <LabsButton size='lg' width='full' onClick={handleGoToMediation}>
              {t('general_okay')}
            </LabsButton>
            <LabsButton size='lg' width='full' variant='outline' onClick={handleGoToIntegration}>
              {t('breadcrumb_back')} Integration
            </LabsButton>
          </Flex>
        </Flex>
      )}
    </Modal>
  )
}
