import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

import { COMPARISON_GAME_ANALYTICS_ROUTE, GAME_ANALYTICS_ROUTE } from 'constants/Routes'

import AuthenticatedRoute from 'components/routes/AuthenticatedRoute'
import PermissionGuardedRoute from 'components/routes/PermissionGuardedRoute'

import { GamePublisherUserPermissionEnum } from 'types/permission'

import { DAILY_MONITORING_GAME_ANALYTICS_ROUTE } from '../constants/Routes'

const DailyMonitoringGameAnalyticsPage = lazy(() => import('pages/game-analytics/daily-monitoring'))
const ComparisonGameAnalyticsPage = lazy(() => import('pages/game-analytics/comparison'))

const GameAnalyticsRouter = (
  <Route path={GAME_ANALYTICS_ROUTE}>
    <Route
      path={DAILY_MONITORING_GAME_ANALYTICS_ROUTE.replace(`${GAME_ANALYTICS_ROUTE}/`, '')}
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <PermissionGuardedRoute
              permission={GamePublisherUserPermissionEnum.VIEW_PAGE_GAME_ANALYTICS}>
              <DailyMonitoringGameAnalyticsPage />
            </PermissionGuardedRoute>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
    <Route
      path={COMPARISON_GAME_ANALYTICS_ROUTE.replace(`${GAME_ANALYTICS_ROUTE}/`, '')}
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <PermissionGuardedRoute
              permission={GamePublisherUserPermissionEnum.VIEW_PAGE_GAME_ANALYTICS}>
              <ComparisonGameAnalyticsPage />
            </PermissionGuardedRoute>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
  </Route>
)

export default GameAnalyticsRouter
