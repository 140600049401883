import { useApolloClient, useMutation } from '@apollo/client'
import {
  UpdateAdjustTokenMutation,
  UpdateAdjustTokenMutationVariables,
} from '__generated__/gql/graphql'
import { Flex, Modal, Typography } from 'antd'
import { useCallback, useState } from 'react'
import { updateAdjustToken } from 'services/graphql/mutations'

import useModalStore from 'stores/useModalStore'

import useIntegration from 'hooks/useIntegration'

import InputText from 'components/commons/form/input-text'
import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function IntegrationAdjustTokenModal() {
  const client = useApolloClient()
  const { selectedStoreId } = useIntegration()

  const { isIntegrationAdjustTokenModalShow, closeAll } = useModalStore()

  const [updateToken, { loading }] = useMutation<
    UpdateAdjustTokenMutation,
    UpdateAdjustTokenMutationVariables
  >(updateAdjustToken)

  const [apiToken, setApiToken] = useState<string>('')
  const [appToken, setAppToken] = useState<string>('')

  const handleHowToGetThis = () => {
    console.log('how to get this')
  }

  const handleSubmitToken = useCallback(() => {
    if (!apiToken || !appToken || !selectedStoreId) return

    updateToken({
      variables: {
        id: selectedStoreId,
        apiToken: apiToken,
        appToken: appToken,
      },
    }).then(() => {
      client.refetchQueries({
        include: ['GetGameStoresByGameId'],
      })
      setApiToken('')
      setAppToken('')
      closeAll()
    })
  }, [selectedStoreId, apiToken, appToken])

  return (
    <Modal
      open={isIntegrationAdjustTokenModalShow}
      title={false}
      centered
      footer={false}
      onCancel={closeAll}
      width={600}>
      <form onSubmit={handleSubmitToken}>
        <Flex
          vertical
          align='center'
          justify='space-between'
          gap={60}
          style={{ padding: '20px 26px' }}>
          <Title level={4} style={{ margin: 0, color: colors.gray90 }}>
            Share Adjust API Token
          </Title>
          <Flex vertical gap={22} style={{ width: '100%' }}>
            <Flex vertical gap={8} style={{ width: '100%' }}>
              <Flex align='center' justify='space-between'>
                <Text style={{ color: colors.gray80, fontWeight: 500 }}>Adjust API Token</Text>
                <LabsButton
                  variant='text'
                  size='compact'
                  onClick={handleHowToGetThis}
                  tabIndex={-1}>
                  How to get this?
                </LabsButton>
              </Flex>
              <InputText
                autoFocus={true}
                value={apiToken}
                onChange={(e) => setApiToken(e.target.value)}
                placeholder='Input your API token'
              />
            </Flex>

            <Flex vertical gap={8} style={{ width: '100%' }}>
              <Flex align='center' justify='space-between'>
                <Text style={{ color: colors.gray80, fontWeight: 500 }}>Adjust App Token</Text>
                <LabsButton
                  variant='text'
                  size='compact'
                  onClick={handleHowToGetThis}
                  tabIndex={-1}>
                  How to get this?
                </LabsButton>
              </Flex>
              <InputText
                autoFocus={true}
                value={appToken}
                onChange={(e) => setAppToken(e.target.value)}
                placeholder='Input your App token'
              />
            </Flex>
          </Flex>
          <LabsButton
            htmlType='submit'
            width='wide'
            onClick={handleSubmitToken}
            isDisabled={!apiToken || !appToken}
            isLoading={loading}>
            Submit
          </LabsButton>
        </Flex>
      </form>
    </Modal>
  )
}
