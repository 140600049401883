import { AddOnCategory } from '__generated__/gql/graphql'

export const cardBackgroundStyle: Record<AddOnCategory, string> = {
  CREATIVE_OPTIMIZATION:
    'linear-gradient(290.84deg, rgba(255, 255, 255, 0) 0.49%, rgba(97, 97, 196, 0.08) 106.63%)',
  GAME_INITIALIZATION:
    'linear-gradient(294.43deg, rgba(255, 255, 255, 0) 0.56%, rgba(255, 152, 34, 0.08) 115.67%)',
  INCREASE_REVENUE:
    'linear-gradient(290.84deg, rgba(255, 255, 255, 0) 0.49%, rgba(123, 226, 107, 0.08) 106.63%)',
  STORE_MANAGEMENT:
    'linear-gradient(290.84deg, rgba(255, 255, 255, 0) 0.49%, rgba(246, 126, 189, 0.08) 106.63%)',
}

export const tagColorStyle: Record<AddOnCategory, string> = {
  CREATIVE_OPTIMIZATION: '#EDE9FE',
  GAME_INITIALIZATION: '#FFEDD5',
  INCREASE_REVENUE: '#ECFCCB',
  STORE_MANAGEMENT: '#FCE7F3',
}

export const fontColorStyle: Record<AddOnCategory, string> = {
  CREATIVE_OPTIMIZATION: '#A78BFA',
  GAME_INITIALIZATION: '#FB923C',
  INCREASE_REVENUE: '#84CC16',
  STORE_MANAGEMENT: '#F472B6',
}
