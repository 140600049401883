import { Color } from 'components/commons/icon-lib/type'

import colors from 'theme/colors'

import { MediationInfoVariant } from './type'

export const cardStyle: Record<MediationInfoVariant, React.CSSProperties> = {
  primary: {
    backgroundColor: colors.labs10,
    borderColor: colors.labs40,
    borderRadius: 4,
    color: colors.labs140,
  },
  secondary: {
    backgroundColor: colors.gray20,
    border: 0,
    borderRadius: 0,
    borderLeft: '3px solid',
    borderLeftColor: colors.greenPass70,
    color: colors.gray70,
  },
}

export const iconStyle: Record<MediationInfoVariant, Color> = {
  primary: 'labs140',
  secondary: 'gray90',
}
