import { Flex, Tabs, Typography } from 'antd'
import clsx from 'clsx'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import useUserStore from 'stores/useUserStore'

import GameSelection from 'components/commons/game-selection'
import IconLib from 'components/commons/icon-lib'
import { labsAdd } from 'components/commons/icon-lib/icons/base'

import colors from 'theme/colors'

import AuthenticatedLayout from './AuthenticatedLayout'

const { TabPane } = Tabs
const { Text } = Typography

type TabProps = {
  key: string
  label: string
  isDisabled?: boolean
  children?: React.ReactNode
}

type Props = {
  path: string
  items: TabProps[]
  showGameSelection?: boolean
  children: React.ReactNode
}

const TabNavigationLayout: React.FC<Props> = ({ path, items, showGameSelection, children }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const pathSegments = location.pathname.split('/').filter((segment) => segment.trim() !== '')
  const lastPath = pathSegments[pathSegments.length - 1]
  const navigate = useNavigate()
  const handleKeyChange = (key: string) => {
    navigate(`/${path}/${key}`)
  }

  const { games, setSelectedGame, setSelectedGameStore } = useUserStore()

  const [scrollTop, setScrollTop] = useState<number>(0)

  const shouldMinimizeTab = useMemo(() => {
    return scrollTop > 20
  }, [scrollTop])

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    if (!games.length) return
    const game = games[0]
    setSelectedGame(game)
    if (game.stores.length) setSelectedGameStore(game.stores[0])
  }, [])

  return (
    <AuthenticatedLayout>
      <Tabs
        className={clsx({ minimize: shouldMinimizeTab }, 'labs-v2')}
        type='card'
        activeKey={lastPath}
        // items={items}
        onChange={handleKeyChange}
        tabBarExtraContent={showGameSelection && <GameSelection showGameStore={false} />}
        style={{
          overflowY: 'auto',
          width: '100%',
          position: 'sticky',
          zIndex: 20,
          top: 64,
        }}>
        {items.map((item) =>
          item.children ? (
            item.children
          ) : !item.isDisabled ? (
            <TabPane style={{ padding: 0 }} key={item.key} tab={t(item.label)} />
          ) : (
            <TabPane
              key={item.key}
              tab={
                <Flex vertical gap={3} align='center' justify='center'>
                  <Flex align='center' gap={8}>
                    {item.key === 'custom-dashboard' && <IconLib icon={labsAdd} />}
                    <Text style={{ color: colors.gray60 }}>{t(item.label)}</Text>
                  </Flex>
                  {!shouldMinimizeTab && (
                    <Text
                      style={{
                        fontSize: 8,
                        padding: '2px 8px',
                        background: colors.gray30,
                        borderRadius: 8,
                      }}>
                      {t('general_coming_soon')}
                    </Text>
                  )}
                </Flex>
              }
              disabled={true}
            />
          ),
        )}
      </Tabs>
      {children}
    </AuthenticatedLayout>
  )
}

export default TabNavigationLayout
