import { Card, Flex, Progress, Typography } from 'antd'

import { labsArrowRight } from 'components/commons/icon-lib/icons/base'
import LabsButton from 'components/commons/labs-kit/button'
import Tooltip from 'components/commons/tooltip'

import colors from 'theme/colors'

const { Text } = Typography

const MAX_INSTALLS = 1000
export default function HeaderPublisherInfoAttributionQuota() {
  const handleSeeResult = () => {
    console.log('See Result')
  }

  const totalInstall = 23

  return (
    <Card
      bodyStyle={{ padding: '10px 12px' }}
      style={{ borderRadius: 10, backgroundColor: colors.gray20, width: '100%' }}>
      <Flex vertical gap={8}>
        <Flex vertical gap={5}>
          <Flex align='center' gap={5}>
            <Text style={{ color: colors.gray90, fontWeight: 700, fontSize: 10 }}>
              Free Attributed Installs Quota
            </Text>
            <Tooltip>The number of installs that can be attributed to your campaigns</Tooltip>
          </Flex>
          <Progress
            percent={(totalInstall / MAX_INSTALLS) * 100}
            showInfo={false}
            size='default'
            style={{ margin: 0 }}
          />
        </Flex>
        <Flex align='center' justify='space-between'>
          <Text style={{ color: colors.gray90, fontSize: 10 }}>
            <span style={{ fontWeight: 700 }}>{totalInstall}</span>/{MAX_INSTALLS} installs
          </Text>
          <LabsButton
            variant='text'
            size='compact'
            icon={labsArrowRight}
            iconPosition='end'
            onClick={handleSeeResult}>
            See results
          </LabsButton>
        </Flex>
      </Flex>
    </Card>
  )
}
