import { Flex, Image, Input, Typography } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { MEDIATION_BEST_PRACTICE_AD_TYPE } from 'constants/Routes'

import IconLib from 'components/commons/icon-lib'
import { labsDelete } from 'components/commons/icon-lib/icons/base'
import LabsButton from 'components/commons/labs-kit/button'
import MediationAdUnitInfo from 'components/modules/mediation/create-ad-unit/info'

import colors from 'theme/colors'

import { ICreateAdUnitModal } from '../type'

const { Title, Text } = Typography
export default function CreateAdUnitInterstitial({
  adUnitsName,
  setAdUnitsName,
}: ICreateAdUnitModal) {
  const { t } = useTranslation()

  const handleChange = useCallback(
    (name: string, index: number) => {
      adUnitsName[index] = name
      setAdUnitsName([...adUnitsName])
    },
    [adUnitsName, setAdUnitsName],
  )

  const handleRemove = useCallback(
    (index: number) => {
      const newAdUnitsName = adUnitsName.filter((_, i) => i !== index)
      setAdUnitsName(newAdUnitsName)
    },
    [adUnitsName],
  )

  const handleSeeBestPractice = () => {
    window.open(
      `https://${window.location.host}/${MEDIATION_BEST_PRACTICE_AD_TYPE}interstitial`,
      '_blank',
    )
  }

  return (
    <Flex vertical gap={20}>
      <Flex align='center' gap={15}>
        <Flex flex={0}>
          <Image
            src='/images/illustration/interstitial.webp'
            height={134}
            width={134}
            preview={false}
          />
        </Flex>
        <Flex vertical justify='center' gap={8}>
          <Flex align='center' gap={20}>
            <Title level={4} style={{ color: colors.gray90, margin: 0, fontWeight: 500 }}>
              Interstitial
            </Title>
          </Flex>
          <Text style={{ color: colors.gray70 }}>
            {t('mediation_create_ad_unit_modal_interstitial_description')}
          </Text>
          <LabsButton variant='outline' size='sm' onClick={handleSeeBestPractice}>
            {t('mediation_create_ad_unit_modal_see_best_practice')}
          </LabsButton>
        </Flex>
      </Flex>
      <MediationAdUnitInfo
        info={[
          t('mediation_create_ad_unit_modal_interstitial_info_first_item'),
          t('mediation_create_ad_unit_modal_interstitial_info_second_item'),
        ]}
      />

      <Flex vertical gap={22}>
        {adUnitsName.map((adUnitName, index) => (
          <Flex key={index} vertical gap={8}>
            <Text style={{ color: colors.gray70, fontWeight: 500 }}>
              {t('mediation_create_ad_unit_modal_ad_unit_name_title')}
            </Text>
            <Flex align='center' gap={10}>
              <Input
                autoFocus
                size='large'
                value={adUnitName}
                placeholder='Ex: Interstitial_GameName_GameFeature'
                onChange={(e) => handleChange(e.target.value, index)}
              />
              {adUnitsName.length > 1 && (
                <IconLib icon={labsDelete} onClick={() => handleRemove(index)} />
              )}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}
