import { useMutation } from '@apollo/client'
import {
  UpdateUserDisplayNameMutation,
  UpdateUserDisplayNameMutationVariables,
} from '__generated__/gql/graphql'
import { Button, Flex, Modal, Typography } from 'antd'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { updateGamePublisherUserDisplayName } from 'services/graphql/mutations'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import InputText from 'components/commons/form/input-text'

const { Text } = Typography

export default function SetDisplayNameModal() {
  const { t } = useTranslation()

  const { isSetDisplayNameModalShow, hideSetDisplayNameModal } = useModalStore()
  const { user, setUser } = useUserStore()

  const [displayName, setDisplayName] = useState<string>('')

  const [updateDisplayName, { loading }] = useMutation<
    UpdateUserDisplayNameMutation,
    UpdateUserDisplayNameMutationVariables
  >(updateGamePublisherUserDisplayName)

  const handleSubmit = useCallback(() => {
    updateDisplayName({ variables: { gamePublisherUserDisplayName: displayName } }).then(() => {
      hideSetDisplayNameModal()
      setUser({ ...user, displayName: displayName })
    })
    hideSetDisplayNameModal()
  }, [displayName, user, updateDisplayName])

  return (
    <Modal centered width={468} open={isSetDisplayNameModalShow} closable={false} footer={false}>
      <Flex vertical align='center' gap={20} style={{ padding: '0px 24px' }}>
        <Text style={{ fontWeight: 700 }}>{t('game_information_basic_title')}</Text>
        <InputText
          title={t('game_information_form_user_name_label')}
          placeholder={t('game_information_form_user_name_placeholder')}
          autoFocus={true}
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
        <Button type='primary' onClick={handleSubmit} disabled={!displayName} loading={loading}>
          {t('general_submit')}
        </Button>
      </Flex>
    </Modal>
  )
}
