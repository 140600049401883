import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  RolesQuery,
  RolesQueryVariables,
  SetGamePublisherUserMutation,
  SetGamePublisherUserMutationVariables,
  UserRolesQuery,
  UserRolesQueryVariables,
} from '__generated__/gql/graphql'
import { Flex, Form, Image, message, Modal, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { setGamePublisherUser } from 'services/graphql/mutations'
import { roles, userRoles } from 'services/graphql/queries'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import InputGroup from 'components/commons/form/input-group'
import InputText from 'components/commons/form/input-text'
import SelectOptions from 'components/commons/form/select-options'
import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Title, Text } = Typography

type EditFormData = {
  name: string
  email: string
  roles: string[]
}

export default function EditMemberRoleModal() {
  const { t } = useTranslation()
  const { isEditMemberRoleModalShow, closeAll, nameRoleSelected, emailRoleSelected } =
    useModalStore()
  const [editMemberForm] = Form.useForm()
  const { user } = useUserStore()
  const client = useApolloClient()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [updateMember, { loading: isUpdateMemberLoading }] = useMutation<
    SetGamePublisherUserMutation,
    SetGamePublisherUserMutationVariables
  >(setGamePublisherUser)

  useEffect(() => {
    editMemberForm.setFieldsValue({
      name: nameRoleSelected,
      email: emailRoleSelected,
    })
  }, [nameRoleSelected, emailRoleSelected])

  const handleEditMember = useCallback(
    (formData: EditFormData) => {
      setIsLoading(true)
      updateMember({
        variables: {
          name: formData.name,
          email: formData.email,
          roles: formData.roles,
        },
      })
        .then((res) => {
          if (res.errors || !res.data?.createOrUpdateGamePublisherUser) {
            if (res.errors) {
              console.error(res.errors)
            }

            message.error(`Failed to update member info`)
          }

          message.success(`Sucessfully update member info`)
        })
        .catch((err) => {
          console.error(err)
          message.error(`Failed to update member info`)
        })
        .finally(() => {
          client.refetchQueries({
            include: ['GetUserListsByPublisherId'],
          })
          setIsLoading(false)
          setTimeout(() => {
            closeAll()
          }, 500)
        })
    },
    [setIsLoading, updateMember, closeAll],
  )

  const { data: userRolesData, loading: isUserRolesDataLoading } = useQuery<
    UserRolesQuery,
    UserRolesQueryVariables
  >(userRoles, {
    variables: {
      email: emailRoleSelected,
    },
    skip: !Object.keys(user).length || !isEditMemberRoleModalShow || !emailRoleSelected.length,
  })

  useEffect(() => {
    if (!userRolesData) return
    editMemberForm.setFieldsValue({
      roles: (userRolesData?.getRolesByEmail || []).map((r) => r.slug),
    })
    editMemberForm.setFieldValue(
      'roles',
      (userRolesData?.getRolesByEmail || []).map((r) => r.slug),
    )
  }, [userRolesData])

  const { data: rolesData, loading: isRolesLoading } = useQuery<RolesQuery, RolesQueryVariables>(
    roles,
    { skip: !Object.keys(user).length },
  )

  const rolesOpts = useMemo(() => {
    const roles =
      rolesData?.roles?.map((role) => ({
        label: role.name,
        value: role.slug,
        disabled: role.slug === 'admin',
      })) || []

    return roles
  }, [rolesData])

  return (
    <Modal
      title={false}
      centered
      open={isEditMemberRoleModalShow}
      onCancel={closeAll}
      footer={false}
      width={653}>
      <Flex
        vertical
        gap={80}
        justify='center'
        align='center'
        style={{ padding: '10px 20px 0px 20px', width: '100%' }}>
        <Flex vertical gap={40} justify='center' align='center' style={{ width: '100%' }}>
          <Title level={4} style={{ margin: 0, padding: 0, color: colors.gray90 }}>
            Edit Member Detail
          </Title>
          <Form<EditFormData>
            name='edit-member-form'
            form={editMemberForm}
            onFinish={handleEditMember}
            initialValues={{
              name: nameRoleSelected,
              email: emailRoleSelected,
              roles: [],
            }}
            disabled={isLoading}
            style={{
              width: '100%',
              padding: '0px 34px',
            }}>
            <InputGroup<EditFormData>
              name='name'
              title='Name'
              rules={[
                {
                  required: true,
                },
              ]}>
              <InputText
                className='input-secondary'
                variant='secondary'
                placeholder={t('game_information_form_user_name_placeholder')}
                size='large'
                autoFocus
                disabled={isLoading}
              />
            </InputGroup>
            <InputGroup<EditFormData>
              name='email'
              title='Email'
              rules={[
                {
                  required: true,
                },
              ]}>
              <InputText
                className='input-secondary'
                variant='secondary'
                placeholder={t('login_form_email_field_placeholder')}
                size='large'
                autoFocus
                disabled
              />
            </InputGroup>
            <InputGroup<EditFormData>
              name='roles'
              title='Role'
              rules={[
                {
                  required: true,
                },
              ]}>
              <SelectOptions
                className={[].length > 1 ? 'invite-member-labs' : undefined}
                mode='multiple'
                loading={isRolesLoading || isUserRolesDataLoading}
                variant='primary'
                placeholder={'Choose user role'}
                options={rolesOpts}
                sortOption={false}
              />
            </InputGroup>
          </Form>
        </Flex>
        <Flex vertical gap={10} justify='center' align='center'>
          <Text style={{ color: colors.gray70 }}>
            We will send email notification to this user for any changes that you’ve made.
          </Text>
          <LabsButton
            form='edit-member-form'
            htmlType='submit'
            variant='solid'
            width='wide'
            isLoading={isUpdateMemberLoading || isRolesLoading}
            isDisabled={isRolesLoading || isUpdateMemberLoading}>
            Save Changes
          </LabsButton>
        </Flex>
      </Flex>
    </Modal>
  )
}
