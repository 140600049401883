import { Button, DatePicker, Flex, Form, Modal, Space, Typography } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'
import dayjs from 'dayjs'
import moment, { Moment } from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useCreateAdsStore from 'stores/useCreateAdsStore'
import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useTracker from 'hooks/useTracker'

import colors from 'theme/colors'

import { ScheduleRunAdFormData } from './type'

const { Text, Title } = Typography

const ModalTitle = () => {
  const { t } = useTranslation()
  return (
    <Space direction='vertical' size={0}>
      <Title level={5} style={{ color: colors.gray90, fontWeight: 700, margin: 0 }}>
        {t('creative_set_date_time_modal_title')}
      </Title>
      <Text style={{ color: colors.gray70, fontWeight: 400 }}>
        {t('creative_set_date_time_modal_subtitle')}
      </Text>
    </Space>
  )
}

export default function CreativeDateTimeModal() {
  const { t } = useTranslation()
  const { track } = useTracker()

  const { user, selectedGame } = useUserStore()
  const {
    isCreativeSetDateTimeShow,
    isPublishIntended,
    showReviewCreativesModal,
    showTopupModal,
    showSetDailyBudgetModal,
    hideCreativeSetDateTime,
  } = useModalStore()
  const { startDate, setStartDate } = useCreateAdsStore()

  const [tempPublishTime, setTempPublishTime] = useState<string>(
    dayjs().add(1, 'hour').toISOString(),
  )

  const [scheduleRunAdForm] = Form.useForm()

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < moment().subtract(1, 'day')
  }

  const disabledTime = (current: Moment | null) => {
    return {
      disabledHours: () =>
        current && current.isSame(moment(), 'day') ? [...Array(moment().hour() + 1).keys()] : [],
      disabledMinutes: () =>
        current && current.isSame(moment().add(1, 'hour'), 'hour')
          ? [...Array(moment().minute()).keys()]
          : [],
      disabledSeconds: () =>
        current && current.isSame(moment(), 'minute') ? [...Array(moment().second()).keys()] : [],
    }
  }

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      const isoString = date.toISOString()
      setTempPublishTime(isoString)
    }
  }

  const handleCancel = useCallback(() => {
    hideCreativeSetDateTime()
    track('cancel_set_ads_publish_date', {
      type: 'click',
      location: 'footer_action',
      location_type: 'text',
    })
    if (isPublishIntended) showReviewCreativesModal()
  }, [isPublishIntended])

  const handlePublish = useCallback(() => {
    if (!user.balance) showTopupModal(true)
    else if (!selectedGame.dailyBudget) showSetDailyBudgetModal(true)
    else {
      showReviewCreativesModal()
      track('review_created_ads', {
        type: 'click',
        location: 'publish_button',
        location_type: 'button',
      })
    }
  }, [track, startDate, selectedGame])

  const handleSave = useCallback(() => {
    setStartDate(tempPublishTime)
    hideCreativeSetDateTime()
    track('save_set_ads_publish_date', {
      type: 'click',
      location: 'footer_action',
      location_type: 'text',
    })

    if (isPublishIntended) handlePublish()
  }, [track, tempPublishTime])

  useEffect(() => {
    if (!startDate) setTempPublishTime(dayjs().add(1, 'hour').toISOString())
    if (startDate && !isCreativeSetDateTimeShow) setTempPublishTime(startDate)
  }, [isCreativeSetDateTimeShow, startDate])

  return (
    <Modal
      open={isCreativeSetDateTimeShow}
      title={<ModalTitle />}
      centered
      footer={false}
      onCancel={handleCancel}
      width={600}>
      <Form<ScheduleRunAdFormData>
        name='basic-information-form'
        form={scheduleRunAdForm}
        onFinish={handleSave}
        initialValues={{
          date: '',
          hours: '00:00',
          hourUnit: 'AM',
        }}>
        <Space direction='vertical' size={50} style={{ width: '100%', marginTop: 20 }}>
          <DatePicker
            showTime
            showSecond={false}
            onChange={handleDateChange}
            value={dayjs(tempPublishTime) || dayjs()}
            format={'DD/MM/YY HH:mm'}
            disabledDate={disabledDate}
            allowClear={false}
            size='large'
            disabledTime={(current) => disabledTime(current as Moment)}
            style={{ width: '100%' }}
          />

          <Flex justify='end' gap={18}>
            <Button size='large' type='link' onClick={handleCancel}>
              {t('general_cancel')}
            </Button>
            <Button type='primary' htmlType='submit' size='large'>
              {t('general_save')}
            </Button>
          </Flex>
        </Space>
      </Form>
    </Modal>
  )
}
