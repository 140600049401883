import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  RolesQuery,
  RolesQueryVariables,
  SetGamePublisherUserMutation,
  SetGamePublisherUserMutationVariables,
} from '__generated__/gql/graphql'
import { Flex, Form, Image, message, Modal, Typography } from 'antd'
import { RuleObject } from 'antd/es/form'
import { useForm } from 'antd/es/form/Form'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { setGamePublisherUser } from 'services/graphql/mutations'
import { roles, userRoles } from 'services/graphql/queries'

import { isValidEmail } from 'utils/StringUtils'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import InputGroup from 'components/commons/form/input-group'
import InputText from 'components/commons/form/input-text'
import SelectOptions from 'components/commons/form/select-options'
import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Title, Text } = Typography

type NewMemberFormData = {
  name: string
  email: string
  roles: string[]
}

export default function CreateNewMemberModal() {
  const { t } = useTranslation()
  const { isCreateNewMemberModalShow, closeAll } = useModalStore()
  const [newMemberForm] = Form.useForm()
  const { user } = useUserStore()
  const client = useApolloClient()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [setNewMember, { loading: isSetNewMemberLoading }] = useMutation<
    SetGamePublisherUserMutation,
    SetGamePublisherUserMutationVariables
  >(setGamePublisherUser)

  const handleNewMember = useCallback(
    (formData: NewMemberFormData) => {
      setIsLoading(true)
      setNewMember({
        variables: {
          name: formData.name,
          email: formData.email,
          roles: formData.roles,
        },
      })
        .then((res) => {
          if (res.errors || !res.data?.createOrUpdateGamePublisherUser) {
            if (res.errors) {
              console.error(res.errors)
            }

            message.error(`Failed to add new member`)
          }

          message.success(`Sucessfully added new member`)
        })
        .catch((err) => {
          console.error(err)
          message.error(`Failed to add new member`)
        })
        .finally(() => {
          client.refetchQueries({
            include: ['GetUserListsByPublisherId'],
          })
          setIsLoading(false)
          setTimeout(() => {
            closeAll()
          }, 500)
        })
    },
    [setIsLoading, setNewMember, closeAll],
  )

  const { data: rolesData, loading: isRolesLoading } = useQuery<RolesQuery, RolesQueryVariables>(
    roles,
    { skip: !Object.keys(user).length },
  )

  const rolesOpts = useMemo(() => {
    const roles =
      rolesData?.roles
        ?.filter((role) => role.slug !== 'admin')
        .map((role) => ({
          label: role.name,
          value: role.slug,
          disabled: role.slug === 'admin',
        })) || []

    return roles
  }, [rolesData])

  const [isEmailValid, setIsEmailValid] = useState<boolean>(false)
  const validateEmail = async (_: RuleObject, value: string) => {
    if (!value) {
      setIsEmailValid(false)
      return Promise.reject(t('validate_input_email_required_message'))
    } else if (!isValidEmail(value)) {
      setIsEmailValid(false)
      return Promise.reject(t('validate_input_valid_email_message'))
    } else {
      setIsEmailValid(true)
      return Promise.resolve()
    }
  }

  return (
    <Modal
      title={false}
      centered
      open={isCreateNewMemberModalShow}
      onCancel={closeAll}
      footer={false}
      width={653}>
      <Flex
        vertical
        gap={80}
        justify='center'
        align='center'
        style={{ padding: '10px 20px 0px 20px', width: '100%' }}>
        <Flex vertical gap={40} justify='center' align='center' style={{ width: '100%' }}>
          <Title level={4} style={{ margin: 0, padding: 0, color: colors.gray90 }}>
            Add New Member
          </Title>
          <Form<NewMemberFormData>
            name='new-member-form'
            form={newMemberForm}
            onFinish={handleNewMember}
            initialValues={{
              name: '',
              email: '',
              roles: [],
            }}
            style={{
              width: '100%',
              padding: '0px 34px',
            }}>
            <InputGroup<NewMemberFormData>
              name='name'
              title='Name'
              rules={[
                {
                  required: true,
                },
              ]}>
              <InputText
                className='input-secondary'
                variant='secondary'
                placeholder={t('game_information_form_user_name_placeholder')}
                size='large'
                autoFocus
                disabled={isLoading}
              />
            </InputGroup>
            <InputGroup<NewMemberFormData>
              name='email'
              title='Email'
              rules={[
                {
                  required: true,
                  validator: validateEmail,
                },
              ]}>
              <InputText
                className='input-secondary'
                variant='secondary'
                placeholder={t('login_form_email_field_placeholder')}
                size='large'
                autoFocus
              />
            </InputGroup>
            <InputGroup<NewMemberFormData>
              name='roles'
              title='Role'
              rules={[
                {
                  required: true,
                },
              ]}>
              <SelectOptions
                className={[].length > 1 ? 'invite-member-labs' : undefined}
                mode='multiple'
                loading={isRolesLoading}
                variant='primary'
                placeholder={'Choose user role'}
                options={rolesOpts}
                sortOption={false}
                disabled={isRolesLoading}
              />
            </InputGroup>
          </Form>
        </Flex>
        <Flex vertical gap={10} justify='center' align='center'>
          <Text style={{ color: colors.gray70 }}>We will send invite email to new members</Text>
          <LabsButton
            form='new-member-form'
            htmlType='submit'
            variant='solid'
            width='wide'
            isLoading={isSetNewMemberLoading || isRolesLoading}
            isDisabled={!isEmailValid || isRolesLoading || isSetNewMemberLoading}>
            {t('general_submit')}
          </LabsButton>
        </Flex>
      </Flex>
    </Modal>
  )
}
