import { useQuery } from '@apollo/client'
import {
  GamePublisherUserPermission,
  GetGamePublisherUserPermissionsQuery,
  GetGamePublisherUserPermissionsQueryVariables,
} from '__generated__/gql/graphql'
import React, { ReactNode, useMemo } from 'react'
import { getGamePublisherUserPermissions } from 'services/graphql/queries'

import useUserStore from 'stores/useUserStore'

export const GamePublisherUserPermissionContext = React.createContext<{
  permissions?: GamePublisherUserPermission[]
  isPermissionLoading: boolean
}>({ permissions: [], isPermissionLoading: false })

type Props = {
  children: ReactNode
}

const GamePublisherUserPermissionContextProvider: React.FC<Props> = ({ children }) => {
  const { user } = useUserStore()

  const { data, loading } = useQuery<
    GetGamePublisherUserPermissionsQuery,
    GetGamePublisherUserPermissionsQueryVariables
  >(getGamePublisherUserPermissions, {
    fetchPolicy: 'cache-and-network',
    skip: !Object.keys(user).length || !user.uid || !user.uid.length,
  })

  const permissions = useMemo(() => {
    if (!data) return []

    return data?.gamePublisherUser?.permissions || []
  }, [data])

  return (
    <GamePublisherUserPermissionContext.Provider
      value={{
        permissions: permissions,
        isPermissionLoading: loading,
      }}>
      {children}
    </GamePublisherUserPermissionContext.Provider>
  )
}

export default GamePublisherUserPermissionContextProvider
