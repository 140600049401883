import { useApolloClient, useMutation } from '@apollo/client'
import { DisableAdUnitsMutation, DisableAdUnitsMutationVariables } from '__generated__/gql/graphql'
import { Flex, Modal, Typography } from 'antd'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { disableAdUnits } from 'services/graphql/mutations'

import { MEDIATION_DELETE_AD_UNIT_ROUTE } from 'constants/Routes'

import useMediationStore from 'stores/useMediationStore'
import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Title, Text } = Typography

export default function DeleteAdUnitConfirmationModal() {
  const client = useApolloClient()
  const navigate = useNavigate()

  const { isDeleteAdUnitConfirmationModalShow, closeAll } = useModalStore()
  const { selectedAdUnit, setSelectedAdUnit, setMutatedAdUnit } = useMediationStore()
  const { selectedGameStore } = useUserStore()
  const [deleteAdUnit, { loading }] = useMutation<
    DisableAdUnitsMutation,
    DisableAdUnitsMutationVariables
  >(disableAdUnits)

  const handleDelete = useCallback(() => {
    if (!selectedGameStore?.mediationId || !selectedAdUnit?.adUnitId) return

    deleteAdUnit({
      variables: {
        gameStoreId: selectedGameStore.id,
        mediationId: selectedGameStore.mediationId,
        adUnitIds: [selectedAdUnit.adUnitId],
      },
    }).then((res) => {
      setSelectedAdUnit(undefined)
      setMutatedAdUnit(
        res.data?.disableAdUnits.map((adUnit) => ({
          id: adUnit.adUnitId || '',
          type: adUnit.adType,
          name: adUnit.labsAdUnitName || '',
          status: adUnit.adUnitStatus,
        })),
      )

      client.refetchQueries({
        include: ['GetIntegratedAdUnits', 'Mediations'],
      })

      navigate(`/${MEDIATION_DELETE_AD_UNIT_ROUTE}`)
      closeAll()
    })
  }, [selectedGameStore, selectedAdUnit])

  if (!isDeleteAdUnitConfirmationModalShow || !selectedAdUnit) return null
  return (
    <Modal
      centered
      width={520}
      open={isDeleteAdUnitConfirmationModalShow}
      onCancel={closeAll}
      footer={false}>
      <Flex vertical align='center' gap={16} style={{ padding: '0px 32px' }}>
        <Title level={5} style={{ margin: 0, color: colors.gray90 }}>
          Are you sure want to delete this item?
        </Title>
        <Text style={{ color: colors.gray70, textAlign: 'center' }}>
          If you mark this as disable, you will need to make adjustments in your app, which we will
          assist with afterward.
        </Text>
        <Flex justify='center' gap={20} style={{ marginTop: 20 }}>
          <LabsButton width='wide' variant='outline' onClick={closeAll}>
            Cancel
          </LabsButton>
          <LabsButton width='wide' color='danger' onClick={handleDelete} isLoading={loading}>
            Confirm Delete
          </LabsButton>
        </Flex>
      </Flex>
    </Modal>
  )
}
