import {
  AudienceAnalyticsAdvanceInformationViewBy,
  CohortMetricType,
  DailyMetricType,
  DimensionType,
  GameRevenueSource,
  SourceType,
} from '__generated__/gql/graphql'
import dayjs from 'dayjs'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { PerformanceDashboardTableColumn } from 'components/modules/dashboard/user-acquisition/custom-table/table/type'

type CustomMetric = {
  label: string
  key: string
  metric: DailyMetricType | CohortMetricType
}

interface DashboardAnalyticsState {
  gameStoresOsSelected: string[]
  setGameStoresOsSelected: (os: string[]) => void

  gameStoresBundleIdsSelected: string[]
  setGameStoresBundleIdsSelected: (bundleIds: string[]) => void

  gameTargetingCountryCode: string[]
  setGameTargetingCountryCode: (countryCodes: string[]) => void

  channels: string[]
  setChannels: (channels: string[]) => void

  audienceAnalyticsRevenueSourceSelected: GameRevenueSource | undefined
  setAudienceAnalyticsRevenueSourceSelected: (source: GameRevenueSource | undefined) => void

  dateValue: string[]
  setDateValue: (date: string[]) => void

  audienceAnalyticsViewBy: AudienceAnalyticsAdvanceInformationViewBy
  setAudienceAnalyticsViewBy: (viewBy: AudienceAnalyticsAdvanceInformationViewBy) => void

  dimension: DimensionType
  dimensionLabel: string
  setDimensionValue: (dimension: DimensionType) => void
  setDimensionLabel: (dimensionLabel: string) => void

  metric: CohortMetricType
  metricLabel: string
  setMetricValue: (metric: CohortMetricType) => void
  setMetricLabel: (metricLabel: string) => void

  source: SourceType
  sourceLabel: string
  setSourceValue: (source: SourceType) => void
  setSourceLabel: (SourceLabel: string) => void

  daysPeriod: number[]
  setDaysPeriod: (daysPeriod: number[]) => void

  uaPerformanceMetricColumns: PerformanceDashboardTableColumn[]
  setUaPerformanceMetricColumns: (columns: PerformanceDashboardTableColumn[]) => void

  uaPerformanceDimensionColumns: PerformanceDashboardTableColumn[]
  setUaPerformanceDimensionColumns: (columns: PerformanceDashboardTableColumn[]) => void

  showPopUp: boolean
  setShowPopUp: (showPopUp: boolean) => void

  customMetricSelected: CustomMetric | undefined
  setCustomMetricSelected: (customMetricSelected: CustomMetric | undefined) => void

  customCohortMetrics: Record<CohortMetricType, number[]>
  setCustomCohortMetrics: (customCohortMetrics: Record<CohortMetricType, number[]>) => void

  resetState: () => void
}

const useDashboardAnalyticsStore = create<DashboardAnalyticsState>()(
  persist(
    (set) => ({
      gameStoresOsSelected: [],
      setGameStoresOsSelected: (os: string[]) =>
        set((state) => ({ ...state, gameStoresOsSelected: os })),

      gameStoresBundleIdsSelected: [],
      setGameStoresBundleIdsSelected: (bundleIds: string[]) =>
        set((state) => ({ ...state, gameStoresBundleIdsSelected: bundleIds })),

      gameTargetingCountryCode: [],
      setGameTargetingCountryCode: (countryCodes: string[]) =>
        set((state) => ({ ...state, gameTargetingCountryCode: countryCodes })),

      channels: [],
      setChannels: (channels: string[]) => set((state) => ({ ...state, channels: channels })),

      audienceAnalyticsRevenueSourceSelected: GameRevenueSource.All,
      setAudienceAnalyticsRevenueSourceSelected: (source: GameRevenueSource | undefined) =>
        set((state) => ({ ...state, audienceAnalyticsRevenueSourceSelected: source })),

      dateValue: [
        dayjs().subtract(8, 'day').format('YYYY-MM-DD'),
        dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
      ],
      setDateValue: (date: string[]) => set((state) => ({ ...state, dateValue: date })),

      audienceAnalyticsViewBy: AudienceAnalyticsAdvanceInformationViewBy.Country,
      setAudienceAnalyticsViewBy: (viewBy: AudienceAnalyticsAdvanceInformationViewBy) =>
        set((state) => ({ ...state, audienceAnalyticsViewBy: viewBy })),

      dimension: DimensionType.Date,
      dimensionLabel: 'Date',
      setDimensionValue: (dimension: DimensionType) =>
        set((state) => ({ ...state, dimension: dimension })),
      setDimensionLabel: (dimensionLabel: string) =>
        set((state) => ({ ...state, dimensionLabel: dimensionLabel })),

      metric: CohortMetricType.Revenue,
      metricLabel: 'Cohort Revenue',
      setMetricValue: (metric: CohortMetricType) => set((state) => ({ ...state, metric: metric })),
      setMetricLabel: (metricLabel: string) =>
        set((state) => ({ ...state, metricLabel: metricLabel })),

      source: SourceType.Qsdk,
      sourceLabel: 'SDK',
      setSourceValue: (source: SourceType) => set((state) => ({ ...state, source: source })),
      setSourceLabel: (sourceLabel: string) =>
        set((state) => ({ ...state, sourceLabel: sourceLabel })),

      daysPeriod: Array.from({ length: 31 }, (_, i) => i),
      setDaysPeriod: (daysPeriod: number[]) =>
        set((state) => ({ ...state, daysPeriod: daysPeriod })),

      uaPerformanceMetricColumns: [],
      setUaPerformanceMetricColumns: (columns: PerformanceDashboardTableColumn[]) =>
        set((state) => ({ ...state, uaPerformanceMetricColumns: columns })),

      uaPerformanceDimensionColumns: [],
      setUaPerformanceDimensionColumns: (columns: PerformanceDashboardTableColumn[]) =>
        set((state) => ({ ...state, uaPerformanceDimensionColumns: columns })),

      showPopUp: false,
      setShowPopUp: (showPopUp: boolean) => set((state) => ({ ...state, showPopUp: showPopUp })),

      customMetricSelected: undefined,
      setCustomMetricSelected: (customMetricSelected: CustomMetric | undefined) =>
        set((state) => ({ ...state, customMetricSelected: customMetricSelected })),

      customCohortMetrics: {} as Record<CohortMetricType, number[]>,
      setCustomCohortMetrics: (customCohortMetrics: Record<CohortMetricType, number[]>) =>
        set((state) => ({ ...state, customCohortMetrics: customCohortMetrics })),

      resetState: () =>
        set(() => ({
          gameStoresOsSelected: [],
          gameStoresBundleIdsSelected: [],
          gameTargetingCountryCode: [],
          channels: [],
          audienceAnalyticsRevenueSourceSelected: GameRevenueSource.All,
          dateValue: [dayjs().subtract(8, 'd').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
          audienceAnalyticsViewBy: AudienceAnalyticsAdvanceInformationViewBy.Country,
          dimension: DimensionType.Date,
          dimensionLabel: 'Date',
          metric: CohortMetricType.Revenue,
          metricLabel: 'Cohort Revenue',
          source: SourceType.Qsdk,
          sourceLabel: 'SDK',
          daysPeriod: Array.from({ length: 31 }, (_, i) => i),
          uaPerformanceMetricColumns: [],
          uaPerformanceDimensionColumns: [],
          customMetricSelected: undefined,
          customCohortMetrics: {} as Record<CohortMetricType, number[]>,
        })),
    }),
    {
      name: 'dashboard-analytics-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useDashboardAnalyticsStore
