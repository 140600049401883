import { LoadingOutlined } from '@ant-design/icons'
import { Flex, Spin, Typography } from 'antd'
import { useEffect, useState } from 'react'

import colors from 'theme/colors'

import './styles.css'
import { ISlideToConfirm } from './type'

const { Text } = Typography
export default function SlideToConfirm({
  label,
  isLoading = false,
  handleConfirm,
}: ISlideToConfirm) {
  const [sliderValue, setSliderValue] = useState(0)
  const [isTapped, setIsTapped] = useState<boolean>(false)

  const handleMouseUp = (e: any) => {
    e.target.value > 0 && e.target.value < 100 && setSliderValue(0)
    setIsTapped(false)
  }

  const onSuccess = () => {
    handleConfirm()
    setTimeout(() => setSliderValue(0), 1000)
  }

  useEffect(() => {
    if (sliderValue === 0) return
    if (sliderValue > 99) {
      onSuccess()
      return
    }
  }, [sliderValue])

  return (
    <div className='slide-to-confirm'>
      <div className='slider-thumb'>
        {!isLoading && (
          <input
            className='confirm'
            type='range'
            value={sliderValue}
            onChange={(e) => setSliderValue(parseInt(e.target.value))}
            onMouseUp={handleMouseUp}
            onBlur={handleMouseUp}
            onMouseDown={() => setIsTapped(true)}
            style={{ cursor: 'pointer' }}
            min='0'
            max='100'
          />
        )}
        <div className='label-overlay' />
        <div className='label' style={{ opacity: isTapped ? 0 : 1 }}>
          {isLoading ? (
            <Flex align='center' gap={10} style={{ position: 'relative' }}>
              <Text style={{ color: colors.gray10 }}>Loading</Text>
              <Spin
                size='small'
                indicator={<LoadingOutlined style={{ color: colors.gray10, fontSize: 14 }} spin />}
              />
            </Flex>
          ) : (
            label
          )}
        </div>
      </div>
    </div>
  )
}
