import posthog from 'posthog-js'
import React, { ReactNode, useEffect } from 'react'
import { browserName, deviceType } from 'react-device-detect'

import appConfig from 'config/appConfig'

import useUserStore from 'stores/useUserStore'

import useIdentifier from 'hooks/useIdentifier'

export const PostHogContext = React.createContext<{ apiKey: string }>({
  apiKey: '',
})

type Props = {
  children: ReactNode
}

const PostHogContextProvider: React.FC<Props> = ({ children }) => {
  const { user } = useUserStore()
  const { generateId } = useIdentifier()
  const { sessionId } = generateId()

  useEffect(() => {
    if (!sessionId || !user) return

    posthog.init(appConfig.posthogKey, {
      capture_pageview: false,
      disable_session_recording: appConfig.env === 'development',
      session_recording: {
        maskAllInputs: true,
        recordBody: true,
        recordHeaders: true,
      },
      capture_pageleave: true,
      request_batching: true,
      autocapture: false,
      debug: appConfig.env === 'development',
      persistence: 'localStorage',
    })
    posthog.identify(sessionId)
    posthog.setPersonProperties({
      userAgent: navigator.userAgent,
      version: appConfig.buildVersion,
      deviceType: deviceType,
      browserName: browserName,
      sessionId: sessionId,
      environment: appConfig.env,
      userId: user.uid,
    })
  }, [user.uid, sessionId])

  if (!user) return null
  return (
    <PostHogContext.Provider value={{ apiKey: appConfig.posthogKey }}>
      {children}
    </PostHogContext.Provider>
  )
}

export default PostHogContextProvider
