import { Input } from 'antd'
import React, { CSSProperties } from 'react'

import colors from 'theme/colors'

import { ITextArea, Variant } from './type'

const variantStyle: { [k in Variant]: CSSProperties } = {
  primary: {
    backgroundColor: colors.labs10,
    borderColor: colors.labs30,
    color: colors.labs90,
  },
  transparent: {},
}

const { TextArea: InputTextArea } = Input

export default function TextArea(props: ITextArea) {
  return (
    <InputTextArea
      size='large'
      className={props.variant || 'primary'}
      style={variantStyle[props.variant || 'primary']}
      {...props}
    />
  )
}
