import { useApolloClient, useMutation } from '@apollo/client'
import {
  CreateGameCreativeFeedbackMutation,
  CreateGameCreativeFeedbackMutationVariables,
} from '__generated__/gql/graphql'
import { Button, Flex, message, Typography } from 'antd'
import Modal from 'antd/es/modal/Modal'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createGameCreativeFeedback } from 'services/graphql/mutations'

import useModalStore from 'stores/useModalStore'

import useTracker from 'hooks/useTracker'

import TextArea from 'components/commons/form/textarea'
import IconLib from 'components/commons/icon-lib'
import { labsThumbsDownOutline, labsThumbsUpOutline } from 'components/commons/icon-lib/icons/base'

const { Text } = Typography
export default function FeedbackModal() {
  const { t } = useTranslation()
  const { track } = useTracker()
  const client = useApolloClient()
  const { isFeedbackModalShow, feedbackData, closeAll } = useModalStore()

  const [feedback, setFeedback] = useState<string>('')

  const [submitFeedback, { loading }] = useMutation<
    CreateGameCreativeFeedbackMutation,
    CreateGameCreativeFeedbackMutationVariables
  >(createGameCreativeFeedback, { fetchPolicy: 'no-cache' })

  const handleSubmit = useCallback(() => {
    submitFeedback({
      variables: {
        gameCreativeId: feedbackData.gameCreativeId,
        isLike: feedbackData.isLiked,
        feedback: feedback,
      },
    }).then(() => {
      message.success(t('feedback_modal_toast_success'))
      client.refetchQueries({
        include: ['GameCreativeFeedbacks'],
      })
      closeAll()
    })
    track('feedback_submission', {
      type: 'click',
      location: 'feedback_modal',
      location_type: 'button',
      module: feedbackData.module,
      feedback: feedback,
      isLike: feedbackData.isLiked,
    })
  }, [track, feedback, feedbackData])

  useEffect(() => {
    if (!isFeedbackModalShow) setFeedback('')
  }, [isFeedbackModalShow])

  return (
    <Modal centered width={468} open={isFeedbackModalShow} onCancel={closeAll} footer={false}>
      <Flex vertical align='center' gap={20}>
        <Text style={{ fontWeight: 700 }}>{t('feedback_modal_title')}</Text>
        <Flex gap={10}>
          <IconLib
            icon={labsThumbsUpOutline}
            color={feedbackData.isLiked ? 'success70' : 'base'}
            size='lg'
          />
          <IconLib
            icon={labsThumbsDownOutline}
            color={!feedbackData.isLiked ? 'danger' : 'base'}
            size='lg'
          />
        </Flex>
        <TextArea
          variant='transparent'
          placeholder={t('feedback_modal_description_placeholder')}
          rows={4}
          autoFocus={true}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
        <Button type='primary' onClick={handleSubmit} disabled={!feedback} loading={loading}>
          {t('feedback_modal_button')}
        </Button>
      </Flex>
    </Modal>
  )
}
