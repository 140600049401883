import { Flex, Typography } from 'antd'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from 'utils/FormatterUtils'
import { capitalizeWords } from 'utils/StringUtils'

import useGeneralStore from 'stores/useGeneralStore'
import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useIsPermissionGranted from 'hooks/useIsPermissionGranted'

import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

import { GamePublisherUserPermissionEnum } from 'types/permission'

import HeaderPublisherInfoApplyFreeCredit from './apply-free-credit'
import HeaderPublisherInfoAttributionQuota from './attribution-quota'

const { Text } = Typography
export default function HeaderPublisherInfo() {
  const { t } = useTranslation()

  const { user } = useUserStore()
  const { info } = useGeneralStore()
  const { showTopupModal, showNotAllowedModal } = useModalStore()

  const { isPermissionGranted: hasTopUpBalancePermission } = useIsPermissionGranted(
    GamePublisherUserPermissionEnum.TOPUP_BALANCE,
  )

  const { isPermissionGranted: hasRequestLimitedBalanceOfferPermission } = useIsPermissionGranted(
    GamePublisherUserPermissionEnum.REQUEST_BALANCE_LIMITED_OFFER,
  )

  const handleClickTopupBalance = useCallback(() => {
    if (hasTopUpBalancePermission) {
      showTopupModal(false)
      return
    }

    showNotAllowedModal()
  }, [hasTopUpBalancePermission])

  return (
    <Flex vertical align='center' gap={12}>
      <Flex vertical align='center'>
        <Text
          style={{
            color: colors.gray90,
            fontSize: 14,
            fontWeight: 500,
            maxWidth: 180,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {capitalizeWords(user.displayName)}
        </Text>
        <Text
          style={{
            color: colors.gray70,
            fontSize: 12,
            maxWidth: 180,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {user.email}
        </Text>
      </Flex>
      <Flex vertical gap={6}>
        <Flex align='center' justify='center' gap={6}>
          <Text
            style={{
              color: colors.gray70,
              fontSize: 12,
            }}>
            {t('layout_header_balance')}:
          </Text>
          <Text
            style={{
              color: colors.gray90,
              fontWeight: 500,
              fontSize: 12,
            }}>
            {formatCurrency(user.balance, 0)}
          </Text>
          <LabsButton size='2xs' variant='outline' onClick={handleClickTopupBalance}>
            {t('header_info_topup_balance')}
          </LabsButton>
        </Flex>
        {hasRequestLimitedBalanceOfferPermission && <HeaderPublisherInfoApplyFreeCredit />}
      </Flex>
      {user.isDemo && <HeaderPublisherInfoAttributionQuota />}
    </Flex>
  )
}
