import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

import { ADS_INSPIRATION_ROUTE } from 'constants/Routes'

import AuthenticatedRoute from 'components/routes/AuthenticatedRoute'

const AdsInspirationPage = lazy(() => import('pages/ads-inspiration'))
const AdsInspirationDetailPage = lazy(() => import('pages/ads-inspiration/detail'))

const CreativeHubRouter = (
  <Route path={ADS_INSPIRATION_ROUTE}>
    <Route
      path=''
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <AdsInspirationPage />
          </AuthenticatedRoute>
        </Suspense>
      }
    />

    <Route
      path=':detail'
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <AdsInspirationDetailPage />
          </AuthenticatedRoute>
        </Suspense>
      }
    />
  </Route>
)

export default CreativeHubRouter
