import { AdType } from '__generated__/gql/graphql'
import { Card, Col, Flex, Modal, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { generateAdUnitName } from 'utils/StringUtils'

import useMediationStore from 'stores/useMediationStore'
import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

import AdUnitTemplate from './ad-unit-template'

const { Title, Text } = Typography

export default function ChooseTemplateAdUnitModal() {
  const { t } = useTranslation()

  const { selectedGame } = useUserStore()
  const { setTempAdUnit, setInitializationWizardStep } = useMediationStore()
  const { isChooseTemplateAdUnitModalShow, closeAll } = useModalStore()

  const interstitialPlacements = ['Home', 'Stage1', 'Success Attempt']
  const rewardedVideoPlacements = ['Home', 'Stage1', 'Success Attempt']
  const bannerPlacements = ['Home', 'Stage1', 'Success Attempt']

  const handleCreateManual = () => {
    setTempAdUnit([])
    closeAll()
    setInitializationWizardStep('create-ad-unit')
  }

  const handleSubmit = () => {
    const interstitialTemplate = interstitialPlacements.map((placement) => ({
      name: generateAdUnitName('Interstitial', selectedGame.name, placement.replace(' ', '_')),
      type: AdType.InterstitialAds,
    }))
    const RewardedVideoTemplate = rewardedVideoPlacements.map((placement) => ({
      name: generateAdUnitName('RV', selectedGame.name, placement.replace(' ', '_')),
      type: AdType.RewardedVideoAds,
    }))
    const BannerTemplate = bannerPlacements.map((placement) => ({
      name: generateAdUnitName('Banner', selectedGame.name, placement.replace(' ', '_')),
      type: AdType.BannerAds,
    }))

    setTempAdUnit([...interstitialTemplate, ...RewardedVideoTemplate, ...BannerTemplate])
    closeAll()
    setInitializationWizardStep('create-ad-unit')
  }

  return (
    <Modal
      centered
      width='calc(100vw - 100px)'
      style={{ maxWidth: 1340 }}
      open={isChooseTemplateAdUnitModalShow}
      title={
        <Title level={4} style={{ fontWeight: 700, margin: 0, color: colors.gray90 }}>
          {t('mediation_create_ad_unit_template_modal_title')}
        </Title>
      }
      onCancel={closeAll}
      footer={false}>
      <Flex vertical align='center' gap={20} style={{ padding: '24px 24px 0px' }}>
        <Text style={{ color: colors.gray90 }}>
          {t('mediation_create_ad_unit_template_modal_description')}
        </Text>
        <Card style={{ backgroundColor: colors.labs10, width: '100%' }}>
          <Flex vertical gap={12}>
            <Text style={{ fontWeight: 500 }}>
              {t('mediation_create_ad_unit_template_modal_template_set_label')}:
            </Text>
            <Row gutter={[14, 14]}>
              <Col span={8}>
                <AdUnitTemplate
                  adUnit='Interstitial'
                  mediaUrl='/videos/mediation-interstitial.mp4'
                  placements={interstitialPlacements}
                  estimatedRevenue={7500}
                />
              </Col>
              <Col span={8}>
                <AdUnitTemplate
                  adUnit='Rewarded Video'
                  adUnitPrefix='RV'
                  mediaUrl='/videos/mediation-rewarded-video.mp4'
                  placements={rewardedVideoPlacements}
                  estimatedRevenue={8800}
                />
              </Col>
              <Col span={8}>
                <AdUnitTemplate
                  adUnit='Banner'
                  mediaUrl='/videos/mediation-banner.mp4'
                  placements={bannerPlacements}
                  estimatedRevenue={7500}
                />
              </Col>
            </Row>
          </Flex>
        </Card>
        <Flex align='center' justify='center' gap={33}>
          <LabsButton width='wide' variant='text' size='lg' onClick={handleCreateManual}>
            {t('mediation_create_ad_unit_template_modal_manual_button')}
          </LabsButton>
          <LabsButton width='wide' size='lg' onClick={handleSubmit}>
            {t('mediation_create_ad_unit_template_modal_use_template_button')}
          </LabsButton>
        </Flex>
      </Flex>
    </Modal>
  )
}
