import Cookies from 'js-cookie'
import React, { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import { COOKIE_KEY_AUTH_TOKEN } from 'constants/CookieKeys'

type Props = {
  children: ReactNode
}

const AnonymousRoute: React.FC<Props> = ({ children }) => {
  if (Cookies.get(COOKIE_KEY_AUTH_TOKEN)) {
    return <Navigate to='/' replace />
  }

  return children
}

export default AnonymousRoute
