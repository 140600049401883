import { Card, Flex, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import IconLib from 'components/commons/icon-lib'
import { labsStar } from 'components/commons/icon-lib/icons/base'

import colors from 'theme/colors'

import { cardStyle, iconStyle } from './style'
import { IMediationAdUnitInfo } from './type'

const { Text } = Typography
export default function MediationAdUnitInfo({ info, variant = 'primary' }: IMediationAdUnitInfo) {
  const { t } = useTranslation()

  return (
    <Card style={cardStyle[variant]} bodyStyle={{ padding: '12px 24px' }}>
      <ul style={{ listStyleType: 'disc' }}>
        <Flex vertical gap={4}>
          <Flex align='center' gap={6}>
            <IconLib icon={labsStar} color={iconStyle[variant]} />
            <Text style={{ color: colors.gray90, fontSize: 12, fontWeight: 700 }}>
              {t('general_info')}
            </Text>
          </Flex>
          {info.map((text) => (
            <li key={text} style={{ marginLeft: 20 }}>
              {text}
            </li>
          ))}
        </Flex>
      </ul>
    </Card>
  )
}
