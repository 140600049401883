import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

import { GETTING_STARTED_ROUTE } from 'constants/Routes'

import AuthenticatedRoute from 'components/routes/AuthenticatedRoute'

const GettingStartedPage = lazy(() => import('pages/getting-started'))

const GettingStartedRouter = (
  <Route
    path={GETTING_STARTED_ROUTE}
    element={
      <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
        <AuthenticatedRoute>
          <GettingStartedPage />
        </AuthenticatedRoute>
      </Suspense>
    }
  />
)

export default GettingStartedRouter
