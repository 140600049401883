import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  gql,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import {
  RefreshTokenGamePublisherUserMutation,
  RefreshTokenGamePublisherUserMutationVariables,
} from '__generated__/gql/graphql'
import { App } from 'antd'
// @ts-ignore
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import Cookies from 'js-cookie'
import React, { ReactNode, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// import { useNavigate } from 'react-router-dom'
import appConfig from 'config/appConfig'

import { COOKIE_KEY_AUTH_REFRESH_TOKEN, COOKIE_KEY_AUTH_TOKEN } from 'constants/CookieKeys'
import { AUTHENTICATION_ROUTE_EMAIL_VERIFICATION } from 'constants/Routes'

import useLogout from 'hooks/useLogout'
import useTracker from 'hooks/useTracker'

type Props = {
  children: ReactNode
}

const httpLink = createHttpLink({
  uri: appConfig.labsNodeJsGQLServiceUrl,
})

const authLink = setContext(async (_, { headers }) => {
  const token = Cookies.get(COOKIE_KEY_AUTH_TOKEN)

  return {
    headers: {
      ...headers,
      authorization: token || '',
    },
  }
})

const uploadLink = createUploadLink({
  uri: appConfig.labsNodeJsGQLServiceUrl,
  headers: {
    authorization: Cookies.get(COOKIE_KEY_AUTH_TOKEN),
  },
})

const unauthenticatedApolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([httpLink]),
})

const ApolloClientContextProvider: React.FC<Props> = ({ children }) => {
  const isRefreshingTokenRef = useRef<boolean>(false)
  const { message } = App.useApp()
  const { t, i18n } = useTranslation()
  const { handleLogout } = useLogout()
  const { track } = useTracker()
  const navigate = useNavigate()

  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors, networkError, operation, response, forward }) => {
        operation.operationName
        if (graphQLErrors) {
          graphQLErrors.forEach(({ extensions, message: msg }) => {
            if (extensions && extensions.code === 'AUTH_TOKEN_EXPIRED') {
              isRefreshingTokenRef.current = true

              const refreshToken = Cookies.get(COOKIE_KEY_AUTH_REFRESH_TOKEN) || ''

              return unauthenticatedApolloClient
                .mutate<
                  RefreshTokenGamePublisherUserMutation,
                  RefreshTokenGamePublisherUserMutationVariables
                >({
                  mutation: gql`
                    mutation RefreshTokenGamePublisherUser($refreshToken: String!) {
                      refreshTokenGamePublisherUserByEmailAndPassword(refreshToken: $refreshToken) {
                        accessToken
                        refreshToken
                      }
                    }
                  `,
                  variables: {
                    refreshToken,
                  },
                })
                .then(({ data }) => {
                  if (data) {
                    const result = data.refreshTokenGamePublisherUserByEmailAndPassword
                    Cookies.set(COOKIE_KEY_AUTH_TOKEN, result.accessToken)
                    Cookies.set(COOKIE_KEY_AUTH_REFRESH_TOKEN, result.refreshToken)

                    operation.setContext(({ headers = {} }) => ({
                      headers: {
                        ...headers,
                        authorization: result.accessToken,
                      },
                    }))

                    return forward(operation)
                  }
                })
                .catch(() => {
                  if (!isRefreshingTokenRef.current) {
                    message.error(t('error_refresh_token_failed'))
                    handleLogout()
                  }
                })
                .finally(() => {
                  isRefreshingTokenRef.current = false
                })
            } else if (extensions && extensions.code === 'AUTH_USER_IS_INACTIVE') {
              message.error(t('error_account_is_not_active'))
              handleLogout()
              navigate(`/${AUTHENTICATION_ROUTE_EMAIL_VERIFICATION}`)
            } else if (extensions && extensions.code === 'AUTH_UNAUTHORIZED_ERROR') {
              message.error({
                content: t('error_unauthorized'),
                key: t('error_unauthorized'),
              })
              handleLogout()
            } else if (extensions && extensions.message) {
              const extensionMessage = extensions.message as { [k in string]: any }
              const errMsg: string =
                ((extensionMessage[i18n.language] || extensionMessage['en']) as string) ||
                'Unknown error'

              message.error({
                content: errMsg,
                key: errMsg,
                duration: 6,
              })

              track(
                `${operation.operationName
                  .replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)
                  .replace(/^_/, '')}_error`,
                {
                  type: 'error',
                  error_message: errMsg,
                },
              )

              return response
            } else if (msg.includes('not provided')) {
              message.error({
                content: 'Some form values are incorrect. Please check and try again.',
                key: 'form-validation-error',
              })

              track(
                `${operation.operationName
                  .replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)
                  .replace(/^_/, '')}_error`,
                {
                  type: 'error',
                  error_message: msg as string,
                },
              )
            }
          })
        } else if (networkError) {
          message.error({
            content: t('error_network_error'),
            key: t('error_network_error'),
          })
        }
      }),
    [message],
  )

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([errorLink, authLink, uploadLink, httpLink]),
      }),
    [errorLink],
  )

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default ApolloClientContextProvider
