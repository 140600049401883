import { GameCreativeFormat } from '__generated__/gql/graphql'
import { Flex, Image, Modal, Typography } from 'antd'

import useModalStore from 'stores/useModalStore'
import useMyAdsStore from 'stores/useMyAdsStore'

const { Text } = Typography
export default function CreativePreviewModal() {
  const { isCreativePreviewModalShow, closeAll } = useModalStore()
  const { selectedCreative, setSelectedCreative } = useMyAdsStore()

  const handleClose = () => {
    setSelectedCreative(null)
    closeAll()
  }

  const fileName = selectedCreative?.mediaUrl ? selectedCreative?.mediaUrl.split('/').pop() : '-'

  if (!isCreativePreviewModalShow) return null
  return (
    <Modal
      title={<Text>{fileName}</Text>}
      centered
      open={isCreativePreviewModalShow}
      onCancel={handleClose}
      footer={false}
      width={800}>
      <Flex
        justify='center'
        style={{
          marginTop: 30,
          maxHeight: 'calc(100vh - 200px)',
          position: 'relative',
        }}>
        {selectedCreative?.mediaType === GameCreativeFormat.Image && (
          <Image
            preview={false}
            src={selectedCreative.mediaUrl}
            style={{ maxHeight: 'calc(100vh - 200px)', objectFit: 'contain' }}
          />
        )}

        {selectedCreative?.mediaType === GameCreativeFormat.Video && (
          <video
            src={selectedCreative.mediaUrl}
            autoPlay
            width='100%'
            controls
            style={{ maxHeight: 'calc(100vh - 200px)' }}
          />
        )}
      </Flex>
    </Modal>
  )
}
