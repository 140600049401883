import { Button, Flex, Modal, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { INTEGRATION_ROUTE } from 'constants/Routes'

import useModalStore from 'stores/useModalStore'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function ScaleIntegrationRequiredModal() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isScaleIntegrationRequiredModalShow, closeAll } = useModalStore()

  const handleStartIntegration = () => {
    closeAll()
    navigate(`/${INTEGRATION_ROUTE}`)
  }

  return (
    <Modal
      width={710}
      open={isScaleIntegrationRequiredModalShow}
      onCancel={() => closeAll()}
      footer={null}
      centered>
      <div style={{ padding: 30 }}>
        <Flex gap={24} vertical align='center' style={{ textAlign: 'center' }}>
          <img
            width={80}
            height={80}
            src='/images/illustration/setting.webp'
            alt='launch-welcome'
          />
          <Flex vertical>
            <Title level={4} style={{ color: colors.gray90 }}>
              {t('scale_integration_required_modal_title')}
            </Title>
            <Text style={{ color: colors.gray70 }}>
              {t('scale_integration_required_modal_description')}
            </Text>
          </Flex>
          <Button
            type='primary'
            size='large'
            onClick={handleStartIntegration}
            style={{ width: 243 }}>
            {t('scale_integration_required_modal_integration_button')}
          </Button>
        </Flex>
      </div>
    </Modal>
  )
}
