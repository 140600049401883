import { useQuery } from '@apollo/client'
import {
  GameSdkIntegrationStatus,
  GameStoreIntegrationStatus,
  GetGameStoresByGameIdQuery,
  GetGameStoresByGameIdQueryVariables,
} from '__generated__/gql/graphql'
import { useMemo } from 'react'
import { getGameStoresByGameId } from 'services/graphql/queries'

import useUserStore from 'stores/useUserStore'

export default function useGameInfo() {
  const { selectedGame, games } = useUserStore()

  // const { data: stores, loading: isLoading } = useQuery<
  //   GetGameStoresByGameIdQuery,
  //   GetGameStoresByGameIdQueryVariables
  // >(getGameStoresByGameId, {
  //   variables: { gameId: selectedGame?.id || '' },
  //   skip: !selectedGame || Object.keys(selectedGame).length === 0,
  // })

  const isOnBoarded = useMemo(() => {
    if (
      !selectedGame?.uniqueSellingPoints &&
      selectedGame?.integrationStatus === GameSdkIntegrationStatus.Inprogress
    )
      return false
    else return true
  }, [selectedGame?.uniqueSellingPoints, selectedGame?.integrationStatus])

  const isGameIntegrated = useMemo(() => {
    const isIntegrated = games.find((game) =>
      game.stores.find((store) =>
        store.integrations
          .filter(
            (integration) =>
              integration.slug === 'adjust-data-tracking' ||
              integration.slug === 'firebase-data-tracking',
          )
          .every((integration) => integration.status === GameStoreIntegrationStatus.Completed),
      ),
    )

    return !!isIntegrated
  }, [games])

  return {
    isOnBoarded,
    isGameIntegrated,
    isLoading: false,
  }
}
