import { useApolloClient, useMutation } from '@apollo/client'
import {
  CreateActivityHistoriesMutation,
  CreateActivityHistoriesMutationVariables,
} from '__generated__/gql/graphql'
import { Button, Flex, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { createActivityHistories } from 'services/graphql/mutations'

import { INTEGRATION_ROUTE } from 'constants/Routes'

import useMarketingOnboardStore from 'stores/useMarketingOnboardStore'
import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useUserInfo from 'hooks/useUserInfo'

import colors from 'theme/colors'

const { Title, Text } = Typography
export default function MarketingOnboardModalFinished() {
  const { t } = useTranslation()
  const { selectedGame } = useUserStore()
  const client = useApolloClient()
  const navigate = useNavigate()
  const { getGames } = useUserInfo()

  const { closeAll } = useModalStore()
  const { resetState } = useMarketingOnboardStore()
  const [createActivities] = useMutation<
    CreateActivityHistoriesMutation,
    CreateActivityHistoriesMutationVariables
  >(createActivityHistories)

  const handleDoThisLater = () => {
    createActivities({
      variables: {
        activityHistories: [
          {
            key: `game_id_${selectedGame.id}_onboarding_marketing-onboard`,
            value: `marketing-preparation`,
          },
        ],
      },
    }).finally(() => {
      client.refetchQueries({
        include: ['GetActivityHistories'],
      })
    })
    getGames()
    resetState()
    closeAll()
  }

  return (
    <Flex vertical justify='center' align='center' gap={24} style={{ padding: '10px 20px' }}>
      <img src='/images/illustration/sdk-download-complete.gif' width={92} height={92} />
      <Flex vertical align='center' justify='center' gap={10}>
        <Title level={5} style={{ color: colors.gray90, margin: 0, fontWeight: 'bold' }}>
          {t('marketing_onboarding_modal_finish_setup_title')}
        </Title>
        <Text style={{ color: colors.gray70, textAlign: 'center' }}>
          {t('marketing_onboarding_modal_finish_setup_description_1')} <br />{' '}
          {t('marketing_onboarding_modal_finish_setup_description_2')}
        </Text>
      </Flex>

      <Flex vertical align='center' gap={12}>
        <Button
          type='primary'
          size='large'
          style={{ width: 82, fontWeight: 500, margin: 0, padding: 0 }}
          onClick={handleDoThisLater}>
          {`${t('general_got_it')}!`}
        </Button>
      </Flex>
    </Flex>
  )
}
