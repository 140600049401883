import { useQuery } from '@apollo/client'
import {
  FindGameStoreByIdQuery,
  FindGameStoreByIdQueryVariables,
  GameStore,
  GameStoreIntegrationStatus,
  GameStoreStatus,
} from '__generated__/gql/graphql'
import { Flex, Image, Modal, Spin, Tabs, Typography } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { findGameStoreById } from 'services/graphql/queries'

import useModalStore from 'stores/useModalStore'

import { labsDelete, labsEdit } from 'components/commons/icon-lib/icons/base'
import LabsButton from 'components/commons/labs-kit/button'
import GameStoreStatusTag from 'components/modules/my-game/status-tag'
import { GameStoreTagStatus } from 'components/modules/my-game/status-tag/type'

import colors from 'theme/colors'

import GameDetailTab from './game-detail'
import GameInfoDatabase from './game-info-database'
import InspirationPreference from './inspiration-preference'
import { GameDetailModalTab } from './type'

const { Text, Title } = Typography
const { TabPane } = Tabs
export default function GameDetailModal() {
  const { t } = useTranslation()
  const {
    selectedGameStoreId,
    isGameDetailModalShow,
    showEditGameModal,
    showDeleteGameStoreConfirmationModal,
    closeAll,
  } = useModalStore()

  const [columnsTab, setColumnsTab] = useState<GameDetailModalTab>('game-detail')

  const { data, loading } = useQuery<FindGameStoreByIdQuery, FindGameStoreByIdQueryVariables>(
    findGameStoreById,
    {
      variables: { gameStoreId: selectedGameStoreId || '-' },
      skip: !selectedGameStoreId,
      fetchPolicy: 'no-cache',
    },
  )

  const gameStore = useMemo(() => {
    return data?.findGameStoreById || null
  }, [data])

  const handleEditGame = useCallback(() => {
    if (!selectedGameStoreId) return

    closeAll()
    showEditGameModal(selectedGameStoreId)
  }, [selectedGameStoreId])

  const handleDeleteGame = useCallback(() => {
    if (!selectedGameStoreId) return

    closeAll()
    showDeleteGameStoreConfirmationModal(selectedGameStoreId)
  }, [selectedGameStoreId])

  const gameStoreStatus = useMemo(() => {
    if (!gameStore) return 'NOT_INTEGRATED'
    let status: GameStoreTagStatus = 'NOT_INTEGRATED'

    if (gameStore.status === GameStoreStatus.Inactive) status = 'INACTIVE'
    else if (
      gameStore.integrations
        .filter(
          (integration) =>
            integration.slug === 'adjust-data-tracking' ||
            integration.slug === 'firebase-data-tracking',
        )
        .every((integration) => integration.status === GameStoreIntegrationStatus.Completed)
    )
      status = 'ACTIVE'
    else status = 'NOT_INTEGRATED'

    return status
  }, [gameStore])

  const ModalTitle = useMemo(() => {
    if (!gameStore) return null
    return (
      <Flex align='center' justify='space-between' style={{ paddingRight: 40 }}>
        <Flex align='center' gap={10}>
          {gameStore.game.gameLogoUrl && (
            <Image
              src={gameStore.game.gameLogoUrl}
              height={32}
              width={32}
              preview={false}
              style={{ borderRadius: 9999 }}
            />
          )}
          <Title level={3} style={{ color: colors.gray90, margin: 0 }}>
            {gameStore?.game.name}
          </Title>
          <GameStoreStatusTag status={gameStoreStatus} />
        </Flex>
        <Flex align='center' gap={6}>
          <LabsButton
            size='xs'
            variant='outline'
            color='secondary'
            icon={labsEdit}
            onClick={handleEditGame}>
            Edit
          </LabsButton>
          <LabsButton
            size='xs'
            variant='outline'
            color='secondary'
            icon={labsDelete}
            onClick={handleDeleteGame}
          />
        </Flex>
      </Flex>
    )
  }, [gameStore])

  return (
    <Modal
      title={ModalTitle}
      centered
      open={isGameDetailModalShow}
      onCancel={closeAll}
      footer={false}
      width={1100}>
      <Flex vertical gap={20} style={{ padding: '12px 20px 24px', minHeight: 500 }}>
        {!gameStore || loading ? (
          <Flex align='center' justify='center' style={{ height: '100%' }}>
            <Spin />
          </Flex>
        ) : (
          <Tabs
            id='tabs-default'
            indicatorSize={0}
            activeKey={columnsTab}
            tabBarGutter={10}
            onTabClick={(tab) => setColumnsTab(tab as GameDetailModalTab)}
            style={{ width: '100%' }}>
            <TabPane
              key='game-detail'
              tabKey='game-detail'
              tab={<Text style={{ color: colors.gray90 }}>Game Details</Text>}>
              <GameDetailTab gameStore={gameStore as GameStore} />
            </TabPane>
            <TabPane
              key='game-info-database'
              tabKey='game-info-database'
              tab={<Text style={{ color: colors.gray90 }}>Game Info Database</Text>}>
              <GameInfoDatabase gameStore={gameStore as GameStore} />
            </TabPane>
            <TabPane
              key='inspiration-preference'
              tabKey='inspiration-preference'
              tab={<Text style={{ color: colors.gray90 }}>Inspiration Preference</Text>}>
              <InspirationPreference gameStore={gameStore as GameStore} />
            </TabPane>
          </Tabs>
        )}
      </Flex>
    </Modal>
  )
}
