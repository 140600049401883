export const IMAGE_EXTENSIONS = ['image/jpeg', 'image/png', 'image/jpg']
export const VIDEO_EXTENSIONS = ['video/mp4']

export const IOS_MIN_DAILY_BUDGET = 50 // $
export const IOS_RECOMMENDED_DAILY_BUDGET = 100 // $
export const ANDROID_MIN_DAILY_BUDGET = 20 // $
export const ANDROID_RECOMMENDED_DAILY_BUDGET = 70 // $
export const RECOMMENDED_TOTAL_DAILY_BUDGET = 120 // $
export const MIN_TOTAL_DAILY_BUDGET = 70 // $
export const MIN_TOTAL_DAYS_PERFORMING_ADS = 4 // days

export const BUNDLE_NAME_PREFIX_NOCTUA_ANDROID = 'com.noctua:android.'
export const BUNDLE_NAME_PREFIX_NOCTUA_IOS = 'com.noctua:ios.'

export const PHONE_NUMBER_COUNTRY_CODE: { name: string; code: string; value: string }[] = [
  {
    name: 'Afghanistan',
    value: '+93',
    code: 'AF',
  },
  {
    name: 'Aland Islands',
    value: '+358',
    code: 'AX',
  },
  {
    name: 'Albania',
    value: '+355',
    code: 'AL',
  },
  {
    name: 'Algeria',
    value: '+213',
    code: 'DZ',
  },
  {
    name: 'AmericanSamoa',
    value: '+1684',
    code: 'AS',
  },
  {
    name: 'Andorra',
    value: '+376',
    code: 'AD',
  },
  {
    name: 'Angola',
    value: '+244',
    code: 'AO',
  },
  {
    name: 'Anguilla',
    value: '+1264',
    code: 'AI',
  },
  {
    name: 'Antarctica',
    value: '+672',
    code: 'AQ',
  },
  {
    name: 'Antigua and Barbuda',
    value: '+1268',
    code: 'AG',
  },
  {
    name: 'Argentina',
    value: '+54',
    code: 'AR',
  },
  {
    name: 'Armenia',
    value: '+374',
    code: 'AM',
  },
  {
    name: 'Aruba',
    value: '+297',
    code: 'AW',
  },
  {
    name: 'Australia',
    value: '+61',
    code: 'AU',
  },
  {
    name: 'Austria',
    value: '+43',
    code: 'AT',
  },
  {
    name: 'Azerbaijan',
    value: '+994',
    code: 'AZ',
  },
  {
    name: 'Bahamas',
    value: '+1242',
    code: 'BS',
  },
  {
    name: 'Bahrain',
    value: '+973',
    code: 'BH',
  },
  {
    name: 'Bangladesh',
    value: '+880',
    code: 'BD',
  },
  {
    name: 'Barbados',
    value: '+1246',
    code: 'BB',
  },
  {
    name: 'Belarus',
    value: '+375',
    code: 'BY',
  },
  {
    name: 'Belgium',
    value: '+32',
    code: 'BE',
  },
  {
    name: 'Belize',
    value: '+501',
    code: 'BZ',
  },
  {
    name: 'Benin',
    value: '+229',
    code: 'BJ',
  },
  {
    name: 'Bermuda',
    value: '+1441',
    code: 'BM',
  },
  {
    name: 'Bhutan',
    value: '+975',
    code: 'BT',
  },
  {
    name: 'Bolivia, Plurinational State of',
    value: '+591',
    code: 'BO',
  },
  {
    name: 'Bosnia and Herzegovina',
    value: '+387',
    code: 'BA',
  },
  {
    name: 'Botswana',
    value: '+267',
    code: 'BW',
  },
  {
    name: 'Brazil',
    value: '+55',
    code: 'BR',
  },
  {
    name: 'British Indian Ocean Territory',
    value: '+246',
    code: 'IO',
  },
  {
    name: 'Brunei Darussalam',
    value: '+673',
    code: 'BN',
  },
  {
    name: 'Bulgaria',
    value: '+359',
    code: 'BG',
  },
  {
    name: 'Burkina Faso',
    value: '+226',
    code: 'BF',
  },
  {
    name: 'Burundi',
    value: '+257',
    code: 'BI',
  },
  {
    name: 'Cambodia',
    value: '+855',
    code: 'KH',
  },
  {
    name: 'Cameroon',
    value: '+237',
    code: 'CM',
  },
  {
    name: 'Canada',
    value: '+1',
    code: 'CA',
  },
  {
    name: 'Cape Verde',
    value: '+238',
    code: 'CV',
  },
  {
    name: 'Cayman Islands',
    value: '+ 345',
    code: 'KY',
  },
  {
    name: 'Central African Republic',
    value: '+236',
    code: 'CF',
  },
  {
    name: 'Chad',
    value: '+235',
    code: 'TD',
  },
  {
    name: 'Chile',
    value: '+56',
    code: 'CL',
  },
  {
    name: 'China',
    value: '+86',
    code: 'CN',
  },
  {
    name: 'Christmas Island',
    value: '+61',
    code: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands',
    value: '+61',
    code: 'CC',
  },
  {
    name: 'Colombia',
    value: '+57',
    code: 'CO',
  },
  {
    name: 'Comoros',
    value: '+269',
    code: 'KM',
  },
  {
    name: 'Congo',
    value: '+242',
    code: 'CG',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    value: '+243',
    code: 'CD',
  },
  {
    name: 'Cook Islands',
    value: '+682',
    code: 'CK',
  },
  {
    name: 'Costa Rica',
    value: '+506',
    code: 'CR',
  },
  {
    name: "Cote d'Ivoire",
    value: '+225',
    code: 'CI',
  },
  {
    name: 'Croatia',
    value: '+385',
    code: 'HR',
  },
  {
    name: 'Cuba',
    value: '+53',
    code: 'CU',
  },
  {
    name: 'Cyprus',
    value: '+357',
    code: 'CY',
  },
  {
    name: 'Czech Republic',
    value: '+420',
    code: 'CZ',
  },
  {
    name: 'Denmark',
    value: '+45',
    code: 'DK',
  },
  {
    name: 'Djibouti',
    value: '+253',
    code: 'DJ',
  },
  {
    name: 'Dominica',
    value: '+1767',
    code: 'DM',
  },
  {
    name: 'Dominican Republic',
    value: '+1849',
    code: 'DO',
  },
  {
    name: 'Ecuador',
    value: '+593',
    code: 'EC',
  },
  {
    name: 'Egypt',
    value: '+20',
    code: 'EG',
  },
  {
    name: 'El Salvador',
    value: '+503',
    code: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    value: '+240',
    code: 'GQ',
  },
  {
    name: 'Eritrea',
    value: '+291',
    code: 'ER',
  },
  {
    name: 'Estonia',
    value: '+372',
    code: 'EE',
  },
  {
    name: 'Ethiopia',
    value: '+251',
    code: 'ET',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    value: '+500',
    code: 'FK',
  },
  {
    name: 'Faroe Islands',
    value: '+298',
    code: 'FO',
  },
  {
    name: 'Fiji',
    value: '+679',
    code: 'FJ',
  },
  {
    name: 'Finland',
    value: '+358',
    code: 'FI',
  },
  {
    name: 'France',
    value: '+33',
    code: 'FR',
  },
  {
    name: 'French Guiana',
    value: '+594',
    code: 'GF',
  },
  {
    name: 'French Polynesia',
    value: '+689',
    code: 'PF',
  },
  {
    name: 'Gabon',
    value: '+241',
    code: 'GA',
  },
  {
    name: 'Gambia',
    value: '+220',
    code: 'GM',
  },
  {
    name: 'Georgia',
    value: '+995',
    code: 'GE',
  },
  {
    name: 'Germany',
    value: '+49',
    code: 'DE',
  },
  {
    name: 'Ghana',
    value: '+233',
    code: 'GH',
  },
  {
    name: 'Gibraltar',
    value: '+350',
    code: 'GI',
  },
  {
    name: 'Greece',
    value: '+30',
    code: 'GR',
  },
  {
    name: 'Greenland',
    value: '+299',
    code: 'GL',
  },
  {
    name: 'Grenada',
    value: '+1473',
    code: 'GD',
  },
  {
    name: 'Guadeloupe',
    value: '+590',
    code: 'GP',
  },
  {
    name: 'Guam',
    value: '+1671',
    code: 'GU',
  },
  {
    name: 'Guatemala',
    value: '+502',
    code: 'GT',
  },
  {
    name: 'Guernsey',
    value: '+44',
    code: 'GG',
  },
  {
    name: 'Guinea',
    value: '+224',
    code: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    value: '+245',
    code: 'GW',
  },
  {
    name: 'Guyana',
    value: '+595',
    code: 'GY',
  },
  {
    name: 'Haiti',
    value: '+509',
    code: 'HT',
  },
  {
    name: 'Holy See (Vatican City State)',
    value: '+379',
    code: 'VA',
  },
  {
    name: 'Honduras',
    value: '+504',
    code: 'HN',
  },
  {
    name: 'Hong Kong',
    value: '+852',
    code: 'HK',
  },
  {
    name: 'Hungary',
    value: '+36',
    code: 'HU',
  },
  {
    name: 'Iceland',
    value: '+354',
    code: 'IS',
  },
  {
    name: 'India',
    value: '+91',
    code: 'IN',
  },
  {
    name: 'Indonesia',
    value: '+62',
    code: 'ID',
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    value: '+98',
    code: 'IR',
  },
  {
    name: 'Iraq',
    value: '+964',
    code: 'IQ',
  },
  {
    name: 'Ireland',
    value: '+353',
    code: 'IE',
  },
  {
    name: 'Isle of Man',
    value: '+44',
    code: 'IM',
  },
  {
    name: 'Israel',
    value: '+972',
    code: 'IL',
  },
  {
    name: 'Italy',
    value: '+39',
    code: 'IT',
  },
  {
    name: 'Jamaica',
    value: '+1876',
    code: 'JM',
  },
  {
    name: 'Japan',
    value: '+81',
    code: 'JP',
  },
  {
    name: 'Jersey',
    value: '+44',
    code: 'JE',
  },
  {
    name: 'Jordan',
    value: '+962',
    code: 'JO',
  },
  {
    name: 'Kazakhstan',
    value: '+77',
    code: 'KZ',
  },
  {
    name: 'Kenya',
    value: '+254',
    code: 'KE',
  },
  {
    name: 'Kiribati',
    value: '+686',
    code: 'KI',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    value: '+850',
    code: 'KP',
  },
  {
    name: 'Korea, Republic of South Korea',
    value: '+82',
    code: 'KR',
  },
  {
    name: 'Kuwait',
    value: '+965',
    code: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    value: '+996',
    code: 'KG',
  },
  {
    name: 'Laos',
    value: '+856',
    code: 'LA',
  },
  {
    name: 'Latvia',
    value: '+371',
    code: 'LV',
  },
  {
    name: 'Lebanon',
    value: '+961',
    code: 'LB',
  },
  {
    name: 'Lesotho',
    value: '+266',
    code: 'LS',
  },
  {
    name: 'Liberia',
    value: '+231',
    code: 'LR',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    value: '+218',
    code: 'LY',
  },
  {
    name: 'Liechtenstein',
    value: '+423',
    code: 'LI',
  },
  {
    name: 'Lithuania',
    value: '+370',
    code: 'LT',
  },
  {
    name: 'Luxembourg',
    value: '+352',
    code: 'LU',
  },
  {
    name: 'Macao',
    value: '+853',
    code: 'MO',
  },
  {
    name: 'Macedonia',
    value: '+389',
    code: 'MK',
  },
  {
    name: 'Madagascar',
    value: '+261',
    code: 'MG',
  },
  {
    name: 'Malawi',
    value: '+265',
    code: 'MW',
  },
  {
    name: 'Malaysia',
    value: '+60',
    code: 'MY',
  },
  {
    name: 'Maldives',
    value: '+960',
    code: 'MV',
  },
  {
    name: 'Mali',
    value: '+223',
    code: 'ML',
  },
  {
    name: 'Malta',
    value: '+356',
    code: 'MT',
  },
  {
    name: 'Marshall Islands',
    value: '+692',
    code: 'MH',
  },
  {
    name: 'Martinique',
    value: '+596',
    code: 'MQ',
  },
  {
    name: 'Mauritania',
    value: '+222',
    code: 'MR',
  },
  {
    name: 'Mauritius',
    value: '+230',
    code: 'MU',
  },
  {
    name: 'Mayotte',
    value: '+262',
    code: 'YT',
  },
  {
    name: 'Mexico',
    value: '+52',
    code: 'MX',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    value: '+691',
    code: 'FM',
  },
  {
    name: 'Moldova',
    value: '+373',
    code: 'MD',
  },
  {
    name: 'Monaco',
    value: '+377',
    code: 'MC',
  },
  {
    name: 'Mongolia',
    value: '+976',
    code: 'MN',
  },
  {
    name: 'Montenegro',
    value: '+382',
    code: 'ME',
  },
  {
    name: 'Montserrat',
    value: '+1664',
    code: 'MS',
  },
  {
    name: 'Morocco',
    value: '+212',
    code: 'MA',
  },
  {
    name: 'Mozambique',
    value: '+258',
    code: 'MZ',
  },
  {
    name: 'Myanmar',
    value: '+95',
    code: 'MM',
  },
  {
    name: 'Namibia',
    value: '+264',
    code: 'NA',
  },
  {
    name: 'Nauru',
    value: '+674',
    code: 'NR',
  },
  {
    name: 'Nepal',
    value: '+977',
    code: 'NP',
  },
  {
    name: 'Netherlands',
    value: '+31',
    code: 'NL',
  },
  {
    name: 'Netherlands Antilles',
    value: '+599',
    code: 'AN',
  },
  {
    name: 'New Caledonia',
    value: '+687',
    code: 'NC',
  },
  {
    name: 'New Zealand',
    value: '+64',
    code: 'NZ',
  },
  {
    name: 'Nicaragua',
    value: '+505',
    code: 'NI',
  },
  {
    name: 'Niger',
    value: '+227',
    code: 'NE',
  },
  {
    name: 'Nigeria',
    value: '+234',
    code: 'NG',
  },
  {
    name: 'Niue',
    value: '+683',
    code: 'NU',
  },
  {
    name: 'Norfolk Island',
    value: '+672',
    code: 'NF',
  },
  {
    name: 'Northern Mariana Islands',
    value: '+1670',
    code: 'MP',
  },
  {
    name: 'Norway',
    value: '+47',
    code: 'NO',
  },
  {
    name: 'Oman',
    value: '+968',
    code: 'OM',
  },
  {
    name: 'Pakistan',
    value: '+92',
    code: 'PK',
  },
  {
    name: 'Palau',
    value: '+680',
    code: 'PW',
  },
  {
    name: 'Palestinian Territory, Occupied',
    value: '+970',
    code: 'PS',
  },
  {
    name: 'Panama',
    value: '+507',
    code: 'PA',
  },
  {
    name: 'Papua New Guinea',
    value: '+675',
    code: 'PG',
  },
  {
    name: 'Paraguay',
    value: '+595',
    code: 'PY',
  },
  {
    name: 'Peru',
    value: '+51',
    code: 'PE',
  },
  {
    name: 'Philippines',
    value: '+63',
    code: 'PH',
  },
  {
    name: 'Pitcairn',
    value: '+872',
    code: 'PN',
  },
  {
    name: 'Poland',
    value: '+48',
    code: 'PL',
  },
  {
    name: 'Portugal',
    value: '+351',
    code: 'PT',
  },
  {
    name: 'Puerto Rico',
    value: '+1939',
    code: 'PR',
  },
  {
    name: 'Qatar',
    value: '+974',
    code: 'QA',
  },
  {
    name: 'Romania',
    value: '+40',
    code: 'RO',
  },
  {
    name: 'Russia',
    value: '+7',
    code: 'RU',
  },
  {
    name: 'Rwanda',
    value: '+250',
    code: 'RW',
  },
  {
    name: 'Reunion',
    value: '+262',
    code: 'RE',
  },
  {
    name: 'Saint Barthelemy',
    value: '+590',
    code: 'BL',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    value: '+290',
    code: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis',
    value: '+1869',
    code: 'KN',
  },
  {
    name: 'Saint Lucia',
    value: '+1758',
    code: 'LC',
  },
  {
    name: 'Saint Martin',
    value: '+590',
    code: 'MF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    value: '+508',
    code: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    value: '+1784',
    code: 'VC',
  },
  {
    name: 'Samoa',
    value: '+685',
    code: 'WS',
  },
  {
    name: 'San Marino',
    value: '+378',
    code: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    value: '+239',
    code: 'ST',
  },
  {
    name: 'Saudi Arabia',
    value: '+966',
    code: 'SA',
  },
  {
    name: 'Senegal',
    value: '+221',
    code: 'SN',
  },
  {
    name: 'Serbia',
    value: '+381',
    code: 'RS',
  },
  {
    name: 'Seychelles',
    value: '+248',
    code: 'SC',
  },
  {
    name: 'Sierra Leone',
    value: '+232',
    code: 'SL',
  },
  {
    name: 'Singapore',
    value: '+65',
    code: 'SG',
  },
  {
    name: 'Slovakia',
    value: '+421',
    code: 'SK',
  },
  {
    name: 'Slovenia',
    value: '+386',
    code: 'SI',
  },
  {
    name: 'Solomon Islands',
    value: '+677',
    code: 'SB',
  },
  {
    name: 'Somalia',
    value: '+252',
    code: 'SO',
  },
  {
    name: 'South Africa',
    value: '+27',
    code: 'ZA',
  },
  {
    name: 'South Sudan',
    value: '+211',
    code: 'SS',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    value: '+500',
    code: 'GS',
  },
  {
    name: 'Spain',
    value: '+34',
    code: 'ES',
  },
  {
    name: 'Sri Lanka',
    value: '+94',
    code: 'LK',
  },
  {
    name: 'Sudan',
    value: '+249',
    code: 'SD',
  },
  {
    name: 'Suriname',
    value: '+597',
    code: 'SR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    value: '+47',
    code: 'SJ',
  },
  {
    name: 'Swaziland',
    value: '+268',
    code: 'SZ',
  },
  {
    name: 'Sweden',
    value: '+46',
    code: 'SE',
  },
  {
    name: 'Switzerland',
    value: '+41',
    code: 'CH',
  },
  {
    name: 'Syrian Arab Republic',
    value: '+963',
    code: 'SY',
  },
  {
    name: 'Taiwan',
    value: '+886',
    code: 'TW',
  },
  {
    name: 'Tajikistan',
    value: '+992',
    code: 'TJ',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    value: '+255',
    code: 'TZ',
  },
  {
    name: 'Thailand',
    value: '+66',
    code: 'TH',
  },
  {
    name: 'Timor-Leste',
    value: '+670',
    code: 'TL',
  },
  {
    name: 'Togo',
    value: '+228',
    code: 'TG',
  },
  {
    name: 'Tokelau',
    value: '+690',
    code: 'TK',
  },
  {
    name: 'Tonga',
    value: '+676',
    code: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    value: '+1868',
    code: 'TT',
  },
  {
    name: 'Tunisia',
    value: '+216',
    code: 'TN',
  },
  {
    name: 'Turkey',
    value: '+90',
    code: 'TR',
  },
  {
    name: 'Turkmenistan',
    value: '+993',
    code: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    value: '+1649',
    code: 'TC',
  },
  {
    name: 'Tuvalu',
    value: '+688',
    code: 'TV',
  },
  {
    name: 'Uganda',
    value: '+256',
    code: 'UG',
  },
  {
    name: 'Ukraine',
    value: '+380',
    code: 'UA',
  },
  {
    name: 'United Arab Emirates',
    value: '+971',
    code: 'AE',
  },
  {
    name: 'United Kingdom',
    value: '+44',
    code: 'GB',
  },
  {
    name: 'United States',
    value: '+1',
    code: 'US',
  },
  {
    name: 'Uruguay',
    value: '+598',
    code: 'UY',
  },
  {
    name: 'Uzbekistan',
    value: '+998',
    code: 'UZ',
  },
  {
    name: 'Vanuatu',
    value: '+678',
    code: 'VU',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    value: '+58',
    code: 'VE',
  },
  {
    name: 'Vietnam',
    value: '+84',
    code: 'VN',
  },
  {
    name: 'Virgin Islands, British',
    value: '+1284',
    code: 'VG',
  },
  {
    name: 'Virgin Islands, U.S.',
    value: '+1340',
    code: 'VI',
  },
  {
    name: 'Wallis and Futuna',
    value: '+681',
    code: 'WF',
  },
  {
    name: 'Yemen',
    value: '+967',
    code: 'YE',
  },
  {
    name: 'Zambia',
    value: '+260',
    code: 'ZM',
  },
  {
    name: 'Zimbabwe',
    value: '+263',
    code: 'ZW',
  },
]

export const GAME_ENGINE_OPTIONS = [
  {
    label: 'Unity',
    value: 'Unity',
  },
  {
    label: 'Unreal',
    value: 'Unreal',
  },
  {
    label: 'Cocos',
    value: 'Cocos',
  },
  {
    label: 'Native iOS',
    value: 'Native iOS',
  },
  {
    label: 'Native Android',
    value: 'Native Android',
  },
  {
    label: 'Other',
    value: 'Other',
  },
]

export const PRODUCT_OF_INTEREST_OPTIONS = [
  {
    label: 'User Acquisition',
    value: 'User Acquisition',
  },
  {
    label: 'Automated Mediation',
    value: 'Automated Mediation',
  },
  {
    label: 'Business Intelligence',
    value: 'Business Intelligence',
  },
  {
    label: 'Advance Analytics',
    value: 'Advance Analytics',
  },
  {
    label: 'Game Infrastructure',
    value: 'Game Infrastructure',
  },
  {
    label: 'Financing',
    value: 'Financing',
  },
]

export const PLANNING_TO_INTEGRATE_OPTIONS = [
  {
    label: 'As soon as possible',
    value: 'As soon as possible',
  },
  {
    label: 'In one month',
    value: 'In one month',
  },
  {
    label: 'In 3 months',
    value: 'In 3 months',
  },
  {
    label: 'Still exploring',
    value: 'Still exploring',
  },
]
