import { Col, Row } from 'antd'

import GameDetailInfoItem from '../info-item'
import { GameInfoData } from '../type'

export default function GameInfoDatabase({ gameStore }: GameInfoData) {
  return (
    <Row style={{ padding: '24px 0' }} gutter={20}>
      <Col span={12}>
        <Row gutter={[12, 12]}>
          <GameDetailInfoItem label='Bundle ID' value={gameStore?.bundleId as string} showCopy />
          <GameDetailInfoItem
            label='FB App ID'
            value={gameStore?.facebookAppId as string}
            showCopy
          />
          <GameDetailInfoItem
            label='FB App Secret'
            value={gameStore?.facebookAppSecret as string}
            showCopy
          />
          <GameDetailInfoItem
            label='FB Client Token'
            value={gameStore?.facebookClientToken as string}
            showCopy
          />
          <GameDetailInfoItem
            label='Adjust App Token'
            value={gameStore?.adjustAppToken as string}
            showCopy
          />
          <GameDetailInfoItem
            label='Adjust App Secret'
            value={gameStore?.adjustSecretKey as string}
            showCopy
          />
          <GameDetailInfoItem
            label='Mediation App ID'
            value={(gameStore?.mediation.length && gameStore.mediation[0].appId) || ''}
            showCopy
          />
        </Row>
      </Col>
      <Col span={12}>
        <Row gutter={[12, 12]}>
          <GameDetailInfoItem
            label='Firebase App ID'
            value={gameStore?.firebaseAppId as string}
            showCopy
          />
          <GameDetailInfoItem
            label='Firebase Property ID'
            value={gameStore?.firebasePropertyId as string}
            showCopy
          />
          <GameDetailInfoItem
            label='SDK Open ID'
            value={gameStore?.qsdkOpenId as string}
            showCopy
          />
          <GameDetailInfoItem
            label='SDK Open Key'
            value={gameStore?.qsdkOpenKey as string}
            showCopy
          />
          <GameDetailInfoItem
            label='SDK Product Code'
            value={gameStore?.qsdkProductCode as string}
            showCopy
          />
          <GameDetailInfoItem
            label='SDK Callback Key'
            value={gameStore?.qsdkCallbackKey as string}
            showCopy
          />
        </Row>
      </Col>
    </Row>
  )
}
