import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { CompanySize } from 'types/form'

interface BasicInformation {
  userName: string
  companyName: string
  companySize: CompanySize | ''
}

interface GameInformation {
  gameLogo: string
  gameTitle: string
  gameGenre: string
  gameTags: string[]
  gameRegions: string[]
  gameBlacklistedRegions: string[]
  gameLanguages: string[]
  gamePlayStoreUrl: string
  gameAppStoreUrl: string
  gameHuaweiStoreUrl: string
  gameUniqueSellingPoint: string
  publishingArea: boolean
}

interface CompleteRegistrationFormState {
  basicInformation: BasicInformation
  gameInformation: GameInformation

  setBasicInformation: (info: BasicInformation) => void
  setGameInformation: (info: GameInformation) => void
  resetState: () => void
}

const useCompleteRegistrationFormStore = create<CompleteRegistrationFormState>()(
  persist(
    (set) => ({
      basicInformation: {
        userName: '',
        companyName: '',
        companySize: '',
      },
      gameInformation: {
        gameLogo: '',
        gameTitle: '',
        gameGenre: '',
        gameTags: [],
        gameRegions: [],
        gameBlacklistedRegions: [],
        gameLanguages: [],
        gamePlayStoreUrl: '',
        gameAppStoreUrl: '',
        gameHuaweiStoreUrl: '',
        gameUniqueSellingPoint: '',
        publishingArea: false,
      },

      setBasicInformation: (info: BasicInformation) =>
        set((state) => ({ ...state, basicInformation: info })),
      setGameInformation: (info: GameInformation) =>
        set((state) => ({ ...state, gameInformation: info })),
      resetState: () =>
        set(() => ({
          basicInformation: {
            userName: '',
            companyName: '',
            companySize: '',
          },
          gameInformation: {
            gameLogo: '',
            gameTitle: '',
            gameGenre: '',
            gameTags: [],
            gameRegions: [],
            gameBlacklistedRegions: [],
            gameLanguages: [],
            gamePlayStoreUrl: '',
            gameAppStoreUrl: '',
            gameHuaweiStoreUrl: '',
            gameUniqueSellingPoint: '',
            publishingArea: false,
          },
        })),
    }),
    {
      name: 'game-information-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useCompleteRegistrationFormStore
