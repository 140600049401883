import { Dropdown, Flex, MenuProps, Typography } from 'antd'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import useTracker from 'hooks/useTracker'

import { labsNotificationOutline } from 'components/commons/icon-lib/icons/base'
import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

import NotificationNoData from './no-data'

const { Title, Text } = Typography

export default function HeaderNotification() {
  const isNotificationEnable = useFeatureFlagEnabled('enable-notification')
  const { track } = useTracker()
  const { t } = useTranslation()

  const items: MenuProps['items'] = [
    {
      label: (
        <Flex gap={24} style={{ padding: 6 }}>
          <div
            style={{
              height: 58,
              width: 58,
              borderRadius: 8,
              backgroundColor: colors.labs10,
            }}
          />
          <Flex vertical gap={6} style={{ flex: 1 }}>
            <Text style={{ fontWeight: 500, color: colors.gray90 }}>Rejected Ads</Text>
            <Text style={{ fontSize: 12, color: colors.gray80 }}>
              Your Labs Scale Ads has been rejected. Let&apos;s reupload your creative again to
              continue publish ads.
            </Text>
            <Text style={{ fontSize: 10, fontWeight: 500, color: colors.gray60 }}>21-02-2024</Text>
          </Flex>
        </Flex>
      ),
      key: '1',
    },
    {
      label: (
        <Flex gap={24} style={{ padding: 6 }}>
          <div
            style={{
              height: 58,
              width: 58,
              borderRadius: 8,
              backgroundColor: colors.labs10,
            }}
          />
          <Flex vertical gap={6} style={{ flex: 1 }}>
            <Text style={{ fontWeight: 500, color: colors.gray90 }}>Rejected Ads</Text>
            <Text style={{ fontSize: 12, color: colors.gray80 }}>
              Your Labs Scale Ads has been rejected. Let&apos;s reupload your creative again to
              continue publish ads.
            </Text>
            <Text style={{ fontSize: 10, fontWeight: 500, color: colors.gray60 }}>21-02-2024</Text>
          </Flex>
        </Flex>
      ),
      key: '2',
    },
    {
      label: (
        <Flex gap={24} style={{ padding: 6 }}>
          <div
            style={{
              height: 58,
              width: 58,
              borderRadius: 8,
              backgroundColor: colors.labs10,
            }}
          />
          <Flex vertical gap={6} style={{ flex: 1 }}>
            <Text style={{ fontWeight: 500, color: colors.gray90 }}>Rejected Ads</Text>
            <Text style={{ fontSize: 12, color: colors.gray80 }}>
              Your Labs Scale Ads has been rejected. Let&apos;s reupload your creative again to
              continue publish ads.
            </Text>
            <Text style={{ fontSize: 10, fontWeight: 500, color: colors.gray60 }}>21-02-2024</Text>
          </Flex>
        </Flex>
      ),
      key: '3',
    },
  ]

  const onOpened = (isOpened: boolean) => {
    if (isOpened) {
      track('show_notification', {
        type: 'click',
        location: 'header',
        location_type: 'button',
      })
    }
  }

  if (!isNotificationEnable) return null
  return (
    <Dropdown
      menu={{ items }}
      dropdownRender={() => (
        <div
          style={{
            backgroundColor: colors.white,
            width: 575,
            border: '1px solid',
            borderColor: colors.gray30,
            padding: 24,
            borderRadius: 10,
          }}>
          <Flex align='center' justify='space-between' style={{ marginBottom: 12 }}>
            <Title level={5} style={{ margin: 0, color: colors.gray90 }}>
              {t('notification_notification_title')}
            </Title>
            {/* <Text style={{ fontSize: 12, color: colors.labs120, fontWeight: 700 }}> */}
            <Text style={{ fontSize: 12, color: colors.gray60, fontWeight: 700 }}>
              {t('notification_mark_all_as_completed_button')}
            </Text>
          </Flex>
          <NotificationNoData />
          {/* {React.cloneElement(menu as React.ReactElement, { style: menuStyle })} */}
        </div>
      )}
      trigger={['click']}
      onOpenChange={onOpened}
      placement='topRight'
      overlayStyle={{ marginTop: 10 }}>
      <LabsButton
        icon={labsNotificationOutline}
        color='secondary'
        variant='outline'
        size='xs'></LabsButton>
    </Dropdown>
  )
}
