import { Flex, Modal, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import useModalStore from 'stores/useModalStore'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function TopupPendingModal() {
  const { t } = useTranslation()
  const { isTopupPendingModalShow, closeAll } = useModalStore()

  return (
    <Modal
      title={false}
      centered
      open={isTopupPendingModalShow}
      onCancel={closeAll}
      footer={false}
      width={663}>
      <Flex vertical justify='center' align='center' gap={20} style={{ padding: 30 }}>
        <img src='/images/illustration/topup-pending.webp' width={100} height={100} />
        <Space direction='vertical' align='center' size={10} style={{ textAlign: 'center' }}>
          <Title level={4} style={{ color: colors.navy80, margin: 0, fontWeight: 500 }}>
            {t('scale_creatives_topup_pending_modal_title')}
          </Title>
          <Text style={{ color: colors.navy70 }}>
            {t('scale_creatives_topup_pending_modal_subtitle')}
          </Text>
        </Space>
      </Flex>
    </Modal>
  )
}
