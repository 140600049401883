import { GamePublisherUserStatus } from '__generated__/gql/graphql'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface UserInformation {
  id: string
  uid: string
  email: string
  displayName: string
  phone?: string
  balance: number
  status: GamePublisherUserStatus
  isDemo: boolean
  companyName: string
  companySize: string
}

interface WaitListStore {
  user: UserInformation

  setUser: (user: UserInformation) => void
  resetState: () => void
}

const useWaitListStore = create<WaitListStore>()(
  persist(
    (set) => ({
      user: {} as UserInformation,

      setUser: (user: UserInformation) => set((state) => ({ ...state, user: user })),
      resetState: () =>
        set(() => ({
          user: {} as UserInformation,
        })),
    }),
    {
      name: 'wait-list-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useWaitListStore
