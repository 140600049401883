import { CSSProperties } from 'react'

import { ButtonSize } from './type'

export const buttonBaseStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  borderWidth: 1,
  borderStyle: 'solid',
}

export const buttonSizeStyle: Record<ButtonSize, CSSProperties> = {
  lg: { lineHeight: '22px', fontSize: 14, padding: '12px 24px', borderRadius: 6 },
  base: { lineHeight: '22px', fontSize: 14, padding: '8px 16px', borderRadius: 6 },
  sm: { lineHeight: '18px', fontSize: 12, padding: '6px 12px', borderRadius: 6 },
  xs: { lineHeight: '18px', fontSize: 12, padding: '6px 8px', borderRadius: 6 },
  '2xs': { lineHeight: '14px', fontSize: 10, padding: '2px 6px', borderRadius: 4 },
  compact: { lineHeight: '18px', fontSize: 12, padding: '6px 0', borderRadius: 6 },
}

export const fontSizeStyle: Record<ButtonSize, number> = {
  lg: 14,
  base: 14,
  sm: 12,
  xs: 12,
  '2xs': 10,
  compact: 12,
}
