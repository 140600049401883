import { CloseOutlined } from '@ant-design/icons'
import { Flex, Tag } from 'antd'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'

export const selectTagRender = (props: CustomTagProps, isNew?: boolean) => {
  const { label, closable, onClose } = props
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      closeIcon={<CloseOutlined style={{ color: isNew ? '#8EBEFF' : '#EBF5FF' }} />}
      color={isNew ? '#EBF5FF' : '#8EBEFF'}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        margin: '3px 5px',
        borderRadius: 20,
        fontSize: 14,
        padding: '3px 10px 3px 5px',
        display: 'flex',
        alignItems: 'center',
        color: isNew ? '#6C9DE0' : '#FFFFFF',
      }}>
      <div style={{ marginLeft: 2, marginRight: 10 }}>{label}</div>
    </Tag>
  )
}
