import { useMutation } from '@apollo/client'
import {
  RequestBalanceLimitedOfferMutation,
  RequestBalanceLimitedOfferMutationVariables,
} from '__generated__/gql/graphql'
import { Flex, message, Typography } from 'antd'
import { useCallback, useState } from 'react'
import { requestBalanceLimitedOffer } from 'services/graphql/mutations'

import useModalStore from 'stores/useModalStore'

import colors from 'theme/colors'

import IconLib from '../icon-lib'
import { labsDiscord, labsDiscount } from '../icon-lib/icons/base'
import { labsArrowRight } from '../icon-lib/icons/base/build/labs-arrow-right.icon'
import LabsButton from '../labs-kit/button/index'

const { Text } = Typography
export default function BalanceLimitedOffer() {
  const { showBalanceLimitedOfferModal, closeAll } = useModalStore()
  const handleClick = useCallback(() => {
    closeAll()
    showBalanceLimitedOfferModal()
  }, [showBalanceLimitedOfferModal, closeAll])
  return (
    <Flex vertical>
      <Flex
        style={{
          background: colors.success50,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          padding: '0px 0px 0px 10px',
        }}>
        <Text
          style={{
            fontWeight: 'bold',
            color: '#FFFFFF',
          }}>
          LIMITED OFFER!
        </Text>
      </Flex>
      <Flex
        align='center'
        justify='space-between'
        style={{
          background: colors.success10,
          padding: '10px 15px 10px 10px',
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}>
        <Flex align='center' gap={5}>
          <IconLib icon={labsDiscount} size='xl' color='success30' />
          <Text style={{ color: colors.success70 }}>
            <span style={{ fontWeight: 'bold' }}>FREE $200 credit</span> for your first ad
            campaign—publish now for free!
          </Text>
        </Flex>
        <LabsButton variant='text' icon={labsArrowRight} iconPosition='end' onClick={handleClick}>
          Apply Now
        </LabsButton>
      </Flex>
    </Flex>
  )
}
