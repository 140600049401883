import Cookies from 'js-cookie'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { COOKIE_KEY_AUTH_REFRESH_TOKEN, COOKIE_KEY_AUTH_TOKEN } from 'constants/CookieKeys'
import { AUTHENTICATION_ROUTE } from 'constants/Routes'

import useCompleteRegistrationFormStore from 'stores/useCompleteRegistrationStore'
import useCreateAdsStore from 'stores/useCreateAdsStore'
import useCreativesStore from 'stores/useCreativesStore'
import useDashboardAnalyticsStore from 'stores/useDashboardAnalyticsStore'
import useDashboardProgressionStore from 'stores/useDashboardProgressionStore'
import useGameManagementStore from 'stores/useGameManagementStore'
import useInspirationStore from 'stores/useInspirationStore'
import useMappedCountryStore from 'stores/useMappedCountryStore'
import useMediationStore from 'stores/useMediationStore'
import useMenuStore from 'stores/useMenuStore'
import useModalStore from 'stores/useModalStore'
import useMyAdsStore from 'stores/useMyAdsStore'
import useUserStore from 'stores/useUserStore'
import useWaitListStore from 'stores/useWaitListStore'

import useTracker from './useTracker'

export default function useLogout() {
  const navigate = useNavigate()
  const { track, reset } = useTracker()

  const { resetState: resetUserState } = useUserStore()
  const { resetState: resetUploadCreativeState } = useCreateAdsStore()
  const { resetState: resetGameInformationState } = useCompleteRegistrationFormStore()
  const { closeAll: closeAllModal } = useModalStore()
  const { resetState: resetMainStoreState } = useCompleteRegistrationFormStore()
  const { resetState: resetCreativeState } = useCreativesStore()
  const { resetState: resetInspirationState } = useInspirationStore()
  const { resetState: resetMyAdsState } = useMyAdsStore()
  const { resetState: resetMenuState } = useMenuStore()
  const { resetState: resetDashboardAnalyticsState } = useDashboardAnalyticsStore()
  const { resetState: resetWaitListState } = useWaitListStore()
  const { resetState: resetMediationState } = useMediationStore()
  const { resetState: resetCountryMappedState } = useMappedCountryStore()
  const { resetState: resetProgressionState } = useDashboardProgressionStore()
  const { resetState: resetGameManagementState } = useGameManagementStore()

  const handleLogout = useCallback(() => {
    track('logout', {
      type: 'click',
      location_type: 'button',
    })

    navigate(`/${AUTHENTICATION_ROUTE}`)
    Cookies.remove(COOKIE_KEY_AUTH_TOKEN)
    Cookies.remove(COOKIE_KEY_AUTH_REFRESH_TOKEN)
    resetUserState()
    resetUploadCreativeState()
    resetGameInformationState()
    closeAllModal()
    resetMainStoreState()
    resetCreativeState()
    resetInspirationState()
    resetMyAdsState()
    resetMenuState()
    resetDashboardAnalyticsState()
    resetWaitListState()
    resetMediationState()
    resetCountryMappedState()
    resetProgressionState()
    resetGameManagementState()

    reset()
  }, [
    track,
    resetUserState,
    resetUploadCreativeState,
    resetGameInformationState,
    closeAllModal,
    resetMainStoreState,
    resetCreativeState,
    resetInspirationState,
    resetMyAdsState,
    resetMenuState,
    resetDashboardAnalyticsState,
    resetWaitListState,
    resetMediationState,
    resetCountryMappedState,
    resetGameManagementState,
    reset,
  ])

  return { handleLogout }
}
