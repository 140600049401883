import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

import {
  DAILY_MARKETING_MARKETING_ANALYTICS_ROUTE,
  MARKETING_ANALYTICS_ROUTE,
  RETENTION_MARKETING_ANALYTICS_ROUTE,
} from 'constants/Routes'

import AuthenticatedRoute from 'components/routes/AuthenticatedRoute'
import PermissionGuardedRoute from 'components/routes/PermissionGuardedRoute'

import { GamePublisherUserPermissionEnum } from 'types/permission'

const DailyMarketingMarketingAnalyticsPage = lazy(
  () => import('pages/marketing-analytics/daily-marketing'),
)

const RetentionMarketingAnalyticsPage = lazy(() => import('pages/marketing-analytics/retention'))

const MarketingAnalyticsRouter = (
  <Route path={MARKETING_ANALYTICS_ROUTE}>
    <Route
      path={DAILY_MARKETING_MARKETING_ANALYTICS_ROUTE.replace(`${MARKETING_ANALYTICS_ROUTE}/`, '')}
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <PermissionGuardedRoute
              permission={GamePublisherUserPermissionEnum.VIEW_PAGE_MARKETING_ANALYTICS}>
              <DailyMarketingMarketingAnalyticsPage />
            </PermissionGuardedRoute>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
    <Route
      path={RETENTION_MARKETING_ANALYTICS_ROUTE.replace(`${MARKETING_ANALYTICS_ROUTE}/`, '')}
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <PermissionGuardedRoute
              permission={GamePublisherUserPermissionEnum.VIEW_PAGE_MARKETING_ANALYTICS}>
              <RetentionMarketingAnalyticsPage />
            </PermissionGuardedRoute>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
  </Route>
)

export default MarketingAnalyticsRouter
