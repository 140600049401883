import { GameStoreIntegrationStatus } from '__generated__/gql/graphql'
import { Flex, message, Modal, Typography } from 'antd'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { INTEGRATION_ROUTE } from 'constants/Routes'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useIntegration from 'hooks/useIntegration'

import LabsButton from 'components/commons/labs-kit/button'
import SlideToConfirm from 'components/commons/slide-to-confirm'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function MediationIntegrationCompletionConfirmationModal() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { updateStoreIntegrationStatus } = useIntegration()
  const { selectedGameStore } = useUserStore()
  const {
    isMediationIntegrationCompletionConfirmationModalShow,
    showMediationIntegratedModal,
    closeAll,
  } = useModalStore()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleGoToIntegration = () => {
    closeAll()
    navigate(`/${INTEGRATION_ROUTE}`)
  }

  const handleConfirm = useCallback(() => {
    if (!selectedGameStore) return

    setIsLoading(true)
    updateStoreIntegrationStatus({
      admob: GameStoreIntegrationStatus.Completed,
    }).then(() => {
      message.success('Integration completed successfully')
      closeAll()
      showMediationIntegratedModal()
    })
  }, [selectedGameStore])

  return (
    <Modal
      open={isMediationIntegrationCompletionConfirmationModalShow}
      title={false}
      centered
      footer={false}
      closable={!isLoading}
      onCancel={closeAll}
      width={623}>
      <Flex
        vertical
        align='center'
        justify='space-between'
        gap={32}
        style={{ padding: '20px 16px' }}>
        <Flex vertical align='center' gap={12}>
          <Title level={4} style={{ margin: 0, color: colors.gray90 }}>
            {t('mediation_integration_completion_modal_title')}
          </Title>
          <Flex vertical>
            <Text style={{ color: colors.gray90, textAlign: 'center' }}>
              {t('modal_integration_completion_first_description')}
            </Text>
            <Text style={{ color: colors.gray90, textAlign: 'center' }}>
              {t('modal_integration_completion_second_description')}
            </Text>
          </Flex>
          <Flex gap={3}>
            <Text style={{ color: colors.gray90, textAlign: 'center' }}>
              {t('mediation_integration_completion_modal_integrate_later')}
            </Text>
            <LabsButton variant='text' onClick={handleGoToIntegration}>
              Integration
            </LabsButton>
          </Flex>
        </Flex>
        <Flex justify='center' style={{ width: 420 }}>
          <SlideToConfirm
            label={t('modal_integration_completion_button')}
            isLoading={isLoading}
            handleConfirm={handleConfirm}
          />
        </Flex>
      </Flex>
    </Modal>
  )
}
