import { useApolloClient, useMutation } from '@apollo/client'
import {
  DeleteGameStoreMutation,
  DeleteGameStoreMutationVariables,
} from '__generated__/gql/graphql'
import { Flex, message, Modal, Typography } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { deleteGameStore } from 'services/graphql/mutations'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useUserInfo from 'hooks/useUserInfo'

import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function DeleteGameStoreConfirmationModal() {
  const { t } = useTranslation()
  const client = useApolloClient()

  const { getGames } = useUserInfo()
  const { selectedGameStoreId, isDeleteGameStoreConfirmationModalShow, closeAll } = useModalStore()
  const { setSelectedGameStore } = useUserStore()

  const [deleteAdUnit, { loading }] = useMutation<
    DeleteGameStoreMutation,
    DeleteGameStoreMutationVariables
  >(deleteGameStore)

  const handleDelete = useCallback(() => {
    if (!selectedGameStoreId) return

    deleteAdUnit({
      variables: {
        gameStoreId: selectedGameStoreId,
      },
    }).then(() => {
      setSelectedGameStore(undefined)
      message.success('Game Store has been deleted successfully')
      client.refetchQueries({
        include: ['GetGamesByPublisherId', 'GetGamePublisherUser'],
      })
      closeAll()
      getGames()
    })
  }, [selectedGameStoreId])

  if (!isDeleteGameStoreConfirmationModalShow || !selectedGameStoreId) return null
  return (
    <Modal
      centered
      width={520}
      open={isDeleteGameStoreConfirmationModalShow}
      onCancel={closeAll}
      footer={false}>
      <Flex vertical align='center' gap={16} style={{ padding: '0px 32px' }}>
        <Title level={5} style={{ margin: 0, color: colors.gray90 }}>
          Are you sure want to delete this app?
        </Title>
        <Text style={{ color: colors.gray70, textAlign: 'center' }}>
          Are you sure you want to permanently delete this app? This action cannot be undone. All
          data associated with this app will be permanently removed and cannot be recovered.
        </Text>
        <Flex justify='center' gap={20} style={{ marginTop: 20 }}>
          <LabsButton width='wide' variant='outline' onClick={closeAll}>
            Cancel
          </LabsButton>
          <LabsButton width='wide' color='danger' onClick={handleDelete} isLoading={loading}>
            Permanently Delete
          </LabsButton>
        </Flex>
      </Flex>
    </Modal>
  )
}
