import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  CreateActivityHistoriesMutation,
  CreateActivityHistoriesMutationVariables,
  GetGameByIdQuery,
  GetGameByIdQueryVariables,
  SetGameSimilarGamesMutation,
  UpdateGameInfoMutation,
  UpdateGameInfoMutationVariables,
  UpdateGameUniqueSellingPointsMutation,
  UpdateGameUniqueSellingPointsMutationVariables,
} from '__generated__/gql/graphql'
import { Button, Col, Flex, message } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createActivityHistories,
  setGameSimilarGame,
  updateGameInfo,
  updateGameUniqueSellingPointsMutations,
} from 'services/graphql/mutations'
import { getGameById } from 'services/graphql/queries'
import { validate as isUUID } from 'uuid'

import useMarketingOnboardStore from 'stores/useMarketingOnboardStore'
import useUserStore from 'stores/useUserStore'

import useUserInfo from 'hooks/useUserInfo'

import colors from 'theme/colors'

import { SetGameSimilarGamesMutationVariables } from '../../../../__generated__/gql/graphql'
import MarketingOnboardModalWizardFormGameOfInterest from './game-of-interest'
import MarketingOnboardModalWizardFormGameRegion from './game-region'
import { ActiveForm } from './type'
import MarketingOnboardModalWizardFormUniqueSellingPoint from './unique-selling-point'

export default function ScaleSdkIntegrationModalWizardForm() {
  const { t } = useTranslation()
  const client = useApolloClient()
  const { getGames } = useUserInfo()
  const { selectedGame } = useUserStore()
  const {
    storeUrl,
    uniqueSellingPoint,
    setStep,
    setStoreUrl,
    setUniqueSellingPoint,
    gameOfInterest,
    setGameOfInterest,
    gameRegion,
    setGameRegion,
  } = useMarketingOnboardStore()

  const [activeForm, setActiveForm] = useState<ActiveForm>()
  const steps = ['gameRegion', 'uniqueSellingPoint', 'gameOfInterest']
  const [stepIdx, setStepIdx] = useState<number>(steps.findIndex((step) => step === activeForm))

  const [setUniqueSellingPoints, { loading: isUniqueSellingPointsLoading }] = useMutation<
    UpdateGameUniqueSellingPointsMutation,
    UpdateGameUniqueSellingPointsMutationVariables
  >(updateGameUniqueSellingPointsMutations)

  const [setSimilarGame, { loading: isSetSimilarGameLoading }] = useMutation<
    SetGameSimilarGamesMutation,
    SetGameSimilarGamesMutationVariables
  >(setGameSimilarGame)

  const [createActivities] = useMutation<
    CreateActivityHistoriesMutation,
    CreateActivityHistoriesMutationVariables
  >(createActivityHistories)

  const [setTargetRegion, { loading: isSetTargetRegionLoading }] = useMutation<
    UpdateGameInfoMutation,
    UpdateGameInfoMutationVariables
  >(updateGameInfo)

  const handleGameRegion = useCallback(() => {
    if (!gameRegion.length) {
      message.error(`Please select target region`)
      return
    }

    setTargetRegion({
      variables: {
        id: selectedGame.id,
        targetRegion: gameRegion,
      },
    })
      .then((res) => {
        if (res.errors) {
          console.error(res.errors)
          message.error(`Failed to update target region right now`)
          return
        }

        getGames()
        setActiveForm('uniqueSellingPoint')
      })
      .catch((err) => {
        console.error(err)
        message.error(`Failed to update target region right now`)
      })
  }, [gameRegion])

  const handleSetUniqueSellingPoints = useCallback(() => {
    if (
      !uniqueSellingPoint.gameplay ||
      !uniqueSellingPoint.socialInteraction ||
      !uniqueSellingPoint.monetizationAndEngagement
    ) {
      message.error('Each section options must be selected')
      return
    }

    const payload = [
      uniqueSellingPoint.gameplay,
      uniqueSellingPoint.socialInteraction,
      uniqueSellingPoint.monetizationAndEngagement,
    ]

    if (uniqueSellingPoint.otherInsights) payload.push(uniqueSellingPoint.otherInsights)
    setUniqueSellingPoints({
      variables: {
        gameId: selectedGame.id,
        uniqueSellingPoints: payload.join('; '),
      },
    }).then(() => setActiveForm('gameOfInterest'))
  }, [uniqueSellingPoint, selectedGame, setUniqueSellingPoints])

  const handleSetGameOfInterest = useCallback(() => {
    if (gameOfInterest.length === 0) {
      message.error(t('marketing_onboarding_modal_my_games_game_inspiration_warning_message'))
      return
    }

    const similarGameIDs = gameOfInterest.filter((id) => isUUID(id))
    const similarGameSubmissions = gameOfInterest.filter((id) => !isUUID(id))

    setSimilarGame({
      variables: {
        id: selectedGame.id,
        similarGameSubmissions,
        similarGameIDs,
      },
    }).then(() => {
      createActivities({
        variables: {
          activityHistories: [
            {
              key: `game_id_${selectedGame.id}_onboarding_marketing-onboard`,
              value: `marketing-preparation`,
            },
          ],
        },
      }).finally(() => {
        client.refetchQueries({
          include: ['GetActivityHistories'],
        })
      })
      setStep('finished')
    })
  }, [gameOfInterest, selectedGame, setGameOfInterest])

  const handleBack = useCallback(() => {
    if (activeForm === 'gameRegion') setStep('onboard')
    if (activeForm === 'uniqueSellingPoint') setActiveForm('gameRegion')
    if (activeForm === 'gameOfInterest') setActiveForm('uniqueSellingPoint')
  }, [activeForm])

  const handleNext = useCallback(() => {
    // if (activeForm === 'storeUrl') handleSubmitStore()
    if (activeForm === 'gameRegion') handleGameRegion()
    if (activeForm === 'uniqueSellingPoint') handleSetUniqueSellingPoints()
    if (activeForm === 'gameOfInterest') handleSetGameOfInterest()
  }, [activeForm, selectedGame, storeUrl, uniqueSellingPoint, gameOfInterest, gameRegion])

  const { data } = useQuery<GetGameByIdQuery, GetGameByIdQueryVariables>(getGameById, {
    fetchPolicy: 'no-cache',
    variables: {
      gameId: parseInt(selectedGame.id),
    },
    skip: !selectedGame?.id,
  })

  const game = useMemo(() => {
    if (!data?.gameById) return null

    return data.gameById
  }, [data])

  useEffect(() => {
    if (!game) return

    const region = [
      ...game.countries.map((country) => country.code),
      ...game.countryGroups.map((country) => country.code),
    ]
    setGameRegion(region)

    setStoreUrl({
      ...storeUrl,
      appStore: game.appStoreUrl as string,
      playStore: game.playStoreUrl as string,
    })

    if (game.uniqueSellingPoints) {
      const arrUniqueSellingPoint = game.uniqueSellingPoints.split(', ')

      setUniqueSellingPoint({
        gameplay: arrUniqueSellingPoint[0],
        socialInteraction: arrUniqueSellingPoint[1],
        monetizationAndEngagement: arrUniqueSellingPoint[2],
        otherInsights: arrUniqueSellingPoint[3],
      })
    }
  }, [game])

  useEffect(() => {
    const stepIdx = steps.findIndex((step) => step === activeForm)
    setStepIdx(stepIdx)
  }, [activeForm])

  useEffect(() => {
    if (!game) return
    if (!game.countryGroups.length) {
      setActiveForm('gameRegion')
      return
    }

    if (!game.uniqueSellingPoints || !game.uniqueSellingPoints.length) {
      setActiveForm('uniqueSellingPoint')
      return
    }

    setActiveForm('gameOfInterest')
  }, [game])

  return (
    <Flex vertical gap={4} style={{ paddingBottom: 20 }}>
      {/* <Title level={5} style={{ color: colors.gray80, fontWeight: 'bold' }}>
        {t('scale_sdk_integration_modal_title')}
      </Title> */}
      <Flex gap={12}>
        {steps.map((step, idx) => {
          return (
            <Col
              key={step}
              style={{
                flex: 1,
                height: 6,
                borderTopRightRadius: 9999,
                borderBottomRightRadius: 9999,
                backgroundColor: idx > stepIdx ? colors.labs30 : colors.labs120,
              }}
            />
          )
        })}
      </Flex>

      <Flex
        vertical
        gap={48}
        style={{
          padding: '30px 18px',
          backgroundColor: colors.gray20,
          borderRadius: 14,
          minHeight: 321,
        }}>
        {activeForm === 'gameRegion' && <MarketingOnboardModalWizardFormGameRegion />}
        {activeForm === 'uniqueSellingPoint' && (
          <MarketingOnboardModalWizardFormUniqueSellingPoint />
        )}
        {activeForm === 'gameOfInterest' && <MarketingOnboardModalWizardFormGameOfInterest />}
      </Flex>

      <Flex justify='space-between' align='center' style={{ paddingTop: 20 }}>
        <Button
          icon={<ArrowLeftOutlined />}
          size='large'
          onClick={handleBack}
          loading={
            isUniqueSellingPointsLoading || isSetSimilarGameLoading || isSetTargetRegionLoading
          }>
          {t('general_back')}
        </Button>
        <Button
          type='primary'
          size='large'
          onClick={handleNext}
          loading={
            isUniqueSellingPointsLoading || isSetSimilarGameLoading || isSetTargetRegionLoading
          }>
          {t('general_next')}
          <ArrowRightOutlined />
        </Button>
      </Flex>
    </Flex>
  )
}
