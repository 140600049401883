import { useApolloClient, useMutation } from '@apollo/client'
import {
  CreateActivityHistoriesMutation,
  CreateActivityHistoriesMutationVariables,
  GameStoreIntegrationMethod,
  GameStoreIntegrationStatus,
  UpsertGameStoreIntegrationsMutation,
  UpsertGameStoreIntegrationsMutationVariables,
} from '__generated__/gql/graphql'
import { Flex, Image, Modal, Tag, Typography } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createActivityHistories, upsertGameStoreIntegrations } from 'services/graphql/mutations'

import { INTEGRATION_BASIC_SCALE_ROUTE } from 'constants/Routes'

import { formatNumber } from 'utils/FormatterUtils'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useIntegration from 'hooks/useIntegration'
import useNavigateWithQueryParams from 'hooks/useNavigateWithQueryParams'

import LabsButton from 'components/commons/labs-kit/button'
import IntegrationIcon from 'components/modules/integration/integration-icon'

import colors from 'theme/colors'

import IntegrationRadio from './integration-radio-group'

const { Text, Title } = Typography

export default function BasicScaleIntegrationMethodModal() {
  const { t } = useTranslation()
  const navigateWithParams = useNavigateWithQueryParams()
  const client = useApolloClient()

  const { isLoading } = useIntegration()
  const { user, selectedGameStore } = useUserStore()
  const { isBasicScaleIntegrationModalShow, closeAll } = useModalStore()

  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)

  const [adjustIntegrationMethod, setAdjustIntegrationMethod] = useState<
    GameStoreIntegrationMethod | undefined
  >(undefined)
  const [firebaseIntegrationMethod, setFirebaseIntegrationMethod] = useState<
    GameStoreIntegrationMethod | undefined
  >(undefined)

  const [integrate, { loading: isIntegrateLoading }] = useMutation<
    UpsertGameStoreIntegrationsMutation,
    UpsertGameStoreIntegrationsMutationVariables
  >(upsertGameStoreIntegrations)

  const [createActivities] = useMutation<
    CreateActivityHistoriesMutation,
    CreateActivityHistoriesMutationVariables
  >(createActivityHistories)

  const handleClickIntegrateAdjustInformation = () => {
    console.log('adjust')
  }

  const handleClickIntegrateFirebaseInformation = () => {
    console.log('adjust')
  }

  const handleSubmit = useCallback(() => {
    if (!selectedGameStore || !adjustIntegrationMethod || !firebaseIntegrationMethod) return

    setIsFormLoading(true)
    integrate({
      variables: {
        gameStoreId: selectedGameStore.id,
        gameStoreIntegrations: [
          {
            slug: 'adjust-data-tracking',
            method: adjustIntegrationMethod,
            status: GameStoreIntegrationStatus.Integrating,
          },
          {
            slug: 'firebase-data-tracking',
            method: firebaseIntegrationMethod,
            status: GameStoreIntegrationStatus.Integrating,
          },
        ],
      },
    }).then(() => {
      if (adjustIntegrationMethod === GameStoreIntegrationMethod.ShareAccess) {
        createActivities({
          variables: {
            activityHistories: [
              {
                key: `game_store_id_${selectedGameStore.id}_adjust_purchase_value_data_correctness_passed`,
                value: 'completed',
              },
              {
                key: `game_store_id_${selectedGameStore.id}_adjust_purchase_event_data_correctness_passed`,
                value: 'completed',
              },
            ],
          },
        }).then(() => {
          client.refetchQueries({
            include: ['GetActivityHistories'],
          })
        })
      }

      if (firebaseIntegrationMethod === GameStoreIntegrationMethod.ShareAccess) {
        createActivities({
          variables: {
            activityHistories: [
              {
                key: `game_store_id_${selectedGameStore.id}_firebase_data_correctness_passed`,
                value: 'completed',
              },
            ],
          },
        }).then(() => {
          client.refetchQueries({
            include: ['GetActivityHistories'],
          })
        })
      }

      if (
        adjustIntegrationMethod === GameStoreIntegrationMethod.ShareAccess &&
        firebaseIntegrationMethod === GameStoreIntegrationMethod.ShareAccess
      ) {
        createActivities({
          variables: {
            activityHistories: [
              {
                key: `game_store_id_${selectedGameStore.id}_sdk_checker_passed`,
                value: 'completed',
              },
            ],
          },
        }).then(() => {
          client.refetchQueries({
            include: ['GetActivityHistories'],
          })
        })
      }

      client
        .refetchQueries({
          include: ['GetGameStoresByGameId', 'GetGamePublisherUser'],
        })
        .then(() => {
          setIsFormLoading(false)
          closeAll()
          navigateWithParams(`/${INTEGRATION_BASIC_SCALE_ROUTE}`, {
            gs_id: selectedGameStore.id,
          })
        })
    })
  }, [selectedGameStore, adjustIntegrationMethod, firebaseIntegrationMethod, integrate])

  useEffect(() => {
    if (!user) return

    if (user.isUseFullSDK) {
      setAdjustIntegrationMethod(GameStoreIntegrationMethod.FullSdk)
      setFirebaseIntegrationMethod(GameStoreIntegrationMethod.FullSdk)
    } else if (!user.isUseFullSDK) {
      setAdjustIntegrationMethod(GameStoreIntegrationMethod.ShareAccess)
      setFirebaseIntegrationMethod(GameStoreIntegrationMethod.ShareAccess)
    }
  }, [user])

  return (
    <Modal
      open={isBasicScaleIntegrationModalShow}
      title={false}
      centered
      footer={false}
      onCancel={closeAll}
      width={766}>
      <Flex
        vertical
        align='center'
        justify='space-between'
        gap={70}
        style={{ padding: '20px 60px' }}>
        <Flex vertical gap={30}>
          <Flex vertical align='center' gap={8} style={{ padding: '0 20px' }}>
            <Title level={4} style={{ margin: 0, color: colors.gray90 }}>
              {t('modal_basic_scale_integration_method_title')}
            </Title>
            <Text style={{ color: colors.gray70, textAlign: 'center' }}>
              {t('modal_basic_scale_integration_method_description')}
            </Text>
          </Flex>
          <Tag
            style={{
              borderColor: colors.greenPass70,
              backgroundColor: colors.greenPass20,
              padding: 17,
              borderRadius: 6,
            }}>
            <Flex align='center' justify='center' gap={10}>
              <Image src='/images/icon/premium-badge.webp' width={17} preview={false} />
              <Text style={{ fontSize: 12, color: colors.greenPass90, lineHeight: '10px' }}>
                <span style={{ fontWeight: 700 }}>
                  {t('modal_basic_scale_integration_method_benefit_1')} {formatNumber(1000, 0)}{' '}
                  {t('modal_basic_scale_integration_method_benefit_2')}
                </span>{' '}
                {t('modal_basic_scale_integration_method_benefit_3')}
              </Text>
            </Flex>
          </Tag>
          <Flex vertical gap={18}>
            <Flex vertical gap={10}>
              <Flex align='center' justify='space-between'>
                <Flex align='center' gap={5}>
                  <IntegrationIcon product='adjust' size={36} />
                  <Text style={{ color: colors.gray90, fontWeight: 700 }}>
                    Adjust {t('general_integration')}
                  </Text>
                </Flex>
                <LabsButton
                  variant='text'
                  size='compact'
                  onClick={handleClickIntegrateAdjustInformation}>
                  {t('modal_basic_scale_integration_method_integration_reason')} Adjust?
                </LabsButton>
              </Flex>
              <IntegrationRadio
                name='adjust-integration-method'
                value={adjustIntegrationMethod}
                onChange={setAdjustIntegrationMethod}
              />
            </Flex>
            <Flex vertical gap={10}>
              <Flex align='center' justify='space-between'>
                <Flex align='center' gap={5}>
                  <IntegrationIcon product='firebase' size={36} />
                  <Text style={{ color: colors.gray90, fontWeight: 700 }}>
                    Firebase {t('general_integration')}
                  </Text>
                </Flex>
                <LabsButton
                  variant='text'
                  size='compact'
                  onClick={handleClickIntegrateFirebaseInformation}>
                  {t('modal_basic_scale_integration_method_integration_reason')} Firebase?
                </LabsButton>
              </Flex>
              <IntegrationRadio
                name='firebase-integration-method'
                value={firebaseIntegrationMethod}
                onChange={setFirebaseIntegrationMethod}
              />
            </Flex>
          </Flex>
        </Flex>
        <LabsButton
          width='wide'
          onClick={handleSubmit}
          isDisabled={!adjustIntegrationMethod || !firebaseIntegrationMethod}
          isLoading={isLoading || isIntegrateLoading || isFormLoading}>
          {t('general_confirm')}
        </LabsButton>
      </Flex>
    </Modal>
  )
}
