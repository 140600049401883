import mixpanel from 'mixpanel-browser'
import posthog from 'posthog-js'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { browserName, deviceType } from 'react-device-detect'

import appConfig from 'config/appConfig'

export default function useTracker() {
  const isTrackerPosthogEnable = useFeatureFlagEnabled('enable-tracker-posthog')
  const isTrackerMixpanelEnable = useFeatureFlagEnabled('enable-tracker-mixpanel')

  const init = (sessionId: string) => {
    mixpanel.init(appConfig.mixPanelToken, {
      debug: appConfig.env === 'development',
      track_pageview: !!isTrackerMixpanelEnable,
      persistence: 'localStorage',
    })

    mixpanel.identify(sessionId)
    mixpanel.register({
      user_agent: navigator.userAgent,
      version: appConfig.buildVersion,
      device_type: deviceType,
      browser_name: browserName,
      session_id: sessionId,
      environment: appConfig.env,
    })
  }

  const register = (props: { [k: string]: string | number | boolean | null }) => {
    if (isTrackerMixpanelEnable) mixpanel.register({ ...props })
    if (isTrackerPosthogEnable) posthog.register({ ...props })
  }

  const track = (eventName: string, props: { [k: string]: string | number | boolean }) => {
    if (isTrackerMixpanelEnable) {
      mixpanel.track(eventName, { path: window.location.pathname, ...props })
    }

    if (isTrackerPosthogEnable) {
      posthog.capture(eventName, { path: window.location.pathname, ...props })
    }
  }

  const reset = () => {
    if (isTrackerMixpanelEnable) {
      mixpanel.register({
        game_campaign_count: null,
        game_genre: null,
        game_name: null,
        game_regions: null,
        game_integration_status: null,
        game_tags: null,
        user_id: null,
        user_status: null,
        user_balance: null,
        user_demo: false,
      })
    }

    if (isTrackerPosthogEnable) {
      posthog.register({
        game_campaign_count: null,
        game_genre: null,
        game_name: null,
        game_regions: null,
        game_integration_status: null,
        game_tags: null,
        user_id: null,
        user_status: null,
        user_balance: null,
        user_demo: false,
      })
    }
  }

  return {
    init,
    register,
    track,
    reset,
  }
}
