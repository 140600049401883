import mixpanel from 'mixpanel-browser'
import posthog from 'posthog-js'
import { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import 'url-change-event'

import { DASHBOARD_ROUTE } from 'constants/Routes'

import useIdentifier from 'hooks/useIdentifier'
import useTracker from 'hooks/useTracker'

import AccountSettingRoute from './account-setting'
import AddOnRouter from './add-on'
import AuthenticationRouter from './authentication'
import CreativeHubRouter from './creative-hub'
import DashboardRouter from './dashboard'
import ErrorRouter from './error'
import FinancingRouter from './financing'
import GameRouter from './game'
import GameAnalyticsRouter from './game-analytics'
import GameManagementRouter from './game-management'
import GettingStartedRouter from './getting-started'
import InfrastructureRouter from './infrastructure'
import IntegrationRouter from './integration'
import MarketingAnalyticsRouter from './marketing-analytics'
import MediationRouter from './mediation'
import NotFoundRouter from './not-found'
import OnboardingRouter from './onboarding'
import PrivacyPolicyRouter from './privacy-policy'
import SettingUpAccountRoute from './setting-up-account'
import TermsOfServiceRouter from './terms-of-service'
import UserAcquisitionRouter from './user-acquisition'
import WaitingListRoute from './waiting-list'

export default function AppRouter() {
  const { generateId } = useIdentifier()
  const { sessionId } = generateId()
  const tracker = useTracker()
  const location = useLocation()

  useEffect(() => {
    if (!sessionId) return

    tracker.init(sessionId)
  }, [sessionId])

  useEffect(() => {
    window.addEventListener('urlchangeevent', function () {
      mixpanel.time_event('page_exit')
    })
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => posthog.capture('$pageview'), 1000)
    return () => clearTimeout(timeout)
  }, [location])

  if (!sessionId) return null
  return (
    <Routes>
      <Route path='' element={<Navigate to={DASHBOARD_ROUTE} />} />
      {AuthenticationRouter}
      {GameRouter}
      {IntegrationRouter}
      {PrivacyPolicyRouter}
      {AddOnRouter}
      {AccountSettingRoute}
      {GameAnalyticsRouter}ß{MarketingAnalyticsRouter}
      {InfrastructureRouter}
      {MediationRouter}
      {FinancingRouter}
      {WaitingListRoute}
      {TermsOfServiceRouter}
      {ErrorRouter}
      {NotFoundRouter}
      {UserAcquisitionRouter}
      {GettingStartedRouter}
      {SettingUpAccountRoute}
      {DashboardRouter}
      {GameManagementRouter}
      {CreativeHubRouter}
      {OnboardingRouter}
    </Routes>
  )
}
