import { gql } from '@apollo/client'

export const loginMutations = gql`
  mutation LoginGamePublisherUser($email: String!, $password: String!) {
    loginGamePublisherUserByEmailAndPassword(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`

export const registerMutations = gql`
  mutation RegisterGamePublisherUser($email: String!, $password: String!) {
    registerGamePublisherUserByEmailAndPassword(email: $email, password: $password) {
      gamePublisher {
        id
        companyName
        balance
        isDemo
        companySize
        companyName
      }
      gamePublisherUser {
        id
        email
        uid
        displayName
        status
        phone
      }
    }
  }
`

export const createBasicInformationMutation = gql`
  mutation CreateBasicInformation(
    $companyEmail: String
    $companyName: String!
    $companySize: String!
    $gamePublisherUserDisplayName: String!
  ) {
    createGamePublisher(
      companyEmail: $companyEmail
      companyName: $companyName
      companySize: $companySize
    ) {
      id
      companyEmail
      companyName
      companySize
    }

    updateGamePublisherUser(gamePublisherUserDisplayName: $gamePublisherUserDisplayName) {
      id
      displayName
    }
  }
`

export const updateGamePublisherUserDisplayName = gql`
  mutation UpdateUserDisplayName($gamePublisherUserDisplayName: String!) {
    updateGamePublisherUser(gamePublisherUserDisplayName: $gamePublisherUserDisplayName) {
      id
      displayName
    }
  }
`

export const createGameInformationMutations = gql`
  mutation CreateGame(
    $gameDevelopmentStage: GameDevelopmentStage!
    $name: String!
    $targetRegion: [String!]!
    $playStoreUrl: String!
    $appleStoreUrl: String!
    $gameStorePlatform: StorePlatformType!
    $genreCodes: [String!]!
    $iconUrl: String!
    $bundleId: String!
    $gameEngine: String!
  ) {
    createGame(
      gameDevelopmentStage: $gameDevelopmentStage
      name: $name
      targetRegion: $targetRegion
      playStoreUrl: $playStoreUrl
      appleStoreUrl: $appleStoreUrl
      gameStorePlatform: $gameStorePlatform
      genreCodes: $genreCodes
      iconUrl: $iconUrl
      bundleId: $bundleId
      gameEngine: $gameEngine
    ) {
      id
      name
      appStoreUrl
      playStoreUrl
      huaweiStoreUrl
      gameDevelopmentStage
      countries {
        code
        name
      }
    }
  }
`

export const createGamePublisherMutations = gql`
  mutation CreateGamePublisher(
    $companyEmail: String
    $companyName: String!
    $companySize: String!
  ) {
    createGamePublisher(
      companyEmail: $companyEmail
      companyName: $companyName
      companySize: $companySize
    ) {
      id
      companyEmail
      companyName
      companySize
    }
  }
`

export const createGameCreativesMutations = gql`
  mutation CreateGameCreatives(
    $gameId: ID!
    $startDate: String!
    $gameCreatives: [GameCreativeItemInput!]!
  ) {
    createGameCreatives(gameId: $gameId, startDate: $startDate, gameCreatives: $gameCreatives) {
      id
      mediaUrl
      format
      status
    }
  }
`

export const uploadMediaMutations = gql`
  mutation UploadMedia($file: File!, $validation: MediaUploadValidationType, $gameId: ID) {
    uploadMedia(file: $file, validation: $validation, gameId: $gameId) {
      url
      info {
        width
        height
        format
        ratio
        extension
        duration
        size
      }
    }
  }
`

export const topupMutations = gql`
  mutation Topup($amount: Float!) {
    topUpGamePublisherBalanceRequest(amount: $amount) {
      id
    }
  }
`

export const verifyEmailTokenMutations = gql`
  mutation VerifyEmailToken($token: String!, $status: String!) {
    verifyEmailToken(token: $token, status: $status) {
      errorCode
      email
    }
  }
`

export const resendEmailVerificationTokenMutations = gql`
  mutation ResendEmailVerificationToken($email: String!) {
    resendEmailVerificationToken(email: $email)
  }
`

export const updateGameUniqueSellingPointsMutations = gql`
  mutation UpdateGameUniqueSellingPoints(
    $gameId: ID!
    $uniqueSellingPoints: String!
    $sendNotification: Boolean
  ) {
    updateGameUniqueSellingPoints(
      id: $gameId
      uniqueSellingPoints: $uniqueSellingPoints
      sendNotification: $sendNotification
    )
  }
`

export const updateGameDailyBudgetMutations = gql`
  mutation UpdateGameDailyBudget(
    $gameId: ID!
    $dailyBudget: Float!
    $iosDailyBudget: Float!
    $androidDailyBudget: Float!
    $isAutoBudgetAllocation: Boolean!
  ) {
    updateGameDailyBudget(
      id: $gameId
      dailyBudget: $dailyBudget
      iosDailyBudget: $iosDailyBudget
      androidDailyBudget: $androidDailyBudget
      isAutoBudgetAllocation: $isAutoBudgetAllocation
    ) {
      id
    }
  }
`

export const updateGameStoreUrl = gql`
  mutation UpdateGameStoreUrl($gameId: Int!, $storeId: Int!, $url: String!) {
    updateGameStoreUrl(gameId: $gameId, storeId: $storeId, url: $url) {
      gameId
      storeId
      url
    }
  }
`

export const createGameStore = gql`
  mutation CreateGameStore(
    $gameId: ID!
    $gameStorePlatform: StorePlatformType!
    $gameEngine: String!
    $gameDevelopmentStage: GameDevelopmentStage!
    $bundleId: String!
    $url: String!
  ) {
    createGameStore(
      gameId: $gameId
      gameStorePlatform: $gameStorePlatform
      gameEngine: $gameEngine
      gameDevelopmentStage: $gameDevelopmentStage
      bundleId: $bundleId
      url: $url
    ) {
      gameId
      storeId
      url
    }
  }
`

export const createGameBundle = gql`
  mutation CreateGameBundle(
    $gameId: ID!
    $storeId: ID!
    $bundleName: String!
    $size: Int
    $path: String
    $releaseNote: String
  ) {
    createGameBundle(
      gameId: $gameId
      storeId: $storeId
      bundleName: $bundleName
      size: $size
      path: $path
      releaseNote: $releaseNote
    ) {
      name
    }
  }
`

export const updateGameBundle = gql`
  mutation UpdateGameBundle(
    $gameId: ID!
    $storeId: ID!
    $name: String!
    $size: Int!
    $path: String!
    $releaseNote: String!
  ) {
    updateGameBundle(
      gameId: $gameId
      storeId: $storeId
      name: $name
      size: $size
      path: $path
      releaseNote: $releaseNote
    ) {
      name
    }
  }
`

export const generateProductCode = gql`
  mutation GenerateProductCode($gameName: String!, $bundleName: String!, $platform: String!) {
    generateProductCode(gameName: $gameName, bundleName: $bundleName, platform: $platform) {
      productCode
      callbackKey
    }
  }
`

export const createGameStoreAssets = gql`
  mutation CreateGameStoreAssets($gameId: Int!, $gameStoreAssets: [GameStoreAssetInput!]!) {
    createGameStoreAssets(gameId: $gameId, gameStoreAssets: $gameStoreAssets) {
      gameId
      type
      path
    }
  }
`

export const updateGame = gql`
  mutation UpdateGame(
    $id: ID!
    $name: String!
    $sdkIntegrationStatus: GameSDKIntegrationStatus!
    $genreCode: String!
    $tagCodes: [String!]
    $targetingCountryCodes: [String!]!
    $storeIds: [ID!]!
    $description: GameDescriptionInput!
    $storeAssets: [GameStoreAssetInput!]!
  ) {
    updateGame(
      id: $id
      name: $name
      sdkIntegrationStatus: $sdkIntegrationStatus
      genreCode: $genreCode
      tagCodes: $tagCodes
      targetingCountryCodes: $targetingCountryCodes
      storeIds: $storeIds
      description: $description
      storeAssets: $storeAssets
    ) {
      name
      sdkIntegrationStatus
      genres {
        name
      }
      tags {
        name
      }
      countries {
        name
      }
      stores {
        store {
          name
        }
      }
      descriptions {
        languageCode
        shortDescription
        longDescription
      }
      storeAssetAppIcon
      storeAssetFeatureGraphic
      storeAssetPhoneScreenshots {
        path
      }
      storeAssetTablet7inScreenshots {
        path
      }
      storeAssetTablet10inScreenshots {
        path
      }
    }
  }
`

export const uploadMultipartFile = gql`
  mutation UploadMultipartFile($file: File!) {
    uploadMultipartFile(file: $file) {
      url
      id
    }
  }
`

export const abortUploadMultipartFile = gql(`
  mutation AbortUploadMultipartFile (
    $uploadID: String!
  ) {
    abortUploadMultipartFile(
      uploadID: $uploadID
    )
  }
`)

export const retryMultipartUpload = gql(`
  mutation RetryMultipartUpload (
    $uploadID: String!
  ) {
    retryMultipartUpload(
      uploadID: $uploadID
    )
  }
`)

export const launchRequest = gql`
  mutation LaunchRequest(
    $gameId: ID!
    $gameName: String!
    $sampleBundlePath: String
    $tapTapUrl: String
    $gameCategoryCode: String!
    $gameTagCodes: [String!]
    $gameCountryCodes: [String!]!
    $storeIds: [ID!]!
  ) {
    launchRequest(
      gameId: $gameId
      gameName: $gameName
      sampleBundlePath: $sampleBundlePath
      tapTapUrl: $tapTapUrl
      gameCategoryCode: $gameCategoryCode
      gameTagCodes: $gameTagCodes
      gameCountryCodes: $gameCountryCodes
      storeIds: $storeIds
    )
  }
`

export const updateGameStoreAppContent = gql`
  mutation UpdateGameStoreAppContent($gameId: ID!, $storeId: ID!, $appContent: JSON!) {
    updateGameStoreAppContent(gameId: $gameId, storeId: $storeId, appContent: $appContent) {
      appContentMap
    }
  }
`

export const upsertGameSkus = gql`
  mutation UpsertGameSkus($gameId: ID!, $gameSkus: [GameSkuInput!]!, $isUpdate: Boolean) {
    upsertGameSkus(gameId: $gameId, gameSkus: $gameSkus, isUpdate: $isUpdate) {
      skuId
      name
      price
      description
      note
      imageUrl
    }
  }
`

export const updateGameStoreBundleStatusByGameStoreId = gql`
  mutation UpdateGameStoreBundleStatusByGameStoreId(
    $gameStoreId: ID!
    $status: GameStoreBundleStatus!
  ) {
    updateGameStoreBundleStatusByGameStoreId(gameStoreId: $gameStoreId, status: $status) {
      createdAt
      gameStoreId
      id
      name
      path
      releaseNote
      size
      status
    }
  }
`

export const createGameRawAssetsByGameId = gql`
  mutation CreateGameRawAssetsByGameId(
    $gameId: ID!
    $gameRawAssets: [GameRawAssetInput!]!
    $isUpdate: Boolean
  ) {
    createGameRawAssetsByGameId(
      gameId: $gameId
      gameRawAssets: $gameRawAssets
      isUpdate: $isUpdate
    ) {
      mediaUrl
    }
  }
`

export const updateGameStoreStatusSetupReview = gql`
  mutation UpdateGameStoreStatusSetupReview($gameId: ID!) {
    updateGameStoreStatusSetupReview(gameId: $gameId)
  }
`

export const updateGameStoreStatusByGameId = gql`
  mutation UpdateGameStoreStatusByGameId($gameId: ID!, $status: GameStoreStatus!) {
    updateGameStoreStatusByGameId(gameId: $gameId, status: $status)
  }
`

export const UpdateGameTrackList = gql`
  mutation UpdateGameTrackList($gameId: ID!, $trackList: JSON!, $isUpdate: Boolean) {
    updateGameTrackList(gameId: $gameId, trackList: $trackList, isUpdate: $isUpdate) {
      id
      trackList
    }
  }
`

export const prepareScaleForkDemoAccount = gql`
  mutation DemoScaleFork {
    demoScaleFork {
      id
    }
  }
`

export const resetDemoAccount = gql`
  mutation ResetLaunchDemoData {
    resetLaunchDemoData {
      id
    }
  }
`

export const updateGameStoreAppContents = gql`
  mutation UpdateGameStoreAppContents($gameId: ID!, $appContents: [AppContentInputWrapper!]!) {
    updateGameStoreAppContents(gameId: $gameId, appContents: $appContents) {
      id
    }
  }
`

export const updateGameIAAList = gql`
  mutation UpdateGameIAAList($gameId: ID!, $iaaList: [GameIAAInput!]!, $isUpdate: Boolean) {
    updateGameIAAList(gameId: $gameId, iaaList: $iaaList, isUpdate: $isUpdate) {
      iaaList
      iaaListMap
    }
  }
`

export const createGameStoreBundle = gql`
  mutation CreateGameStoreBundle(
    $gameStoreId: ID!
    $path: String!
    $releaseName: String!
    $releaseNote: String!
    $size: Int
  ) {
    createGameStoreBundle(
      gameStoreId: $gameStoreId
      path: $path
      releaseName: $releaseName
      releaseNote: $releaseNote
      size: $size
    ) {
      id
      gameStoreId
      path
      url
      name
      size
      releaseNote
      status
      createdAt
    }
  }
`
export const bookmarkCreativeInspiration = gql`
  mutation BookmarkCreativeInspiration(
    $bookmarkType: BookmarkTypeEnum!
    $creativeInspirationId: ID!
  ) {
    bookmarkCreativeInspiration(
      bookmarkType: $bookmarkType
      creativeInspirationId: $creativeInspirationId
    ) {
      id
    }
  }
`

export const cancelGameStoreBundle = gql`
  mutation CancelGameStoreBundle($id: ID!) {
    cancelGameStoreBundle(id: $id) {
      id
      status
    }
  }
`

export const demoState = gql`
  mutation DemoState($type: String!, $gameStoreId: String!) {
    demoState(type: $type, gameStoreId: $gameStoreId)
  }
`
export const manageGameStores = gql`
  mutation UpsertGameStores($gameId: ID!, $gameStores: [GameStoreInput!]!) {
    upsertGameStores(gameId: $gameId, gameStores: $gameStores)
  }
`

export const updateGameStoreIsShownScaleCompletedModal = gql`
  mutation UpdateGameStoreIsShownScaleCompletedModal(
    $id: ID!
    $isShownScaleCompletedModal: Boolean!
  ) {
    updateGameStoreIsShownScaleCompletedModal(
      id: $id
      isShownScaleCompletedModal: $isShownScaleCompletedModal
    )
  }
`

export const updateGameStoreIsShownScaleSdkCheckerCompletedModal = gql`
  mutation UpdateGameStoreIsShownScaleSdkCheckerCompletedModal(
    $id: ID!
    $isShownScaleSdkCheckerCompletedModal: Boolean!
  ) {
    updateGameStoreIsShownScaleSdkCheckerCompletedModal(
      id: $id
      isShownScaleSdkCheckerCompletedModal: $isShownScaleSdkCheckerCompletedModal
    )
  }
`

export const updateGameStoreIsShownScaleSdkIntegrationCompletedModal = gql`
  mutation UpdateGameStoreIsShownScaleSdkIntegrationCompletedModal(
    $id: ID!
    $isShownScaleSdkIntegrationCompletedModal: Boolean!
  ) {
    updateGameStoreIsShownScaleSdkIntegrationCompletedModal(
      id: $id
      isShownScaleSdkIntegrationCompletedModal: $isShownScaleSdkIntegrationCompletedModal
    )
  }
`
export const upsertGameStoreIntegrations = gql`
  mutation UpsertGameStoreIntegrations(
    $gameStoreId: ID!
    $gameStoreIntegrations: [GameStoreIntegrationInput!]!
  ) {
    upsertGameStoreIntegrations(
      gameStoreId: $gameStoreId
      gameStoreIntegrations: $gameStoreIntegrations
    )
  }
`

export const setGameSimilarGame = gql`
  mutation SetGameSimilarGames(
    $id: ID!
    $similarGameIDs: [String!]!
    $similarGameSubmissions: [String!]!
    $sendNotification: Boolean
  ) {
    setGameSimilarGames(
      id: $id
      similarGameIDs: $similarGameIDs
      similarGameSubmissions: $similarGameSubmissions
      sendNotification: $sendNotification
    )
  }
`

export const updateGameTagsMutations = gql`
  mutation UpdateGameTags($gameId: ID!, $tagCodes: [String!]!, $sendNotification: Boolean) {
    updateGameTags(gameId: $gameId, tagCodes: $tagCodes, sendNotification: $sendNotification)
  }
`

export const createActivityHistories = gql`
  mutation CreateActivityHistories($activityHistories: [CreateActivityHistoryInput!]!) {
    createActivityHistories(activityHistories: $activityHistories)
  }
`

export const sendAddOnNotification = gql`
  mutation SendAddOnNotification($id: ID!, $addOn: String!, $action: AddOnAction!) {
    sendAddOnNotification(id: $id, addOn: $addOn, action: $action)
  }
`

export const updateMyGames = gql`
  mutation UpdateMyGames(
    $gameId: ID!
    $targetingCountryCodes: [String!]
    $blacklistedCountryCodes: [String!]
    $targetingCountryGroupCodes: [String!]
    $genreCode: String
    $tagCodes: [String!]
    $uniqueSellingPoint: String
  ) {
    updateMyGames(
      gameId: $gameId
      targetingCountryCodes: $targetingCountryCodes
      blacklistedCountryCodes: $blacklistedCountryCodes
      targetingCountryGroupCodes: $targetingCountryGroupCodes
      genreCode: $genreCode
      tagCodes: $tagCodes
      uniqueSellingPoint: $uniqueSellingPoint
      sendNotification: true
    )
  }
`

export const addAddOnProduct = gql`
  mutation AddAddOnProduct($productId: ID!, $gameId: ID!) {
    addAddOnProduct(productId: $productId, gameId: $gameId) {
      id
    }
  }
`

export const addNewGamePublisherWaitlistMutation = gql`
  mutation AddNewGamePublisherWaitlist(
    $monetizationStrategy: [GamePublisherMonetizationStrategy!]!
    $gameEngine: String!
    $isUseFullSDK: Boolean!
    $country: String!
    $displayName: String!
    $companyName: String!
    $phoneNumber: String!
    $productOfInterest: String!
    $integrationPlan: String!
    $email: String!
  ) {
    addNewGamePublisherWaitlist(
      monetizationStrategy: $monetizationStrategy
      gameEngine: $gameEngine
      isUseFullSDK: $isUseFullSDK
      country: $country
      displayName: $displayName
      companyName: $companyName
      phoneNumber: $phoneNumber
      productOfInterest: $productOfInterest
      integrationPlan: $integrationPlan
      email: $email
    ) {
      id
    }
  }
`

export const integrationRequest = gql`
  mutation IntegrationRequest(
    $gameStoreId: String!
    $gameStoreIntegration: GameStoreIntegrationInput!
  ) {
    integrationRequest(gameStoreId: $gameStoreId, gameStoreIntegration: $gameStoreIntegration) {
      id
      gameStore {
        id
        bundleId
        status
      }
      integration {
        id
        name
        slug
      }
    }
  }
`

export const createGameCreativeFeedback = gql`
  mutation CreateGameCreativeFeedback($gameCreativeId: ID!, $isLike: Boolean!, $feedback: String) {
    createGameCreativeFeedback(
      gameCreativeId: $gameCreativeId
      isLike: $isLike
      feedback: $feedback
    ) {
      id
    }
  }
`

export const sendRequestResetPassword = gql`
  mutation SendRequestForgotPassword($email: String!) {
    sendResetPasswordRequest(email: $email) {
      errorCode
    }
  }
`

export const verifyRequestResetPassword = gql`
  mutation VerifyRequestResetPassword($token: String!) {
    verifyResetPasswordToken(token: $token) {
      errorCode
    }
  }
`

export const setNewPassword = gql`
  mutation ResetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token) {
      errorCode
    }
  }
`

export const notifyBundleUpload = gql`
  mutation NotifyBundleUpload($gameStoreId: ID!) {
    notifyBundleUpload(gameStoreId: $gameStoreId)
  }
`

export const updateAdjustToken = gql`
  mutation UpdateAdjustToken($id: String!, $apiToken: String!, $appToken: String!) {
    updateAdjustToken(id: $id, apiToken: $apiToken, appToken: $appToken) {
      id
    }
  }
`

export const skipGameInfoOnboarding = gql`
  mutation UpdateGameInfoOnboarding($value: String!) {
    updateGameInfoOnboarding(value: $value)
  }
`

export const invitingOtherMembers = gql`
  mutation InvitingOtherMembers($members: [InviteMemberInput!]!) {
    inviteMembers(members: $members)
  }
`

export const upsertMediationAdUnit = gql`
  mutation UpsertMediationAdUnit(
    $gameStoreId: String!
    $mediationId: String!
    $mediationAdUnits: [MediationAdUnitInput!]!
  ) {
    upsertMediationAdUnit(
      gameStoreId: $gameStoreId
      mediationId: $mediationId
      mediationAdUnits: $mediationAdUnits
    ) {
      id
      adUnitId
      adType
      labsAdUnitName
      adUnitStatus
    }
  }
`

export const disableAdUnits = gql`
  mutation DisableAdUnits($gameStoreId: String!, $mediationId: String!, $adUnitIds: [String!]!) {
    disableAdUnits(gameStoreId: $gameStoreId, mediationId: $mediationId, adUnitIds: $adUnitIds) {
      id
      adUnitId
      adType
      labsAdUnitName
      adUnitStatus
    }
  }
`

export const sdkBanUser = gql`
  mutation sdkBanUser($uid: Int!) {
    sdkBanUser(uid: $uid) {
      code
      error
    }
  }
`

export const sdkUnbanUser = gql`
  mutation sdkUnbanUser($uid: Int!) {
    sdkUnbanUser(uid: $uid) {
      code
      error
    }
  }
`

export const sdkSyncOrderCallback = gql`
  mutation sdkSyncOrderCallback($id: Int!) {
    sdkSyncOrderCallback(id: $id) {
      code
      error
    }
  }
`

export const setGamePublisherUser = gql`
  mutation SetGamePublisherUser($name: String!, $email: String!, $roles: [String!]!) {
    createOrUpdateGamePublisherUser(name: $name, email: $email, roles: $roles)
  }
`

export const removeGamePublisherUser = gql`
  mutation RemoveGamePublisherUser($email: String!) {
    removePublisherUserByEmail(email: $email)
  }
`

export const updateGameInfo = gql`
  mutation UpdateGameInfo($id: ID!, $targetRegion: [String!]) {
    updateGameInfo(id: $id, targetRegion: $targetRegion)
  }
`

export const requestBalanceLimitedOffer = gql`
  mutation RequestBalanceLimitedOffer {
    balanceLimitedOfferRequest {
      id
    }
  }
`

export const deleteGameStore = gql`
  mutation DeleteGameStore($gameStoreId: String!) {
    deleteGameStore(gameStoreId: $gameStoreId) {
      id
      game {
        name
      }
      store {
        os
      }
    }
  }
`

export const updateGameDetail = gql`
  mutation UpdateGameDetail(
    $gameStoreId: String!
    $gameDevelopmentStage: GameDevelopmentStage!
    $gameTitle: String!
    $gameEngine: String!
    $gameGenre: [String!]!
    $appUrl: String!
    $logoUrl: String!
    $bundleId: String!
    $uniqueSellingPoints: String!
    $gameTag: [String!]!
    $similarGame: [String!]!
    $targetRegion: [String!]!
    $blocklistRegion: [String!]!
  ) {
    updateGameDetail(
      gameStoreId: $gameStoreId
      gameDevelopmentStage: $gameDevelopmentStage
      gameTitle: $gameTitle
      gameEngine: $gameEngine
      gameGenre: $gameGenre
      appUrl: $appUrl
      logoUrl: $logoUrl
      bundleId: $bundleId
      uniqueSellingPoints: $uniqueSellingPoints
      gameTag: $gameTag
      similarGame: $similarGame
      targetRegion: $targetRegion
      blocklistRegion: $blocklistRegion
    ) {
      id
    }
  }
`

export const createMediation = gql`
  mutation CreateMediation($gameStoreId: String!) {
    createMediation(gameStoreId: $gameStoreId) {
      id
      appId
    }
  }
`
