import { Input } from 'antd'
import React, { CSSProperties } from 'react'

import colors from 'theme/colors'

import { IInputText, Variant } from './type'

const variantStyle: { [k in Variant]: CSSProperties } = {
  primary: {
    borderColor: colors.gray40,
    color: colors.gray90,
    fontWeight: 500,
    height: 48,
  },
  transparent: {
    borderColor: colors.labs50,
    background: 'transparent',
    color: colors.white,
    height: 48,
  },
  grey: {
    borderColor: colors.gray40,
    background: 'transparent',
    color: colors.gray90,
    height: 48,
  },
  secondary: {
    borderColor: colors.gray40,
    color: colors.gray60,
    fontWeight: 500,
    height: 48,
  },
}

export default function InputText(props: IInputText) {
  return (
    <Input
      className={props.className || 'primary'}
      size='large'
      style={variantStyle[props.variant || 'primary']}
      {...props}
    />
  )
}
