import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

import { WAITING_LIST_ROUTE } from 'constants/Routes'

const WaitingListPage = lazy(() => import('pages/waiting-list'))

const WaitingListRoute = (
  <Route
    path={WAITING_LIST_ROUTE}
    element={
      <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
        <WaitingListPage />
      </Suspense>
    }
  />
)

export default WaitingListRoute
