import { Flex, Row, Typography } from 'antd'

import GameDetailInfoItem from '../info-item'
import { GameInfoData } from '../type'

const { Text } = Typography
export default function InspirationPreference({ gameStore }: GameInfoData) {
  const usp = gameStore?.game.uniqueSellingPoints || ''
  const uniqueSellingPoints = usp
    .split(';')
    .filter((value) => value !== '')
    .map((usp) => usp.trim())

  const countries = [
    ...(gameStore?.game.countries || []).map((country) => country.name),
    ...(gameStore?.game.countryGroups || []).map((country) => country.name),
  ].join(', ')

  return (
    <Flex vertical gap={36} style={{ padding: '24px 0' }}>
      <Flex vertical gap={14}>
        <Text style={{ fontWeight: 500 }}>Unique Selling Point</Text>
        <Row gutter={[12, 12]}>
          <GameDetailInfoItem
            label='Gameplay'
            value={uniqueSellingPoints.length >= 1 ? uniqueSellingPoints[0] : '-'}
          />
          <GameDetailInfoItem
            label='Social Interaction'
            value={uniqueSellingPoints.length >= 2 ? uniqueSellingPoints[1] : '-'}
          />
          <GameDetailInfoItem
            label='Monetization and Engagement'
            value={uniqueSellingPoints.length >= 3 ? uniqueSellingPoints[2] : '-'}
          />
          <GameDetailInfoItem
            label='More Description'
            value={uniqueSellingPoints.length >= 4 ? uniqueSellingPoints[3] : '-'}
          />
        </Row>
      </Flex>
      <Flex vertical gap={14}>
        <Text style={{ fontWeight: 500 }}>Other Info</Text>
        <Row gutter={[12, 12]}>
          <GameDetailInfoItem
            label='Game Tag'
            value={gameStore?.game.tags.map((tag) => tag.name).join(', ')}
          />
          <GameDetailInfoItem
            label='Similar Game'
            value={(gameStore?.game.similarGames || []).map((game) => game.name).join(', ')}
          />
          <GameDetailInfoItem label='Publish Region' value={countries} />
          <GameDetailInfoItem
            label='Blocklisted Country'
            value={gameStore?.game.blacklistedCountries.map((country) => country.name).join(', ')}
          />
        </Row>
      </Flex>
    </Flex>
  )
}
