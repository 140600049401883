import { Card, Col, Flex, Radio, Row, Typography } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useMarketingOnboardStore from 'stores/useMarketingOnboardStore'
import useUserStore from 'stores/useUserStore'

import TextArea from 'components/commons/form/textarea'

import colors from 'theme/colors'

import { IRadioItem } from './type'

const { Text } = Typography
const { Group: RadioGroup } = Radio

export default function MarketingOnboardModalWizardFormUniqueSellingPoint() {
  const { t } = useTranslation()

  const { selectedGame } = useUserStore()
  const { uniqueSellingPoint, setUniqueSellingPoint } = useMarketingOnboardStore()

  useEffect(() => {
    if (!selectedGame.uniqueSellingPoints) return

    const gameUsp = selectedGame.uniqueSellingPoints.split('; ')
    setUniqueSellingPoint({
      gameplay: gameUsp[0] || '',
      socialInteraction: gameUsp[1] || '',
      monetizationAndEngagement: gameUsp[2] || '',
      otherInsights: gameUsp[3] || '',
    })
  }, [selectedGame.uniqueSellingPoints])

  return (
    <Row
      className='scrollbar-hide'
      style={{ overflow: 'scroll', maxHeight: 420, paddingBottom: 60 }}>
      <Flex vertical justify='center' align='center' gap={24} style={{ width: '100%' }}>
        <Text style={{ color: colors.gray90, fontWeight: 'bold' }}>
          {t('marketing_onboarding_modal_unique_selling_points_title')}
        </Text>
        <Flex vertical justify='center' gap={60}>
          <Flex vertical align='center' gap={25}>
            <Card
              size='small'
              style={{
                width: 360,
                backgroundColor: colors.gray30,
                borderRadius: 10,
                border: 0,
              }}>
              <Flex align='center' justify='space-between'>
                <Text style={{ color: colors.gray80, fontWeight: 'bold' }}></Text>
                <Text style={{ color: colors.gray80, fontWeight: 'bold' }}>
                  {t('marketing_onboarding_modal_unique_selling_points_gameplay_label')}
                </Text>
                <Text style={{ color: colors.gray80 }}>1/4</Text>
              </Flex>
            </Card>

            <RadioGroup
              name='gameplay'
              value={uniqueSellingPoint.gameplay}
              onChange={(e) =>
                setUniqueSellingPoint({ ...uniqueSellingPoint, gameplay: e.target.value })
              }
              style={{ width: '100%' }}>
              <Row gutter={[10, 14]} style={{ width: '100%', paddingLeft: 30, paddingRight: 30 }}>
                <Col span={12}>
                  <RadioItem
                    label='Story and Narrative'
                    value='Story and Narrative'
                    isSelected={uniqueSellingPoint.gameplay === 'Story and Narrative'}
                  />
                </Col>
                <Col span={12}>
                  <RadioItem
                    label='Exploration and Open World'
                    value='Exploration and Open World'
                    isSelected={uniqueSellingPoint.gameplay === 'Exploration and Open World'}
                  />
                </Col>
                <Col span={12}>
                  <RadioItem
                    label='Casual and Relaxing'
                    value='Casual and Relaxing'
                    isSelected={uniqueSellingPoint.gameplay === 'Casual and Relaxing'}
                  />
                </Col>
                <Col span={12}>
                  <RadioItem
                    label='Action and Combat'
                    value='Action and Combat'
                    isSelected={uniqueSellingPoint.gameplay === 'Action and Combat'}
                  />
                </Col>
              </Row>
            </RadioGroup>
          </Flex>

          <Flex vertical align='center' gap={25}>
            <Card
              size='small'
              style={{
                width: 360,
                backgroundColor: colors.gray30,
                borderRadius: 10,
                border: 0,
              }}>
              <Flex align='center' justify='space-between'>
                <Text style={{ color: colors.gray80, fontWeight: 'bold' }}></Text>
                <Text style={{ color: colors.gray80, fontWeight: 'bold' }}>
                  {t('marketing_onboarding_modal_unique_selling_points_social_interaction_label')}
                </Text>
                <Text style={{ color: colors.gray80 }}>2/4</Text>
              </Flex>
            </Card>

            <RadioGroup
              name='socialInteraction'
              value={uniqueSellingPoint.socialInteraction}
              onChange={(e) =>
                setUniqueSellingPoint({
                  ...uniqueSellingPoint,
                  socialInteraction: e.target.value,
                })
              }
              style={{ width: '100%' }}>
              <Row gutter={[10, 14]} style={{ width: '100%', paddingLeft: 30, paddingRight: 30 }}>
                <Col span={12}>
                  <RadioItem
                    label='Competitive Multiplayer'
                    value='Competitive Multiplayer'
                    isSelected={uniqueSellingPoint.socialInteraction === 'Competitive Multiplayer'}
                  />
                </Col>
                <Col span={12}>
                  <RadioItem
                    label='Cooperative Multiplayer'
                    value='Cooperative Multiplayer'
                    isSelected={uniqueSellingPoint.socialInteraction === 'Cooperative Multiplayer'}
                  />
                </Col>
                <Col span={12}>
                  <RadioItem
                    label='Social and Community'
                    value='Social and Community'
                    isSelected={uniqueSellingPoint.socialInteraction === 'Social and Community'}
                  />
                </Col>
                <Col span={12}>
                  <RadioItem
                    label='Solo Play'
                    value='Solo Play'
                    isSelected={uniqueSellingPoint.socialInteraction === 'Solo Play'}
                  />
                </Col>
              </Row>
            </RadioGroup>
          </Flex>

          <Flex vertical align='center' gap={25}>
            <Card
              size='small'
              style={{
                width: 360,
                backgroundColor: colors.gray30,
                borderRadius: 10,
                border: 0,
              }}>
              <Flex align='center' justify='space-between'>
                <Text style={{ color: colors.gray80, fontWeight: 'bold' }}></Text>
                <Text style={{ color: colors.gray80, fontWeight: 'bold' }}>
                  {t('marketing_onboarding_modal_unique_selling_points_monetization_label')}
                </Text>
                <Text style={{ color: colors.gray80 }}>3/4</Text>
              </Flex>
            </Card>

            <RadioGroup
              name='monetizationAndEngagement'
              value={uniqueSellingPoint.monetizationAndEngagement}
              onChange={(e) =>
                setUniqueSellingPoint({
                  ...uniqueSellingPoint,
                  monetizationAndEngagement: e.target.value,
                })
              }
              style={{ width: '100%' }}>
              <Row gutter={[10, 14]} style={{ width: '100%', paddingLeft: 30, paddingRight: 30 }}>
                <Col span={12}>
                  <RadioItem
                    label='In-App Ads only'
                    value='In-App Ads only'
                    isSelected={uniqueSellingPoint.monetizationAndEngagement === 'In-App Ads only'}
                  />
                </Col>
                <Col span={12}>
                  <RadioItem
                    label='In-App Purchases only'
                    value='In-App Purchases only'
                    isSelected={
                      uniqueSellingPoint.monetizationAndEngagement === 'In-App Purchases only'
                    }
                  />
                </Col>
                <Col span={12}>
                  <RadioItem
                    label='Both IAA and IAP, with a focus on IAA'
                    value='Both IAA and IAP, with a focus on IAA'
                    isSelected={
                      uniqueSellingPoint.monetizationAndEngagement ===
                      'Both IAA and IAP, with a focus on IAA'
                    }
                  />
                </Col>
                <Col span={12}>
                  <RadioItem
                    label='Both IAA and IAP, with a focus on IAP'
                    value='Both IAA and IAP, with a focus on IAP'
                    isSelected={
                      uniqueSellingPoint.monetizationAndEngagement ===
                      'Both IAA and IAP, with a focus on IAP'
                    }
                  />
                </Col>
              </Row>
            </RadioGroup>
          </Flex>

          <Flex vertical align='center' gap={25}>
            <Card
              size='small'
              style={{
                width: 360,
                backgroundColor: colors.gray30,
                borderRadius: 10,
                border: 0,
              }}>
              <Flex align='center' justify='space-between'>
                <Text style={{ color: colors.gray80, fontWeight: 'bold' }}></Text>
                <Text style={{ color: colors.gray80, fontWeight: 'bold' }}>
                  {t('marketing_onboarding_modal_unique_selling_points_other_label')} (
                  {t('general_optional')})
                </Text>
                <Text style={{ color: colors.gray80 }}>4/4</Text>
              </Flex>
            </Card>

            <TextArea
              variant='transparent'
              rows={5}
              value={uniqueSellingPoint.otherInsights}
              onChange={(e) =>
                setUniqueSellingPoint({
                  ...uniqueSellingPoint,
                  otherInsights: e.target.value,
                })
              }
              placeholder={t('marketing_onboarding_modal_unique_selling_points_other_placeholder')}
            />
          </Flex>
        </Flex>
      </Flex>
    </Row>
  )
}

const RadioItem = ({ value, label, isSelected }: IRadioItem) => {
  return (
    <Radio
      value={value}
      style={{
        width: '100%',
        flex: 1,
        paddingTop: 17,
        paddingBottom: 17,
        paddingLeft: 20,
        paddingRight: 20,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: isSelected ? colors.gray60 : colors.gray40,
        borderRadius: 8,
        cursor: 'pointer',
      }}>
      {label}
    </Radio>
  )
}
