import { colorStyles, sizeStyles } from './style'
import IconLibProps from './type'

export default function IconLib({
  refs,
  icon,
  size = 'sm',
  color = 'base',
  onClick,
}: IconLibProps) {
  return (
    <div
      ref={refs}
      style={{
        height: sizeStyles[size],
        width: sizeStyles[size],
        color: colorStyles[color],
        lineHeight: 1,
        cursor: onClick ? 'pointer' : 'inherit',
      }}>
      <div onClick={onClick} dangerouslySetInnerHTML={{ __html: icon.data }}></div>
    </div>
  )
}
