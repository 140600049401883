import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

const TermsOfServicePage = lazy(() => import('pages/terms-of-service'))

const TermsOfServiceRouter = (
  <Route
    path='terms-of-service'
    element={
      <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
        <TermsOfServicePage />
      </Suspense>
    }></Route>
)

export default TermsOfServiceRouter
