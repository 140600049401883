import colors from 'theme/colors'

import { IMediationAdUnitPreview } from './type'

export default function MediationAdUnitPreview({ mediaUrl, width = 240 }: IMediationAdUnitPreview) {
  const borderWidth = `${width / 10}px ${width / 20}px`

  return (
    <div
      style={{
        position: 'relative',
      }}>
      <video
        src={mediaUrl}
        autoPlay={true}
        loop
        muted
        width={width}
        style={{
          borderStyle: 'solid',
          borderColor: colors.gray90,
          borderRadius: 16,
          borderWidth: borderWidth,
        }}
      />
    </div>
  )
}
