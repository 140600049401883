export const formatCurrency = (amount: number | string, decimal?: number) => {
  const formattedAmount = amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimal !== undefined ? decimal : 2,
  })

  return formattedAmount
}

export const formatNumber = (number: number | string, decimal?: number) => {
  const formattedNumber = number.toLocaleString('en-US', {
    maximumFractionDigits: decimal !== undefined ? decimal : 2,
  })
  return formattedNumber
}

export const formatStringToId = (label: string) => {
  return label.replace(/\s+/g, '-').replace('(', '').replace(')', '').toLowerCase()
}
