import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  GameStoreIntegrationMethod,
  GameStoreIntegrationStatus,
  GetGameStoresByGameIdQuery,
  GetGameStoresByGameIdQueryVariables,
  GetIntegrationBySlugQuery,
  GetIntegrationBySlugQueryVariables,
  IntegrationRequestMutation,
  IntegrationRequestMutationVariables,
} from '__generated__/gql/graphql'
import { Flex, Image, Modal, Spin, Typography } from 'antd'
import { useCallback, useMemo } from 'react'
import { integrationRequest } from 'services/graphql/mutations'
import { getGameStoresByGameId, getIntegrationBySlug } from 'services/graphql/queries'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Title, Text } = Typography

export default function IntegrationModalConfirmation() {
  const client = useApolloClient()

  const {
    isIntegrationConfirmationModalShow,
    selectedIntegrationSlug,
    showIntegrationRequestFeedbackModal,
    closeAll,
  } = useModalStore()
  const { selectedGame } = useUserStore()

  const { data: gameStores, loading: isGameStoresLoading } = useQuery<
    GetGameStoresByGameIdQuery,
    GetGameStoresByGameIdQueryVariables
  >(getGameStoresByGameId, {
    variables: { gameId: selectedGame?.id || '' },
    skip:
      !selectedGame ||
      Object.keys(selectedGame).length === 0 ||
      !isIntegrationConfirmationModalShow,
  })

  const { data: integrationData, loading: isIntegrationLoading } = useQuery<
    GetIntegrationBySlugQuery,
    GetIntegrationBySlugQueryVariables
  >(getIntegrationBySlug, {
    variables: { slug: selectedIntegrationSlug },
    skip:
      !selectedGame ||
      Object.keys(selectedGame).length === 0 ||
      !isIntegrationConfirmationModalShow,
  })

  const [integrate, { loading }] = useMutation<
    IntegrationRequestMutation,
    IntegrationRequestMutationVariables
  >(integrationRequest)

  const integration = useMemo(() => {
    return integrationData?.getIntegrationBySlug
  }, [integrationData])

  const handleIntegrate = useCallback(() => {
    if (!gameStores?.getGameStoresByGameId?.length) return

    integrate({
      variables: {
        gameStoreId: gameStores.getGameStoresByGameId[0].id,
        gameStoreIntegration: {
          slug: selectedIntegrationSlug,
          status: GameStoreIntegrationStatus.InReview,
          method: GameStoreIntegrationMethod.FullSdk,
        },
      },
    }).then(() => {
      client.refetchQueries({
        include: ['GetGameStoreIntegrationBySlug'],
      })
      closeAll()
      showIntegrationRequestFeedbackModal()
    })
  }, [gameStores, selectedIntegrationSlug])

  return (
    <Modal
      title={false}
      open={isIntegrationConfirmationModalShow}
      footer={false}
      centered
      width={560}
      onCancel={closeAll}>
      {integration && !isIntegrationLoading && !isGameStoresLoading ? (
        <Flex vertical justify='center' align='center' gap={20} style={{ padding: '0 48px' }}>
          {integration.iconUrl && (
            <Image
              src={integration.iconUrl}
              height={50}
              width={50}
              preview={false}
              style={{ borderRadius: 4 }}
            />
          )}

          <Flex vertical gap={10} align='center'>
            <Title level={5} style={{ color: colors.gray90, margin: 0, textAlign: 'center' }}>
              Are you sure you want to activate <br />
              <span>{integration.name}</span> ?
            </Title>
            <Text style={{ color: colors.gray70, textAlign: 'center' }}>
              Once you confirm, the add-on will be in the sidebar menu and you are ready to use it
              to optimize your game.
            </Text>
          </Flex>
          <Flex gap={40} style={{ marginTop: 20 }}>
            <LabsButton width='wide' variant='outline' onClick={closeAll} isDisabled={loading}>
              Cancel
            </LabsButton>
            <LabsButton width='wide' onClick={handleIntegrate} isLoading={loading}>
              Confirm
            </LabsButton>
          </Flex>
        </Flex>
      ) : (
        <Flex
          vertical
          justify='center'
          align='center'
          gap={10}
          style={{ height: 500, width: '100%' }}>
          <Spin />
        </Flex>
      )}
    </Modal>
  )
}
