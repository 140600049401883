import { CheckCircleOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import {
  AddOnAction,
  AddOnProductQuery,
  AddOnProductQueryVariables,
  AddOnStatus,
  GameAddOnsQuery,
  GameAddOnsQueryVariables,
  SendAddOnNotificationMutation,
  SendAddOnNotificationMutationVariables,
} from '__generated__/gql/graphql'
import { Button, Divider, Flex, Image, Modal, Spin, Tag, Typography } from 'antd'
import { capitalize } from 'lodash'
import { CSSProperties, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { sendAddOnNotification } from 'services/graphql/mutations'
import { addOnProduct, gameAddOns } from 'services/graphql/queries'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useIsPermissionGranted from 'hooks/useIsPermissionGranted'

import { fontColorStyle, tagColorStyle } from 'components/modules/add-ons/product-item/styles'

import colors from 'theme/colors'

import { GamePublisherUserPermissionEnum } from 'types/permission'

const { Title, Text } = Typography

const textColor: CSSProperties = {
  color: colors.gray90,
  margin: 0,
}

const disableButtonInterested: CSSProperties = {
  backgroundColor: colors.labs30,
  border: 'none',
  color: colors.labs70,
  fontWeight: 'bold',
}

export default function ProductModal() {
  const { t } = useTranslation()

  const {
    isProductModalShow,
    closeProductModal,
    selectedAddOnProductSlug,
    showProductConfirmationModal,
  } = useModalStore()
  const { selectedGame } = useUserStore()
  const { isPermissionGranted } = useIsPermissionGranted(
    GamePublisherUserPermissionEnum.ADD_NEW_ADD_ONS,
  )

  const { data: addOnData, loading: isAddOnLoading } = useQuery<
    AddOnProductQuery,
    AddOnProductQueryVariables
  >(addOnProduct, {
    variables: { productSlug: selectedAddOnProductSlug },
    skip: !selectedAddOnProductSlug || !isProductModalShow,
  })

  const { data: gameAddOnData, loading: isGameAddOnsLoading } = useQuery<
    GameAddOnsQuery,
    GameAddOnsQueryVariables
  >(gameAddOns, {
    variables: { gameId: selectedGame?.id },
    skip: !selectedGame || !selectedGame.id || !isProductModalShow,
  })

  const [createAddOnNotification] = useMutation<
    SendAddOnNotificationMutation,
    SendAddOnNotificationMutationVariables
  >(sendAddOnNotification)

  const product = useMemo(() => {
    return addOnData?.addOn
  }, [addOnData])

  const isProductAdded: boolean = useMemo(() => {
    if (gameAddOnData?.gameAddOns.find((addOn) => addOn.id === product?.id)) return true
    else return false
  }, [gameAddOnData, addOnData])

  const handleClickContactSupport = useCallback(() => {
    window.location.href = 'mailto:support-labs.tech@noctua.gg'

    createAddOnNotification({
      variables: {
        id: selectedGame.id,
        addOn: product?.title ?? '',
        action: AddOnAction.ContactSupport,
      },
    })
  }, [product, selectedGame])

  const handleInterested = () => {
    closeProductModal()
    showProductConfirmationModal()
  }

  return (
    <Modal
      title={false}
      open={isProductModalShow}
      footer={false}
      centered
      width={700}
      onCancel={() => closeProductModal()}>
      {product && !isAddOnLoading && !isGameAddOnsLoading ? (
        <Flex vertical gap={20}>
          {product.iconUrl && (
            <Image
              preview={false}
              src={product.iconUrl}
              width={50}
              height={50}
              style={{ borderRadius: 4 }}
              alt='product-icon'
            />
          )}
          <Flex vertical gap={10}>
            <Flex gap={20} align='center'>
              <Title level={4} style={{ margin: 0, padding: 0, ...textColor }}>
                {product.title}
              </Title>
              <Flex align='center'>
                <Tag
                  color={tagColorStyle[product.tags]}
                  style={{
                    padding: '0px 10px',
                    color: fontColorStyle[product.tags],
                    borderRadius: 20,
                  }}>
                  {capitalize(product.tags.replace('_', ' '))}
                </Tag>
                {product.status === AddOnStatus.ComingSoon && (
                  <Tag
                    color={colors.gray30}
                    style={{
                      padding: '0px 10px',
                      color: colors.gray70,
                      borderRadius: 20,
                    }}>
                    Coming Soon
                  </Tag>
                )}
              </Flex>
            </Flex>
            <Text style={{ color: colors.gray70, width: '80%' }}>{product.description}</Text>
          </Flex>
          <Flex vertical gap={10}>
            <Flex gap={15} align='center'>
              <Button
                style={isProductAdded ? disableButtonInterested : {}}
                disabled={isProductAdded || !isPermissionGranted}
                type='primary'
                loading={isGameAddOnsLoading}
                onClick={handleInterested}
                icon={isProductAdded ? <CheckCircleOutlined /> : undefined}>
                {t('add_on_interested_button')}
              </Button>
              <Button
                type='link'
                onClick={handleClickContactSupport}
                style={{
                  padding: 0,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #3685F1',
                  borderRadius: 6,
                }}>
                <img
                  src='/images/icon/contact-support-icon.webp'
                  alt='Contact Support'
                  style={{ height: 32, width: 'auto' }} // Set max height to be 100% of the button
                />
              </Button>
            </Flex>
            {isProductAdded && (
              <Text
                style={{
                  color: colors.labs70,
                  fontWeight: 400,
                  fontSize: 12,
                }}>
                We will reach you in 1 business day. Contact our support if you haven&apos;t
                received anything.
              </Text>
            )}
          </Flex>
          {(Object.keys(product.keyFeatures).length > 0 || product.keyFeatures) && (
            <>
              <Divider />
              <Flex vertical gap={10} style={{ maxHeight: 350, overflowY: 'auto' }}>
                {product.objective && (
                  <Flex vertical>
                    <Title level={5} style={textColor}>
                      Objective :
                    </Title>
                    <Text style={textColor}>{product.objective}</Text>
                  </Flex>
                )}
                {Object.keys(product.keyFeatures).length > 0 && (
                  <Flex vertical>
                    <Title level={5} style={textColor}>
                      Key Features:
                    </Title>
                    <div>
                      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        {Object.entries(product.keyFeatures as Record<string, string>).map(
                          ([key, value]) => (
                            <li key={key}>
                              <Flex vertical>
                                <Text style={{ color: colors.gray90, fontWeight: 500 }}>{key}</Text>
                                <Text style={{ color: colors.gray90 }}>{value}</Text>
                              </Flex>
                            </li>
                          ),
                        )}
                      </ul>
                    </div>
                  </Flex>
                )}
              </Flex>
            </>
          )}
        </Flex>
      ) : (
        <Flex
          vertical
          justify='center'
          align='center'
          gap={10}
          style={{ height: 500, width: '100%' }}>
          <Spin />
        </Flex>
      )}
    </Modal>
  )
}
