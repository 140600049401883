import { lazy, Suspense } from 'react'
import { Navigate, Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

import { USER_ACQUISITION_ROUTE } from 'constants/Routes'

import TabNavigationLayout from 'components/layouts/TabNavigationLayout'
import AuthenticatedRoute from 'components/routes/AuthenticatedRoute'
import PermissionGuardedRoute from 'components/routes/PermissionGuardedRoute'

import { GamePublisherUserPermissionEnum } from 'types/permission'

const PublishAdsPage = lazy(() => import('pages/create-ads/index'))
const MyAdsPage = lazy(() => import('pages/my-ads'))
const items = [
  {
    key: 'my-ads',
    label: 'My Ads',
  },
  {
    key: 'publish-ads',
    label: 'Publish Ads',
  },
]

const UserAcquisitionRouter = (
  <Route path={USER_ACQUISITION_ROUTE}>
    <Route path='' element={<Navigate to='my-ads' />} />
    <Route
      path='publish-ads'
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <TabNavigationLayout
              path={USER_ACQUISITION_ROUTE}
              items={items}
              showGameSelection={true}>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.CREATE_NEW_ADS}
                hasTab>
                <PublishAdsPage />
              </PermissionGuardedRoute>
            </TabNavigationLayout>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
    <Route
      path='my-ads'
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <TabNavigationLayout
              path={USER_ACQUISITION_ROUTE}
              items={items}
              showGameSelection={true}>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_PAGE_MY_ADS}
                hasTab>
                <MyAdsPage />
              </PermissionGuardedRoute>
            </TabNavigationLayout>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
  </Route>
)

export default UserAcquisitionRouter
