import { Card, Flex, Image, Typography } from 'antd'

import useModalStore from 'stores/useModalStore'

import IconLib from 'components/commons/icon-lib'
import { labsArrowRight } from 'components/commons/icon-lib/icons/base'

import colors from 'theme/colors'

const { Text } = Typography
export default function HeaderPublisherInfoApplyFreeCredit() {
  const { showBalanceLimitedOfferModal } = useModalStore()

  return (
    <Card
      bodyStyle={{ padding: '4px 8px' }}
      style={{ borderRadius: 20, backgroundColor: colors.success10, cursor: 'pointer' }}
      onClick={showBalanceLimitedOfferModal}>
      <Flex align='center' gap={12}>
        <Image
          src='/images/icon/discount.webp'
          height={16}
          preview={false}
          style={{ verticalAlign: 'baseline' }}
        />
        <Text style={{ color: colors.success70, fontWeight: 700, fontSize: 10 }}>
          FREE $200 credits
        </Text>
        <IconLib icon={labsArrowRight} color='success70' size='xs' />
      </Flex>
    </Card>
  )
}
