import { Flex, Image, Select, Typography } from 'antd'
import { useCallback, useMemo } from 'react'

import { stringToColor } from 'utils/ColorUtils'
import { getFirstCharacters } from 'utils/GenerateNameAllias'

import useUserStore from 'stores/useUserStore'

import colors from 'theme/colors'

import IconLib from '../icon-lib'
import { labsAndroid, labsApple } from '../icon-lib/icons/colored'
import { IGameSelection } from './type'

const { Text } = Typography
export default function GameSelection({ showGameStore = true }: IGameSelection) {
  const { games, selectedGame, selectedGameStore, setSelectedGame, setSelectedGameStore } =
    useUserStore()

  const gameOptions = useMemo(() => {
    return games.map((game) => ({
      disabled: !game.isIntegrated,
      label: (
        <Flex align='center' gap={10}>
          <Flex flex={0}>
            {game.logo ? (
              <Image
                preview={false}
                src={game.logo}
                height={24}
                width={24}
                style={{ borderRadius: 9999, verticalAlign: 'top' }}
              />
            ) : (
              <Flex
                justify='center'
                align='center'
                style={{
                  height: 24,
                  width: 24,
                  backgroundColor: stringToColor(game.name),
                  borderRadius: 9999,
                }}>
                <Text
                  style={{
                    fontSize: 10,
                    color: colors.steel10,
                    textTransform: 'uppercase',
                  }}>
                  {getFirstCharacters(game.name)}
                </Text>
              </Flex>
            )}
          </Flex>
          <span
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {game.name}
          </span>
        </Flex>
      ),
      value: game.id,
    }))
  }, [games])

  const gameStoreOptions = useMemo(() => {
    if (!selectedGame?.id) return []
    if (!showGameStore) return []
    return selectedGame.stores
      .filter((store) => store.isBasicScaleIntegrated)
      .map((store) => ({
        label: (
          <Flex align='center' gap={10}>
            {store.os === 'Android' && <IconLib icon={labsAndroid} />}
            {store.os === 'iOS' && <IconLib icon={labsApple} />}
            <Text style={{ color: colors.gray90 }}>{store.os}</Text>
          </Flex>
        ),
        value: store.id,
      }))
  }, [games, showGameStore, selectedGame])

  const handleChangeGame = useCallback(
    (gameId: string) => {
      const game = games.find((game) => game.id === gameId)
      if (game) setSelectedGame(game)

      const integratedStore = game?.stores.filter((store) => store.isBasicScaleIntegrated) || []
      if (integratedStore.length) setSelectedGameStore(integratedStore[0])
      else setSelectedGameStore(game?.stores[0])
    },
    [games],
  )

  const handleChangeGameStore = useCallback(
    (gameId: string) => {
      const gameStore = selectedGame.stores.find((gameStore) => gameStore.id === gameId)
      if (gameStore) setSelectedGameStore(gameStore)
    },
    [selectedGame],
  )

  return (
    <Flex align='center' gap={15}>
      <Select
        size='large'
        value={selectedGame.id}
        placeholder='Select Game'
        options={gameOptions}
        onChange={handleChangeGame}
        style={{ width: '100%', minWidth: 200 }}
      />

      {showGameStore && selectedGameStore && (
        <Select
          // className='transparent'
          size='large'
          value={selectedGameStore.id}
          placeholder='Select Game Store'
          options={gameStoreOptions}
          onChange={handleChangeGameStore}
          style={{ minWidth: 130 }}
        />
      )}
    </Flex>
  )
}
