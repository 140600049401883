import { InputNumber as AntdInputNumber } from 'antd'
import React, { CSSProperties } from 'react'

import colors from 'theme/colors'

import { IInputNumber, Variant } from './type'

const variantStyle: { [k in Variant]: CSSProperties } = {
  primary: {
    borderColor: colors.gray40,
    color: colors.gray90,
  },
  transparent: {},
}

export default function InputNumber(props: IInputNumber) {
  return (
    <AntdInputNumber
      size='large'
      className={props.variant || 'primary'}
      style={{ ...variantStyle[props.variant || 'primary'], width: '100%', borderRadius: 6 }}
      {...props}
    />
  )
}
