import { createContext, useContext } from 'react'

export enum GamePublisherUserPermissionEnum {
  VIEW_PAGE_HOME = 'view_page_home',
  UPDATE_ONBOARDING_STATE = 'update_onboarding_state',
  VIEW_PAGE_MY_ADS = 'view_page_my_ads',
  CREATE_NEW_ADS = 'create_new_ads',
  PUBLISH_MY_ADS = 'publish_my_ads',
  VIEW_PAGE_ADS_INSPIRATION = 'view_page_ads_inspiration',
  EDIT_PREFERENCES_ADS_INSPIRATION = 'edit_preferences_ads_inspiration',
  VIEW_PAGE_ANALYTICS_MARKETING = 'view_page_analytics_marketing',
  VIEW_PAGE_ANALYTICS_REVENUE = 'view_page_analytics_revenue',
  VIEW_PAGE_ANALYTICS_GAME = 'view_page_analytics_game',
  VIEW_PAGE_INTEGRATION = 'view_page_integration',
  VIEW_PAGE_ADD_ONS = 'view_page_add_ons',
  ADD_NEW_ADD_ONS = 'add_new_add_ons',
  VIEW_PAGE_LAUNCH = 'view_page_launch',
  VIEW_PAGE_SCALE = 'view_page_scale',
  VIEW_PAGE_MONETIZE = 'view_page_monetize',
  VIEW_PAGE_LAUNCH_STORE = 'view_page_launch_store',
  VIEW_PAGE_LAUNCH_MANAGE_BUNDLE = 'view_page_launch_manage_bundle',
  VIEW_PAGE_LAUNCH_INQUIRY = 'view_page_launch_inquiry',
  VIEW_PAGE_GAME_ANALYTICS = 'view_page_game_analytics',
  VIEW_PAGE_MARKETING_ANALYTICS = 'view_page_marketing_analytics',
  VIEW_PAGE_INFRASTRUCTURE = 'view_page_infrastructure',
  VIEW_PAGE_ACCOUNT_SETTING = 'view_page_account_setting',
  UPDATE_MY_GAMES = 'update_my_games',
  VIEW_PAGE_GAME_MANAGEMENT = 'view_page_game_management',
  VIEW_MEDIATION_PAGE = 'view_page_mediation',
  VIEW_FINANCING_PAGE = 'view_page_financing',
  UPDATE_ACCOUNT_SETTING = 'update_account_setting',
  TOPUP_BALANCE = 'topup_balance',
  EXPORT_ANALYTICS_DATA = 'export_analytics_data',
  REQUEST_BALANCE_LIMITED_OFFER = 'request_balance_limited_offer',
}

interface PermissionContextType {
  isAuthenticated: boolean
}

export const PermissionContext = createContext<PermissionContextType | undefined>(undefined)
export const usePermissionContext = () => {
  const context = useContext(PermissionContext)
  if (context) return context
  return {
    isAuthenticated: true,
  }
}
