import { Button, Flex, Modal, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import useModalStore from 'stores/useModalStore'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function InsufficientBalanceModal() {
  const { t } = useTranslation()
  const { isInsufficientBalanceModalShow, showTopupModal } = useModalStore()
  const { closeAll } = useModalStore()

  const handleTopup = () => {
    closeAll()
    showTopupModal(true)
  }

  return (
    <Modal
      title={false}
      open={isInsufficientBalanceModalShow}
      footer={false}
      centered
      onCancel={closeAll}>
      <Flex vertical justify='center' align='center' gap={20}>
        <img src='/images/illustration/insufficient-balance.webp' width={94} height={94} />
        <Space direction='vertical' align='center' size={2} style={{ textAlign: 'center' }}>
          <Title level={4} style={{ color: colors.navy80, margin: 0, fontWeight: 500 }}>
            {t('scale_insufficient_balance_modal_title')}
          </Title>
          <Text style={{ color: colors.navy70 }}>
            {t('scale_insufficient_balance_modal_description')}
          </Text>
        </Space>
        <Button type='primary' onClick={handleTopup}>
          {t('scale_insufficient_balance_modal_topup_button')}
        </Button>
      </Flex>
    </Modal>
  )
}
