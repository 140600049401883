import { useLazyQuery } from '@apollo/client'
import {
  GamePublisherStatus,
  GameStoreStatus,
  GetGamePublisherUserQuery,
  GetGamePublisherUserQueryVariables,
} from '__generated__/gql/graphql'
import { message } from 'antd'
import Cookies from 'js-cookie'
import { getGamePublisherUser } from 'services/graphql/queries'

import { COOKIE_KEY_AUTH_TOKEN } from 'constants/CookieKeys'

import { CountryGroup } from 'stores/useCreateAdsStore'
import useGeneralStore from 'stores/useGeneralStore'
import useUserStore from 'stores/useUserStore'

import useLogout from './useLogout'
import useTracker from './useTracker'

export default function useUserInfo() {
  const { handleLogout } = useLogout()
  const {
    user,
    selectedGame,
    selectedGameStore,
    setGames,
    setUser,
    setSelectedGame,
    setSelectedGameStore,
    setHasGameCreative,
    setHasIntegratedGame,
    setHasIntegratedMediation,
  } = useUserStore()
  const tracker = useTracker()
  const { setGeneralInfo } = useGeneralStore()

  const [getGamePublisherUserInfo] = useLazyQuery<
    GetGamePublisherUserQuery,
    GetGamePublisherUserQueryVariables
  >(getGamePublisherUser, { fetchPolicy: 'no-cache' })

  const getGames = async () => {
    await getGamePublisherUserInfo()
      .then((res) => {
        if (!res.data || !res.data.gamePublisherUser) return

        const { gamePublisherUser } = res.data
        const gamePublisher = gamePublisherUser.gamePublisher || null

        if (!gamePublisher) return
        tracker.register({
          user_status: gamePublisherUser.status.toLowerCase() || null,
          user_balance: typeof gamePublisher.balance === 'number' ? gamePublisher.balance : null,
        })

        const games = gamePublisher.games
          .filter((game) => game.stores && game.stores.length >= 1)
          .map((game) => {
            const mappedCountries = game.countries.map((country) => {
              return { code: country.code, name: country.name }
            })

            const mappedBlacklistedCountries = game.blacklistedCountries.map((country) => {
              return { code: country.code, name: country.name }
            })

            const mappedCountryGroups = game.countryGroups.map((countryGroup) => ({
              code: countryGroup.code,
              name: countryGroup.name,
              isMultipleCountryGroup: true,
            }))

            const combinedCountries: CountryGroup[] = []
            game.countries.map((country) => combinedCountries.push(country))
            game.countryGroups.map((country) =>
              combinedCountries.push({ ...country, isMultipleCountryGroup: true }),
            )

            return {
              id: game.id,
              name: game.name,
              logo: game.gameLogoUrl || undefined,
              integrateInAppAds: game.integrateInAppAds,
              integrateInAppPurchase: game.integrateInAppPurchase,
              countries: mappedCountries,
              blacklistedCountries: mappedBlacklistedCountries,
              countryGroups: mappedCountryGroups,
              tags: game.tags,
              genre: game.genres,
              integrationStatus: game.sdkIntegrationStatus,
              isIntegrated: game.stores?.some((store) => store.isBasicScaleIntegrated) || false,
              uniqueSellingPoints: game.uniqueSellingPoints || '',
              dailyBudget: game.dailyBudget || 0,
              androidDailyBudget: game.androidDailyBudget || 0,
              iosDailyBudget: game.iosDailyBudget || 0,
              isAutoBudgetAllocation: game.isAutoBudgetAllocation,
              googleBundleId: game.googleBundleId || '',
              similarGames: game.similarGames || [],
              similarGameSubmissions:
                game.similarGameSubmissions.map((gameSubmission) => gameSubmission.name) || [],
              hasCreatives: game.isAnyCreatives || false,
              isCheckCreativeValidation: game.isCheckCreativeValidation || false,
              stores:
                game.stores
                  ?.filter((store) => store.status !== GameStoreStatus.Inactive)
                  .map((store) => ({
                    id: store.id,
                    url: store.url || '',
                    name: store.store.name,
                    os: store.store.os as string,
                    iconUrl: store.store.iconUrl || undefined,
                    mediationId: store.mediation.length ? store.mediation[0].id : undefined,
                    packageName: store.bundleId || null,
                    integrations: store.integrations,
                    qsdkProductCode: store.qsdkProductCode || null,
                    isBasicScaleIntegrated: store.isBasicScaleIntegrated,
                    isMediationIntegrated: store.isMediationIntegrated,
                  })) || [],
            }
          })

        // const integratedGame = games.filter((game) => game.isIntegrated)
        // const activeGame = integratedGame.length
        // ? integratedGame
        // : games.filter((game) => game.stores.length >= 1)
        const activeGame = games.filter((game) => game.stores.length >= 1)

        setGames(activeGame)

        // const integratedGame = games.filter((game) => game.isIntegrated)
        // if (JSON.stringify(selectedGame) === '{}' && !!integratedGame.length) {
        //   setSelectedGame(integratedGame[0])
        // } else {
        //   setSelectedGame(games[0])
        // }

        if (!activeGame.length || (activeGame.length && !activeGame[0].stores.length)) return

        if (selectedGame?.id) {
          const game = activeGame.find((game) => game.id === selectedGame.id)
          if (game) setSelectedGame(game)
          else setSelectedGame(activeGame[0])
        } else setSelectedGame(activeGame[0])

        if (selectedGameStore) {
          const selectedStore = activeGame
            .map((game) => game.stores)
            .flat()
            .find((store) => store.id === selectedGameStore.id)
          if (selectedStore) setSelectedGameStore(selectedStore)
          else setSelectedGameStore(activeGame[0].stores[0])
        } else setSelectedGameStore(activeGame[0].stores[0])

        const hasCreative = games.some((game) => game.hasCreatives)
        if (hasCreative) setHasGameCreative(true)

        const hasIntegratedGame = games.find((game) =>
          game.stores.find((store) => store.isBasicScaleIntegrated),
        )
        if (hasIntegratedGame) setHasIntegratedGame(true)

        const hasIntegratedMediation = games.find((game) =>
          game.stores.find((store) => store.isMediationIntegrated),
        )
        if (hasIntegratedMediation) setHasIntegratedMediation(true)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const getUserInfo = async () => {
    if (!Cookies.get(COOKIE_KEY_AUTH_TOKEN)) {
      handleLogout()
      return
    }

    await getGamePublisherUserInfo()
      .then((res) => {
        if (!res.data || !res.data.gamePublisherUser) return
        const { gamePublisherUser } = res.data
        const gpuStatus = gamePublisherUser?.status || 'PENDING'

        if (gpuStatus !== 'ACTIVE') {
          message.error(`Please activate your account`)
          handleLogout()
          return
        }

        const gamePublisher = gamePublisherUser.gamePublisher || null
        if (!gamePublisher) {
          message.error(`Your account not registered for any publisher games`)
          handleLogout()
          return
        }

        const roles = gamePublisherUser.roles?.map((role) => role.slug) || []
        setUser({
          ...user,
          email: gamePublisherUser.email,
          uid: gamePublisherUser.uid,
          displayName: gamePublisherUser.displayName,
          balance: gamePublisher.balance,
          status: gamePublisherUser.status || false,
          isDemo: gamePublisher.isDemo,
          companyName: gamePublisher.companyName,
          companySize: gamePublisher.companySize || '',
          roles: roles,
          gamePublisherStatus: gamePublisher.status || GamePublisherStatus.WaitList,
          gameEngine: gamePublisher.gameEngine || '',
          isUseFullSDK: gamePublisherUser?.gamePublisher?.isUseFullSDK || false,
        })

        setGeneralInfo({
          showBalanceLimitedOffer: gamePublisherUser.showBalanceLimitedOffer,
          showOnboard: gamePublisherUser.showOnboard,
          hasFirstTopupBalance: gamePublisherUser.hasFirstTopupBalance,
        })

        tracker.register({
          user_id: gamePublisherUser.uid || null,
          user_status: gamePublisherUser.status.toLowerCase() || null,
          user_demo: gamePublisher.isDemo ? true : false,
          user_balance: typeof gamePublisher.balance === 'number' ? gamePublisher.balance : null,
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return {
    getUserInfo,
    getGames,
  }
}
