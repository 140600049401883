import type { ThemeConfig } from 'antd'

import colors from 'theme/colors'

const theme: ThemeConfig = {
  token: {
    fontFamily: 'DMS Sans',
    fontFamilyCode: 'DMS Sans',

    // Color
    colorPrimary: colors.labs120,
    colorText: colors.secondary,

    // Sizing
    fontSizeHeading1: 54,
    fontSizeHeading2: 36,
    fontSizeHeading3: 24,
    fontSizeHeading4: 20,
    fontSizeHeading5: 16,
    fontSize: 14,
    fontSizeSM: 12,
  },
  components: {
    Typography: {
      colorTextHeading: colors.labs120,
      colorLink: colors.labs120,
      linkDecoration: 'none',
      colorLinkHover: colors.labs120,
    },
    Layout: {},
    Menu: {
      itemBg: 'transparent',
      subMenuItemBg: 'transparent',
      itemColor: colors.gray80,
      itemHoverBg: '#DAEDFF',
      itemHoverColor: '#3685F1',
      itemSelectedBg: '#DAEDFF',
      itemSelectedColor: '#3685F1',
      fontSize: 14,

      // horizontalItemBorderRadius: 9999,
      // activeBarBorderWidth: 4,
      // itemPaddingInline: 0,
    },
    Button: {
      // Background Color
      colorPrimary: colors.labs120,
      colorPrimaryBorder: colors.labs120,
      colorPrimaryHover: colors.labs110,
      colorPrimaryActive: colors.labs130,

      defaultColor: colors.gray70,
      defaultBg: 'transparent',
      defaultBorderColor: colors.gray60,

      colorLink: colors.labs120,
      colorLinkHover: colors.labs110,
      colorLinkActive: colors.labs90,

      // Font Color
      primaryColor: colors.gray10,

      // Font Size
      fontSize: 14,
      fontSizeLG: 14,

      // Padding
      paddingContentHorizontal: 30,
      paddingContentVertical: 8,
      paddingContentHorizontalLG: 24,
      paddingContentVerticalLG: 14,

      // Border Radius
      borderRadius: 6,
      borderRadiusLG: 8,
    },
    Input: {
      fontSizeLG: 14,
    },
    InputNumber: {
      fontSizeLG: 14,
      colorBorder: colors.gray40,
      colorBgContainer: colors.gray10,
      colorText: colors.gray70,
      borderRadiusLG: 6,
      colorTextPlaceholder: colors.gray60,
      colorIcon: colors.gray70,
    },
    Select: {
      colorBgContainer: colors.gray10,
      colorBorder: colors.gray40,
      colorText: colors.gray90,
      borderRadiusLG: 8,
      fontSizeLG: 14,
      colorTextPlaceholder: colors.gray60,
      colorIcon: colors.gray70,
    },
    Dropdown: {
      borderRadius: 6,
    },
    Card: {
      colorBorder: 'transparent',
      boxShadow: '0px 4px 12px 0px rgba(74, 100, 124, 0.09)',
    },
    Upload: {
      colorFillAlter: colors.navy20,
      colorBorder: colors.labs30,
      colorText: colors.labs50,
      fontSize: 27,
      lineHeight: 0,
    },
    Tooltip: {
      colorBgSpotlight: colors.steel10,
      borderRadius: 9999,
      padding: 0,
      sizePopupArrow: 30,
    },
    Carousel: {
      dotHeight: 6,
      dotWidth: 6,
      dotActiveWidth: 6,
    },
    Radio: {
      buttonBg: colors.labs10,
      buttonCheckedBg: colors.labs20,
      buttonColor: colors.labs60,
    },
    Table: {
      headerBorderRadius: 8,
      borderRadius: 8,
      headerSplitColor: 'transparent',
      bodySortBg: 'transparent',
      headerSortActiveBg: colors.gray20,
      headerSortHoverBg: colors.gray20,
      fixedHeaderSortActiveBg: colors.gray20,
      cellPaddingBlock: 16,
      headerBg: colors.gray20,
      headerColor: colors.gray90,
      colorText: colors.gray90,
      borderColor: colors.gray20,
    },
    Slider: {
      borderRadiusXS: 16,
      railBg: colors.labs40,
      railHoverBg: colors.labs40,
      railSize: 11,
      trackBg: colors.labs40,
      trackHoverBg: colors.labs40,
      handleColor: colors.labs120,
      handleSize: 12,
      handleSizeHover: 15,
      handleLineWidth: 8,
      handleLineWidthHover: 8,
    },
    DatePicker: {
      colorText: colors.gray90,
    },
  },
}

export default theme
