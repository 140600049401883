import { PropsWithChildren } from 'react'

import BalanceLimitedOfferModal from 'components/commons/modal/balance-limited-offer-modal'
import BalanceLimitedOfferSubmittedModal from 'components/commons/modal/balance-limited-offer-submitted-modal'
import BasicScaleIntegrationMethodModal from 'components/commons/modal/basic-scale-integration-method-modal'
import ChooseTemplateAdUnitModal from 'components/commons/modal/choose-template-ad-unit-modal'
import ComingSoonModal from 'components/commons/modal/coming-soon-modal'
import CreateAdUnitModal from 'components/commons/modal/create-ad-unit-modal'
import CreateNewMemberModal from 'components/commons/modal/create-new-member-modal'
import CreativeInspirationFilterModal from 'components/commons/modal/creative-inspiration-filter-modal'
import CreativePreviewModal from 'components/commons/modal/creative-preview-modal'
import CreativeSubmittedModal from 'components/commons/modal/creative-submitted-modal'
import CreativeSubmittingModal from 'components/commons/modal/creative-submitting-modal'
import DeleteAdUnitConfirmationModal from 'components/commons/modal/delete-ad-unit-confirmation-modal'
import DeleteGameStoreConfirmationModal from 'components/commons/modal/delete-game-store-confirmation-modal'
import DetailAdUnitModal from 'components/commons/modal/detail-ad-unit-modal'
import GameDetailModal from 'components/commons/modal/detail-game-modal'
import EditAdUnitModal from 'components/commons/modal/edit-ad-unit-modal'
import EditGameModal from 'components/commons/modal/edit-game-modal'
import EditMemberRoleModal from 'components/commons/modal/edit-member-role-modal'
import FeedbackModal from 'components/commons/modal/feedback-modal'
import ForgotPasswordModal from 'components/commons/modal/forgot-password-modal'
import GuidelineCreativesUploadModal from 'components/commons/modal/guideline-creatives-upload-modal'
import InsufficientBalanceModal from 'components/commons/modal/insufficient-balance'
import IntegrationAdjustTokenModal from 'components/commons/modal/integration-adjust-token-modal'
import IntegrationCompletionConfirmationModal from 'components/commons/modal/integration-completion-confirmation-modal'
import IntegrationModalConfirmation from 'components/commons/modal/integration-modal-confirmation'
import IntegrationNotEligibleCompletionConfirmationModal from 'components/commons/modal/integration-not-eligible-completion-confirmation-modal'
import IntegrationRequestFeedback from 'components/commons/modal/integration-request-feedback'
import LabsSupportModal from 'components/commons/modal/labs-support-modal'
import MarketingOnboardingModal from 'components/commons/modal/marketing-onboarding-modal'
import MediationDataGlossaryModal from 'components/commons/modal/mediation-data-glossary-modal'
import MediationIntegratedModal from 'components/commons/modal/mediation-integrated-modal'
import MediationIntegrationCompletionConfirmationModal from 'components/commons/modal/mediation-integration-completion-confirmation-modal'
import NoPermissionModal from 'components/commons/modal/no-permission-modal'
import NotAllowedModal from 'components/commons/modal/not-allowed-modal'
import ProductModal from 'components/commons/modal/product-modal'
import ProductConfirmationModal from 'components/commons/modal/product-modal-confirmation'
import ProductInterestFeedbackModal from 'components/commons/modal/product-modal-interest-feedback'
import ReviewCreativesModal from 'components/commons/modal/review-creatives-modal'
import ScaleEditGamePreferenceModal from 'components/commons/modal/scale-edit-game-preference-modal'
import ScaleIntegrationRequiredModal from 'components/commons/modal/scale-integration-required-modal'
import CreativeDateTimeModal from 'components/commons/modal/set-creative-date-time-modal'
import SetDailyBudgetModal from 'components/commons/modal/set-daily-budget-modal'
import SetDisplayNameModal from 'components/commons/modal/set-display-name-modal'
import StartOnboardModal from 'components/commons/modal/start-onboard-modal'
import TopUpModal from 'components/commons/modal/topup-modal'
import TopupPendingModal from 'components/commons/modal/topup-pending-modal'
import TopupSuccessModal from 'components/commons/modal/topup-success-modal'
import WaitingCurrentIntegration from 'components/commons/modal/waiting-current-integration'
import WarningIncompleteDataModal from 'components/commons/modal/warning-incomplete-data-modal'

import RemoveRoleConfirmationModal from '../commons/modal/remove-user-role-confirmation/index'

export default function ModalRegister({ children }: PropsWithChildren) {
  return (
    <>
      <CreativeSubmittedModal />
      <CreativeSubmittingModal />
      <GuidelineCreativesUploadModal />
      <ReviewCreativesModal />
      <CreativeDateTimeModal />
      <InsufficientBalanceModal />
      <TopUpModal />
      <TopupPendingModal />
      <TopupSuccessModal />
      <SetDailyBudgetModal />

      <ScaleEditGamePreferenceModal />
      <CreativeInspirationFilterModal />
      <ComingSoonModal />
      <ScaleIntegrationRequiredModal />
      <WaitingCurrentIntegration />
      <ProductModal />
      <ProductConfirmationModal />
      <ProductInterestFeedbackModal />
      <IntegrationModalConfirmation />
      <IntegrationRequestFeedback />
      <FeedbackModal />
      <LabsSupportModal />
      <MediationDataGlossaryModal />
      <SetDisplayNameModal />
      <NoPermissionModal />
      <ForgotPasswordModal />
      <BasicScaleIntegrationMethodModal />
      <IntegrationCompletionConfirmationModal />
      <IntegrationNotEligibleCompletionConfirmationModal />
      <IntegrationAdjustTokenModal />
      <CreateAdUnitModal />
      <EditAdUnitModal />
      <DeleteAdUnitConfirmationModal />
      <DetailAdUnitModal />
      <StartOnboardModal />
      <RemoveRoleConfirmationModal />
      <CreateNewMemberModal />
      <EditMemberRoleModal />
      <BalanceLimitedOfferModal />
      <MarketingOnboardingModal />
      <BalanceLimitedOfferSubmittedModal />
      <NotAllowedModal />
      <GameDetailModal />
      <DeleteGameStoreConfirmationModal />
      <EditGameModal />
      <ChooseTemplateAdUnitModal />
      <MediationIntegrationCompletionConfirmationModal />
      <MediationIntegratedModal />
      <CreativePreviewModal />
      <WarningIncompleteDataModal />
      {children}
    </>
  )
}
