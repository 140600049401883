import { useQuery } from '@apollo/client'
import { AddOnProductQuery, AddOnProductQueryVariables } from '__generated__/gql/graphql'
import { Flex, Image, Modal, Spin, Typography } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { addOnProduct } from 'services/graphql/queries'

import useModalStore from 'stores/useModalStore'

import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Title, Text } = Typography

export default function ProductInterestFeedbackModal() {
  const { t } = useTranslation()

  const { isProductInterestFeedbackModalShow, selectedAddOnProductSlug, closeAll } = useModalStore()

  const { data: addOnData, loading: isAddOnLoading } = useQuery<
    AddOnProductQuery,
    AddOnProductQueryVariables
  >(addOnProduct, {
    variables: { productSlug: selectedAddOnProductSlug },
    skip: !selectedAddOnProductSlug || !isProductInterestFeedbackModalShow,
  })

  const product = useMemo(() => {
    return addOnData?.addOn
  }, [addOnData])

  return (
    <Modal
      title={false}
      open={isProductInterestFeedbackModalShow}
      footer={false}
      centered
      width={600}
      closable={false}>
      {product && !isAddOnLoading ? (
        <Flex vertical justify='center' align='center' gap={30} style={{ padding: '24px 48px' }}>
          {product.iconUrl && (
            <Image
              src={product.iconUrl}
              height={50}
              width={50}
              preview={false}
              style={{ borderRadius: 4 }}
            />
          )}

          <Flex vertical gap={10} align='center'>
            <Title level={5} style={{ color: colors.gray90, margin: 0 }}>
              Thank you for your interest
            </Title>
            <Text style={{ color: colors.gray70, textAlign: 'center' }}>
              We will send an email notification when the {product.title} is ready
            </Text>
          </Flex>
          <LabsButton width='wide' onClick={closeAll}>
            {t('general_okay')}
          </LabsButton>
        </Flex>
      ) : (
        <Flex
          vertical
          justify='center'
          align='center'
          gap={10}
          style={{ height: 500, width: '100%' }}>
          <Spin />
        </Flex>
      )}
    </Modal>
  )
}
