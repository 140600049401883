import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  AddAddOnProductMutation,
  AddAddOnProductMutationVariables,
  AddOnProductQuery,
  AddOnProductQueryVariables,
} from '__generated__/gql/graphql'
import { Flex, Image, message, Modal, Spin, Typography } from 'antd'
import { useCallback, useMemo } from 'react'
import { addAddOnProduct } from 'services/graphql/mutations'
import { addOnProduct } from 'services/graphql/queries'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Title, Text } = Typography

export default function ProductConfirmationModal() {
  const client = useApolloClient()

  const {
    isProductConfirmationModalShow,
    selectedAddOnProductSlug,
    showProductInterestFeedbackModalShow,
    showProductModal,
    closeAll,
  } = useModalStore()
  const { user, selectedGame } = useUserStore()

  const { data: addOnData, loading: isAddOnLoading } = useQuery<
    AddOnProductQuery,
    AddOnProductQueryVariables
  >(addOnProduct, {
    variables: { productSlug: selectedAddOnProductSlug },
    skip: !selectedAddOnProductSlug || !isProductConfirmationModalShow,
  })

  const [addProduct, { loading }] = useMutation<
    AddAddOnProductMutation,
    AddAddOnProductMutationVariables
  >(addAddOnProduct)

  const product = useMemo(() => {
    return addOnData?.addOn
  }, [addOnData])

  const handleAddAddOn = useCallback(() => {
    if (!product) return

    addProduct({
      variables: {
        productId: product.id,
        gameId: selectedGame.id,
      },
    }).then(() => {
      message.success(`add-on successfully added`)
      client.refetchQueries({
        include: ['GameAddOns', 'AddOnIntegration'],
      })
      closeAll()
      showProductInterestFeedbackModalShow()
    })
  }, [user, addOnData, product, selectedGame])

  const handleCancel = useCallback(() => {
    closeAll()
    showProductModal(selectedAddOnProductSlug)
  }, [selectedAddOnProductSlug])

  return (
    <Modal
      title={false}
      open={isProductConfirmationModalShow}
      footer={false}
      centered
      width={560}
      onCancel={handleCancel}>
      {product && !isAddOnLoading ? (
        <Flex vertical justify='center' align='center' gap={20} style={{ padding: '0 48px' }}>
          {product.iconUrl && (
            <Image
              src={product.iconUrl}
              height={50}
              width={50}
              preview={false}
              style={{ borderRadius: 4 }}
            />
          )}

          <Flex vertical gap={10} align='center'>
            <Title level={5} style={{ color: colors.gray90, margin: 0, textAlign: 'center' }}>
              Are you sure yout want to activate <br />
              <span>{product.title}</span> ?
            </Title>
            <Text style={{ color: colors.gray70, textAlign: 'center' }}>
              Once you confirm, the add-on will be in the sidebar menu and you are ready to use it
              to optimize your game.
            </Text>
          </Flex>
          <Flex gap={40} style={{ marginTop: 20 }}>
            <LabsButton width='wide' variant='outline' onClick={handleCancel} isDisabled={loading}>
              Cancel
            </LabsButton>
            <LabsButton width='wide' onClick={handleAddAddOn} isLoading={loading}>
              Confirm
            </LabsButton>
          </Flex>
        </Flex>
      ) : (
        <Flex
          vertical
          justify='center'
          align='center'
          gap={10}
          style={{ height: 500, width: '100%' }}>
          <Spin />
        </Flex>
      )}
    </Modal>
  )
}
