import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationCN from './translation_cn.json'
import translationEN from './translation_en.json'
import translationID from './translation_id.json'

const resources = {
  en: {
    translation: translationEN,
  },
  id: {
    translation: translationID,
  },
  cn: {
    translation: translationCN,
  },
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en', //default language
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
