import { useMutation } from '@apollo/client'
import {
  CreateActivityHistoriesMutation,
  CreateActivityHistoriesMutationVariables,
} from '__generated__/gql/graphql'
import { Flex, Image, Modal, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { createActivityHistories } from 'services/graphql/mutations'

import { GETTING_STARTED_ROUTE } from 'constants/Routes'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function StartOnboardModal() {
  const navigate = useNavigate()
  const { isStartOnboardModalShow, closeAll } = useModalStore()

  const { selectedGame, user } = useUserStore()

  const [createActivities] = useMutation<
    CreateActivityHistoriesMutation,
    CreateActivityHistoriesMutationVariables
  >(createActivityHistories)

  const handleStartOnboard = () => {
    createActivities({
      variables: {
        activityHistories: [
          {
            key: `game_id_${selectedGame.id}_onboarding_started`,
            value: `${user.uid}`,
          },
        ],
      },
    })

    closeAll()
    navigate(`/${GETTING_STARTED_ROUTE}`)
  }

  return (
    <Modal
      title={false}
      open={isStartOnboardModalShow}
      footer={false}
      centered
      width={492}
      closable={false}
      styles={{ content: { overflow: 'hidden' } }}>
      <div style={{ position: 'absolute', bottom: -60, left: 0, zIndex: 0 }}>
        <Image src='/images/background/background-gradient.webp' preview={false} />
      </div>
      <Flex
        vertical
        align='center'
        justify='center'
        style={{ width: '100%', height: '100%', position: 'relative', zIndex: 1 }}>
        <Image
          src='/images/illustration/login-carousel-ilustration-4.webp'
          height={240}
          preview={false}
        />
        <Flex vertical gap={12} align='center' style={{ width: 340, marginBottom: 50 }}>
          <Title level={4} style={{ color: colors.gray90, fontWeight: 'bold', margin: 0 }}>
            Welcome to Labs!
          </Title>
          <Text style={{ fontSize: 14, color: colors.gray70, textAlign: 'center' }}>
            We can help you <span style={{ fontWeight: 700 }}>optimize your revenue</span> in your
            game environment, from user acquisition to monetization, revenue-based financing, and
            more.
          </Text>
          <Text style={{ fontSize: 14, color: colors.gray70, textAlign: 'center' }}>
            But before you can enjoy all of that, let us help you prepare everything.{' '}
            <span style={{ fontWeight: 700 }}>Follow the step-by-step guide</span> we have prepared
            so you can go through it easily.
          </Text>
        </Flex>
        <LabsButton width='wide' onClick={handleStartOnboard}>
          Get Started
        </LabsButton>
      </Flex>
    </Modal>
  )
}
