import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

import { INFRASTRUCTURE_ROUTE } from 'constants/Routes'

import AuthenticatedRoute from 'components/routes/AuthenticatedRoute'
import PermissionGuardedRoute from 'components/routes/PermissionGuardedRoute'

import { GamePublisherUserPermissionEnum } from 'types/permission'

const InfrastructurePage = lazy(() => import('pages/infrastructure'))

const InfrastructureRouter = (
  <Route
    path={INFRASTRUCTURE_ROUTE}
    element={
      <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
        <AuthenticatedRoute>
          <PermissionGuardedRoute
            permission={GamePublisherUserPermissionEnum.VIEW_PAGE_INFRASTRUCTURE}>
            <InfrastructurePage />
          </PermissionGuardedRoute>
        </AuthenticatedRoute>
      </Suspense>
    }
  />
)

export default InfrastructureRouter
