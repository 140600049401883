import { Flex, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import colors from 'theme/colors'

import { useLayoutContext } from 'types/layout'

const { Title, Text } = Typography
type PermissionDeniedProps = {
  height?: number | string
}

export default function PermissionDeniedPage({ height }: PermissionDeniedProps) {
  const { t } = useTranslation()
  const { authenticatedHeaderHeight, headerHeight, footerHeight } = useLayoutContext()
  return (
    <div
      style={{
        height:
          height ??
          `calc(100vh - ${authenticatedHeaderHeight}px - ${headerHeight}px - ${footerHeight}px)`,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Flex
        vertical
        gap={20}
        align='center'
        justify='center'
        style={{ width: '100%', height: '100%' }}>
        <img src='/images/illustration/forbidden.webp' height={250} width={250} />
        <Flex vertical gap={12} align='center'>
          <Title level={4} style={{ color: colors.gray90, fontWeight: 'bold', margin: 0 }}>
            {t('forbidden_page_no_allowed_title')}
          </Title>
          <Flex vertical align='center'>
            <Text style={{ fontSize: 14, color: colors.gray70 }}>
              {t('forbidden_page_no_allowed_text_1')}
            </Text>
            <Text style={{ fontSize: 14, color: colors.gray70 }}>
              {t('forbidden_page_no_allowed_text_2')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </div>
  )
}
