import { TimeRangePickerProps } from 'antd'
import dayjs from 'dayjs'

export const COMPANY_SIZE_OPTIONS = [
  { label: '1-10 employees', value: '1-10 employees' },
  { label: '11-50 employees', value: '11-50 employees' },
  { label: '51-200 employees', value: '51-200 employees' },
  { label: '>200 employees', value: '>200 employees' },
]

export const HOURS_OPTIONS = [
  { label: '00:00', value: '00:00' },
  { label: '00:30', value: '00:30' },
  { label: '01:00', value: '01:00' },
  { label: '01:30', value: '01:30' },
  { label: '02:00', value: '02:00' },
  { label: '02:30', value: '02:30' },
  { label: '03:00', value: '03:00' },
  { label: '03:30', value: '03:30' },
  { label: '04:00', value: '04:00' },
  { label: '04:30', value: '04:30' },
  { label: '05:00', value: '05:00' },
  { label: '05:30', value: '05:30' },
  { label: '06:00', value: '06:00' },
  { label: '06:30', value: '06:30' },
  { label: '07:00', value: '07:00' },
  { label: '07:30', value: '07:30' },
  { label: '08:00', value: '08:00' },
  { label: '08:30', value: '08:30' },
  { label: '09:00', value: '09:00' },
  { label: '09:30', value: '09:30' },
  { label: '10:00', value: '10:00' },
  { label: '10:30', value: '10:30' },
  { label: '11:00', value: '11:00' },
  { label: '11:30', value: '11:30' },
]

export const HOUR_UNIT_OPTIONS = [
  { label: 'AM', value: 'AM' },
  { label: 'PM', value: 'PM' },
]

export type TOPUP_VALUE = '500' | '1000' | '2000' | '3000' | '5000'
export const TOPUP_VALUE_OPTIONS = [
  { label: '500', value: '500' },
  { label: '1000', value: '1000' },
  { label: '2000', value: '2000' },
  { label: '3000', value: '3000' },
  { label: '5000', value: '5000' },
]

export const CREATIVE_INSPIRATION_COUNTRY = [
  { id: 'ID', name: 'Indonesia' },
  { id: 'TH', name: 'Thailand' },
  { id: 'MY', name: 'Malaysia' },
  { id: 'PH', name: 'Philippines' },
  { id: 'SG', name: 'Singapore' },
  { id: 'SEA', name: 'All SEA' },
]

export const LANGUAGE_OPTIONS = [
  { code: 'en', label: 'EN', name: 'english', path: 'translation_en.json' },
  // { code: 'id', label: 'ID', name: 'indonesia', path: 'translation_id.json' },
  { code: 'cn', label: 'CN', name: 'chinese', path: 'translation_cn.json' },
]

export const DATA_CHECKER_LIMIT_DURATION_OPTIONS = [
  { label: 'Last 1 hour', value: '1' },
  { label: 'Last 3 hours', value: '3' },
  { label: 'Last 6 hours', value: '6' },
  { label: 'Last 12 hours', value: '12' },
]

export const rangePresets: TimeRangePickerProps['presets'] = [
  { label: 'Last 7 Days', value: [dayjs().subtract(8, 'd'), dayjs().subtract(2, 'd')] },
  { label: 'Last 14 Days', value: [dayjs().subtract(15, 'd'), dayjs().subtract(2, 'd')] },
  { label: 'Last 30 Days', value: [dayjs().subtract(31, 'd'), dayjs().subtract(2, 'd')] },
  { label: 'Last 2 months', value: [dayjs().subtract(61, 'd'), dayjs().subtract(2, 'd')] },
  { label: 'Last 3 months', value: [dayjs().subtract(91, 'd'), dayjs().subtract(2, 'd')] },
]
