import { lazy, Suspense } from 'react'
import { Navigate, Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

import { MEDIATION_BEST_PRACTICE_AD_TYPE, MEDIATION_ROUTE } from 'constants/Routes'

import AuthenticatedRoute from 'components/routes/AuthenticatedRoute'
import PermissionGuardedRoute from 'components/routes/PermissionGuardedRoute'

import { GamePublisherUserPermissionEnum } from 'types/permission'

const MediationPage = lazy(() => import('pages/mediation'))
const MediationCreateAdUnitPage = lazy(() => import('pages/mediation/create-ad-unit'))
const MediationDeleteAdUnitPage = lazy(() => import('pages/mediation/delete-ad-unit'))
// const MediationCaseStudyPage = lazy(() => import('pages/mediation/case-study'))
const MediationCaseStudyHamsterJumpPage = lazy(
  () => import('pages/mediation/case-study/hamster-jump'),
)
const MediationBestPracticePage = lazy(() => import('pages/mediation/best-practice'))
const MediationBestPracticeAdTypePage = lazy(() => import('pages/mediation/best-practice/ad-type'))
const MediationInitializationSetupPage = lazy(() => import('pages/mediation/initialization-setup'))

const MediationRouter = (
  <Route path={MEDIATION_ROUTE}>
    <Route
      path=''
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <PermissionGuardedRoute
              permission={GamePublisherUserPermissionEnum.VIEW_MEDIATION_PAGE}>
              <MediationPage />
            </PermissionGuardedRoute>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
    <Route
      path='initialization-setup'
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <PermissionGuardedRoute
              permission={GamePublisherUserPermissionEnum.VIEW_MEDIATION_PAGE}>
              <MediationInitializationSetupPage />
            </PermissionGuardedRoute>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
    <Route
      path='create-ad-unit'
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <PermissionGuardedRoute
              permission={GamePublisherUserPermissionEnum.VIEW_MEDIATION_PAGE}>
              <MediationCreateAdUnitPage />
            </PermissionGuardedRoute>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
    <Route
      path='delete-ad-unit'
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <PermissionGuardedRoute
              permission={GamePublisherUserPermissionEnum.VIEW_MEDIATION_PAGE}>
              <MediationDeleteAdUnitPage />
            </PermissionGuardedRoute>
          </AuthenticatedRoute>
        </Suspense>
      }
    />

    <Route path='case-study'>
      <Route path='' element={<Navigate to='hamster-jump' />} />
      {/* <Route
        path=''
        element={
          <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
            <AuthenticatedRoute>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_MEDIATION_PAGE}>
                <MediationDeleteAdUnitPage />
              </PermissionGuardedRoute>
            </AuthenticatedRoute>
          </Suspense>
        }
      /> */}

      <Route
        path='hamster-jump'
        element={
          <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
            <AuthenticatedRoute>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_MEDIATION_PAGE}>
                <MediationCaseStudyHamsterJumpPage />
              </PermissionGuardedRoute>
            </AuthenticatedRoute>
          </Suspense>
        }
      />
    </Route>
    <Route path='best-practice'>
      {/* <Route
        path=''
        element={
          <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
            <AuthenticatedRoute>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_MEDIATION_PAGE}>
                <MediationBestPracticePage />
              </PermissionGuardedRoute>
            </AuthenticatedRoute>
          </Suspense>
        }
      /> */}
      <Route path='' element={<Navigate to={`/${MEDIATION_BEST_PRACTICE_AD_TYPE}banner`} />} />
      <Route
        path=':ad-type'
        element={
          <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
            <AuthenticatedRoute>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_MEDIATION_PAGE}>
                <MediationBestPracticeAdTypePage />
              </PermissionGuardedRoute>
            </AuthenticatedRoute>
          </Suspense>
        }
      />
    </Route>
  </Route>
)

export default MediationRouter
