import { Flex, Modal, Typography } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { INTEGRATION_BASIC_SCALE_CHECKER_ROUTE } from 'constants/Routes'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useIntegration from 'hooks/useIntegration'
import useNavigateWithQueryParams from 'hooks/useNavigateWithQueryParams'

import LabsButton from 'components/commons/labs-kit/button'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function IntegrationNotEligibleCompletionConfirmationModal() {
  const navigateWithParams = useNavigateWithQueryParams()
  const { t } = useTranslation()

  const { selectedGameStore } = useUserStore()
  const { hybridWizardStep } = useIntegration()

  const { isIntegrationNotEligibleCompletionConfirmationModalShow, closeAll } = useModalStore()

  const handleGoToIntegration = useCallback(() => {
    if (!selectedGameStore) return

    closeAll()

    if (hybridWizardStep)
      navigateWithParams(`/${INTEGRATION_BASIC_SCALE_CHECKER_ROUTE}`, {
        gs_id: selectedGameStore.id,
        hybrid_step: hybridWizardStep,
      })
    else
      navigateWithParams(`/${INTEGRATION_BASIC_SCALE_CHECKER_ROUTE}`, {
        gs_id: selectedGameStore.id,
      })
  }, [selectedGameStore, hybridWizardStep])

  return (
    <Modal
      open={isIntegrationNotEligibleCompletionConfirmationModalShow}
      title={false}
      centered
      footer={false}
      onCancel={closeAll}
      width={623}>
      <Flex
        vertical
        align='center'
        justify='space-between'
        gap={32}
        style={{ padding: '20px 16px' }}>
        <Flex vertical align='center' gap={12}>
          <Title level={4} style={{ margin: 0, color: colors.gray90 }}>
            {t('modal_integration_completion_not_complete_modal_title')}
          </Title>
          <Text style={{ color: colors.gray90, textAlign: 'center', padding: '0 36px' }}>
            {t('modal_integration_completion_not_complete_modal_description_1')} &quot;
            <span style={{ fontWeight: 700 }}>
              {t('modal_integration_completion_not_complete_modal_description_2')}
            </span>
            &quot; {t('modal_integration_completion_not_complete_modal_description_3')}
          </Text>
        </Flex>
        <LabsButton variant='outline' onClick={handleGoToIntegration}>
          {t('modal_integration_completion_not_complete_modal_button')}
        </LabsButton>
      </Flex>
    </Modal>
  )
}
