import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { CreativeInspirationFilter } from 'pages/ads-inspiration/type'

import { CountryGroup } from './useCreateAdsStore'

type View = 'grid' | 'masonry'
interface CreativesState {
  view: View
  bookmarkedInspiration: string[]
  filter: CreativeInspirationFilter

  setView: (view: View) => void
  setBookmarkedInspiration: (ids: string[]) => void
  setFilter: (filter: CreativeInspirationFilter) => void
  resetState: () => void
}

const useInspirationStore = create<CreativesState>()(
  persist(
    (set) => ({
      view: 'grid',
      bookmarkedInspiration: [],
      filter: {
        country: {} as CountryGroup,
        bookmark: false,
        channel: [],
        orientation: [],
        mediaType: [],
        tags: [],
      },

      setView: (view: View) => set((state) => ({ ...state, view })),
      setBookmarkedInspiration: (ids: string[]) =>
        set((state) => ({ ...state, bookmarkedInspiration: ids })),
      setFilter: (filter: CreativeInspirationFilter) =>
        set((state) => ({ ...state, filter: filter })),
      resetState: () =>
        set(() => ({
          view: 'grid',
          bookmarkedInspiration: [],
          filter: {
            country: {} as CountryGroup,
            bookmark: false,
            channel: [],
            orientation: [],
            mediaType: [],
            tags: [],
          },
        })),
    }),
    {
      name: 'inspiration-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useInspirationStore
