import dayjs from 'dayjs'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface GameManagementState {
  platformSelected: string
  dateValue: string[] | undefined

  setPlatformSelected: (plaftform: string) => void
  setDateValue: (date: string[] | undefined) => void
  resetState: () => void
}

const useGameManagementStore = create<GameManagementState>()(
  persist(
    (set) => ({
      platformSelected: '',
      setPlatformSelected: (platformSelected: string) =>
        set((state) => ({ ...state, platformSelected: platformSelected })),

      dateValue: undefined,
      setDateValue: (date: string[] | undefined) => set((state) => ({ ...state, dateValue: date })),
      resetState: () =>
        set(() => ({
          platformSelected: '',
          dateValue: undefined,
        })),
    }),
    {
      name: 'game-management-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useGameManagementStore
