import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

const GameDetailInformationPage = lazy(() => import('pages/game-info/detail'))
const MyGamePage = lazy(() => import('pages/my-game'))
const AddGamePage = lazy(() => import('pages/my-game/add-game'))
const AddPlatformPage = lazy(() => import('pages/my-game/add-platform'))

MyGamePage
const GameRouter = (
  <Route path='game'>
    <Route
      path=''
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <MyGamePage />
        </Suspense>
      }
    />
    <Route
      path='add-game'
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AddGamePage />
        </Suspense>
      }
    />
    <Route
      path='add-platform'
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AddPlatformPage />
        </Suspense>
      }
    />
    <Route path='information'>
      <Route
        path='detail'
        element={
          <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
            <GameDetailInformationPage />
          </Suspense>
        }
      />
    </Route>
  </Route>
)

export default GameRouter
