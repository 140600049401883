import { Flex, Menu, MenuProps, Tooltip, Typography } from 'antd'
import Sider from 'antd/es/layout/Sider'
import { CSSProperties, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  ADD_ON_ROUTE,
  ADS_INSPIRATION_ROUTE,
  COMPARISON_GAME_ANALYTICS_ROUTE,
  DAILY_MARKETING_MARKETING_ANALYTICS_ROUTE,
  DAILY_MONITORING_GAME_ANALYTICS_ROUTE,
  GAME_MANAGEMENT_ROUTE,
  GETTING_STARTED_ROUTE,
  INDEX_ROUTE,
  INFRASTRUCTURE_ROUTE,
  INTEGRATION_ROUTE,
  MEDIATION_ROUTE,
  RETENTION_MARKETING_ANALYTICS_ROUTE,
  USER_ACQUISITION_ROUTE,
} from 'constants/Routes'

import useMenuStore from 'stores/useMenuStore'
import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useIsPermissionGranted from 'hooks/useIsPermissionGranted'

import colors from 'theme/colors'

import { GamePublisherUserPermissionEnum } from 'types/permission'

import IconLib from '../icon-lib'
import {
  labsGameManagement,
  labsLock,
  labsMenuAddOn,
  labsMenuAdsAnalytics,
  labsMenuAdsInspiration,
  labsMenuAnalytics,
  labsMenuDashboard,
  labsMenuDocumentation,
  labsMenuFinancing,
  labsMenuGameAnalytics,
  labsMenuInfrastructure,
  labsMenuIntegration,
  labsMenuMediation,
  labsMenuMyAds,
  labsSupport,
  labsWarningCircle,
} from '../icon-lib/icons/base'

const navigationStyle: CSSProperties = {
  textAlign: 'center',
  lineHeight: '120px',
  backgroundColor: colors.gray20,
}

const { Text } = Typography
export default function SideNavigation() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isExpanded, activeNavigation, activeGroupNavigation, setActiveGroupNavigation } =
    useMenuStore()
  const { showLabsSupportModal } = useModalStore()
  const { user } = useUserStore()
  const { isPermissionGranted: isHaveGameAnalyticsPermission } = useIsPermissionGranted(
    GamePublisherUserPermissionEnum.VIEW_PAGE_GAME_ANALYTICS,
  )

  const { isPermissionGranted: isHaveMarketingAnalyticsPermission } = useIsPermissionGranted(
    GamePublisherUserPermissionEnum.VIEW_PAGE_MARKETING_ANALYTICS,
  )

  const { isPermissionGranted: hasCreativeHubPermission } = useIsPermissionGranted(
    GamePublisherUserPermissionEnum.VIEW_PAGE_ADS_INSPIRATION,
  )

  const { isPermissionGranted: hasGameManagementPermission } = useIsPermissionGranted(
    GamePublisherUserPermissionEnum.VIEW_PAGE_GAME_MANAGEMENT,
  )

  const { isPermissionGranted: hasInfrastructurePermission } = useIsPermissionGranted(
    GamePublisherUserPermissionEnum.VIEW_PAGE_INFRASTRUCTURE,
  )

  const handleShowInternalGameDashboardGroup = useCallback(() => {
    const isOpened = activeGroupNavigation.includes('game-analytics')
    if (isOpened)
      setActiveGroupNavigation(activeGroupNavigation.filter((group) => group !== 'game-analytics'))
    else setActiveGroupNavigation(activeGroupNavigation.concat('game-analytics'))
  }, [activeGroupNavigation])

  const handleShowInternalMarketingDashboardGroup = useCallback(() => {
    const isOpened = activeGroupNavigation.includes('marketing-analytics')
    if (isOpened)
      setActiveGroupNavigation(
        activeGroupNavigation.filter((group) => group !== 'marketing-analytics'),
      )
    else setActiveGroupNavigation(activeGroupNavigation.concat('marketing-analytics'))
  }, [activeGroupNavigation])

  const openedKeys = useMemo(() => {
    if (isExpanded) return activeGroupNavigation
    else return []
  }, [isExpanded, activeGroupNavigation])

  useEffect(() => {
    if (
      ['analytics/marketing', 'analytics/revenue', 'analytics/game'].includes(activeNavigation) &&
      !activeGroupNavigation.includes('analytics')
    ) {
      setActiveGroupNavigation(activeGroupNavigation.concat('analytics'))
    }

    if (
      ['my-ads', 'ads-inspiration'].includes(activeNavigation) &&
      !activeGroupNavigation.includes('marketing')
    ) {
      setActiveGroupNavigation(activeGroupNavigation.concat('marketing'))
    }

    if (
      ['game-analytics/daily-monitoring', 'game-analytics/comparison'].includes(activeNavigation) &&
      !activeGroupNavigation.includes('game-analytics')
    ) {
      setActiveGroupNavigation(activeGroupNavigation.concat('game-analytics'))
    }

    if (
      ['marketing-analytics/daily-marketing', 'marketing-analytics/retention'].includes(
        activeNavigation,
      ) &&
      !activeGroupNavigation.includes('marketing-analytics')
    ) {
      setActiveGroupNavigation(activeGroupNavigation.concat('marketing-analytics'))
    }
  }, [activeNavigation])

  type MenuItem = Required<MenuProps>['items'][number]

  const mainMenu = useMemo(() => {
    const mainMenuItems: MenuItem[] = [
      {
        key: 'dashboard',
        icon: (
          <Flex align='center' style={{ height: 40 }}>
            <IconLib icon={labsMenuAnalytics} size='lg' color='none' />
          </Flex>
        ),
        label: t('menu_dashboard'),
        // label: t('menu_getting_started'),
        onClick: () => navigate(INDEX_ROUTE),
      },
      {
        key: 'user-acquisition',
        label: t('menu_user_acquisition'),
        icon: (
          <Flex align='center' style={{ height: 40 }}>
            <IconLib icon={labsMenuMyAds} size='lg' color='none' />
          </Flex>
        ),
        onClick: () => navigate(`/${USER_ACQUISITION_ROUTE}`),
      },
      {
        key: 'mediation',
        icon: (
          <Flex align='center' style={{ height: 40 }}>
            <IconLib icon={labsMenuMediation} size='lg' color='none' />
          </Flex>
        ),
        label: t('menu_easy_monetization'),
        onClick: () => navigate(`/${MEDIATION_ROUTE}`),
      },
      {
        key: 'financing',
        className: 'coming-soon',
        icon: (
          <Flex align='center' style={{ height: 40 }}>
            <IconLib icon={labsMenuFinancing} size='lg' color='none' />
          </Flex>
        ),
        label: (
          <Tooltip
            title={<span style={{ color: 'black', fontSize: 12 }}>Coming Soon</span>}
            placement='right'>
            <Flex align='center' gap={6}>
              {t('menu_financing')} <IconLib icon={labsLock} size='xs' />
            </Flex>
          </Tooltip>
        ),
      },
      {
        key: 'add-on',
        icon: (
          <Flex align='center' style={{ height: 40 }}>
            <IconLib icon={labsMenuAddOn} size='lg' color='none' />
          </Flex>
        ),
        label: t('menu_toolbox'),
        onClick: () => navigate(`/${ADD_ON_ROUTE}`),
      },
    ]

    if (!user.isDemo) {
      mainMenuItems.unshift({
        key: 'getting-started',
        icon: (
          <Flex align='center' style={{ height: 40 }}>
            <IconLib icon={labsMenuDashboard} size='lg' color='none' />
          </Flex>
        ),
        label: t('menu_getting_started'),
        // label: t('menu_getting_started'),
        onClick: () => navigate(`/${GETTING_STARTED_ROUTE}`),
      })
    }

    return mainMenuItems
  }, [user, t])

  const toolboxMenuItems = useMemo(() => {
    const menuItems: MenuItem[] = []

    if (hasCreativeHubPermission)
      menuItems.push({
        key: 'creative-hub',
        icon: (
          <Flex align='center' style={{ height: 40 }}>
            <IconLib icon={labsMenuAdsInspiration} size='lg' color='none' />
          </Flex>
        ),
        label: t('menu_creative_hub'),
        onClick: () => navigate(`/${ADS_INSPIRATION_ROUTE}`),
      })

    if (hasGameManagementPermission)
      menuItems.push({
        key: 'game-management',
        icon: (
          <Flex align='center' style={{ height: 40 }}>
            <IconLib icon={labsGameManagement} size='lg' color='none' />
          </Flex>
        ),
        label: t('game_management'),
        onClick: () => navigate(`/${GAME_MANAGEMENT_ROUTE}`),
      })

    if (hasInfrastructurePermission)
      menuItems.push({
        key: 'infrastructure',
        icon: (
          <Flex align='center' style={{ height: 40 }}>
            <IconLib icon={labsMenuInfrastructure} size='lg' color='none' />
          </Flex>
        ),
        label: t('menu_infrastructure'),
        onClick: () => navigate(`/${INFRASTRUCTURE_ROUTE}`),
      })

    return menuItems
  }, [hasCreativeHubPermission, hasGameManagementPermission, hasInfrastructurePermission, t])

  const internalMenuItems: MenuItem[] = [
    {
      key: 'game-analytics',
      label: t('menu_game_analytics'),
      icon: (
        <Flex align='center' style={{ height: 40 }}>
          {/* <IconLib icon={labsMenuGameAnalytics} size='lg' color='none' /> */}
          <IconLib icon={labsWarningCircle} color='danger' size='base' />
        </Flex>
      ),
      onTitleClick: handleShowInternalGameDashboardGroup,
      children: [
        {
          key: 'game-analytics/daily-monitoring',
          label: t('menu_daily_monitoring_game_analytics'),
          onClick: () => navigate(`/${DAILY_MONITORING_GAME_ANALYTICS_ROUTE}`),
        },
        {
          key: 'game-analytics/comparison',
          label: t('menu_comparison_game_analytics'),
          onClick: () => navigate(`/${COMPARISON_GAME_ANALYTICS_ROUTE}`),
        },
      ],
    },
    {
      key: 'marketing-analytics',
      label: t('menu_marketing_analytics'),
      icon: (
        <Flex align='center' style={{ height: 40 }}>
          {/* <IconLib icon={labsMenuAdsAnalytics} size='lg' color='none' /> */}
          <IconLib icon={labsWarningCircle} color='danger' size='base' />
        </Flex>
      ),
      onTitleClick: handleShowInternalMarketingDashboardGroup,
      children: [
        {
          key: 'marketing-analytics/daily-marketing',
          label: t('menu_daily_marketing_marketing_analytics'),
          onClick: () => navigate(`/${DAILY_MARKETING_MARKETING_ANALYTICS_ROUTE}`),
        },
        {
          key: 'marketing-analytics/retention',
          label: t('menu_retention_marketing_analytics'),
          onClick: () => navigate(`/${RETENTION_MARKETING_ANALYTICS_ROUTE}`),
        },
      ],
    },
  ]

  const footerMenuItems: MenuItem[] = [
    {
      key: 'integration',
      icon: (
        <Flex align='center' style={{ height: 40 }}>
          <IconLib icon={labsMenuIntegration} size='lg' color='none' />
        </Flex>
      ),
      label: t('menu_integration'),
      onClick: () => navigate(`/${INTEGRATION_ROUTE}`),
    },
    {
      key: 'documentation',
      icon: (
        <Flex align='center' style={{ height: 40 }}>
          <IconLib icon={labsMenuDocumentation} size='lg' color='none' />
        </Flex>
      ),
      label: t('menu_documentation'),
      onClick: () => window.open(`https://noctua-labs.gitbook.io/noctua-labs`, '_blank'),
    },
    {
      key: 'labs-support',
      icon: (
        <Flex align='center' style={{ height: 40 }}>
          <IconLib icon={labsSupport} size='lg' color='none' />
        </Flex>
      ),
      label: t('menu_labs_support'),
      onClick: showLabsSupportModal,
    },
  ]

  return (
    <Sider
      className='side-navigation'
      trigger={null}
      collapsible
      collapsed={!isExpanded}
      style={navigationStyle}
      collapsedWidth={64}
      width={220}>
      <Flex
        vertical
        gap={20}
        style={{
          position: 'sticky',
          top: 76,
          height: 'calc(100vh - 100px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
        justify='space-between'>
        <Flex vertical gap={40}>
          <Menu
            selectedKeys={[activeNavigation]}
            defaultOpenKeys={openedKeys}
            mode='inline'
            inlineCollapsed={!isExpanded}
            items={mainMenu}
            style={{
              fontWeight: 500,
              textAlign: 'left',
              border: 0,
              padding: isExpanded ? '0 6px' : 0,
            }}
          />

          {!!toolboxMenuItems.length && (
            <Flex vertical gap={20} style={{ position: 'relative', paddingTop: 20 }}>
              <Text
                style={{
                  display: isExpanded ? 'block' : 'none',
                  textAlign: 'left',
                  marginLeft: 20,
                  color: colors.gray60,
                  fontSize: 12,
                  position: 'absolute',
                  top: 0,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}>
                Toolbox
              </Text>
              <Menu
                selectedKeys={[activeNavigation]}
                mode='inline'
                inlineCollapsed={!isExpanded}
                items={toolboxMenuItems}
                style={{
                  fontWeight: 500,
                  textAlign: 'left',
                  border: 0,
                  padding: isExpanded ? '0 6px' : 0,
                }}
              />
            </Flex>
          )}

          {(isHaveGameAnalyticsPermission || isHaveMarketingAnalyticsPermission) && (
            <Flex vertical gap={20} style={{ position: 'relative', paddingTop: 20 }}>
              <Text
                style={{
                  display: isExpanded ? 'block' : 'none',
                  textAlign: 'left',
                  marginLeft: 20,
                  color: colors.gray60,
                  fontSize: 12,
                  position: 'absolute',
                  top: 0,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}>
                {t('side_navigation_internal_use_only_title')}
              </Text>
              <Menu
                defaultOpenKeys={openedKeys}
                selectedKeys={[activeNavigation]}
                mode='inline'
                inlineCollapsed={!isExpanded}
                items={internalMenuItems}
                style={{
                  fontWeight: 500,
                  textAlign: 'left',
                  border: 0,
                  padding: isExpanded ? '0 6px' : 0,
                }}
              />
            </Flex>
          )}
        </Flex>

        <Menu
          selectedKeys={[activeNavigation]}
          mode='inline'
          inlineCollapsed={!isExpanded}
          items={footerMenuItems}
          style={{
            fontWeight: 500,
            textAlign: 'left',
            border: 0,
            padding: isExpanded ? '0 6px' : 0,
          }}
        />
      </Flex>
    </Sider>
  )
}
