import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import FullScreenLoader from 'pages/fullscreen-loader'

import AuthenticatedRoute from 'components/routes/AuthenticatedRoute'
import PermissionGuardedRoute from 'components/routes/PermissionGuardedRoute'

import { GamePublisherUserPermissionEnum } from 'types/permission'

const IntegrationPage = lazy(() => import('pages/integration'))
const IntegrationBasicScalePage = lazy(() => import('pages/integration/basic-scale-integration'))
const IntegrationBasicScaleCheckerPage = lazy(
  () => import('pages/integration/basic-scale-integration/integration-checker'),
)
const IntegrationBasicScaleFirebaseInvitePage = lazy(
  () => import('pages/integration/basic-scale-integration/firebase-invite'),
)
const IntegrationBasicScaleAdjustInvitePage = lazy(
  () => import('pages/integration/basic-scale-integration/adjust-invite'),
)
const IntegrationBasicScaleAddBigQueryPage = lazy(
  () => import('pages/integration/basic-scale-integration/add-bigquery'),
)
const IntegrationFacebookPage = lazy(() => import('pages/integration/facebook'))
const IntegrationAdMobPage = lazy(() => import('pages/integration/admob'))
const IntegrationAdMobCheckerPage = lazy(
  () => import('pages/integration/admob/integration-checker'),
)

const IntegrationRouter = (
  <Route path='integration'>
    <Route
      path=''
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <PermissionGuardedRoute
              permission={GamePublisherUserPermissionEnum.VIEW_PAGE_INTEGRATION}>
              <IntegrationPage />
            </PermissionGuardedRoute>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
    <Route path='basic-scale-integration'>
      <Route
        path=''
        element={
          <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
            <AuthenticatedRoute>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_PAGE_INTEGRATION}>
                <IntegrationBasicScalePage />
              </PermissionGuardedRoute>
            </AuthenticatedRoute>
          </Suspense>
        }
      />
      <Route
        path='integration-checker'
        element={
          <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
            <AuthenticatedRoute>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_PAGE_INTEGRATION}>
                <IntegrationBasicScaleCheckerPage />
              </PermissionGuardedRoute>
            </AuthenticatedRoute>
          </Suspense>
        }
      />
      <Route
        path='adjust-invite'
        element={
          <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
            <AuthenticatedRoute>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_PAGE_INTEGRATION}>
                <IntegrationBasicScaleAdjustInvitePage />
              </PermissionGuardedRoute>
            </AuthenticatedRoute>
          </Suspense>
        }
      />
      <Route
        path='firebase-invite'
        element={
          <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
            <AuthenticatedRoute>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_PAGE_INTEGRATION}>
                <IntegrationBasicScaleFirebaseInvitePage />
              </PermissionGuardedRoute>
            </AuthenticatedRoute>
          </Suspense>
        }
      />
      <Route
        path='add-bigquery'
        element={
          <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
            <AuthenticatedRoute>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_PAGE_INTEGRATION}>
                <IntegrationBasicScaleAddBigQueryPage />
              </PermissionGuardedRoute>
            </AuthenticatedRoute>
          </Suspense>
        }
      />
    </Route>
    <Route
      path='facebook-integration'
      element={
        <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
          <AuthenticatedRoute>
            <PermissionGuardedRoute
              permission={GamePublisherUserPermissionEnum.VIEW_PAGE_INTEGRATION}>
              <IntegrationFacebookPage />
            </PermissionGuardedRoute>
          </AuthenticatedRoute>
        </Suspense>
      }
    />
    <Route path='admob-integration'>
      <Route
        path=''
        element={
          <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
            <AuthenticatedRoute>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_PAGE_INTEGRATION}>
                <IntegrationAdMobPage />
              </PermissionGuardedRoute>
            </AuthenticatedRoute>
          </Suspense>
        }
      />

      <Route
        path='integration-checker'
        element={
          <Suspense fallback={<FullScreenLoader isAuthenticated={true} />}>
            <AuthenticatedRoute>
              <PermissionGuardedRoute
                permission={GamePublisherUserPermissionEnum.VIEW_PAGE_INTEGRATION}>
                <IntegrationAdMobCheckerPage />
              </PermissionGuardedRoute>
            </AuthenticatedRoute>
          </Suspense>
        }
      />
    </Route>
  </Route>
)

export default IntegrationRouter
