import { Button, Flex, Modal, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import useModalStore from 'stores/useModalStore'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function NotAllowedModal() {
  const { t } = useTranslation()
  const { isNotAllowedModalShow, closeAll } = useModalStore()

  return (
    <Modal
      title={false}
      centered
      open={isNotAllowedModalShow}
      onCancel={closeAll}
      footer={false}
      width={550}>
      <Flex
        vertical
        justify='center'
        align='center'
        gap={20}
        style={{ padding: '30px 0px 10px 0px' }}>
        <img src='/images/icon/warning-not-allowed-icon.webp' width={100} height={100} />
        <Space direction='vertical' align='center' size={10} style={{ textAlign: 'center' }}>
          <Title level={4} style={{ color: colors.gray90, margin: 0, fontWeight: 500 }}>
            {t('not_allowed_modal_title')}
          </Title>
          <Text style={{ color: colors.gray70 }}>
            {t('not_allowed_modal_explanation_1')} <br /> {t('not_allowed_modal_explanation_2')}
          </Text>
        </Space>
        <Button
          type='primary'
          onClick={closeAll}
          style={{
            marginTop: 10,
            padding: 0,
            justifyContent: 'center',
            width: 78,
            height: 36,
          }}>
          {t('general_got_it')}
        </Button>
      </Flex>
    </Modal>
  )
}
