const colors = {
  primary: '#5099FC',
  secondary: '#042033',
  white: '#FFFFFF',
  black: '#000000',

  gray10: '#FCFCFC',
  gray20: '#F5F5F5',
  gray30: '#E7E7E7',
  gray40: '#D3D3D3',
  gray50: '#BFBFBF',
  gray60: '#AAAAAA',
  gray70: '#818181',
  gray80: '#595757',
  gray90: '#444242',
  gray100: '#242424',
  greenPass100: '#042033',
  greenPass90: '#30540F',
  greenPass80: '#73C32A',
  greenPass70: '#94E14B',
  greenPass50: '#BEF286',
  greenPass30: '#DDF6C1',
  greenPass20: '#E9F3E1',
  greenPass10: '#F6F9F3',
  greenAIDesc: '#7AE095',
  greenAITitle: '#37B1A6',
  labs10: '#EBF5FF',
  labs20: '#DAEDFF',
  labs30: '#C8E4FF',
  labs40: '#86B5F4',
  labs50: '#6C9DE0',
  labs60: '#527CB5',
  labs70: '#426BA4',
  labs80: '#1E4E90',
  labs90: '#04387E',
  labs100: '#00285F',
  labs110: '#65A7FF',
  labs120: '#3685F1',
  labs130: '#0A54BB',
  labs140: '#06459B',
  navy10: '#F6FBFF',
  navy20: '#E2F1FF',
  navy30: '#CEE7FF',
  navy60: '#5884AE',
  navy70: '#25496A',
  navy80: '#142F48',
  steel10: '#F6FBFF',
  steel30: '#DCEEFF',
  steel40: '#CDE7FF',
  steel50: '#99B6D0',
  steel60: '#7E9AB2',
  steel70: '#647E95',
  steel90: '#374A5B',
  steel100: '#222E39',

  yellow10: '#FFF8E6',
  yellow40: '#FFD65F',
  yellow60: '#FBBC05',
  yellow70: '#D29C00',
  yellow80: '#A97E00',

  violet10: '#F2F2FF',
  violet20: '#D6D6FF',
  violet30: '#B9B9FF',
  violet50: '#6161D8',
  violet60: '#6161D8',
  violet70: '#4848C1',
  violet80: '#323294',
  violet90: '#202072',

  success10: '#EFFFF5',
  success20: '#D2FFE4',
  success30: '#9AFFC2',
  success40: '#7DFFB1',
  success50: '#49B274',
  success60: '#38C872',
  success70: '#1C9F51',

  red10: '#FFF0F0',
  red20: '#FFCECE',
  red50: '#FB6767',
  red60: '#D94D4D',
  red70: '#B73737',
  red80: '#952525',

  heatMapRed: '#BD0026',
  heatMapOrange: '#FD8D3C',
  heatMapYellow: '#FFEDA0',
  heatMapGray: '#BFBFBF',

  heatMapRedHover: '#E13F5F',
  heatMapOrangeHover: '#FBAA6F',
  heatMapYellowHover: '#FFF4C6',
}

export default colors
