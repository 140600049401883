import { Flex, Image } from 'antd'
import { useMemo } from 'react'

import IconLib from 'components/commons/icon-lib'
import { LabsIconBase } from 'components/commons/icon-lib/icons/base'
import { labsAndroid, labsApple, LabsIconColored } from 'components/commons/icon-lib/icons/colored'

import { IIntegrationIcon } from './type'

export default function IntegrationIcon({ product, platform, size }: IIntegrationIcon) {
  const productIcon: string | null = useMemo(() => {
    if (product === 'adjust') return '/images/icon/adjust.webp'
    else if (product === 'firebase') return '/images/icon/firebase.webp'
    else if (product === 'facebook') return '/images/icon/facebook.webp'
    else if (product === 'admob') return '/images/icon/admob.webp'
    return null
  }, [product])

  const platformIcon: LabsIconBase | LabsIconColored | null = useMemo(() => {
    if (platform === 'Android') return labsAndroid
    else if (platform === 'iOS') return labsApple
    return null
  }, [platform])

  return (
    <div style={{ position: 'relative' }}>
      {productIcon && <Image src={productIcon} preview={false} height={size} width={size} />}
      {platformIcon && (
        <div
          style={{
            position: 'absolute',
            bottom: 2,
            right: 2,
            height: 12,
            width: 12,
            borderRadius: 9999,
            backgroundColor: 'white',
          }}>
          <Flex align='center' justify='center'>
            <IconLib icon={platformIcon} size='xs' />
          </Flex>
        </div>
      )}
    </div>
  )
}
