import { JwtTokenErrorCode } from '__generated__/gql/graphql'
import { Button, Flex, Form, message, Modal, Typography } from 'antd'
import { RuleObject } from 'antd/es/form'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { isValidEmail } from 'utils/StringUtils'

import useModalStore from 'stores/useModalStore'

import { formatTime, useCountdown } from 'hooks/useCountdown'
import useForgotPassword from 'hooks/useForgotPassword'
import useTracker from 'hooks/useTracker'

import InputText from 'components/commons/form/input-text'

import colors from 'theme/colors'

import InputGroup from '../../form/input-group/index'

// Disable for 5 minutes
const TIME_COUNTDOWN = 5 * 60 * 1000
const { Text, Title } = Typography
export type ForgotPasswordFormData = {
  email: string
}

export default function ForgotPasswordModal() {
  const { track } = useTracker()
  const { isForgotPasswordModalShow, closeAll } = useModalStore()
  const location = useLocation()
  const { pathname } = location
  const isDisabled = pathname !== '/authentication'
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false)
  const email = Form.useWatch(['email'], form)
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false)
  const { getTime, startCountdown, resetCountdown } = useCountdown(TIME_COUNTDOWN)
  const [timeLeft, setTimeLeft] = useState<number>(0)
  const { requestResetPassword, requestResetPasswordLoading } = useForgotPassword()

  const validateEmail = async (_: RuleObject, value: string) => {
    if (!value) {
      setIsEmailValid(false)
      return Promise.reject(t('validate_input_email_required_message'))
    } else if (!isValidEmail(value)) {
      setIsEmailValid(false)
      return Promise.reject(t('validate_input_valid_email_message'))
    } else {
      setIsEmailValid(true)
      return Promise.resolve()
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTime())
    }, 1000)

    return () => clearInterval(timer)
  }, [getTime])

  const callbackCountdown = useCallback(() => {
    setIsResendDisabled(false)
    resetCountdown()
  }, [setIsResendDisabled, resetCountdown])

  const sendRequest = useCallback(() => {
    if (!email) {
      message.error(t('verification_email_email_not_found_message'))
      return
    }

    setIsLoading(true)
    track('send_token_reset_password_request', {
      type: 'click',
      location: 'reset_password_form',
      location_type: 'button',
    })
    requestResetPassword({
      variables: {
        email: email,
      },
    })
      .then((res) => {
        if (res.errors || !res.data) {
          message.error(t('failed_to_send_request_general_message'))
          return
        }

        const errCode = res.data.sendResetPasswordRequest.errorCode
        if (errCode && errCode === JwtTokenErrorCode.CoolingDown) {
          message.error(t('reset_password_just_changed_password_message'))
          return
        }

        track('send_email_verification_forgot_password_success', {
          type: 'success',
          location: 'forgot_password_form',
        })

        message.success(t('email_verification_form_success_send_nofitication'))
        setIsResendDisabled(true)
        setTimeLeft(TIME_COUNTDOWN)
        startCountdown(callbackCountdown)
        setTimeout(() => {
          closeAll()
        }, 2000)
      })
      .catch((err) => {
        console.error(err)
        message.error(t('failed_to_send_request_general_message'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [track, requestResetPassword, callbackCountdown, email])

  return (
    <Modal
      title={false}
      open={isForgotPasswordModalShow}
      footer={false}
      centered
      width={467}
      onCancel={() => closeAll()}>
      <Form<ForgotPasswordFormData>
        name='forgot-password-form'
        form={form}
        onFinish={sendRequest}
        initialValues={{
          email: '',
        }}
        disabled={isLoading}
        style={{
          width: '100%',
          padding: '15px 5px 0px 5px',
        }}>
        <Flex gap={24} vertical justify='center'>
          <Flex vertical gap={7} align='center' justify='center'>
            <Title level={4} style={{ margin: 0, color: colors.gray90 }}>
              {t('reset_password_title')}
            </Title>
            <Text style={{ textAlign: 'center', color: colors.gray70 }}>
              {t('reset_password_description')}
            </Text>
          </Flex>
          <Flex gap={20} vertical justify='center'>
            <InputGroup<ForgotPasswordFormData>
              name='email'
              title={t('login_form_email_field_label')}
              rules={[
                {
                  required: true,
                  validator: validateEmail,
                },
              ]}>
              <InputText
                className='input-secondary'
                variant='secondary'
                placeholder={t('login_form_email_field_placeholder')}
                size='large'
                autoFocus
                disabled={isDisabled}
              />
            </InputGroup>
            <Flex justify='center'>
              <Button
                type='primary'
                form='forgot-password-form'
                htmlType='submit'
                style={{
                  width: 176,
                  height: 38,
                }}
                loading={isLoading || requestResetPasswordLoading}
                disabled={isLoading || isDisabled || !isEmailValid || isResendDisabled}>
                {t('general_submit')} {isResendDisabled ? `(${formatTime(timeLeft)})` : ``}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  )
}
