import { Flex, Image, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import colors from 'theme/colors'

const { Title, Text } = Typography

export default function NotificationNoData() {
  const { t } = useTranslation()

  return (
    <Flex vertical align='center' gap={12}>
      <Image
        src='/images/illustration/no-notification.webp'
        preview={false}
        width={100}
        height={100}
      />
      <Flex vertical align='center' gap={4}>
        <Title level={4} style={{ fontWeight: 700, color: colors.gray90, margin: 0 }}>
          {t('notification_no_notification_title')}
        </Title>
        <Text style={{ color: colors.gray70 }}>
          {t('notification_no_notification_description')}
        </Text>
      </Flex>
    </Flex>
  )
}
