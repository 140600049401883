import { GlobalOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import React from 'react'

import { PHONE_NUMBER_COUNTRY_CODE } from 'constants/Form'

import colors from 'theme/colors'

import { ICountryFlag } from './type'

const CountryFlag = ({ id, height = 16, width = 19, name = '' }: ICountryFlag) => {
  if (id === 'SEA' || id === 'ZZ' || id === 'MY-SG')
    return (
      <img
        src='/images/icon/sea.png'
        height={height}
        width={width}
        style={{ objectFit: 'contain' }}
      />
    )

  const countryCode = id || PHONE_NUMBER_COUNTRY_CODE.find((country) => country.name === name)?.code

  if (id === 'WW' || !countryCode)
    return (
      <Flex justify='center' style={{ width: width }}>
        <GlobalOutlined style={{ color: colors.gray90 }} />
      </Flex>
    )

  return (
    <img
      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
      height={height}
      width={width}
      alt={name}
    />
  )
}

export default React.memo(CountryFlag)
