import colors from 'theme/colors'

import { Color, Size } from './type'

export const colorStyles: { [k in Color]: string } = {
  none: 'inherit',
  transparent: 'transparent',
  base: colors.gray70,
  primary: colors.labs40,
  labs10: colors.labs10,
  labs30: colors.labs30,
  labs40: colors.labs40,
  labs90: colors.labs90,
  labs120: colors.labs120,
  labs140: colors.labs140,
  gray10: colors.gray10,
  gray70: colors.gray70,
  gray80: colors.gray80,
  gray90: colors.gray90,
  yellow70: colors.yellow70,
  yellow80: colors.yellow80,
  success70: colors.success70,
  'green-pass-50': colors.greenPass50,
  danger: colors.red70,
  success30: colors.success30,
  success50: colors.success50,
}

export const sizeStyles: { [k in Size]: number } = {
  '2xl': 45,
  xl: 32,
  lg: 24,
  base: 20,
  sm: 16,
  xs: 12,
}
