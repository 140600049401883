import { InfoCircleOutlined } from '@ant-design/icons'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  CreateActivityHistoriesMutation,
  CreateActivityHistoriesMutationVariables,
  GetGamePublisherUserQuery,
  GetGamePublisherUserQueryVariables,
  UpdateGameDailyBudgetMutation,
  UpdateGameDailyBudgetMutationVariables,
} from '__generated__/gql/graphql'
import { Alert, Button, Card, Flex, Modal, Slider, Spin, Switch, Tooltip, Typography } from 'antd'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createActivityHistories, updateGameDailyBudgetMutations } from 'services/graphql/mutations'
import { getGamePublisherUser } from 'services/graphql/queries'

import { COOKIE_KEY_AUTH_TOKEN } from 'constants/CookieKeys'
import {
  ANDROID_MIN_DAILY_BUDGET,
  ANDROID_RECOMMENDED_DAILY_BUDGET,
  IOS_MIN_DAILY_BUDGET,
  IOS_RECOMMENDED_DAILY_BUDGET,
  MIN_TOTAL_DAILY_BUDGET,
  MIN_TOTAL_DAYS_PERFORMING_ADS,
  RECOMMENDED_TOTAL_DAILY_BUDGET,
} from 'constants/Form'

import { formatCurrency } from 'utils/FormatterUtils'

import useModalStore from 'stores/useModalStore'
import useUserStore from 'stores/useUserStore'

import useTracker from 'hooks/useTracker'
import useUserInfo from 'hooks/useUserInfo'

import InputNumber from 'components/commons/form/input-number'
import IconLib from 'components/commons/icon-lib'
import { labsInfoCircleOutline } from 'components/commons/icon-lib/icons/base'
import { labsBoosted } from 'components/commons/icon-lib/icons/colored'

import colors from 'theme/colors'

const { Text } = Typography

export default function SetDailyBudgetModal() {
  const { t } = useTranslation()

  const { track } = useTracker()
  const { getGames } = useUserInfo()
  const { selectedGame, user } = useUserStore()
  const {
    isSetDailyBudgetModalShow,
    isPublishIntended,
    hideSetDailyBudgetModal,
    showReviewCreativesModal,
  } = useModalStore()

  const { data: gamePublisher, loading: isGetGameLoading } = useQuery<
    GetGamePublisherUserQuery,
    GetGamePublisherUserQueryVariables
  >(getGamePublisherUser, {
    skip: !Cookies.get(COOKIE_KEY_AUTH_TOKEN),
  })
  const client = useApolloClient()

  const game = useMemo(() => {
    const game = gamePublisher?.gamePublisherUser?.gamePublisher?.games.find(
      (game) => game.name === selectedGame.name,
    )

    return {
      daily_budget: game?.dailyBudget || 0,
      ios_daily_budget: game?.iosDailyBudget || 0,
      android_daily_budget: game?.androidDailyBudget || 0,
      appStoreUrl: game?.appStoreUrl,
      playStoreUrl: game?.playStoreUrl,
    }
  }, [gamePublisher])

  const [isBudgetAllocationAuto, setIsBudgetAllocationAuto] = useState<boolean>(true)
  const [totalBudget, setTotalBudget] = useState<number>(0)
  const [iosBudget, setIosBudget] = useState<number>(0)
  const [androidBudget, setAndroidBudget] = useState<number>(0)

  const hasMultiplePlatform = useMemo(() => {
    return !!game.appStoreUrl && !!game.playStoreUrl
  }, [game.appStoreUrl, game.playStoreUrl])

  const availableToBoost: boolean = useMemo(() => {
    if (game.playStoreUrl) return true
    if (isBudgetAllocationAuto) return true
    return false
  }, [game.appStoreUrl, game.playStoreUrl])

  const isBudgetBoosted: boolean = useMemo(() => {
    if (availableToBoost) {
      if (
        hasMultiplePlatform &&
        isBudgetAllocationAuto &&
        totalBudget >= RECOMMENDED_TOTAL_DAILY_BUDGET
      )
        return true
      else if (!hasMultiplePlatform && totalBudget >= ANDROID_RECOMMENDED_DAILY_BUDGET) return true
    }

    return false
  }, [
    totalBudget,
    hasMultiplePlatform,
    game.appStoreUrl,
    game.playStoreUrl,
    isBudgetAllocationAuto,
  ])

  const minBudget = useMemo(() => {
    if (game.appStoreUrl && !game.playStoreUrl) return IOS_MIN_DAILY_BUDGET
    else if (!game.appStoreUrl && game.playStoreUrl) return ANDROID_MIN_DAILY_BUDGET
    else return MIN_TOTAL_DAILY_BUDGET
  }, [game.appStoreUrl, game.playStoreUrl])

  const recommendBudget = useMemo(() => {
    if (game.appStoreUrl && !game.playStoreUrl) return IOS_RECOMMENDED_DAILY_BUDGET
    else if (!game.appStoreUrl && game.playStoreUrl) return ANDROID_RECOMMENDED_DAILY_BUDGET
    else return RECOMMENDED_TOTAL_DAILY_BUDGET
  }, [game.appStoreUrl, game.playStoreUrl])

  const isSubmitDisabled: boolean = useMemo(() => {
    if (!isBudgetAllocationAuto) {
      if (iosBudget < IOS_MIN_DAILY_BUDGET || androidBudget < ANDROID_MIN_DAILY_BUDGET) return true
    }

    if (totalBudget < minBudget) return true
    if (totalBudget > user?.balance / MIN_TOTAL_DAYS_PERFORMING_ADS) return true

    return false
  }, [
    iosBudget,
    androidBudget,
    totalBudget,
    isBudgetAllocationAuto,
    minBudget,
    game.appStoreUrl,
    game.playStoreUrl,
  ])

  const inputBorderColor: string = useMemo(() => {
    if (totalBudget < minBudget) return colors.red70
    else if (totalBudget > user?.balance / MIN_TOTAL_DAYS_PERFORMING_ADS) return colors.red70
    else if (isBudgetBoosted) return colors.success70
    else return colors.gray40
  }, [
    game.appStoreUrl,
    game.playStoreUrl,
    minBudget,
    totalBudget,
    isBudgetBoosted,
    hasMultiplePlatform,
    isBudgetAllocationAuto,
  ])

  useEffect(() => {
    setTotalBudget(
      game.daily_budget || user?.balance / MIN_TOTAL_DAYS_PERFORMING_ADS || recommendBudget,
    )
    setAndroidBudget(game.android_daily_budget)
    setIosBudget(game.ios_daily_budget)
  }, [
    recommendBudget,
    game.appStoreUrl,
    game.playStoreUrl,
    game.daily_budget,
    game.ios_daily_budget,
    game.android_daily_budget,
  ])

  useEffect(() => {
    if (game.daily_budget) return
    if (totalBudget / 2 < IOS_MIN_DAILY_BUDGET) {
      setIosBudget(IOS_MIN_DAILY_BUDGET)
      setAndroidBudget(totalBudget - IOS_MIN_DAILY_BUDGET)
    } else {
      setIosBudget(totalBudget / 2)
      setAndroidBudget(totalBudget / 2)
    }
  }, [game.daily_budget, totalBudget])

  const [setDailyBudget, { loading }] = useMutation<
    UpdateGameDailyBudgetMutation,
    UpdateGameDailyBudgetMutationVariables
  >(updateGameDailyBudgetMutations)

  const handleToggleAutoAllocation = useCallback(() => {
    track('toggle_automated_budget_allocation', {
      type: 'click',
      location: 'budget_allocation_card',
      location_type: 'button',
      value: !isBudgetAllocationAuto,
    })
    setIsBudgetAllocationAuto((prev) => !prev)

    if (totalBudget > minBudget && iosBudget === 0) {
      setIosBudget(IOS_MIN_DAILY_BUDGET)
      setAndroidBudget(totalBudget - IOS_MIN_DAILY_BUDGET)
    }
  }, [totalBudget, iosBudget, androidBudget, minBudget, game.appStoreUrl, game.playStoreUrl])

  const validateBudget = useCallback(() => {
    if (totalBudget < MIN_TOTAL_DAILY_BUDGET) return false
    else if (iosBudget < IOS_MIN_DAILY_BUDGET) return false
    else if (androidBudget < ANDROID_MIN_DAILY_BUDGET) return false
    else return true
  }, [totalBudget, iosBudget, androidBudget])

  const handleChangeSlider = useCallback(
    (value: number) => {
      setIosBudget(value)
      setAndroidBudget(totalBudget - value)
    },
    [totalBudget],
  )

  const onDailyBudgetTooltipShow = (visible: boolean) => {
    if (visible) {
      track('show_daily_budget_limit_tooltip', {
        type: 'hover',
        location: 'daily_budget_setup_modal',
        location_type: 'icon',
      })
    }
  }

  const onBudgetAllocationTooltipShow = (visible: boolean) => {
    if (visible) {
      track('show_automated_budget_allocation_tooltip', {
        type: 'hover',
        location: 'daily_budget_setup_modal',
        location_type: 'icon',
      })
    }
  }

  const [createActivities] = useMutation<
    CreateActivityHistoriesMutation,
    CreateActivityHistoriesMutationVariables
  >(createActivityHistories)

  const handleCancel = useCallback(() => {
    hideSetDailyBudgetModal()
    if (isPublishIntended) showReviewCreativesModal()
  }, [isPublishIntended])

  const handleSubmit = useCallback(() => {
    if (!isBudgetAllocationAuto && !validateBudget()) return

    track('set_daily_budget_attempt', {
      type: 'click',
      location: 'daily_budget_setup_modal',
      location_type: 'button',
    })
    setDailyBudget({
      variables: {
        gameId: selectedGame.id,
        dailyBudget: totalBudget,
        androidDailyBudget:
          isBudgetAllocationAuto && hasMultiplePlatform
            ? 0
            : hasMultiplePlatform
            ? androidBudget
            : game.playStoreUrl
            ? totalBudget
            : 0,
        iosDailyBudget:
          isBudgetAllocationAuto && hasMultiplePlatform
            ? 0
            : hasMultiplePlatform
            ? iosBudget
            : game.appStoreUrl
            ? totalBudget
            : 0,
        isAutoBudgetAllocation: hasMultiplePlatform ? isBudgetAllocationAuto : false,
      },
    }).then(() => {
      track('set_daily_budget_success', {
        type: 'success',
        location: 'daily_budget_setup_modal',
        location_type: 'button',
      })
      getGames()
      hideSetDailyBudgetModal()
      if (isPublishIntended) showReviewCreativesModal()
    })
  }, [
    hasMultiplePlatform,
    setDailyBudget,
    totalBudget,
    androidBudget,
    iosBudget,
    isBudgetAllocationAuto,
    isPublishIntended,
  ])

  return (
    <Modal
      centered
      open={isSetDailyBudgetModalShow}
      title={false}
      cancelText={false}
      onCancel={handleCancel}
      footer={false}
      width={806}>
      <Flex
        vertical
        justify='center'
        gap={28}
        style={{ paddingTop: 40, paddingLeft: 36, paddingRight: 36 }}>
        <Flex
          vertical
          align='center'
          justify='center'
          gap={12}
          style={{ paddingLeft: 128, paddingRight: 128 }}>
          <Text style={{ color: colors.gray90, fontWeight: 'bold' }}>
            {t('set_daily_budget_modal_title')}
          </Text>
        </Flex>

        <Alert
          style={{
            border: 0,
            background: colors.yellow10,
            padding: '10px 24px 10px 12px',
            borderRadius: 8,
          }}
          message={
            <Flex align='center' gap={12}>
              <Flex flex={0}>
                <IconLib icon={labsInfoCircleOutline} color='yellow70' size='base' />
              </Flex>
              <Flex flex={1}>
                <Text style={{ fontSize: 12, color: colors.yellow70 }}>
                  {t('set_daily_budget_modal_information')}
                </Text>
              </Flex>
            </Flex>
          }
        />
        {isGetGameLoading ? (
          <Flex align='center' justify='center' style={{ height: 200 }}>
            <Spin />
          </Flex>
        ) : (
          <>
            <Flex justify={hasMultiplePlatform ? 'space-between' : 'center'}>
              <Flex vertical gap={11} style={{ width: 400 }}>
                <Flex align='center' gap={4}>
                  <Text style={{ fontSize: 12, color: colors.gray90, fontWeight: 'bold' }}>
                    {t('set_daily_budget_modal_daily_budget_limit_label')}
                  </Text>

                  <Tooltip
                    overlayInnerStyle={{ padding: 14, borderRadius: 10 }}
                    title={
                      <span style={{ color: 'black', fontSize: 12 }}>
                        {t('set_daily_budget_modal_daily_budget_limit_tooltip')}
                      </span>
                    }
                    placement='top'
                    onOpenChange={onDailyBudgetTooltipShow}>
                    <InfoCircleOutlined style={{ color: colors.gray70 }} />
                  </Tooltip>
                </Flex>

                <Flex vertical gap={4}>
                  <Flex justify='space-between' gap={48} align='center'>
                    <Flex flex={1}>
                      <InputNumber
                        placeholder='Enter Amount'
                        size='large'
                        value={totalBudget}
                        type='string'
                        prefix='$'
                        suffix={
                          <Text
                            style={{
                              fontSize: 12,
                              color:
                                totalBudget > user?.balance / MIN_TOTAL_DAYS_PERFORMING_ADS
                                  ? colors.red70
                                  : colors.gray60,
                            }}>
                            Max:{' '}
                            {formatCurrency(
                              Math.floor(user?.balance / MIN_TOTAL_DAYS_PERFORMING_ADS),
                              0 || 0,
                            )}
                          </Text>
                        }
                        style={{
                          borderColor: inputBorderColor,
                          width: '100%',
                        }}
                        onChange={(e) => {
                          const value = e ? parseInt(e as string) : minBudget
                          setTotalBudget(value)
                        }}
                      />
                    </Flex>
                  </Flex>
                  <Flex gap={8} align='center'>
                    {game.appStoreUrl && !game.playStoreUrl && (
                      <Text
                        style={{
                          fontSize: 10,
                          color: totalBudget < minBudget ? colors.red70 : colors.gray60,
                        }}>
                        *{t('set_daily_budget_modal_daily_budget_validation_minimum')} $
                        {IOS_MIN_DAILY_BUDGET}{' '}
                        {t('set_daily_budget_modal_daily_budget_validation_recommended')} $
                        {IOS_RECOMMENDED_DAILY_BUDGET}
                      </Text>
                    )}

                    {!game.appStoreUrl && game.playStoreUrl && (
                      <Text
                        style={{
                          fontSize: 10,
                          color: totalBudget < minBudget ? colors.red70 : colors.gray60,
                        }}>
                        *{t('set_daily_budget_modal_daily_budget_validation_minimum')} $
                        {ANDROID_MIN_DAILY_BUDGET}{' '}
                        {t('set_daily_budget_modal_daily_budget_validation_recommended')} $
                        {ANDROID_RECOMMENDED_DAILY_BUDGET}
                      </Text>
                    )}

                    {game.appStoreUrl && game.playStoreUrl && (
                      <Text
                        style={{
                          fontSize: 10,
                          color: totalBudget < minBudget ? colors.red70 : colors.gray60,
                        }}>
                        *{t('set_daily_budget_modal_daily_budget_validation_minimum')} $
                        {MIN_TOTAL_DAILY_BUDGET}{' '}
                        {t('set_daily_budget_modal_daily_budget_validation_recommended')} $
                        {RECOMMENDED_TOTAL_DAILY_BUDGET}
                      </Text>
                    )}

                    {availableToBoost && (
                      <Flex gap={4} align='center'>
                        <IconLib icon={labsBoosted} />
                        <Text style={{ fontSize: 10, color: colors.success70 }}>
                          Boosted Performance
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            {hasMultiplePlatform && (
              <Flex vertical gap={11}>
                <Text style={{ fontSize: 12, color: colors.gray90, fontWeight: 'bold' }}>
                  {t('set_daily_budget_modal_budget_allocation_label')}
                </Text>
                <Card size='small'>
                  <Flex vertical gap={18}>
                    <Flex align='center' justify='space-between'>
                      <Flex align='center' gap={4}>
                        <Text style={{ color: colors.gray90, fontWeight: 400 }}>
                          {t('set_daily_budget_modal_automated_budget_allocation_label')}
                        </Text>
                        <Tooltip
                          overlayInnerStyle={{ padding: 14, borderRadius: 10 }}
                          title={
                            <span style={{ color: 'black', fontSize: 12 }}>
                              {t('set_daily_budget_modal_automated_budget_allocation_tooltip')}
                            </span>
                          }
                          placement='top'
                          onOpenChange={onBudgetAllocationTooltipShow}>
                          <InfoCircleOutlined style={{ color: colors.gray70 }} />
                        </Tooltip>
                      </Flex>
                      <Switch
                        checked={isBudgetAllocationAuto}
                        onChange={handleToggleAutoAllocation}
                      />
                    </Flex>
                    {!isBudgetAllocationAuto && (
                      <Flex vertical gap={8}>
                        <Flex justify='space-between'>
                          <Flex align='center' gap={12}>
                            <Text style={{ color: colors.labs120, fontWeight: 'bold' }}>iOS</Text>
                            <Flex
                              align='center'
                              justify='center'
                              style={{
                                padding: '4px 8px',
                                borderRadius: 6,
                                backgroundColor: colors.gray30,
                              }}>
                              <Text style={{ color: colors.gray80, fontWeight: 'bold' }}>
                                {Math.round((iosBudget / totalBudget) * 100)}%
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex align='center' gap={12}>
                            <Flex
                              align='center'
                              justify='center'
                              style={{
                                padding: '4px 8px',
                                borderRadius: 6,
                                backgroundColor: colors.gray30,
                              }}>
                              <Text style={{ color: colors.gray80, fontWeight: 'bold' }}>
                                {Math.round((androidBudget / totalBudget) * 100)}%
                              </Text>
                            </Flex>
                            <Text style={{ color: colors.labs120, fontWeight: 'bold' }}>
                              Android
                            </Text>
                          </Flex>
                        </Flex>
                        <Slider
                          min={0}
                          max={totalBudget}
                          step={1}
                          value={iosBudget}
                          tooltip={{ open: false }}
                          onChange={handleChangeSlider}
                        />
                        <Flex justify='space-between'>
                          <Flex vertical gap={4}>
                            <InputNumber
                              placeholder='Enter Amount'
                              size='large'
                              value={iosBudget}
                              type='string'
                              prefix='$'
                              min={IOS_MIN_DAILY_BUDGET}
                              max={totalBudget}
                              style={{ width: 100 }}
                              onChange={(e) => {
                                const value = e ? parseInt(e as string) : IOS_MIN_DAILY_BUDGET
                                setIosBudget(value)
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 10,
                                color: colors.red60,
                                visibility: iosBudget < IOS_MIN_DAILY_BUDGET ? 'visible' : 'hidden',
                              }}>
                              *minimum ${IOS_MIN_DAILY_BUDGET}
                            </Text>
                          </Flex>
                          <Flex vertical gap={4}>
                            <InputNumber
                              placeholder='Enter Amount'
                              size='large'
                              value={androidBudget}
                              type='string'
                              prefix='$'
                              min={ANDROID_MIN_DAILY_BUDGET}
                              max={totalBudget}
                              style={{ width: 100 }}
                              onChange={(e) => {
                                const value = e ? parseInt(e as string) : ANDROID_MIN_DAILY_BUDGET
                                setAndroidBudget(value)
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 10,
                                color: colors.red60,
                                visibility:
                                  androidBudget < ANDROID_MIN_DAILY_BUDGET ? 'visible' : 'hidden',
                              }}>
                              *minimum ${ANDROID_MIN_DAILY_BUDGET}
                            </Text>
                          </Flex>
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                </Card>
              </Flex>
            )}
          </>
        )}

        <Flex justify='center'>
          <Button
            type='primary'
            size='large'
            style={{ width: 124 }}
            onClick={handleSubmit}
            disabled={isSubmitDisabled || loading}
            loading={loading}>
            {t('general_save')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}
