import {
  AdType,
  AdTypeReportFilterBy,
  AdTypeReportViewBy,
  AdUnitStatus,
  AipuCohortFIlterBy,
  MediationAnalyticDataViewBy,
  MediationFilterBy,
  MediationViewBy,
} from '__generated__/gql/graphql'
import dayjs from 'dayjs'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { MediationInitializationSetupWizardStep } from 'components/modules/mediation/initialization-setup/wizard/type'

export interface AdUnitData {
  adUnitId: string
  adUnitName: string
  adType: AdType
  rpm: number
  aipu: number
  fillRate: number
  status: AdUnitStatus
}

export interface AdUnit {
  name: string
  type: AdType
  id?: string
  status?: AdUnitStatus
}
interface MediationState {
  initializationWizardStep: MediationInitializationSetupWizardStep
  setInitializationWizardStep: (step: MediationInitializationSetupWizardStep) => void

  tempAdUnit: AdUnit[]
  setTempAdUnit: (adUnits: AdUnit[]) => void

  selectedTempAdUnit: AdUnit | null
  setSelectedTempAdUnit: (adUnit: AdUnit | null) => void

  selectedAdType: AdType | undefined
  setSelectedAdType: (adType: AdType | undefined) => void

  selectedMediationId: string | undefined
  setSelectedMediationId: (mediationId: string) => void

  selectedAdUnit: AdUnitData | undefined
  setSelectedAdUnit: (adUnit: AdUnitData | undefined) => void

  mutatedAdUnit: AdUnit[] | undefined
  setMutatedAdUnit: (adUnit: AdUnit[] | undefined) => void

  gameTargetingCountryCode: string[]
  setGameTargetingCountryCode: (countryCodes: string[]) => void

  mediationViewBy: MediationViewBy
  mediationFilterBy: MediationFilterBy
  mediationDateFilter: string[]
  aipuCohortFilterBy: AipuCohortFIlterBy
  aipuCohortDateFilter: string[]
  adTypeReportViewBy: AdTypeReportViewBy
  adTypeReportFilterBy: AdTypeReportFilterBy
  adTypeReportDateFilter: string[]
  analyticsDataViewBy: MediationAnalyticDataViewBy
  analyticsDataDateFilter: string[]
  setMediationViewBy: (mediationViewBy: MediationViewBy) => void
  setMediationDateFilter: (date: string[]) => void
  setMediationFilterBy: (mediationFilterBy: MediationFilterBy) => void
  setAipuCohortFilterBy: (aipuCohortFilterBy: AipuCohortFIlterBy) => void
  setAipuCohortDateFilter: (date: string[]) => void
  setAdTypeReportViewBy: (adTypeReportViewBy: AdTypeReportViewBy) => void
  setAdTypeReportFilterBy: (adTypeReportFilterBy: AdTypeReportFilterBy) => void
  setAdTypeReportDateFilter: (date: string[]) => void
  setAnalyticsDataViewBy: (analyticsDataViewBy: MediationAnalyticDataViewBy) => void
  setAnalyticsDataDateFilter: (analyticsDataDateFilter: string[]) => void

  resetState: () => void
}

const useMediationStore = create<MediationState>()(
  persist(
    (set) => ({
      initializationWizardStep: 'choose-template',
      tempAdUnit: [],
      selectedTempAdUnit: null,
      selectedAdType: undefined,
      selectedMediationId: undefined,
      selectedAdUnit: undefined,
      mutatedAdUnit: undefined,
      gameTargetingCountryCode: [],

      mediationViewBy: MediationViewBy.AdType,
      mediationFilterBy: MediationFilterBy.All,
      mediationDateFilter: [
        dayjs().subtract(8, 'd').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ],
      aipuCohortFilterBy: AipuCohortFIlterBy.All,
      aipuCohortDateFilter: [
        dayjs().subtract(8, 'd').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ],
      adTypeReportViewBy: AdTypeReportViewBy.AdNetwork,
      adTypeReportFilterBy: AdTypeReportFilterBy.All,
      adTypeReportDateFilter: [
        dayjs().subtract(8, 'd').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ],
      analyticsDataViewBy: MediationAnalyticDataViewBy.Country,
      analyticsDataDateFilter: [
        dayjs().subtract(8, 'd').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ],

      setInitializationWizardStep: (step: MediationInitializationSetupWizardStep) =>
        set((state) => ({ ...state, initializationWizardStep: step })),
      setTempAdUnit: (adUnits: AdUnit[]) => set((state) => ({ ...state, tempAdUnit: adUnits })),
      setSelectedTempAdUnit: (adUnit: AdUnit | null) =>
        set((state) => ({ ...state, selectedTempAdUnit: adUnit })),
      setSelectedAdType: (adType: AdType | undefined) =>
        set((state) => ({ ...state, selectedAdType: adType })),
      setSelectedMediationId: (mediationId: string) =>
        set((state) => ({ ...state, selectedMediationId: mediationId })),
      setSelectedAdUnit: (adUnit: AdUnitData | undefined) =>
        set((state) => ({
          ...state,
          selectedAdUnit: adUnit,
        })),
      setMutatedAdUnit: (adUnit: AdUnit[] | undefined) =>
        set((state) => ({ ...state, mutatedAdUnit: adUnit })),
      setGameTargetingCountryCode: (countryCodes: string[]) =>
        set((state) => ({ ...state, gameTargetingCountryCode: countryCodes })),

      setMediationViewBy: (mediationViewBy: MediationViewBy) =>
        set((state) => ({ ...state, mediationViewBy: mediationViewBy })),
      setMediationFilterBy: (mediationFilterBy: MediationFilterBy) =>
        set((state) => ({ ...state, mediationFilterBy: mediationFilterBy })),
      setMediationDateFilter: (date: string[]) =>
        set((state) => ({ ...state, mediationDateFilter: date })),
      setAipuCohortFilterBy: (aipuCohortFilterBy: AipuCohortFIlterBy) =>
        set((state) => ({ ...state, aipuCohortFilterBy: aipuCohortFilterBy })),
      setAipuCohortDateFilter: (date: string[]) =>
        set((state) => ({ ...state, aipuCohortDateFilter: date })),
      setAdTypeReportViewBy: (adTypeReportViewBy: AdTypeReportViewBy) =>
        set((state) => ({ ...state, adTypeReportViewBy: adTypeReportViewBy })),
      setAdTypeReportFilterBy: (adTypeReportFilterBy: AdTypeReportFilterBy) =>
        set((state) => ({ ...state, adTypeReportFilterBy: adTypeReportFilterBy })),
      setAdTypeReportDateFilter: (date: string[]) =>
        set((state) => ({ ...state, adTypeReportDateFilter: date })),
      setAnalyticsDataViewBy: (analyticsDataViewBy: MediationAnalyticDataViewBy) =>
        set((state) => ({ ...state, analyticsDataViewBy: analyticsDataViewBy })),
      setAnalyticsDataDateFilter: (date: string[]) =>
        set((state) => ({ ...state, analyticsDataDateFilter: date })),

      resetState: () =>
        set(() => ({
          initializationWizardStep: 'choose-template',
          tempAdUnit: [],
          selectedTempAdUnit: null,
          selectedAdType: undefined,
          selectedMediationId: undefined,
          selectedAdUnit: undefined,
          mutatedAdUnit: undefined,
          gameTargetingCountryCode: [],

          mediationViewBy: MediationViewBy.AdType,
          mediationFilterBy: MediationFilterBy.All,
          mediationDateFilter: [
            dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
            dayjs().format('YYYY-MM-DD'),
          ],
          aipuCohortFilterBy: AipuCohortFIlterBy.All,
          aipuCohortDateFilter: [
            dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
            dayjs().format('YYYY-MM-DD'),
          ],
          adTypeReportViewBy: AdTypeReportViewBy.AdNetwork,
          adTypeReportFilterBy: AdTypeReportFilterBy.All,
          adTypeReportDateFilter: [
            dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
            dayjs().format('YYYY-MM-DD'),
          ],
          analyticsDataViewBy: MediationAnalyticDataViewBy.Country,
          analyticsDataDateFilter: [
            dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
            dayjs().format('YYYY-MM-DD'),
          ],
        })),
    }),
    {
      name: 'mediation-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useMediationStore
