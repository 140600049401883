import { Button, Flex, Modal, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import useModalStore from 'stores/useModalStore'

import colors from 'theme/colors'

const { Text, Title } = Typography

export default function TopupSuccessModal() {
  const { t } = useTranslation()
  const { isTopupSuccessModalShow, closeAll } = useModalStore()

  return (
    <Modal
      title={false}
      centered
      open={isTopupSuccessModalShow}
      onCancel={closeAll}
      footer={false}
      width={663}>
      <Flex vertical justify='center' align='center' gap={20} style={{ padding: 30 }}>
        <img src='/images/illustration/topup-balance-request-icon.webp' width={100} height={100} />
        <Space direction='vertical' align='center' size={10} style={{ textAlign: 'center' }}>
          <Title level={4} style={{ color: colors.gray90, margin: 0, fontWeight: 500 }}>
            {t('balance_topup_submitted_modal_title')}
          </Title>
          <Text style={{ color: colors.gray70 }}>
            {t('balance_topup_submitted_modal_explanation')}
          </Text>
        </Space>
      </Flex>
    </Modal>
  )
}
