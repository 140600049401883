import type Entity from '@ant-design/cssinjs/es/Cache'
import { createCache, StyleProvider } from '@ant-design/cssinjs/lib'
import React from 'react'

const StyledComponentsRegistry = ({ children }: React.PropsWithChildren) => {
  const cache = React.useMemo<Entity>(() => createCache(), [])
  return <StyleProvider cache={cache}>{children}</StyleProvider>
}

export default StyledComponentsRegistry
